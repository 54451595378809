import React from "react";
import PropTypes from 'prop-types';
import changeLanguage from "../../../setting/languages/languages";

class RangeSlider extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      value: props.value,
      warning: false,
      init: false
    }
    this.textStore = changeLanguage(props.language);
    this.handleChange = this.handleChange.bind(this);
    this.handleUnfocused = this.handleUnfocused.bind(this); 
  }

  componentDidMount() {
    this.setState({init: false})
  }

  componentDidUpdate(prevState) {
    // Handle show popup when first initialize
    if (prevState.value !== this.props.value) {
      this.setState({value: this.props.value})
    }
    if (!this.state.init) {
      if (this.state.value > this.props.highRiskThreshold) {
        this.setState({init: true});
        this.props.handleUnfocused(true)
      }
    }
  }

  handleChange(e){
    const { value } = e.target
    this.setState({
      value: value,
    }, () => {
      this.props.handleValue(this.state.value)
    })
  }

  handleUnfocused(e) {
    const { value } = e.target;
    if (parseInt(value, 10) > this.props.highRiskThreshold) {
      this.props.handleUnfocused(true, value)
    }
    else {
      this.props.handleUnfocused(false, value)
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <div style={{ fontFamily: 'OpenSans', fontSize: '13px' }}>{this.textStore.rangeSlider.riskTolerance}</div>
        <div style={{ fontFamily: 'SansSemibold', fontSize: '15px', color: '#4261ff', paddingBottom: '12px' }}>
          {this.props.risk}&nbsp;
          <span style={{fontFamily: 'OpenSans', fontSize: '15px', color: '#00b961', }}>{this.props.advise ? `(${this.props.advise})`: ''}</span>
        </div>
        <div className="slider-container">
          <input 
            style={{background: 
              `linear-gradient(to right, #eaecff ${this.props.highRiskThreshold}%, red 0%`}}
            className="slider__risks" 
            id="rangeSlider" 
            type="range"
            value={this.state.value} 
            onInput={this.handleChange}
            onChange={this.handleChange}
            onTouchStart={this.handleChange}
            onMouseUp={this.handleUnfocused}
            onTouchEnd={this.handleUnfocused}/>
        </div>
        <div className="slider-text-container">
          <span className="slider-sub-text">{this.textStore.rangeSlider.veryLow}</span>
          <span className="slider-sub-text">{this.textStore.rangeSlider.veryHigh}</span>
        </div>
      </React.Fragment>
      );
  }
}

RangeSlider.defaultProps = {
  highRiskThreshold: 75,
  handleUnfocused: () => {},
}

RangeSlider.propTypes = {
  highRiskThreshold: PropTypes.number,
  handleUnfocused: PropTypes.func,
}

export default RangeSlider;
