import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withBaseLayout from "../../HoC/withBaseLayout";
import changeLanguage from "../../../setting/languages/languages";
import GoalFooter from "../../Goals/GoalFooter/GoalFooter";
import DropDown from "../../common/Dropdown/Dropdown";
import CONFIG from "../../../setting/config";
import { savePreviousUserInfo, getTestData, getCTB } from "../../../actions/Onboarding/OnboardingActions";
import { unFormatPhoneNumber, formatPhoneNumber } from "../../../helpers/formatPhoneNumber";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import Stepper from '../../Onboarding/RiskProfiling/Stepper/Stepper';
import { savePreviousScreen } from "../../../actions/Screen/ScreenAction";
import TextInput from "../../common/CustomInput/TextInput/TextInput"

import { toggleAsyncLoadingStatus } from "../../../actions/AppMain/AppMainAction";

class PersonalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canGoNext: false,
      firstName: this.props.onboardingData.userInfo.firstName,
      lastName: this.props.onboardingData.userInfo.lastName,
      selectedCountryIndex: this.props.onboardingData.userInfo.selectedCountryIndex,
      phoneNumber: formatPhoneNumber(this.props.onboardingData.userInfo.phoneNumber),
      showError: false,
      activeError: [false, false, false, false],
      activeStep: 2,
      totalSteps: 8,
    }
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.updateSelectedCountryIndex = this.updateSelectedCountryIndex.bind(this);
    this.DETAIL_CONSTANT_NAME = {
      firstName: "firstName",
      lastName: "lastName",
      phoneNumber: "phoneNumber"
    }
    this.timer = null;
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.textStore.common.classicRoboTitle,
      },
      headerRight: "",
      currentPage: "leadGeneration",
    })
    this.fieldsValidation();
  }

  onFirstNameChange(e) {
    this.setState({ firstName: e.target.value }, () => {
      this.fieldsValidation();
    })
  }

  onLastNameChange(e) {
    this.setState({ lastName: e.target.value }, () => {
      this.fieldsValidation();
    })
  }

  onPhoneNumberChange(e) {
    if (isNaN(e.target.value)) {
      return;
    }
    this.setState({ phoneNumber: e.target.value }, () => {
      this.fieldsValidation();
    })
  }

  updateSelectedCountryIndex(index) {
    this.setState({selectedCountryIndex: index }, () => {
      this.fieldsValidation();
    })
  }

  onClickNext() {
    if (
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.phoneNumber === ""
    ) {
      this.setState({ showError: true });
    } else {
      
      this.props.toggleAsyncLoadingStatus({ status: true, text: 'Verifying' });
      this.props.getTestData(() => {
        // Timer to make the loading alway appear at least 1 second
        this.timer = window.setTimeout(() => {
          this.props.toggleAsyncLoadingStatus({ status: false, text: 'Verifying' });
          this.props.history.push(this.routesName.ONBOARDING.VERIFY.OTP.INPUT);
          window.clearTimeout(this.timer);
        }, 1000);
      });
    }
  }

  renderErrorMessage(flag, styleClass) {
    if (flag) {
      return (
        <p className={`text-error__small ${styleClass}`}>{this.textStore.personalDetail.fieldCannotEmpty}</p>
      )
    }
  }

  onInputBlur = e => {
    const { id, value } = e.target;
    let activeError = this.state.activeError;
    switch (id) {
      case this.DETAIL_CONSTANT_NAME.firstName:
        if (value === "") {
          activeError[0] = true;
          this.setState({ activeError });
        }
        this.setState({firstName: value.trim()})
        break;
      case this.DETAIL_CONSTANT_NAME.lastName:
        if (value === "") {
          activeError[1] = true;
          this.setState({ activeError });
        }
        this.setState({lastName: value.trim()})
        break;
      case this.DETAIL_CONSTANT_NAME.phoneNumber:
        if (value === "") {
          activeError[2] = true;
          this.setState({ activeError });
        } else {
          this.setState({ phoneNumber: formatPhoneNumber(value) });
        }
        break;
      default:
        break;
    }
  }

  fieldsValidation() {
    if (this.state.firstName && this.state.lastName && this.state.phoneNumber.length >= 4 && this.state.selectedCountryIndex >= 0) {
      this.setState({ canGoNext: true })
    } else {
      this.setState({ canGoNext: false })
    }
  }

  onInputFocus = e => {
    const { id, value } = e.target;
    let activeError = this.state.activeError;
    switch (id) {
      case this.DETAIL_CONSTANT_NAME.firstName:
        if (value === "") {
          activeError[0] = false;
          this.setState({ activeError });
        }
        break;
      case this.DETAIL_CONSTANT_NAME.lastName:
        if (value === "") {
          activeError[1] = false;
          this.setState({ activeError });
        }
        break;
      case this.DETAIL_CONSTANT_NAME.phoneNumber:
        if (value === "") {
          activeError[2] = false;
          this.setState({ activeError });
        }
        this.setState({phoneNumber: unFormatPhoneNumber(this.state.phoneNumber)})
        break;
      default:
        break;
    }
  }

  errorPhoneCondition = value => {
    const activeError = this.state.activeError;
    activeError[3] = value;
    this.setState({ activeError });
  }

  renderPhoneErrorMessage(flagInput, flagDialCode, styleClass) {
    if (flagInput || flagDialCode) {
      return (
        <p className={`text-error__small ${styleClass}`}>{this.textStore.personalDetail.fieldCannotEmpty}</p>
      )
    }
  }

  render() {
    const { countryData } = this.props.onboardingData.CTB || [];
    return (
      <React.Fragment>
        <Stepper 
          activeStep={this.state.activeStep} 
          totalSteps={this.state.totalSteps}
          textStore={this.textStore} 
          headerLeft={this.textStore.riskProfiling.contactInfo}
        />
        <div className="personal-detail-container">
          <p className="text-title__large personal-detail__title">{this.textStore.personalDetail.tellUs}</p>
          <p className="text-title__small">{this.textStore.personalDetail.firstName}<span className="obligatory">*</span></p>
          <TextInput
            className={`onboarding-input-field__full__large personal-detail__input-field ${this.state.activeError[0] ? "border-error__bottom" : ""}`}
            id={this.DETAIL_CONSTANT_NAME.firstName}
            type="text"
            maxLength={24}
            value={this.state.firstName}
            autoComplete="off"
            onChange={this.onFirstNameChange}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
          />
          {this.renderErrorMessage(this.state.activeError[0], "personal-detail__error-message")}
          <p className="text-title__small">{this.textStore.personalDetail.lastName}<span className="obligatory">*</span></p>
          <TextInput
            className={`onboarding-input-field__full__large personal-detail__input-field ${this.state.activeError[1] ? "border-error__bottom" : ""}`}
            id={this.DETAIL_CONSTANT_NAME.lastName}
            type="text"
            maxLength={24}
            value={this.state.lastName}
            autoComplete="off"
            onChange={this.onLastNameChange}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
          />
          {this.renderErrorMessage(this.state.activeError[1], "personal-detail__error-message")}
          <p className="text-title__small">{this.textStore.personalDetail.mobileNumber}<span className="obligatory">*</span></p>
          <div className="personal-detail--phone-number-container">
            <DropDown
              updateSelectedCountryIndex={this.updateSelectedCountryIndex}
              selectedIndex={this.state.selectedCountryIndex}
              activeError={this.state.activeError}
              language={this.props.language}
              items={countryData}
              hasIcon={true}
              styledClass="phone-number-drop-down "
              errorDialCode={this.errorPhoneCondition}
            />
            <TextInput
              className={`onboarding-input-field__full__large personal-detail__phone-input ${this.state.activeError[2] ? "border-error__bottom" : ""}`}
              id={this.DETAIL_CONSTANT_NAME.phoneNumber}
              type="text"
              maxLength={13}
              value={this.state.phoneNumber}
              autoComplete="off"
              numericKeyboard={true}
              onChange={this.onPhoneNumberChange}
              onBlur={this.onInputBlur}
              onFocus={this.onInputFocus}
            />
          </div>
          {this.renderPhoneErrorMessage(this.state.activeError[2], this.state.activeError[3], "personal-detail__error-message-phone")}
        </div>
        <GoalFooter
          onClickNext={this.onClickNext}
          renderBackButton={false}
          nextButtonFullWidth={true}
          enableNextButton={this.state.canGoNext}
        />
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    this.props.savePreviousScreen(this.textStore.screen.personalDetail);
    this.props.savePreviousUserInfo({
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: unFormatPhoneNumber(this.state.phoneNumber),
      selectedCountryIndex: this.state.selectedCountryIndex
    })
    clearTimeout(this.timer);
  }
}

function mapStateToProps(props) {
  return {
    onboardingData: props.onboardingData,
    appMainUI: props.appMainUI,
  }
}

const EnhancedPersonalDetail = withBaseLayout(PersonalDetail);

export default withRouter(
  connect(
    mapStateToProps,
    { 
      savePreviousUserInfo, 
      getTestData, 
      switchHeader, 
      getCTB, 
      savePreviousScreen, 
      toggleAsyncLoadingStatus
    }
  )(EnhancedPersonalDetail)
);
