import React from "react";
import PropTypes from "prop-types";
import RangeSliderWithInput from "../../common/RangeSliderWithInput/RangeSliderWithInput";
import changeLanguage from "../../../setting/languages/languages";

class PotentialSection extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language)
  }

  renderHeaderText() {
    if (this.props.renderHeader) {
      return (
        <div className="potential-contain">
          <p className="potential__title">{this.textStore.potentialSection.discover}</p>
          <p className="potential__description">{this.textStore.potentialSection.professionally}</p>
        </div>
      )
    }
  }

  render() {
    const data = this.props.data;
    return (
      <React.Fragment>
        {this.renderHeaderText()}
        {data.map((el, id) => (
          <div 
            key={id}
            className={`potential__sliders ${this.props.sliderContainerClass}`}
            style={{borderBottom: id === data.length - 1 ? "none" : ""}}
          >
            <RangeSliderWithInput
              language={this.props.language}
              updateOnboardingChart={this.props.updateOnboardingChart}
              onMouseUp={this.props.onMouseUp}
              changeValue={this.props.changeValue}              
              title={el.title}
              value={el.defaultValue}
              step={el.step}
              minValue={el.min}
              maxValue={el.max}
              rightText={el.rightText}
              inputWidth={el.inputWidth}
              isExponential={el.isExponential}
            />
          </div>
        ))}
      </React.Fragment>
    )
  }
}

PotentialSection.defaultProps = {
  renderHeader: true,
  onMouseUp: () => {},
  changeValue: () => {},
  updateOnboardingChart: () => {},
  sliderContainerClass: "",
}

PotentialSection.propTypes = {
  renderHeader: PropTypes.bool,
  onMouseUp: PropTypes.func,
  changeValue: PropTypes.func,
  updateOnboardingChart: PropTypes.func,
  sliderContainerClass: PropTypes.string,
}

export default PotentialSection;