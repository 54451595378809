import React from 'react';
import { withRouter } from 'react-router-dom';
import DropDown from '../../common/Dropdown/Dropdown';
import CONFIG from '../../../setting/config';
/*
	TODO:refactor table list component for goal-detail-screen and goal-create components
*/

class GoalWithdraw extends React.Component {
	constructor(){
		super()
		this.state = {
			checked:null,
			display:'none'
		}
		this.routesName = CONFIG.APP_ROUTE;
		this.addAccount = this.addAccount.bind(this);
	}

	onItemClick(e){
		// TODO:
		
	}

	addAccount(){
		this.props.history.push(this.routesName.BANK_ACCOUNT_EDIT.NEW);
	}

	render(){
		return(
			<React.Fragment>
			<div className="with-draw">
				<h3 className="primary-title"
					style={{
						fontSize:"20px"
					}}
				>
          Withdrawals
				</h3>
				<table className="list-view">
					<tbody>
						<tr>
							<td>
								<span className={`icn goal-name goal--${this.props.withDraws.type}`}>
								{this.props.withDraws.goalName}
								</span>
								<span className="tooltip-toggle" role="button" tabIndex="-1"></span>
							</td>
							<td className="text-right">
								{/* <span className="list-view-row amount">Amount</span> */}
								<span className="list-view-row">{this.props.withDraws.initialInvestment}</span>
							</td>
						</tr>
					</tbody>
				</table>

				<h3 className="primary-title"
					style={{
						fontSize:"20px"
					}}
				>
          Bank account
				</h3>
				<div className="list-view account">
					<div>Withdraw to</div>
					<DropDown 
						items={this.props.withDraws.userBankAccount}
						onItemClick={this.onItemClick.bind(this)}
						styledClass="dropdown-item"
					/>
				</div>
				<div className="form-actions-1">
					<div className="form-actions__item">
						<label 
							className="btn-with-draw btn-add" 
							onClick={this.addAccount}
						>
							Add account
						</label>
					</div>
				</div>
				</div>
			</React.Fragment>
		)
	}
}

export default withRouter(GoalWithdraw);