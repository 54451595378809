import React from 'react';
import { formatCurrency, unformatCurrency } from '../../../helpers/formatCurrency';
function goalSummary({summary, screen, textStore}){
	return(
		<table className="list-view">
			<tbody>
				<tr>
					<th>
						<span className={`icn goal--${summary.type}`}>
							Goal
						</span>
					</th>
					<td className="text-right">
						{summary.goalName}
					</td>
				</tr>
				<tr
					style={{
						display:summary.type === "investment" ? 'none' : ''
					}}
				>
					<th>
						<span className="icn icn--target-amount">
							Target value
						</span>
					</th>
					<td className="text-right">	
						{formatCurrency(summary.targetAmount)}
					</td>
				</tr>
				<tr
					style={{
						display:summary.type === "investment" ? 'none' : ''
					}}
				>
					<th>
						<span className="icn icn--calendar">
							Target date
						</span>
					</th>
					<td className="text-right">
						{summary.targetDate}
					</td>
				</tr>
				<tr>
					<th>
						<span className="icn icn--strategy">
							Strategy
						</span>
					</th>
					<td className="text-right">
						{screen === textStore.screen.createGoal ? textStore.common.moderate : summary.investmenStrategy}
					</td>
				</tr>
				<tr 
					style={{
						display: summary.typeScreen === 'EditGoal'  && unformatCurrency(summary.topUpInvestmentAmount) > 0 ? '' : "none"
					}}
				>
					<th>
						<span className="icn icn--edit--goal--topup">
							Top-up
						</span>
					</th>
					<td className="text-right">
						{formatCurrency(summary.topUpInvestmentAmount)}
					</td>
				</tr>
				<tr style={{
						display: summary.typeScreen === 'EditGoal' && unformatCurrency(summary.withdrawInvestmentAmount) > 0 ? '' : "none"
					}}>
					<th>
						<span className="icn icn--edit--goal--withdraw">
							Withdraw
						</span>
					</th>
					<td className="text-right">
						{formatCurrency(summary.withdrawInvestmentAmount)}
					</td>
				</tr>
				<tr
				style={{
					display: summary.typeScreen !== 'EditGoal' ? '' : "none"
				}}>
				
					<th>
						<span className="icn icn--investment">
							Initial Investment
						</span>
					</th>
					<td className="text-right">
						{formatCurrency(summary.initialInvestment)}
					</td>
				</tr>
				<tr id="borderless-bottom" >
					<th id="borderless-bottom" >
						<span className="icn icn--recurring">
							Monthly Investment
						</span>
					</th>
					<td 
						className="text-right"
						id="borderless-bottom"
					>
						{formatCurrency(summary.monthlyInvestment)}
					</td>
				</tr>
			</tbody>
		</table>
	)
}

export default goalSummary;