import React from "react";
import PropTypes from "prop-types";

class ExpandableView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: [],
    };
    this.onTitleClick = this.onTitleClick.bind(this);
  }

  componentDidMount() {
    let initVal = this.state.activeIndex.concat(false);
    for(var i = 0; i < this.props.data.length - 1; i++) {
      this.setState({activeIndex: initVal});
      initVal.push(false);
    };
  }

  onTitleClick(index) {
    let array = this.state.activeIndex.slice();
    array[index] = !array[index];
    this.setState({activeIndex: array});
  }

  renderDescription(data, index) {
    if (this.state.activeIndex[index]) {
      return (
        <p className="expandable-view-description">{data.description}</p>
      );
    }
  }

  render() {
    const closedClass = "list-action__link list-action__link--expand";
    const openClass = "list-action__link list-action__link--expand open";
    return (
      <React.Fragment>
        {this.props.data.map((el, id) => (
          <div
            key={id}
            className={`expandable-view-row ${(id !== 0 || this.props.firstElementHasBorderTop) ? '' : 'borderless-top'}`}
            onClick={() => this.onTitleClick(id)}
          >
            <div className={`expandable-view-title ${this.props.rowClass || ""} ${this.props.fixedRowHeight ? "row-padding__none" : ""}`}>
              <span 
                className="row-height-controller"
                style={{height: this.props.fixedRowHeight}}
              />
              <span 
                className={this.state.activeIndex[id] ? openClass : closedClass}
                style={{margin: '0px 10px 0 0'}}
              />
              <p style={{width: '95%'}}>{el.title}</p>
            </div>
            {this.renderDescription(el, id)}
          </div>
        ))}
      </React.Fragment>
    );
  }
}

ExpandableView.defaultProps = {
  data: [],
  firstElementHasBorderTop: false,
  rowClass: "",
  fixedRowHeight: "",
};

ExpandableView.propTypes = {
  data: PropTypes.array.isRequired,
  firstElementHasBorderTop: PropTypes.bool,
  rowClass: PropTypes.string,
  fixedRowHeight: PropTypes.string,
};

export default ExpandableView;
