import {
	FETCH_GOAL_DETAIL,
	PASS_DATA_FROM_DASHBOARD,
} from './GoalDetailActionType';
import firebase from 'firebase/app';
import 'firebase/firestore';

function getGoalDetailSuccess(data, index){
	data.id = index;
	return {
		type:FETCH_GOAL_DETAIL,
		payload:data
	}
}


// function getAmountValueSuccess(data, index){
// 	data.id = index;
// 	return {
// 		type: FETCH_AMOUNT_VALUE,
// 		payload: data
// 	}
// }


export function getGoalDetail(id){
	return (dispatch) => {
		firebase.firestore().collection("PortfolioManagement_GetPortfolioDetails").doc(id).onSnapshot(snapShot => {
			return dispatch(getGoalDetailSuccess(snapShot.data(), id))
		})
	}
}

export function passDataFromDashBoard(data){
	return {
		type: PASS_DATA_FROM_DASHBOARD,
		payload: data
	}
}

// export function getAmountValue(index){
// 	return (dispatch => {
// 		firebase.firestore().collection('PortfolioManagement_GetInvestmentOverview').doc("1").onSnapshot(doc => {
// 			dispatch(loadingLayout(''));
// 			dispatch(switchHeader({
// 				headerLeft: 'back',
// 				headerMiddle: {
// 					type: 'text',
// 					text: doc.data().Portfolios[index - 1].Goal.Name
// 				},
// 				headerRight: 'menu',
// 				currentPage: ''
// 			}))
// 			dispatch(getAmountValueSuccess(doc.data().Portfolios[index - 1], index));
// 		})
// 	})
// }