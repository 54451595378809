import React from "react";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { connect } from "react-redux";
import { invertLayout } from "../../../actions/AppMain/AppMainAction";

class GenericPage extends React.Component {
  renderTitle() {
    if (this.props.title.text !== "" || this.props.title.text !== undefined) {
      return (
        <p className="generic-page-title" style={this.props.title.style}>
          {this.props.title.text}
        </p>
      );
    }
  }
  render() {
    return (
      <main className="content-main" id="ContentMain">
        <div className="content-main__inner">
          <div className="content-main__body content-main__body--centered text-center">
            {this.props.icon}
            {this.renderTitle()}
            <p className="content-lead">{this.props.text}</p>
            {this.props.bodyComponent}
          </div>
          <div className="content-main__footer">
            {this.props.footerComponent}
          </div>
        </div>
      </main>
    );
  }
}

function mapStateToProps(props) {
  return {
    appMainUI: props.appMainUI
  };
}

export default connect(
  mapStateToProps,
  { switchHeader, invertLayout }
)(GenericPage);
