import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { formatCurrency } from '../../../helpers/formatCurrency';
import { checkBrowser } from '../../../helpers/checkBrowser';
import CONFIG from '../../../setting/config';
class ExpandableListView extends React.Component {
	constructor(){
		super()
		this.state = {
			activeIndex:[]
		};
		this.routesName = CONFIG.APP_ROUTE;
	}

	onNestedRowClick(value){
		if (!this.props.showOnly) {
			this.props.passDataFromPerformanceGoalDetail(value);
			this.props.savePreviousTab(this.props.currentTab)
			this.props.history.push(this.routesName.POSITION.DETAILS);
		}
	}


	onExpandListView(index){
		if (
      this.state.activeIndex[index] === false ||
      this.state.activeIndex[index] === undefined
    ) {
      let arr = this.state.activeIndex.slice();
      arr[index] = true;
      this.setState({
        activeIndex: arr
      });
    } else {
      let arr = this.state.activeIndex.slice();
      arr[index] = false;
      this.setState({
        activeIndex: arr
      });
    }
	}

	renderAmount(amount){
		if(amount >= 0){
			return (
				<span 
					className="amount-changed"  
					style={{
						color:this.props.color,
					}}
				>
					+{formatCurrency(amount*100)}%
				</span>
			)
		}else{
			return (
				<span 
					className="amount-changed"  
					style={{
						color:'#FF376B',
					}}
				>
					{formatCurrency(amount*100)}%
				</span>
			)
		}
	}

	renderNestedRows(data){
		return data.map(value => {
			return (
				<tr 
					key={value.name}
					onClick={this.onNestedRowClick.bind(this,value)}
				>
					<th>

						<span className="text-ellipsis" style={{marginLeft:"10px",fontFamily:"OpenSans"}}>
							{value.name}
						</span>
					</th>
					<td className="text-right"> 
						<div className="list-action">
							<span className="list-action__text">
								{this.props.defaultView === 'Performance' ? this.renderAmount(value.performance): formatCurrency(value.allocation*100,2) + '%'}
							</span>
							<Link 
								to={this.routesName.POSITION.DETAILS}
								className="list-action__link list-action__link--more"
							>
								<span className="sr-only">Show more</span>
							</Link>
						</div>
					</td>
				</tr>
			)
		}) 
	}
	renderRows(){
		const closedClass = 'list-action__link list-action__link--expand';
		const openClass = 'list-action__link list-action__link--expand open';
		return this.props.data.map((value, key) => {
			return(
				<React.Fragment key={value.type}>
					<tr id="borderless-bottom" 
						onClick={this.onExpandListView.bind(this, key)} >
						<th>
							<button 
								type="button" 
								className=
								{this.state.activeIndex[key] ? 
									openClass: 
									closedClass
								}
								style={{
									position: checkBrowser() ? this.state.activeIndex[key] ? 'relative' : 'relative' : '',
									bottom: checkBrowser() ? this.state.activeIndex[key]  ? '' : '5px' : '',
									top: checkBrowser() ? this.state.activeIndex[key] ? '0px' : '' : '',
								}}
								data-toggle="expand"
							>
								<span className="sr-only">Show positions</span>
							</button>
							<span style={{marginLeft:"10px",fontFamily: "OpenSans", fontSize: "15px"}}>
								{value.type}
							</span>
						</th>
						<td className="cell-percentage text-right">
							<div className="list-action">
								<span className="list-action__text"
									style={{paddingRight:"20px"}}
								>
									{this.props.defaultView === 'Performance' ? this.renderAmount(value.totalPerformance) :  formatCurrency(value.totalAllocation * 100) + '%'}
								</span>
							</div>
						</td>
					</tr>
					<tr id="borderless-top" className={this.state.activeIndex[key]  ? 'row-expandable': 'row-expandable hidden'}>
						<td colSpan={2}>
							<table className="table-listing table-nested">
								<tbody>
									{this.renderNestedRows(value.security)}
								</tbody>
							</table>
						</td>
					</tr>
				</React.Fragment>
			)
		})
	}
	render(){
		return(
			this.renderRows()
		)
	}
}

ExpandableListView.defaultProps = {
	color:"#4261ff",
	defaultView:'Performance',
	showOnly: false,
}

ExpandableListView.propTypes = {
	passDataFromPerformanceGoalDetail: PropTypes.func,
	currentTab: PropTypes.string,
	savePreviousTab: PropTypes.func,
	color: PropTypes.string,
	showOnly: PropTypes.bool,
}

export default withRouter(ExpandableListView);