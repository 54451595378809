import React from 'react';
import './styles.less';

export default class SearchForm extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      searchStr: '',
    }
  }

  onSubmit = (evt) => {
    evt.preventDefault();

    const { onSearch } = this.props;
    typeof onSearch === 'function' && onSearch(this.state.searchStr);
  }

  onClear = (evt) => {
    const { onSearch } = this.props;

    this.setState({ searchStr: '' });
    typeof onSearch === 'function' && onSearch('');
  }

  onChange = (evt) => {
    this.setState({ searchStr: evt.target.value });
  }

  render() {
    const { searchStr } = this.state;

    return (
      <form className="search-form" onSubmit={this.onSubmit}>
        <span className="search-form__search-icon" onClick={this.onSubmit}></span>
        <input className="search-form__input" onChange={this.onChange} value={searchStr}/>
        {
          searchStr.length ? (
            <span className="search-form__close-icon close-icon-default" onClick={this.onClear}></span>
          ) : ''
        }
      </form>
    )
  }
}