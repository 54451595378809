import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import PopUp from "../../common/Popup/PopUp";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import lightning from '../../../css/images/icons-png/lightning.png';
import bellline from '../../../css/images/icons-png/bell-line.png';
import useralt from '../../../css/images/icons-png/user-alt.png';
import list from '../../../css/images/icons-png/list.png';
import wallet from '../../../css/images/icons-png/wallet.png';
import drawer from '../../../css/images/icons-png/drawer.png';
import closeline from '../../../css/images/icons-png/close-line.png';
import angleBoldRight from '../../../css/images/icons/arrow-list.svg';

import './styles.less';

class Settings extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      openConfirm: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.switchHeader({
      headerLeft: 'menu',
      headerMiddle: { type: 'text', text: 'Settings' },
      headerRight: '',
      currentPage: 'Settings',
    })
  }

  onCloseAccount = (evt) => {
    evt.preventDefault();

    this.setState({ openConfirm: true, });
  }

  onclosePopUp = () => {
    this.setState({ openConfirm: false, });
  }

  render() {
    const { openConfirm } = this.state;
    return (
      <div className="settings">
        <div className="settings-group">
          <div className="settings-group-title">Portfolio</div>
          <div className="settings-list">
            <Link to="/riskprofile" className="settings-item">
              <img className="settings-item-icon" src={lightning} alt="email" />
              <span className="settings-item-text">Risk profile</span>
              <span className="settings-item-text-right">Moderate</span>
              <img className="settings-item-goto-icon" src={angleBoldRight} alt="goto" />
            </Link>
            <Link to="/notification" className="settings-item">
              <img className="settings-item-icon" src={bellline} alt="change password" />
              <span className="settings-item-text">Notifications</span>
              <img className="settings-item-goto-icon" src={angleBoldRight} alt="goto" />
            </Link>
          </div>
        </div>
        <div className="settings-group">
          <div className="settings-group-title">User</div>
          <div className="settings-list">
            <Link to="/settings/account" className="settings-item">
              <img className="settings-item-icon" src={useralt} alt="email" />
              <span className="settings-item-text">Account details</span>
              <img className="settings-item-goto-icon" src={angleBoldRight} alt="goto" />
            </Link>
            <Link to="/settings/personal-information" className="settings-item">
              <img className="settings-item-icon" src={list} alt="change password" />
              <span className="settings-item-text">Personal information</span>
              <img className="settings-item-goto-icon" src={angleBoldRight} alt="goto" />
            </Link>
            <Link to="/settings/financial-situation" className="settings-item">
              <img className="settings-item-icon" src={wallet} alt="change password" />
              <span className="settings-item-text">Financial situation</span>
              <img className="settings-item-goto-icon" src={angleBoldRight} alt="goto" />
            </Link>
            <Link to="/linkedbankaccounts" className="settings-item">
              <img className="settings-item-icon" src={drawer} alt="change password" />
              <span className="settings-item-text">Linked bank accounts</span>
              <img className="settings-item-goto-icon" src={angleBoldRight} alt="goto" />
            </Link>
            <a href="#" className="settings-item" onClick={this.onCloseAccount}>
              <img className="settings-item-icon" src={closeline} alt="change password" />
              <span className="settings-item-text">Close account</span>
              <img className="settings-item-goto-icon" src={angleBoldRight} alt="goto" />
            </a>
          </div>
        </div>
        {
          openConfirm ? (
            <PopUp
              onConfirmClick={this.onclosePopUp}
              closePopUp={this.onclosePopUp}
              message="Are you sure you want to delete your goal?"
              secondMessage="Data such as historical performance and transaction history will get deleted permanently."
              rightButtonText="Confirm"
            />
          ) : ''
        }
      </div>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(Settings));