import {
  SWITCH_TAB_BAR,
  SAVE_PREVIOUS_TAB,
  SET_TABBAR_REF,
  CLEAR_PREVIOUS_TAB,

} from './TabBarActionType'

export function switchTabBar(type){
  return {
    type:SWITCH_TAB_BAR,
    payload:type
  }
}


export function savePreviousTab(type){
  return {
    type: SAVE_PREVIOUS_TAB,
    payload:type
  }
}

export function clearPreviousTab(){
  return {
    type:CLEAR_PREVIOUS_TAB,
  }
}

export function setTabBar(tabBarRef){
  return {
    type: SET_TABBAR_REF,
    payload:tabBarRef
  };
}

