import firebase from "firebase/app";
import "firebase/firestore";

import {
  ONBOARDING_SELECTED_GOAL,
  GET_DEFAULT_GOALS,
  SAVE_PREVIOUS_INVESTMENT_INFO,
  SAVE_PREVIOUS_USER_INFO,
  CLEAR_PREVIOUS_INPUT,
  GET_CTB,
  GET_CALCULATE_RISK,
  GET_TEST_DATA,
  SAVE_PERSONAL_PARTICULAR,
  UPDATE_FIRST_LOAD_ONBOARDING,
  VERIFY_ONLINE_STEP,
} from "./OnboardingActionType";
import { func } from "prop-types";

export function updateFirstLoadOnboarding(status) {
  return {
    type: UPDATE_FIRST_LOAD_ONBOARDING,
    payload: status
  };
}

export function updateOnBoardingSelectedGoal(goal) {
  return {
    type: ONBOARDING_SELECTED_GOAL,
    payload: goal
  };
}

function getDefaultGoalsSuccess(data) {
  return {
    type: GET_DEFAULT_GOALS,
    payload: data
  };
}

function getCTBSuccess(data) {
  return {
    type: GET_CTB,
    payload: data
  };
}

function getTestDataSuccess(data) {
  return {
    type: GET_TEST_DATA,
    payload: data,
  }
}

function getCalculateRiskSuccess(data) {
  return {
    type: GET_CALCULATE_RISK,
    payload: data
  }
}

export function savePreviousInvestmentInfo(data) {
  return {
    type: SAVE_PREVIOUS_INVESTMENT_INFO,
    payload: data
  };
}

export function savePersonalParticular(data) {
  return {
    type: SAVE_PERSONAL_PARTICULAR,
    payload: data
  };
}

export function savePreviousUserInfo(data) {
  return {
    type: SAVE_PREVIOUS_USER_INFO,
    payload: data
  };
}

export function clearPreviousInput() {
  return {
    type: CLEAR_PREVIOUS_INPUT
  };
}

export function getDefaultGoals() {
  return dispatch => {
    firebase
      .firestore()
      .collection("Compliance_GetGoalTemplates")
      .get()
      .then(snapshot => {
        let arr = [];
        snapshot.forEach(doc => {
          arr.push(doc.data());
        });
        arr.sort((a, b) => {
          return a.id - b.id;
        });
        dispatch(getDefaultGoalsSuccess(arr));
        return arr;
      });
  };
}

export function getCTB(documentPosition) {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_CTB")
      .onSnapshot(snapshots => {
        dispatch(getCTBSuccess(snapshots.docs[documentPosition].data()));
      });
  };
}

export function getCalculateRisk() {
  return dispatch => {
    firebase
      .firestore()
      .collection("Compliance_GetCalculaterisk")
      .onSnapshot(snapshot => {
        dispatch(getCalculateRiskSuccess(snapshot.docs[0].data()));
      })
  }
}

export function getTestData(_callback) {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_AppTestParameters")
      .onSnapshot(snapshot => {
        dispatch(getTestDataSuccess(snapshot.docs[0].data()))
        typeof _callback === 'function' && _callback();
      })
  }
}

export function saveVerifyOnlineStep(step) {
  return {
    type: VERIFY_ONLINE_STEP,
    payload: step
  };
}