import React from 'react';

function positionOverview(props){
  return(
    <table className="table-listing">
      <tbody>
        <tr>
          <td className="th position-td-row">Name</td>
          <td className="text-right">
            {props.positionDetail.name}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">ISIN</td>
          <td className="text-right">
            {props.positionDetail.isin}
          </td>
        </tr>
        <tr>
          <td className="th  position-td-row">Units</td>
          <td className="text-right">
            {props.positionDetail.quantity}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">Currency</td>
          <td className="text-right">
            {props.positionDetail.currencyCode}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">Price</td>
          <td className="text-right">
            {props.positionDetail.price}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">Market</td>
          <td className="text-right">
            {props.positionDetail.market}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">Market Cap</td>
          <td className="text-right">
            {props.positionDetail.marketCap}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">Industry</td>
          <td className="text-right">
            {props.positionDetail.industry}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">Sector</td>
          <td className="text-right">
            {props.positionDetail.sector}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">Country</td>
          <td className="text-right">
            {props.positionDetail.country}
          </td>
        </tr>
        <tr>
          <td className="th position-td-row">Continent</td>
          <td className="text-right">
            {props.positionDetail.continent}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default positionOverview;