import {
  SWITCH_NAVBAR,
  NAVBAR_ITEM_SELECT
 } from './NavBarActionType';


 export function switchNavBar(type){
  return {
    type:SWITCH_NAVBAR,
    payload:type
  }
 }

export function switchNavBarItem(type) {
  return {
    type: NAVBAR_ITEM_SELECT,
    payload: type
  }
}