import {
  SWITCH_DATE_XAXIS,
  CHANGE_TO_RELATIVE_CHART,
  RESET_LABEL_DATE,
  DISABLE_RELATIVE_CHART_ANIMATION,
  ENABLE_RELATIVE_CHART_ANIMATION
} from '../../actions/Chart/ChartActionType';
import amountConfig from '../../components/Charts/config/HighStockConfigAmount';
import relativeConfig from '../../components/Charts/config/HighStockConfigRelative';


export default function(states = amountConfig,actions){
  switch(actions.type){
    case SWITCH_DATE_XAXIS:
      return { ...states,
          xAxis:{ ...states.xAxis,
            labels: { ...states.xAxis.labels,format:actions.payload } } };
    case CHANGE_TO_RELATIVE_CHART:
      return states = relativeConfig;
    case DISABLE_RELATIVE_CHART_ANIMATION:
        relativeConfig.plotOptions.series.animation = false;
        return states = relativeConfig;
    case ENABLE_RELATIVE_CHART_ANIMATION:
        relativeConfig.plotOptions.series.animation = true;
        return states = relativeConfig;
    case RESET_LABEL_DATE:
      return { ...states,
          xAxis:{ ...states.xAxis,
            labels: { ...states.xAxis.labels,format:"{value: %d.%m.%y}" } } };   
    default:
      return states;
  }
}