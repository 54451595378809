import React from 'react';
import { connect } from 'react-redux';
import {getTransactionHistoryOverview}  from '../../../actions/TransactionHistory/TransactionHistoryAction';
import GenericListItem from '../../common/GenericList/GenericListItem';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { FixedSizeList as List } from 'react-window';
import { switchHeader } from '../../../actions/Header/HeaderActions'
import {withRouter} from 'react-router-dom';
import TabBar from '../../GoalDetail/TabBar/TabBar';
import { savePreviousScreen } from '../../../actions/Screen/ScreenAction';
import CONFIG from '../../../setting/config';
const moment = require('moment');


class TransactionHistoryDetail extends React.Component {
	constructor(){
		super();
		this.state = {
			searchContain: '',
			allviewHistoryItemsLength: 0,
			transactionHistoryType: -1,
			tabIndex: 0
		}
		this.routesName = CONFIG.APP_ROUTE;
		this.onClickLoadMore = this.onClickLoadMore.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
		this.filterTransactionHistoryByType = this.filterTransactionHistoryByType.bind(this);
	}

	componentDidMount() {
		if (this.props.screen === 'search') {
			this.props.switchHeader({headerLeft: 'back', headerMiddle: { type: 'text', text: 'Search'},currentPage: "transactionHistorySearch"})		
		}
		if (this.props.transactionHistoryData.offSet !== this.state.allviewHistoryItemsLength) {
				this.setState({allviewHistoryItemsLength: this.props.transactionHistoryData.offSet})
		}
	}

	onItemClick(value) {
		this.props.savePreviousScreen('transactionHistory/details');
		switch(value.Type) {
			case 1:
				this.props.history.push(this.routesName.TRANSACTION_HISTORY.WITHDRAWAL);
				break;
			case 2:
				this.props.history.push(this.routesName.TRANSACTION_HISTORY.SECURITY);
				break;
			case 3: 
				this.props.history.push(this.routesName.TRANSACTION_HISTORY.FUNDING);
				break;
			case 4: 
				this.props.history.push(this.routesName.TRANSACTION_HISTORY.MONTHLY);
				break;
			default:
				break;
		}
	}
	
	renderTransactionHistoryDetails(value, index, length){
		return (
			<GenericListItem   
				onItemClick={this.onItemClick.bind(this, value)}
				key={value.Id}
				iconClass={value.iconClass}
				topLeft={value.TransactionDate}
				left= {value.TransactionName}
				right={formatCurrency(value.Amount)}
				hasArrow={true}
				hasBorder={index !== length -1}
				/>
		);
	}

	/**
	 * Click 'Load More' then add 10 items 
	 * */
	onClickLoadMore() {
		let startIndex = this.state.allviewHistoryItemsLength;
		// Specs: Default loading is 10
		let total = startIndex + defaultviewHistoryItemsLength;
		this.setState({allviewHistoryItemsLength: total});
		this.props.getTransactionHistoryOverview(total);
	}

	sortByDate(data) {
		return data.sort((a, b) => 
				moment(a.TransactionDate, 'DD-MM-YYYY') > moment(b.TransactionDate, 'DD-MM-YYYY') ? -1 : 1
		);
	}
	filterTransactionHistoryByType(type) {
		let data = [...this.props.transactionHistoryData.details];
		let returnObj = {
			isLoadMore: false,
			data: []
		}
		let offSet = this.props.transactionHistoryData.offSet;
		if (type > 0) {
			// Filter
			data = data.filter((item) => {
				return (item.Type === type);
			});
		} 
		if (data.length > offSet) {
			returnObj.isLoadMore = true;
		}
		for (let i = data.length; i > offSet; i--) {
			// Remove items
			data.pop();
		}
		this.sortByDate(data);
		returnObj.data = data;
		return returnObj;
	}

	clearSearch() {
		this.setState({searchContain: ''})
	}
	handleChangeSearch(event) {
		this.setState({searchContain: event.target.value});
	}

	componentDidUpdate(prevProps) {
		if (this.props.transactionHistoryData.offSet !== this.state.allviewHistoryItemsLength) {
		 	this.setState({allviewHistoryItemsLength: this.props.transactionHistoryData.offSet})
    }
	}

	render(){
		const settings = {
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			slide:"ul",
			swipeToSlide:true,
			infinite:false
		};
		let tabs =  ["All", "Withdraw", "Security", "Funding", "Fee"];
		const items = this.filterTransactionHistoryByType(this.props.type);
		const itemsLength = items.data.length;
		const Row = ({index}) => (
			this.renderTransactionHistoryDetails(items.data[index], index, itemsLength)
		);
		// Height per row is 76px
		let height = 76 * itemsLength;
		return(
			<React.Fragment>
				<span
				  style={{display: this.props.screen === 'search' ? '': 'none'}}
					>
					<GenericListItem   
          iconClass='icn--search--default'
          left={<input className="transaction-history-search" value={this.state.searchContain} onChange={this.handleChangeSearch}  type="search" placeholder="Search..."></input>}
					right={this.state.searchContain.length > 0 ? <div onClick={this.clearSearch.bind(this)} className="close-icon-default"></div>: ''}
          ></GenericListItem>
				</span>
					<h3 
					  style={{display: this.props.screen === 'search' ? 'none': ''}}
					className="section-title border-bottom-title">Details</h3>
					<nav className="tablist sticky">
						<div className="tabs">
							<div className="tabpanels">
							<TabBar 
								tabs={tabs}
								settings={settings}
								/>	
							</div>
						</div>
					</nav>
				<h3 className="no-transaction"
					style={{
						display: itemsLength > 0 ? 'none': ''
					}}
				>No transactions found </h3>
				<List 
					style={{
								display: itemsLength > 0 ? '': 'none'
						}}
					className="List"
					height={height}
					itemCount={itemsLength}
					itemSize={35}
				>
				{Row}
				</List>
				<div className="content-main__footer">
					<div className="form-actions__item">
						<button 
							style={{
								display: items.isLoadMore ? '': 'none'
							}}
							onClick={this.onClickLoadMore}
							type="button" 
							className="btn btn-default"
						>
							Load more
						</button>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

function mapStateToProps(props){
	return {
		transactionHistoryData: props.transactionHistoryData
	}
}
const defaultviewHistoryItemsLength = 10;

export default withRouter(connect(mapStateToProps,{
	getTransactionHistoryOverview,
	switchHeader,
	savePreviousScreen
})(TransactionHistoryDetail));