import React from 'react';

function allocationRegions({ regions }){
	function renderStatusBar(){
	const color = ["#E64F97","#5AB1FB","#6D41B8","#FDAC68","#B3B9C1","#D49DBF"];

	 return regions.map((value,index) => {
		return (
			<tr key={value.continent} id="borderless">
				<th>
					{value.continent}
				</th>
				<td className="cell-status-bar">
					<span 
						className="status-bar"
						style={{
							backgroundColor:color[index],
							width:(value.totalAllocation * 100).toFixed(2)+'%'
						}}
					>

					</span>
				</td>
				<td className="cell-percentage text-right" style={{width: "22%"}}>
					{(value.totalAllocation * 100).toFixed(2)}%
				</td>
			</tr>
		)
	 })
	}
	return(
		<section
			className="tabpanel"
			style={{width: "330px"}}
		>
			<table 
				className="table-listing table-listing--status-bar"
				style={{
					marginLeft: "2px"
				}}
			>
				<tbody>
					{renderStatusBar()}
				</tbody>
			</table>
		</section>
	)
}

export default allocationRegions;