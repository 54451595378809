import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withBaseLayout from "../../HoC/withBaseLayout";
import { invertLayout, toggleAsyncLoadingStatus } from "../../../actions/AppMain/AppMainAction";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import CONFIG from "../../../setting/config";
import changeLanguage from "../../../setting/languages/languages";
import Modal from '../Modal/Modal';

class OTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: this.props.onboardingData.CTB.timeOutOTP,
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      isVerifying: false,
      isShowModal: false,
      rendered: false
    };
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.timer = null;
    this.loadingTimer = null;
  }

  componentWillMount() {
    if (this.props.appMainUI.layout !== "viewport layout--inverted") {
      this.props.invertLayout("viewport layout--inverted");
    }
    this.props.switchHeader({
      headerLeft: "back-white",
      headerMiddle: { type: "text", text: "Classic Robo" },
      headerRight: "",
      currentPage: this.props.type
    });
  }

  componentDidMount() {
    this.timer = setInterval(
      () => {
        this.setState({ timer: this.state.timer - 1 })
      },
      1000
    );

    window.scrollTo(0,0);
    const firstInput = document.getElementById('code-num-1');
    firstInput.focus();
  }

  componentDidUpdate() {
    if (this.state.timer <= 0) {
      clearInterval(this.timer);
      this.props.history.push({
        pathname: this.routesName.ONBOARDING.VERIFY.OTP.TIMED_OUT,
        state: {
          title: this.textStore.verification.verificationTimeOut,
          desc: this.textStore.verification.exceedVerifyDuration,
          buttonName: this.textStore.common.requestNewVerificationCode
        }
      });
    }
  }

  onButtonClick = () => {
    if (
      this.state.num1.trim() !== "" &&
      this.state.num2.trim() !== "" &&
      this.state.num3.trim() !== "" &&
      this.state.num4.trim() !== "" &&
      this.state.num5.trim() !== "" &&
      this.state.num6.trim() !== ""
    ) {
      this.props.history.push({
        pathname: this.routesName.GOALS.COMPLETE,
        state: {
          icon: "icn icn--right-tick",
          desc: this.textStore.common.mobileNumberUpdatedSuccess
        }
      });
    }
  }

  handleKeyDown = (evt) => {
    const backCondition = evt.keyCode === 8;
    const charRegex = /[0-9]/;

    const { name, id } = evt.target;
    const number = id.split('-')[2];

    if ((evt.key && !charRegex.test(evt.key) && !backCondition) || (backCondition && id === 1)) {
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    }

    const nextNumber = parseInt(number, 10) + (backCondition ? -1 : 1);
    const nextId = `code-num-${nextNumber}`;

    const input = document.getElementById(id);
    const nextInput = document.getElementById(nextId);

    this.setState({ [name]: evt.key });

    if (nextNumber < 0) { return; }

    if (nextNumber > 6 && !this.props.isOnboarding) {
      input.removeAttribute('disabled');
      input.classList.remove('focusing');

      this.onButtonClick();

      return;
    }

    if (nextNumber > 6) {
      input.classList.remove('focusing');
      input.blur();

      this.props.toggleAsyncLoadingStatus({ status: true, text: 'Verifying' });
      this.loadingTimer = setTimeout(() => {
        
        this.props.toggleAsyncLoadingStatus({ status: false, text: 'Verifying' });
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.VERIFY.OTP.SUCCESS,
          state: {
            title: this.textStore.verification.verifySuccess,
            desc: this.textStore.common.mobileNumberUpdatedSuccess,
            buttonName: this.textStore.common.continueToRiskProfiling,
          }
        })
      }, 1000);

      return;
    }

    if (!nextInput) { return; }

    input.setAttribute('disabled', true);
    input.classList.remove('focusing');

    nextInput.removeAttribute('disabled');
    nextInput.classList.add('focusing');
    nextInput.focus();
  }

  handleBlur = (evt) => {
    if (evt.target.classList.contains('focusing')) {
      evt.target.focus();
    }
  }

  closePopup = () => {
    this.setState({ isShowModal: false })
  }

  onShowSuccessModal = () => {
    this.props.history.push(this.routesName.ONBOARDING.PERSONAL_DETAIL);

  }

  renderConfirmButton() {
    if (this.props.buttonName !== "") {
      return (
        <input
          type="button"
          className="otp-button"
          value={this.props.buttonName}
          onClick={this.onButtonClick}
        />
      );
    } else {
      return (
        <div className="otp-bottom-space" />
      )
    }
  }

  renderVerifyPage() { }

  renderModalSuccess() {
    if (this.state.isShowModal) {
      return (
        <Modal
          title={this.textStore.common.titleSendingPass}
          message={this.textStore.common.messageSendingPass}
          closePopup={this.closePopup}
        />
      )
    }
  }

  render() {
    return (
      <div className="content-main__inner">
        {this.renderModalSuccess()}
        <div className="content-main__body content-main__body--centered text-center">
          <span className={`otp-icon ${this.props.icon}`} />
          <h3 className="otp-title">{this.props.title}</h3>
          <span className="otp-description">{this.props.description}</span>
          <div className="otp-container">
            <input
              type="number"
              id="code-num-1"
              autoFocus={true}
              name="num1"
              className="otp-code focusing"
              value={this.state.num1}
              maxLength={1}
              pattern="[0-9]*"
              inputMode="numeric"
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
            />
            <input
              type="number"
              id="code-num-2"
              name="num2"
              className="otp-code"
              disabled={true}
              value={this.state.num2}
              maxLength={1}
              pattern="[0-9]*"
              inputMode="numeric"
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
            />
            <input
              type="number"
              id="code-num-3"
              name="num3"
              className="otp-code"
              disabled={true}
              value={this.state.num3}
              maxLength={1}
              pattern="[0-9]*"
              inputMode="numeric"
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
            />
            <input
              type="number"
              id="code-num-4"
              name="num4"
              className="otp-code"
              disabled={true}
              value={this.state.num4}
              maxLength={1}
              pattern="[0-9]*"
              inputMode="numeric"
              onKeyPress={this.handleKeyPress}
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
            />
            <input
              type="number"
              id="code-num-5"
              name="num5"
              className="otp-code"
              disabled={true}
              value={this.state.num5}
              maxLength={1}
              pattern="[0-9]*"
              inputMode="numeric"
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
            />
            <input
              type="number"
              id="code-num-6"
              name="num6"
              className="otp-code"
              disabled={true}
              value={this.state.num6}
              maxLength={1}
              pattern="[0-9]*"
              inputMode="numeric"
              onKeyDown={this.handleKeyDown}
              onBlur={this.handleBlur}
            />
          </div>
          <div className="otp-timer">
            <span>{this.textStore.otp.codeExpire}</span>
            <span className="code-timer__text">{this.state.timer}s</span>
          </div>
          {this.renderConfirmButton()}
        </div>
        <div className="content-main__footer footer">
          <div className="otp-footer-description">
            <span>
              {this.textStore.otp.didNotReceiveSMS}
              <Link to="#" className="page-link" onClick={this.onShowSuccessModal}>
                {this.textStore.otp.requestNewCode}
              </Link>
              <br />
              <br />
              {this.textStore.otp.stillNotReceiveSMS}
              <a
                className="page-link"
                href={`mailto:${this.props.onboardingData.CTB.supportMail}?subject=${this.props.onboardingData.CTB.supportSubject}&body=''`}
              >
                {this.textStore.verification.contactSupport}
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    if (this.props.appMainUI.layout !== "viewport layout--default") {
      this.props.invertLayout("viewport layout--default");
    }
    clearInterval(this.timer);
    clearInterval(this.loadingTimer);
  }
}

OTP.defaultProps = {
  iconClass: undefined,
  title: "Title",
  description: "Description",
  buttonName: "",
  isOnboarding: false,
  type: "",
};

OTP.propTypes = {
  iconClass: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonName: PropTypes.string,
  isOnboarding: PropTypes.bool,
  type: PropTypes.string,
};

const EnhancedOTP = withBaseLayout(OTP);

function mapStateToProps(props) {
  return {
    appMainUI: props.appMainUI,
    onboardingData: props.onboardingData
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { invertLayout, switchHeader, toggleAsyncLoadingStatus }
  )(EnhancedOTP)
);
