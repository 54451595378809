import React from 'react';
import { connect } from 'react-redux';
import {getTransactionHistoryOverview}  from '../../actions/TransactionHistory/TransactionHistoryAction';
import TransactionHistoryDetail from './TransactionHistoryDetail/TransactionHistoryDetails';
import withBaseLayout from "../HoC/withBaseLayout";
import TransactionOverview from './TransactionHistoryOverview/TransactionHistoryOverview';
import { switchHeader } from '../../actions/Header/HeaderActions'
import { switchScreen, savePreviousScreen, clearPreviousScreen } from '../../actions/Screen/ScreenAction';
import { switchTabBar, savePreviousTab, clearPreviousTab } from '../../actions/TabBar/TabBarActions';

class TransactionHistory extends React.Component {
	componentWillUnmount() {
		this.props.savePreviousTab(this.props.tabBarUI.currentTab);
	}

	componentDidUpdate() {
		if (!this.props.screenUI.previousScreen) {
			return;
		}
		switch(this.props.tabBarUI.previousTab) {
			case 'Securiry':
				break;
			case 'Funding':
				this.props.tabBarUI.tabBarRef.current.slickGoTo(1);
				break;
			case 'Fee':
				this.props.tabBarUI.tabBarRef.current.slickGoTo(2);
				break;
			default:
				return;
		}
		this.props.clearPreviousTab();
	}
	componentDidMount(){
		if (this.props.screen === 'search') {
			this.props.switchScreen('search');
		} else {
			if (this.props.screenUI.previousScreen !== 'transactionHistory' 
				&& this.props.screenUI.previousScreen !== 'transactionHistory/details' 
				&& this.props.screenUI.previousScreen !== 'search') {
				// Defaul loading is 10 items
				let defaultItemsNumber = 10;
				this.props.getTransactionHistoryOverview(defaultItemsNumber);
				this.props.switchTabBar('All');
			}
			this.props.switchScreen('transactionHistory');
			this.props.switchHeader({
        headerLeft: "menu",
        headerMiddle: { type: "text", text: "History" },
        headerRight: { type: "search" }
      });
		}
	}

	renderTransactionHistory(){
		let  transactionOverviewData = '';
		// TODO : In search screen it is not show transaction overview data
		if (!this.props.screen || this.props.screen !== 'search') {
			transactionOverviewData = <TransactionOverview 
			overview={this.props.transactionHistoryData.overview}/>;
		}
		return (
			<React.Fragment>
				{transactionOverviewData}
				{this.renderTransactionHistoryDetails()}
			</React.Fragment>
		) ;

	}
    
	renderTransactionHistoryDetails(){
		let type = 0;
		switch(this.props.tabBarUI.currentTab) {
			case 'All':
				type = 0;
				break;
			case 'Withdraw':
				type = 1;
				if(this.props.tabBarUI.tabBarRef.current) {
					this.props.tabBarUI.tabBarRef.current.slickGoTo(-1);
				}
				break;
			case 'Security':
				if(this.props.tabBarUI.tabBarRef.current) {
					this.props.tabBarUI.tabBarRef.current.slickGoTo(1);
				}
				type = 2;
				break;
			case 'Funding':
				if(this.props.tabBarUI.tabBarRef.current) {
					this.props.tabBarUI.tabBarRef.current.slickGoTo(2);
				}
				type = 3;
				break;
			case 'Fee':
				type = 4;
				break;
			default:
				type = 0;
				break;
		}
		return (
			<TransactionHistoryDetail type={type} screen={this.props.screen}></TransactionHistoryDetail>
		)
	}

	render(){
		return(
			<React.Fragment>
				<div className="content-main__body">
					{this.renderTransactionHistory()}
				</div>	
			</React.Fragment>
		)
	}

}

const TransactionHistoryEnhanced = withBaseLayout(TransactionHistory);
function mapStateToProps(props){
	return {
		goalUI:props.goalUI,
		tabBarUI:props.tabBarUI,
		screenUI:props.screenUI,
		goalData:props.goalData,
		transactionHistoryData: props.transactionHistoryData
	}
}

export default connect(mapStateToProps,{
	getTransactionHistoryOverview,
	switchHeader,
	switchTabBar,
	savePreviousTab,
	clearPreviousTab,
	switchScreen, 
	savePreviousScreen, 
	clearPreviousScreen
})(TransactionHistoryEnhanced);