import React from "react";
import { Link } from "react-router-dom";
import InvestSummary from "./InvestSummary/InvestSummary";
import withBaseLayout from "../HoC/withBaseLayout";
import withTableViewData from "../HoC/withTableViewData";
import { switchTabBar } from "../../actions/TabBar/TabBarActions";
import DashBoardTableView from "./DashBoardTableView/DashBoardTableView";
import {
  fetchDashBoardData,
  fetchUnreadMessage,
  switchTimeLine
} from "../../actions/DashBoard/DashBoardActions";
import { switchHeader } from "../../actions/Header/HeaderActions";
import {
  switchNavBar,
  switchNavBarItem
} from "../../actions/NavBar/NavBarActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getDefaultGoals,

} from "../../actions/Goals/GoalActions";
import { resetLabelDate, dashboardChart, changeToAmountChart } from '../../actions/Chart/ChartActions';
import LoadingScreen from "../common/LoadingScreen/LoadingScreen";
import { switchScreen } from '../../actions/Screen/ScreenAction';
import changeLanguage from "../../setting/languages/languages";
import CONFIG from "../../setting/config";
import withAuth from "../HoC/withAuth";

export class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onCreatGoalClick = this.onCreatGoalClick.bind(this);
    this.timeout = null;
  }

  onCreatGoalClick() {
    this.props.history.push(this.routesName.GOALS.CREATE);
    this.props.switchTabBar("Goal");
    this.props.getDefaultGoals();
    this.props.switchScreen(this.textStore.screen.createGoal);
  }

  renderReferral() {
    if (
      this.props.dashBoardData.referral.accountAge > 6 &&
      this.props.dashBoardData.performance.currentPerformanceAll * 100 >= 5 &&
      this.props.dashBoardData.performance.currentValue >= 50000
    ) {
      return (
        <div className="referal layout--inverted">
          <p>
            {this.textStore.dashboard.shareYourReferral}
          </p>
          <Link
            className="btn btn-primary btn-no-focus__primary"
            style={{ width: "100%" }}
            to={this.routesName.REFERRAL}
            onClick={() => this.props.switchNavBarItem("Refer a friend")}
          >
            {this.textStore.referAFriend}
          </Link>
        </div>
      );
    }
  }
  
  componentDidMount() {
    if (this.props.dashBoardData.performance.currencyCode === "") {
      // this.props.fetchDashBoardData(() => {
      //   this.timeout = setTimeout(() => {
      //     this.props.disableChartAnimation() 
      //   }, 1000);
      // });
      this.props.fetchDashBoardData();
      this.props.fetchUnreadMessage();
    }
    this.props.switchHeader({
      headerLeft: "menu",
      headerMiddle: {
        type: "logo",
        text: ""
      },
      headerRight: { type: "notification" },
      currentPage: "dashboard"
    });
    this.props.switchScreen(this.textStore.screen.dashBoard);
    this.props.switchTimeLine("ALL");
    this.props.switchNavBarItem("Dashboard");
    this.props.switchNavBar("dashboard");
    this.props.dashboardChart();   
  }

  render() {
    const DashBoardTableViewData = withTableViewData(
      DashBoardTableView,
      this.props.dashBoardData.portfolios
    );
    if (this.props.appMainUI.loading === "loading") {
      return <LoadingScreen />;
    }

    return (
      <React.Fragment>
        <div className="contain-main__body">
          <InvestSummary />
          <section>
            <table className="list-view">
              <tbody>
                <DashBoardTableViewData />
              </tbody>
            </table>
          </section>
        </div>
        <div className="content-main__footer">
          <div className="form-actions p-t-sm">
            <div className="form-actions__item">
              <button
                className="btn btn-primary btn-no-focus__primary"
                onClick={this.onCreatGoalClick}
              >
                {this.textStore.dashboard.createNewGoal}
              </button>
            </div>
          </div>
        </div>
        {this.renderReferral()}
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this.props.resetLabelDate();
    this.props.changeToAmountChart();
    clearTimeout(this.timeout);
  }
}

const DashBoardEnhanced = withAuth(withBaseLayout(DashBoard));
function mapStateToProps(props) {
  return {
    dashBoardData: props.dashBoardData,
    appMainUI: props.appMainUI,
    screenUI: props.screenUI
  };
}

export default withRouter(
  connect(
    mapStateToProps, {
      switchHeader,
      switchNavBar,
      switchNavBarItem,
      fetchDashBoardData,
      fetchUnreadMessage,
      getDefaultGoals,
      switchTabBar,
      switchTimeLine,
      resetLabelDate,
      dashboardChart,
      changeToAmountChart,
      switchScreen
    }
  )(DashBoardEnhanced)
);
