import { combineReducers } from 'redux';
// import { combineReducers } from 'redux-immutable';
import DashBoardData from './DashBoard/DashBoardData/DashBoardData';
import TransactionHistoryData from './TransactionHistory/TransactionHistoryData/TransactionHistoryData';
import HeaderUI from './Header/HeaderUI/HeaderUI';
import AuthUI from './Auth/AuthUI';
import AuthData from './Auth/AuthData';
import chartUI from './Charts/ChartsUI';
import NavBarUI from './NavBar/NavBarUI';
import ScreenUI from './Screen/ScreenUI';
import TabBarUI from './TabBar/TabBarUI';
import GoalDetailData from './GoalDetail/GoalDetailData/GoalDetailData';
import GoalUI from './Goals/GoalUI/GoalUI';
import GoalData from './Goals/GoalData/GoalData';
import PositionDetailData from './PositionDetail/PositionDetailData';
import AppMainUI from './AppMain/AppMainUI';
import SupportData from './Support/SupportData';
import FundingData from './Funding/FundingData/FundingData';
import OnboardingData from './Onboarding/OnboardingData';
import ChartUIRelative from './Charts/ChartUIRelative';
import ChartUIGauge from './Charts/ChartUIGauge';
import Questionnaires from '../components/Onboarding/containers/QuestionnairesPage/reducer';
import PersonalFinance from '../components/Onboarding/containers/PersonalFinancePage/reducer';


const rootReducer = combineReducers({
	dashBoardData: DashBoardData,
	questionnaires: Questionnaires,
	personalFinance: PersonalFinance,
	transactionHistoryData: TransactionHistoryData,
	headerUI: HeaderUI,
	authUI:AuthUI,
	authData:AuthData,
	chartUI:chartUI,
	chartUIRelative: ChartUIRelative,
	chartUIGauge: ChartUIGauge,
	navBarUI:NavBarUI,
	tabBarUI:TabBarUI,
	screenUI:ScreenUI,
	goalDetailData:GoalDetailData,
	goalUI:GoalUI,
	goalData:GoalData,
	positionDetailData:PositionDetailData,
	appMainUI:AppMainUI,
	supportData: SupportData,
	fundingData: FundingData,
	onboardingData: OnboardingData,
});

export default rootReducer;