import data from '../../../data/performance-instrument-percentage';
import { formatCurrency } from "../../../helpers/formatCurrency";

const moment = require('moment');


const fontStyle = {
  color: "#B3C0FF",
  font: "OpenSans"
}
const config = {
  exporting: { enabled: false },

  credits: {
    enabled: false
  },
  navigator: {
    enabled: false
  },
  scrollbar: {
    enabled: false
  },
  title: {
    enabled: false
  },
  chart: {
    style: fontStyle,
    spacing: 0,
    animation: true,
    zoomType: '',
    pinchType: '',
    panning: false,
  },
  series: [{
    data:data,

    name:"Investment"
  }],
  colors:["#4261FF"],
  xAxis: {
    visible: true,
    // tickInterval:0,
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    minorTickLength: 0,
    tickLength: 0,
    type: "datetime",
    dateTimeLabelFormats: {
      day:'%a'
    },
    labels: {
      format: '{value: %d.%m.%y}',
      style: fontStyle,
      y: 25
    },
  },
  yAxis: {
    min: 0,
    opposite: false, 
    // tickInterval: 30000,
    gridLineDashStyle: 'dot',
    gridLineColor: '#a1b0fc',
    gridLineWidth: 1.6,
    labels:{
      formatter:function(){
        return this.value+"%"
      },
      style:fontStyle
      
    }
  },
  useHTML:true,
  tooltip: {
    valueDecimals: 2,
    followTouchMove: false,
    crosshair: false,
    formatter: function() {
      
      return `<strong>${formatCurrency(this.y)} %</strong>
      <br/>
      ${moment(this.x).format('LL')}`;
    },
 
  },
  plotOptions:{
    series:{
      turboThreshold:10000,
      animation:true
    },
    line: {
      lineWidth: 0.8,
      shadow: {
        color: '#4261FF',
        width: 3.5,
        offsetX: 0,
        offsetY: 0
      }
    }
  },
  rangeSelector: {
    selected: 4,
    inputEnabled: false,
    height: 0,
    buttons: [{
      type: "week",
      count: 1,
      text: "1W",
    }, {
      type: "month",
      count: 1,
      text: "1M"
    }, {
      type:"month",
      count:3,
      text:"3M"
    },{
      type: "month",
      count: 6,
      text: "6M"
    },{
      type: "all",
      text: "All"
    }],
    buttonTheme: { 
      fill: "none",
      stroke: "none",
      style: {
        color: "transparent"
      },
      states: {
        hover: {
          fill: "none",
          stroke: "none",
          style: {
            color: "transparent"
          }
        },
        select: {
          fill: "none",
          stroke: "none",
          style: {
            color: "transparent"
          }
        },
        disabled: {
          fill: "none",
          stroke: "none",
          style: {
            color: "transparent"
          }
        }
      }
    }
  },
}
export default config;