import { PASS_DATA_FROM_PERFORMANCE_GOALDETAIL } from '../../actions/Positions/PositionActionType';
	
const mockData = {
	performance:0,
	name:'',
	currencyCode: '' ,
	performanceAmount:0,
	currentValue: 0,
	country: '',
	continent: '',
	price: 0,
	sector:0,
	industry: '',
	market: '',
	marketCap: '',
	quantity:0,
	isin:'',
}

export default function(data = mockData,actions){
	switch(actions.type){
		case PASS_DATA_FROM_PERFORMANCE_GOALDETAIL:
			return { ...data,
				performance:actions.payload.performance,
				name:actions.payload.name,
				currencyCode:actions.payload.currencyCode,
				performanceAmount:actions.payload.performanceAmount,
				currentValue:actions.payload.currentValue,
				country:actions.payload.country,
				continent:actions.payload.continent,
				price:actions.payload.price,
				sector:actions.payload.sector,
				industry:actions.payload.industry,
				market:actions.payload.market,
				marketCap:actions.payload.marketCap,
				quantity:actions.payload.quantity,
				isin:actions.payload.isin,
			};
		default:
			return data;
	}
}