import React from 'react';
import { Link } from 'react-router-dom';

import angleBoldRight from '../../../css/images/icons/arrow-list.svg';
import archive from '../../../css/images/icons-png/box-default.png';

import './styles.less';

export default class MessageItem extends React.PureComponent {
  render() {
    const { data, actived } = this.props;
    return (
      <Link to={data.link} className={['messages-item', actived ? 'active' : ''].join(' ')}>
        <div className="messages-item__archive">
          <img className="messages-item__archive-icon" src={archive} />
          <span className="messages-item__archive-text">Archive</span>
        </div>
        <img src={data.icon} alt="" className="messages-item__icon" />
        <div className="messages-item__time">{data.time}</div>
        <span className="messages-item__text">{data.text}</span>
        <img className="messages-item__go-icon" src={angleBoldRight} alt="goto" />
      </Link>
    )
  }
}