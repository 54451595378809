export const GET_DEFAULT_GOALS = 'GET_DEFAULT_GOALS';
export const ONBOARDING_SELECTED_GOAL = 'ONBOARDING_SELECTED_GOAL';
export const SAVE_PREVIOUS_INVESTMENT_INFO = 'SAVE_PREVIOUS_INVESTMENT_INFO';
export const SAVE_PREVIOUS_USER_INFO = 'SAVE_PREVIOUS_USER_INFO';
export const CLEAR_PREVIOUS_INPUT = 'CLEAR_PREVIOUS_INPUT';
export const GET_CTB = 'GET_CTB';
export const GET_CALCULATE_RISK = 'GET_CALCULATE_RISK';
export const GET_TEST_DATA = 'GET_TEST_DATA';
export const SAVE_PERSONAL_PARTICULAR = 'SAVE_PERSONAL_PARTICULAR';

export const VERIFY_ONLINE_STEP = 'VERIFY_ONLINE_STEP';

// UPDATE FIRST LOADING STATUS
export const UPDATE_FIRST_LOAD_ONBOARDING = 'UPDATE_FIRST_LOAD_ONBOARDING';