import React from 'react';


function goalStrategyOverview({ description }){
	return (
		<p style={{lineHeight:"25px",letterSpacing:"0.25px",marginTop:'15px'}}>
			{description}
		</p>
	)
}

export default goalStrategyOverview;