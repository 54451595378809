import React from "react";
import PropTypes from "prop-types"

const Modal = (props) => {
  return (
    <React.Fragment>
      <div
        className="modal success-modal"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" >
                {props.title}
                <span
                  className="icn icn--close-blue"
                  onClick={props.closePopup}
                >
                </span>
              </h5>
            </div>
            <div className="modal-body">
              {props.message}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  closePopup: PropTypes.func
}

export default Modal;
