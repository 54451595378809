import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import envolopeDefault from '../../../css/images/icons-png/envolope-default.png';
import lockLocked from '../../../css/images/icons-png/lock-locked.png';
import angleBoldRight from '../../../css/images/icons/arrow-list.svg';

import './styles.less';

class AccountDetails extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0,0);
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Account details' },
      headerRight: '',
      currentPage: 'accountDetails',
    })
  }
  
  render() {
    return (
      <div className="account-details">
        <Link to="/settings/account/change-email" className="account-details-item">
          <img className="account-details-item-icon" src={envolopeDefault} alt="email" />
          <span className="account-details-item-text">Change email address</span>
          <img className="account-details-item-goto-icon" src={angleBoldRight} alt="goto" />
        </Link>
        <Link to="/settings/account/change-password" className="account-details-item">
          <img className="account-details-item-icon" src={lockLocked} alt="change password" />
          <span className="account-details-item-text">Change password</span>
          <img className="account-details-item-goto-icon" src={angleBoldRight} alt="goto" />
        </Link>
      </div>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(AccountDetails));