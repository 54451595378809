import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import DocumentList from '../List';
import Tabs from '../../common/Tabs';

import { data } from '../data';

import './styles.less';

class Documents extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      openConfirm: false,
      openSearchForm: false,
      searchStr: '',
    }
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'menu',
      headerMiddle: { type: 'text', text: 'Documents' },
      headerRight: { type: "search", onOpenSearch: this.onOpenSearchForm },
      currentPage: 'Documents',
    })
  }

  onOpenSearchForm = () => {
    this.props.history.push('/documents/search')
  }

  onSearch = (searchStr) => {
    this.setState({ searchStr });
  }

  render() {
    const { searchStr, openSearchForm } = this.state;
    const tabs = [
      { 
        id: 'all', 
        header: 'All', 
        comp: () => <DocumentList list={data.all}/>
      }, { 
        id: 'statements', 
        header: 'Statements', 
        comp: () => <DocumentList list={data.statements}/> 
      }, { 
        id: 'others', 
        header: 'Others', 
        comp: () => <DocumentList list={data.others}/>
      }
    ]

    return (
      <div className="document">
        <Tabs tabs={tabs} headerFluid="true" defaultTab="all"/>
      </div>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(withRouter(Documents)));