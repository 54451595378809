const CONFIG = {};

CONFIG.APP_ROUTE = {
  ADMIN: "/admin",
  BANK_ACCOUNT_EDIT: {
    LINKED: "/linkedbankaccounts",
    UPDATE: "/updatebankaccount",
    NEW: "/newbankaccount"
  },
  CONTACT_SUPPORT: "/contactsupport",
  DASHBOARD: "/dashboard",
  DELETED: "/deleted",
  ERROR: "/error",
  FUNDING_TERMS: "/fundingterms",
  FUNDING_WITHDRAWAL: "/fundingwithdrawal",
  GOALS: {
    COMPLETE: "/goals/complete",
    CREATE: "/goals",
    DETAIL: {
      DETAILS: "/goaldetail/:id",
      EDIT: "/goaldetail/:id/edit",
      EDIT_STRATEGY: "/goaldetail/:id/editstrategy",
      EDIT_STRATEGY_COMPLETED: "/goaldetail/:id/editstrategy/completed",
    },
    STRATEGY: {
      DETAILS: "/goals/strategy/detail/:id",
    }
  },
  IN_PROGRESS: "/inprogress",
  LOG_IN: "/login",
  LOG_IN_APP: "/loginApp",
  LOG_OUT: "/logout",
  MESSAGE_SUCCESS: "/messagesuccess",
  NOTIFICATION: "/notification",
  ONBOARDING: {
    COMPLIANCE_DECLARATION: '/onboarding/compliancedeclaration',
    DISCOVER_MORE: '/onboarding/discovermore',
    GOAL_CALCULATOR: '/onboarding/goalcalculator',
    GREETING: '/onboarding/greeting',
    INVESTMENT_DETAIL: '/onboarding/investmentdetail',
    INVESTMENT_PROPOSAL: '/onboarding/investmentproposal',
    MAIN_PAGE: '/onboarding',
    PERSONAL_DETAIL: '/onboarding/personaldetail',
    PERSONAL_PARTICULAR: '/onboarding/personalparticular',
    REQUEST: {
      REQUESTING: '/onboarding/requesting',
      FAILED: '/onboarding/requestfailed',
      TIMED_OUT: '/onboarding/requesttimedout',
    },
    RISK_PROFILING: {
      QUESTIONNAIRE: '/onboarding/riskprofiling/questionnaires',
      RISK_SCORE: '/onboarding/riskprofiling/riskscore'
    },
    KYC:{
      ONLINE_VERIFICATION: {
        PATH: '/onboarding/customer-online-verification',
        FAILED: '/onboarding/verify/customer-online-verification/failed',
        VERIFYING: '/onboarding/verify/customer-online-verification/verifying',
      }
    },
    VERIFY: {
      FAILED: '/onboarding/verify/failed',
      OTP: {
        INPUT: '/onboarding/verify/otp',
        FAILED: '/onboarding/verify/otp/failed',
        SUCCESS: '/onboarding/verify/otp/success',
        TIMED_OUT: '/onboarding/verify/otp/timedout',
        VERIFYING: '/onboarding/verify/otp/verifying',        
      },
      COMPLIANCE_DECLARATION: {
        FAILED: '/onboarding/verify/compliancedeclaration/failed',
        TIMED_OUT: '/onboarding/verify/timedout',
        VERIFYING: '/onboarding/verify/compliancedeclaration',
      },
      INVESTMENT_PROPOSAL: {
        FAILED: '/onboarding/verify/inverstmentproposal/failed',
        VERIFYING: '/onboarding/verify/inverstmentproposal',
      },
      SUCCESS: '/onboarding/verify/success',
      TIMED_OUT: '/onboarding/verify/timedout',
      VERIFYING: '/onboarding/verify',
    },
    PERSONAL_FINANCE: {
      PATH: '/onboarding/personal_finance',
      VERIFY: {
        VERIFYING: '/onboarding/personal_finance/verifying',
        SUCCESS: '/onboarding/personal_finance/verify/success',
        FAILED: '/onboarding/personal_finance/verify/failed',
        TIMED_OUT: '/onboarding/personal_finance/verify/timedout',
      }
    },
    VISIT_BRANCH: {
      PATH: '/onboarding/visit_branch',
    },
    INDENTITY_VERIFICATION: {
      PATH: '/onboarding/indentity_verification',
    },
  },
  OTP: "/otp",
  PLANNED_INVESTMENT: "/plannedinvestment",
  POSITION: {
    DETAILS: "/position/details",
  },
  REFERRAL: "/referral",
  RISK_PROFILE: "/riskprofile",
  RESET_PASSWORD: {
    EMAIL_INPUT: "/resetpassword",
    EMAIL_SENT: "/emailsent",
    NEW_PASSWORD: "/newpassword",
    COMPLETE: "/resetcomplete"
  },
  SIGN_OUT: "/signout",
  SETTINGS_UPDATED: "/settingsupdated",
  TRANSACTION_HISTORY: {
    FUNDING: "/transactionhistory/funding",
    MONTHLY: "/transactionhistory/monthly",
    OVERVIEW: "/transactionhistory",
    SEARCH: "/transactionhistory/search",
    SECURITY: "/transactionhistory/security",
    WITHDRAWAL: "/transactionhistory/withdrawal"
  },

  QUESTIONNAIRES: '/questionnaires',

  SETTINGS: {
    MAIN: '/settings',
    MAIN_SECONDS: '/settings-seconds',
    ACCOUNT_DETAILS: '/settings/account',
    CHANGE_EMAIL: '/settings/account/change-email',
    CHANGE_PASSWORD: '/settings/account/change-password',
    FINANCIAL_SITUATION: '/settings/financial-situation',
    PERSONAL_INFORMATION: '/settings/personal-information',
  },

  VERIFY_EMAIL: '/verify-email',

  FEEDBACK: {
    MAIN: '/feedback',
    LEAVE_SUGGESTION: '/feedback/leave-suggestion',
    SENT_SUGGESTION: '/feedback/sent-suggestion'
  },

  DOCUMENTS: {
    MAIN: '/documents',
    SEARCH: '/documents/search'
  },

  SUPPORT: {
    MAIN: '/support',
    CATEGORY: '/support/category/:id',
    DETAIL: '/support/detail/:id',
    SEND_MAIL: '/support/send-mail'
  },

  MESSAGES: {
    MAIN: '/messages',
    ENGAGEMENT: '/engagement',
    ARCHIVE: '/messages-archive',
    SEARCH: '/messages/search',
    GOAL_MILESTONE: '/messages/goal-milestone',
    GOAL_ALERT: '/messages/goal-alert',
    NEW_DOCUMENT: '/messages/new-document',
    RISK_PROFILE_EXPIRED: '/messages/risk-profile-expired',
    SECURITY_TRANSACTION: '/messages/security-transaction',
    CUSTOMER_SUPPORT: '/messages/customer-support'
  }
};

CONFIG.CONSTANT = {
  VISIT_BRANCH: {
    LAT: '1.277200',
    LONG: '103.847730',
  },
  STRATEGY_INVESTMENT: ['Very conservative', 'Conservative', 'Moderate','Aggressive', 'Very aggressive']
}

export default CONFIG;
