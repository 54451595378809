import React from "react";

function HeaderButton({onClick, buttonClass, iconClass, renderNotification, text}) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`toggle-header ${buttonClass}`}
    >
      <span className="sr-only">Show navigation</span>
      <span className="toggle-icon toggle-icon--back" role="presentation">
        <div className={iconClass}>{text}</div>
      </span>
      {renderNotification}
    </button>
  );
}

export default HeaderButton;