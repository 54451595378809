import React from 'react';

import MessageItem from '../MessageItem';
import { messages } from '../data';

import './styles.less';

export default class MessageListArchive extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      activeId: '2'
    }
  }

  render() {
    const { activeId } = this.state;

    return (
      <div className="messages-list">
        {
          messages.map((msg) => (
            <MessageItem actived={activeId === msg.id} key={msg.id} data={msg} />
          ))
        }
      </div>
    )
  }
}