import React from 'react';
import NavBarLinks from './NavBarLinks';

function navbarDashBoard({ onCloseNavBar, version }){
  return(
    <nav 
      className="nav-offcanvas nav-offcanvas--left nav-offcanvas--main" 
      id="NavMain"
    >
      <div className="nav-offcanvas__inner">
        <div className="nav__actions">
          <div/>
          <button 
            className="toggle-offcanvas--close" 
            id="CloseNavMain" 
            onClick = {onCloseNavBar}
          >
            <span className="sr-only">Hide navigation</span>
            <span 
              className="toggle-icon toggle-icon--nav" 
              role="presentation"
            >				
              <div className="nav-menu-inverted-close-icon"/>
            </span>
          </button>
        </div>
        <NavBarLinks version={version} onCloseNavBar={onCloseNavBar}/>
      </div>
    </nav>
  )
}

export default navbarDashBoard;