const config = {
  chart: {
    type: 'gauge',
    backgroundColor: '#4261ff',
    height: 200,
  },

  credits: {
    enabled : false,
  },

  title: {
    text: ''
  },
  exporting: { enabled: false },
  pane: {
    startAngle: -120,
    endAngle: 120,
    center: ["50%", "67%"],
    background: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderWidth: 0,
      color: 'none',
    },
    size: '100%'
  },
  
  plotOptions: {
    gauge: {
      dial: {
        backgroundColor: '#dd0f43',
        baseLength: '100%',
        baseWidth: 3,
        radius: '113%',
        rearLength: '-72%',
      },
      pivot: {
        radius: 0,
      },
    },
  },

  tooltip: {
    enabled: false,
  },

  yAxis: [{
    min: 0,
    max: 10,

    minColor: 'white',
    maxColor: 'rgba(66,97,255,0.6)',
    minorTickInterval: 0.2,
    minorTickWidth: 2,
    minorTickLength: 16,
    minorTickPosition: 'inside',
    minorTickColor: 'white',

    tickWidth: 0,
    labels: {
      enabled: false,
    },
    plotBands: [{
      from: 0,
      to: 10,
      color: 'rgba(66,97,255,0.6)',
      outerRadius: '102%',
      thickness: 18,
      zIndex: 2,
      id: 'inactive',
    }],
    lineWidth: 0,
  }, {
    min: 0,
    max: 10,

    minorTickInterval: 0.2,
    minorTickWidth: 0.5,
    minorTickLength: 3,
    minorTickPosition: 'inside',
    minorTickColor: '#a1b0fc',

    tickWidth: 0,
    labels: {
      enabled: false,
    },
    offset: -28,
    lineWidth: 0,
  }],

  series: [{
    name: 'Risk',
    animation: false,
    data: [0],
    dataLabels: {
      backgroundColor: '#4261ff',
      borderWidth: 0,
      x: -2,
      y: -30,
      zIndex: 3,
      style: {
        fontFamily: 'OpenSans',
        fontSize: '36px',
        fontWeight: 'normal',
        color: 'white',
        textOutline: 'none',
        lineHeight: '28px',
        letterSpacing: '0.5px'
      }
    },
  }]

}

export default config;