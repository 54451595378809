import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Stepper from "../../Onboarding/RiskProfiling/Stepper/Stepper";
import changeLanguage from "../../../setting/languages/languages";
import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import PotentialSection from "../PotentialSection/PotentialSection";
import GoalFooter from "../../Goals/GoalFooter/GoalFooter";
import CONFIG from "../../../setting/config";
import { formatCurrency, unformatCurrency, formatRoundedCurrency } from '../../../helpers/formatCurrency';
import {updateInitialInvestmentOnboarding} from '../../../actions/Goals/GoalActions';


class GoalCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: "133px",
      expandButton: {
        icon: "",
        text: ""
      },
      activeStep: 4,
      totalSteps: 8,
      initialInvestment: unformatCurrency(props.onboardingData.investmentInfo.initialAmount),
      monthlyInvestment: this.getDefaultMonthly(),
      timeHorizon: props.onboardingData.investmentInfo.targetYears
    };
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    //TODO: Placeholder for currency, wait for currency implementation
    this.currency = "USD";
    this.onClickMore = this.onClickMore.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.updateOnboardingChart = this.updateOnboardingChart.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);

  }

  getDefaultMonthly() {
    return  Math.round(this.props.onboardingData.testParams.lowerMonthlyInvestment + this.props.onboardingData.testParams.upperMonthlyInvestment)/2
  }


  changeValue = (value, keyTitle) => {
    // Key title is 
    if (keyTitle === this.textStore.potentialSection.initialInvestment) {
      this.setState({initialInvestment: value });
    } else if (keyTitle === this.textStore.potentialSection.monthlyInvestment) {
      this.setState({monthlyInvestment: value });
    } else if (keyTitle === this.textStore.potentialSection.timeHorizon) {
      this.setState({timeHorizon: value });
    }
  };

  // Will be refactor
  updateOnboardingChart(value, keyTitle) {
    if (keyTitle === this.textStore.potentialSection.initialInvestment) {
      this.setState({initialInvestment: value }, () => {
        this.props.updateInitialInvestmentOnboarding(this.state.initialInvestment, this.state.monthlyInvestment, this.state.timeHorizon)
      });
    } else if (keyTitle === this.textStore.potentialSection.monthlyInvestment) {
      this.setState({monthlyInvestment: value }, () => {
        this.props.updateInitialInvestmentOnboarding(this.state.initialInvestment, this.state.monthlyInvestment, this.state.timeHorizon)
      })
    } else if (keyTitle === this.textStore.potentialSection.timeHorizon) {
      this.setState({timeHorizon: value }, () => {
        this.props.updateInitialInvestmentOnboarding(this.state.initialInvestment, this.state.monthlyInvestment, this.state.timeHorizon)
      })
    }
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.updateInitialInvestmentOnboarding(this.state.initialInvestment, this.state.monthlyInvestment, this.state.timeHorizon)
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.textStore.common.classicRoboTitle
      },
      headerRight: "",
      currentPage: this.textStore.screen.goalCalculator
    });
    this.setState({
      expandButton: {
        icon: "icn--triangle-down",
        text: this.textStore.goalCalculator.moreSettings
      }
    });
  }

  onClickMore() {
    if (this.state.height === "133px") {
      this.setState({
        height: "100%",
        expandButton: {
          icon: "icn--triangle-up",
          text: this.textStore.goalCalculator.lesserSettings
        }
      });
    } else {
      this.setState({
        height: "133px",
        expandButton: {
          icon: "icn--triangle-down",
          text: this.textStore.goalCalculator.moreSettings
        }
      });
    }
  }

  onClickNext() {
    this.props.history.push(this.routesName.ONBOARDING.INVESTMENT_PROPOSAL)
  }

  onMouseUp = () => {
    this.props.updateInitialInvestmentOnboarding(this.state.initialInvestment, this.state.monthlyInvestment, this.state.timeHorizon)
  };

  render() {
    const isExponential = this.props.onboardingData.testParams.sliderBehavior !== 'Step';
    const data = [
      {
        title: this.textStore.potentialSection.monthlyInvestment, 
        rightText: "USD", 
        defaultValue: this.state.monthlyInvestment, 
        min: 0, 
        max: 15000, 
        step: this.props.onboardingData.CTB.monthlyInvestmentSliderStep,
        inputWidth: '30%', 
        isExponential: isExponential
      }, {
        title: this.textStore.potentialSection.initialInvestment, 
        rightText: "USD", 
        defaultValue: this.state.initialInvestment, 
        min: 5000, 
        max: 100000, 
        step: this.props.onboardingData.CTB.initialInvestmentSliderStep,
        inputWidth: '35%', 
        isExponential: isExponential
      }, {
        title: this.textStore.potentialSection.timeHorizon, 
        rightText: "Years", 
        defaultValue: this.state.timeHorizon, 
        min: 1, 
        max: 40, 
        step: 1,
        inputWidth: '16%', 
        isExponential: false
      },
    ];
    
    return (
      <React.Fragment>
        <Stepper
          activeStep={this.state.activeStep}
          totalSteps={this.state.totalSteps}
          textStore={this.textStore}
          headerLeft={this.textStore.goalCalculator.goalCalculator}
        />
        <label className="text-title__large goal-calculator-title">
          {this.textStore.goalCalculator.weEstimate
            .replace("{currency}", this.currency)
            .replace(
              "{amountLower}",
              this.props.onboardingData.testParams.lowerMonthlyInvestment
            )
            .replace("{currency}", this.currency)
            .replace(
              "{amountUpper}",
              this.props.onboardingData.testParams.upperMonthlyInvestment
            )}
        </label>
        <div
          className="goal-calculator-sliders-section"
          style={{ height: this.state.height }}
        >
          <PotentialSection
            onMouseUp={this.onMouseUp}
            updateOnboardingChart={this.updateOnboardingChart}
            changeValue={this.changeValue}
            data={data}
            language={this.props.language}
            renderHeader={false}
            sliderContainerClass="goal-calculator-sliders-section__container"
          />
        </div>
        <div className="goal-calculator-more" onClick={this.onClickMore}>
          <span
            className={`icn ${
              this.state.expandButton.icon
            } goal-calculator-more-icon`}
          />
          <label>{this.state.expandButton.text}</label>
        </div>
        <div className="goal-calculator-projection-section">
          <label className="text-title__large goal-calculator-projection-section__title">
            {this.textStore.onBoarding.outlook}
          </label>
          <table className="list-view" style={{ tableLayout: "fixed" }}>
            <tbody>
              <tr className="borderless-top">
                <th className="cell-icn">
                  <span className="icn icn--expected-amount">
                    {this.textStore.common.expected}
                  </span>
                </th>
                <td className="text-right">
                  <span className="total-balance-background">
                    ≈
                    {formatRoundedCurrency(
                      this.props.goalData.planning.totalBalanceAmount
                    )}
                  </span>
                </td>
              </tr>
              <tr className="borderless-bottom">
                <th className="cell-icn borderless-bottom">
                  <span className="icn icn--strategy">
                    {this.textStore.common.investmentStrategy}
                  </span>
                </th>
                <td className="text-right borderless-bottom">
                  {this.props.onboardingData.calculateRisk.riskCategory.Name}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <GoalFooter
          onClickNext={this.onClickNext}
          renderBackButton={false}
          nextButtonFullWidth={true}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(props) {
  return {
    goalData: props.goalData,
    onboardingData: props.onboardingData
  };
}

const EnhancedGoalCalculator = withBaseLayout(GoalCalculator);

export default withRouter(
  connect(
    mapStateToProps,
    { switchHeader, updateInitialInvestmentOnboarding }
  )(EnhancedGoalCalculator)
);
