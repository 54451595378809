import React from 'react';
// import { Link } from 'react-router-dom';

// import angleBoldRight from '../../../css/images/icons/arrow-list.svg';
// import archive from '../../../css/images/icons-png/box-default.png';

import './styles.less';

export default class MessageBoxItem extends React.Component {
  render() {
    const { data } = this.props;
    const dataList = data.map((item, key) => {
        return (
          <div className={`message-box-container ${item.contentType}`}  key={key} >
            <div className={`message-body ${item.contentType}`}>
              {item.contentType === "answer" && (
                <span className="message-box-arrow"/>
              )}
              <p>{item.message}</p>
          </div>
          {
           item.contentType === 'answer' ? 
            <p className='status '>
              <span style={{ fontWeight: 'bold' }}>Friday</span> {item.status}
            </p>:
            <p className='status '>{item.status}</p>
          }
        </div>
        )
    })
    console.log(data)
    return (
      <div className='message-list'>
        {dataList}
        <div className="message-text-footer">
          <span className="icn icn--plus"/>
          <input className="message-text-input" type="text"/>
          <span className="icn icn--camera"/>
          <span className="icn icn--menu"/>
          <span className="icn icn--microphone"/>
        </div>
      </div>
    )
  }
}