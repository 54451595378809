import {
  SELECT_GOAL,
  CHANGE_GOAL_NAME,
  CHANGE_INITIAL_INVESTMENT,
  CHANGE_MONTHLY_INVESTMENT,
  CHANGE_SLIDER_VALUE_ONBOARDING,
  CHANGE_TARGET_AMOUNT,
  CHANGE_TARGET_DATE,
  INSTRUCTIONS_COMPLETED,
  GOAL_COMPLETED,
  STRATEGY_COMPLETED,
  SUMMARY_COMPLETED,
  TOP_UP_COMPLETED,
  WITHDRAW_COMPLETED,
  TRANSFER_COMPLETED,
  GOAL_CONFIRM,
  GET_GOAL_CALCULATE,
  STATUS_ERROR_DISPLAY,
  RESET_GOAL,
  GET_DEFAULT_GOALS,
  GET_DEFAULT_GOAL,
  DISPLAY_GOAL_NAME_ERROR,
  HIDE_GOAL_NAME_ERROR,
  DISPLAY_TARGET_AMOUNT_ERROR,
  HIDE_TARGET_AMOUNT_ERROR,
  DISPLAY_TOPUP_AMOUNT_ERROR,
  HIDE_TOPUP_AMOUNT_ERROR,
  CHANGE_TOPUP_AMOUNT,
  DISPLAY_WITHDRAW_AMOUNT_ERROR,
  HIDE_WITHDRAW_AMOUNT_ERROR,
  CHANGE_WITHDRAW_AMOUNT,
  DISPLAY_INITIAL_AMOUNT_ERROR,
  HIDE_INITIAL_AMOUNT_ERROR,
  DISPLAY_MONTHLY_AMOUNT_ERROR,
  HIDE_MONTHLY_AMOUNT_ERROR,
  DISPLAY_TARGET_DATE_ERROR,
  HIDE_TARGET_DATE_ERROR,
  CALCULATE_PROBABILITY,
  GET_DEFAULT_GOALS_STRATEGY,
  CHANGE_GOALS_STRATEGY,
  PASS_GOALS_TO_SUMMARY,
  GET_DEFAULT_GOALS_TRANSFER,
  TRANSFER_UPDATE_TOTAL_ONE_TRANSFER,
  TRANSFER_UPDATE_TOTAL_MONTHLY_TRANSFER,
  GET_DEFAULT_GOALS_BANK_ACCOUNT,
  GET_CONFIGURATION,
  GET_WITHDRAW_ACCOUNT,
  EDIT_GOAL,
  EDIT_GOAL_STRATEGY,
  GET_DEFAULT_GOALS_ONBOARDING
} from "./GoalActionType";

import firebase from "firebase/app";
import "firebase/firestore";
import { unformatCurrency } from "../../helpers/formatCurrency";
import { loadingLayout } from "../AppMain/AppMainAction";

const moment = require("moment");

export function selectGoal(goal) {
  return dispatch => {
    dispatch(goalSelected(goal.selected));
    dispatch(loadSingleDefaulGoal(goal.goal));
  };
}

function loadSingleDefaulGoal(goal) {
  return {
    type: GET_DEFAULT_GOAL,
    payload: goal
  };
}

function goalSelected(selected) {
  return {
    type: SELECT_GOAL,
    payload: selected
  };
}

function loadEditGoal(goal) {
  return {
    type: EDIT_GOAL,
    payload: goal
  };
}

export function editGoal(goals) {
  return dispatch => {
    dispatch(goalSelected(true));
    dispatch(loadEditGoal(goals));
  };
}

function displayGoalNameError() {
  return {
    type: DISPLAY_GOAL_NAME_ERROR
  };
}

function changeGoalName(goalName) {
  return {
    type: CHANGE_GOAL_NAME,
    payload: goalName
  };
}

function hideGoalNameError() {
  return {
    type: HIDE_GOAL_NAME_ERROR
  };
}

export function handleGoalName(goalName) {
  return dispatch => {
    dispatch(changeGoalName(goalName));
    dispatch(hideGoalNameError());
    if (goalName.trim().length === 0) {
      dispatch(displayGoalNameError());
    }
  };
}

export function changeInitialInvestment(initialInvestment) {
  return {
    type: CHANGE_INITIAL_INVESTMENT,
    payload: initialInvestment
  };
}

function displayInitialAmountError(message) {
  return {
    type: DISPLAY_INITIAL_AMOUNT_ERROR,
    payload: message
  };
}

function hideInitialAmountError() {
  return {
    type: HIDE_INITIAL_AMOUNT_ERROR
  };
}

function hideMonthlyAmountError() {
  return {
    type: HIDE_MONTHLY_AMOUNT_ERROR
  };
}

function changeInitialInvestmentOnboarding(chartData) {
  return {
    type: CHANGE_SLIDER_VALUE_ONBOARDING,
    payload: chartData
  };
}

export function updateInitialInvestmentOnboarding(initialInvestment, monthlyInvestment, timeZone) {
  return dispatch => {
    let chartData = {
      initialInvestment: initialInvestment,
      monthlyInvestment: monthlyInvestment,
      timeZone: timeZone
    }
    dispatch(changeInitialInvestmentOnboarding(chartData));
  }
}

function hideTargetAmountError() {
  return {
    type: HIDE_TARGET_AMOUNT_ERROR
  };
}

export function handleInitialInvestment(
  initialInvestment,
  monthlyInvestment,
  targetAmount,
  type
) {
  return dispatch => {
    dispatch(changeInitialInvestment(initialInvestment));
    dispatch(hideInitialAmountError());
    let regEx = /^\d+$/;
    let decimal = regEx.test(initialInvestment);
    if (!decimal) {
      if (initialInvestment.trim().length === 0) {
        return dispatch(
          displayInitialAmountError(
            "Please enter a valid initial investment amount. If you don’t want to make an initial investment, then indicate 0"
          )
        );
      }
      dispatch(displayInitialAmountError("Please enter a valid number"));
    } else if (
      unformatCurrency(initialInvestment) < 5000 &&
      unformatCurrency(initialInvestment) !== 0
    ) {
      dispatch(
        displayInitialAmountError(
          "The minimum initial investment amount is USD 5,000"
        )
      );
    } else if ( type !== "investment") {
      if (
        unformatCurrency(targetAmount) <
        unformatCurrency(initialInvestment) + unformatCurrency(monthlyInvestment)) {
          dispatch(
            displayTargetAmountError(
              "Please enter a target amount that is larger than the initial and recurring amount summed up"
            )
          );
      } else {
        return dispatch(hideTargetAmountError())
      }
    }
  };
}

export function handleTargetAmount(
  targetAmount,
  initialInvestment,
  monthlyInvestment
) {
  return dispatch => {
    dispatch(changeTargetAmount(targetAmount));
    dispatch(hideTargetAmountError());
    let regEx = /^\d+$/;
    let decimal = regEx.test(targetAmount);
    if (!decimal) {
      if (!targetAmount.trim()) {
        return dispatch(
          displayTargetAmountError("Please enter a target amount")
        );
      }
      dispatch(displayTargetAmountError("Please enter a valid number"));
    } else if (
      unformatCurrency(targetAmount) <
      unformatCurrency(initialInvestment) + unformatCurrency(monthlyInvestment)
    ) {
      dispatch(
        displayTargetAmountError(
          "Please enter a target amount that is larger than the initial and recurring amount summed up"
        )
      );
    } else {
      return dispatch(hideTargetAmountError())
    }
  };
}

export function handleMonthlyInvestment(
  monthlyInvestment,
  initialInvestment,
  targetAmount,
  type
) {
  return dispatch => {
    dispatch(changeMonthlyInvestment(monthlyInvestment));
    dispatch(hideMonthlyAmountError());
    let regEx = /^\d+$/;
    let decimal = regEx.test(monthlyInvestment);
    if (!decimal) {
      if (monthlyInvestment.trim().length === 0) {
        return dispatch(
          displayMonthlyAmountError(
            "Please enter a valid monthly investment amount. If you don’t want to make a monthly investment, then indicate 0"
          )
        );
      }
      dispatch(displayMonthlyAmountError("Please enter a valid number"));
    } else if (
      unformatCurrency(monthlyInvestment) +
        unformatCurrency(initialInvestment) <
      0 || unformatCurrency(initialInvestment) === ""
    ) {
      return dispatch(
        displayMonthlyAmountError(
          "Please enter either an initial or a monthly investment amount"
        )
      );
    } else if (
      unformatCurrency(targetAmount) <
      unformatCurrency(initialInvestment) + unformatCurrency(monthlyInvestment)
      &&  type !== "investment"
    ) {
      return dispatch(
        displayTargetAmountError(
          "Please enter a target amount that is larger than the initial and recurring amount summed up"
        )
      );
    } else {
      return dispatch(hideTargetAmountError())
    }
  };
}

function displayTargetAmountError(message) {
  return {
    type: DISPLAY_TARGET_AMOUNT_ERROR,
    payload: message
  };
}

export function changeTargetAmount(targetAmount) {
  return {
    type: CHANGE_TARGET_AMOUNT,
    payload: targetAmount
  };
}

export function changeMonthlyInvestment(monthlyInvestment) {
  return {
    type: CHANGE_MONTHLY_INVESTMENT,
    payload: monthlyInvestment
  };
}

function displayMonthlyAmountError(message) {
  return {
    type: DISPLAY_MONTHLY_AMOUNT_ERROR,
    payload: message
  };
}

function displayTargetDateError(message) {
  return {
    type: DISPLAY_TARGET_DATE_ERROR,
    payload: message
  };
}

function hideTargetDateError() {
  return {
    type: HIDE_TARGET_DATE_ERROR
  };
}

function changeTargetDate(targetDate) {
  return {
    type: CHANGE_TARGET_DATE,
    payload: targetDate
  };
}

export function handleTargetDate(targetDate) {
  return dispatch => {
    dispatch(changeTargetDate(targetDate));
    dispatch(hideTargetDateError());
    let regex = /(^(((0[1-9]|1[0-9]|2[0-8])[.](0[1-9]|1[012]))|((29|30|31)[.](0[13578]|1[02]))|((29|30)[.](0[4,6,9]|11)))[.](19|[2-9][0-9])\d\d$)|(^29[.]02[.](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
    if (!regex.test(targetDate)) {
      dispatch(displayTargetDateError("Please enter a valid target date"));
    } else {
      if (
        parseInt(targetDate.slice(-4), 10) -
          new Date(Date.now()).getFullYear() >
        100
      ) {
        dispatch(
          displayTargetDateError(
            "Please choose a target date that is within the next 100 years"
          )
        );
      }
      let targetObj = moment(targetDate, "DD-MM-YYYY");
      let now = new Date();
      let oneMonth = moment(now).add("month", 1);
      if (targetObj.diff(oneMonth, "day") < 0) {
        dispatch(
          displayTargetDateError(
            "Please choose a target date that is at least in 1 month from today"
          )
        );
      }
    }
  };
}

export function instructionCompleted(completed) {
  return {
    type: INSTRUCTIONS_COMPLETED,
    payload: completed
  };
}

export function goalCompleted(completed) {
  return {
    type: GOAL_COMPLETED,
    payload: completed
  };
}

export function strategyCompleted(completed) {
  return {
    type: STRATEGY_COMPLETED,
    payload: completed
  };
}

export function summaryCompleted(completed) {
  return {
    type: SUMMARY_COMPLETED,
    payload: completed
  };
}

export function topUpCompleted(completed) {
  return {
    type: TOP_UP_COMPLETED,
    payload: completed
  };
}

export function withdrawCompleted(completed) {
  return {
    type: WITHDRAW_COMPLETED,
    payload: completed
  };
}

export function transferCompleted(completed) {
  return {
    type: TRANSFER_COMPLETED,
    payload: completed
  };
}

export function confirmGoal(confirmed) {
  return {
    type: GOAL_CONFIRM,
    payload: confirmed
  };
}


export function editGoalStrategy(data) {
  return (dispatch)  => {
    dispatch(editGoalStrategySuccess(data));
  } 
}

function editGoalStrategySuccess(data) {
  return {
    type: EDIT_GOAL_STRATEGY,
    payload: data
  };
}

export function resetGoal() {
  return {
    type: RESET_GOAL
  };
}

function getOnboardingDefaultGoalsSuccess(goals) {
  return {
    type: GET_DEFAULT_GOALS_ONBOARDING,
    payload: goals
  };
}

function getDefaultGoalsSuccess(goals) {
  return {
    type: GET_DEFAULT_GOALS,
    payload: goals
  };
}

function calculateProbabilitySuccess(data) {
  return {
    type: CALCULATE_PROBABILITY,
    payload: data
  };
}

function getGoalCalculateSuccess(data) {
  return {
    type: GET_GOAL_CALCULATE,
    payload: data,
  }
}

function getDefaultGoalsStrategySuccess(data) {
  return {
    type: GET_DEFAULT_GOALS_STRATEGY,
    payload: data
  };
}

function changeGoalStrategySuccess(data) {
  return {
    type: CHANGE_GOALS_STRATEGY,
    payload: data
  };
}

function getDefaultGoalTransferSuccess(data) {
  return {
    type: GET_DEFAULT_GOALS_TRANSFER,
    payload: data
  };
}

function getDefaultGoalBankAccountSuccess(data, investment) {
  data.monthlyInvestment = investment.monthlyInvestment;
  data.initialInvestment = investment.initialInvestment;
  return {
    type: GET_DEFAULT_GOALS_BANK_ACCOUNT,
    payload: data
  };
}

export function passGoalsToSummary(data) {
  return {
    type: PASS_GOALS_TO_SUMMARY,
    payload: data
  };
}

export function calculateProbability() {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_GoalCalculate")
      .get()
      .then(snapshot => {
        dispatch(calculateProbabilitySuccess(snapshot.docs[0].data()));
      });
  };
}

export function getDefaultGoalTransfer(currentGoals) {
  let arr = [];
  arr.push(currentGoals);
  return dispatch => {
    firebase
      .firestore()
      .collection("New_CreateGoalFundingOverview")
      .onSnapshot(docs => {
        docs.forEach(doc => {
          arr.push({
            goalName: doc.data().GoalName,
            initialInvestmentAmount: doc.data().InitialInvestmentAmount,
            monthlyInvestmentAmount: doc.data().MonthlyInvestmentAmount,
            type: doc.data().Type
          });
        });
        let totalOneTimeTransfer = 0;
        let totalMonthlyInvestmentAmount = 0;
        arr.forEach(el => {
          totalOneTimeTransfer +=
            el.typeScreen === "EditGoal"
              ? unformatCurrency(el.topUpInvestmentAmount)
              : unformatCurrency(el.initialInvestmentAmount);
          totalMonthlyInvestmentAmount += unformatCurrency(
            el.monthlyInvestmentAmount
          );
        });
        dispatch(updateTotalOneTimeTransfer(totalOneTimeTransfer));
        dispatch(updateTotalMonthlyTransfer(totalMonthlyInvestmentAmount));
        dispatch(getDefaultGoalTransferSuccess(arr));
      });
  };
}

export function getDefaultGoalBankAccount(investment) {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_BankAccount")
      .onSnapshot(snapshots => {
        dispatch(
          getDefaultGoalBankAccountSuccess(snapshots.docs[0].data(), investment)
        );
      });
  };
}

export function getDefaultGoalsStrategy() {
  return  dispatch => {
    firebase
      .firestore()
      .collection("PortfolioManagement_GetModelPortfolioDetails")
      .doc("3_moderate")
      .onSnapshot(doc => {
        dispatch(getDefaultGoalsStrategySuccess(doc.data()));
      });
  };
}

export function changeGoalStrategy(level, currentValue) {
  return dispatch => {
    switch (level) {
      case "Moderate":
        firebase
          .firestore()
          .collection("PortfolioManagement_GetModelPortfolioDetails")
          .doc("3_moderate")
          .onSnapshot(doc => {
            let data = doc.data();
            data.currentValue = currentValue;
            dispatch(changeGoalStrategySuccess(data));
          });
        break;
      case "Conservative":
        firebase
          .firestore()
          .collection("PortfolioManagement_GetModelPortfolioDetails")
          .doc("2_conservative")
          .onSnapshot(doc => {
            let data = doc.data();
            data.currentValue = currentValue;
            dispatch(changeGoalStrategySuccess(data));
          });
        break;
      case "Aggressive":
        firebase
          .firestore()
          .collection("PortfolioManagement_GetModelPortfolioDetails")
          .doc("4_aggressive")
          .onSnapshot(doc => {
            let data = doc.data();
            data.currentValue = currentValue;
            dispatch(changeGoalStrategySuccess(data));
          });
        break;
      case "Very conservative":
        firebase
          .firestore()
          .collection("PortfolioManagement_GetModelPortfolioDetails")
          .doc("1_very_conservative")
          .onSnapshot(doc => {
            let data = doc.data();
            data.currentValue = currentValue;
            dispatch(changeGoalStrategySuccess(data));
          });
        break;
      case "Very aggressive":
        firebase
          .firestore()
          .collection("PortfolioManagement_GetModelPortfolioDetails")
          .doc("5_very_aggressive")
          .onSnapshot(doc => {
            let data = doc.data();
            data.currentValue = currentValue;
            dispatch(changeGoalStrategySuccess(data));
          });
        break;
      default:
        firebase
          .firestore()
          .collection("PortfolioManagement_GetModelPortfolioDetails")
          .doc("3_moderate")
          .onSnapshot(doc => {
            let data = doc.data();
            data.currentValue = currentValue;
            dispatch(changeGoalStrategySuccess(data));
          });
    }
  };
}

export function getOnBoardingDefaultGoals() {
  return dispatch => {
    dispatch(loadingLayout('loading'))
    firebase
      .firestore()
      .collection("Compliance_GetGoalTemplates")
      .onSnapshot(snapshot => {
        dispatch(loadingLayout(""));
        let arr = [];
        snapshot.forEach(doc => {
          // TODO: Remove 'General investment in onBoarding'
          let data = doc.data();
          if (data.Name !== 'General investment') {
            arr.push(data);
          }
        });
        dispatch(
          getOnboardingDefaultGoalsSuccess(
            arr.sort((a, b) => {
              return a.id - b.id;
            })
          )
        ); 
      });
  };
}
export function getDefaultGoals() {
  return dispatch => {
    dispatch(loadingLayout('loading'))
    firebase
      .firestore()
      .collection("Compliance_GetGoalTemplates")
      .onSnapshot(snapshot => {
        let arr = [];
        snapshot.forEach(doc => {
          arr.push(doc.data());
        });
        dispatch(loadingLayout(""));
        dispatch(
          getDefaultGoalsSuccess(
            arr.sort((a, b) => {
              return a.id - b.id;
            })
          )
        );
      });
  };
}

export function getGoalCalculate() {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_GoalCalculate")
      .onSnapshot(snapshot => {
        dispatch(getGoalCalculateSuccess(snapshot.docs[0].data()))
      })
  }
}

export function getWithDrawAccounts(goalData) {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_EditGoalOptionalWithdraw")
      .onSnapshot(snapshots => {
        dispatch(
          getWithDrawAccountsSuccess(snapshots.docs[0].data(), goalData)
        );
      });
  };
}

function getWithDrawAccountsSuccess(data, goalData) {
  data.goalData = goalData;
  return {
    type: GET_WITHDRAW_ACCOUNT,
    payload: data
  };
}

export function getConfiguration() {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_Configuration")
      .onSnapshot(snapshots => {
        dispatch(getConfigurationSuccess(snapshots.docs[0].data()));
      });
  };
}

function getConfigurationSuccess(data) {
  return {
    type: GET_CONFIGURATION,
    payload: data
  };
}

// Top up action
function displayTopUpAmountError(message) {
  return {
    type: DISPLAY_TOPUP_AMOUNT_ERROR,
    payload: message
  };
}

function hideTopUpAmountError() {
  return {
    type: HIDE_TOPUP_AMOUNT_ERROR
  };
}

export function changeTopUpAmount(topUpAmount) {
  return {
    type: CHANGE_TOPUP_AMOUNT,
    payload: topUpAmount
  };
}

export function handleTopUpAmount(topUpAmount, editGoalData) {
  return dispatch => {
    let withdrawAmount = unformatCurrency(editGoalData.withdrawInvestmentAmount);
    dispatch(changeTopUpAmount(topUpAmount));
    dispatch(hideTopUpAmountError());
    if (withdrawAmount > 0 && topUpAmount > 0) {
      return dispatch(
        displayTopUpAmountError(
          "Please note that you are not able to top-up and withdraw at the same time. Please indicate 0 for either one of the values"
        )
      );
    }
    let regEx = /^[0-9.,]+$/;
    let decimal = regEx.test(topUpAmount);
    if (!decimal) {
      if (!topUpAmount.trim()) {
        return dispatch(displayTopUpAmountError("Please enter a topUp amount"));
      }
      dispatch(displayTopUpAmountError("Please enter a valid number"));
    } else if ( 
      editGoalData.type !== "investment" && 
      unformatCurrency(editGoalData.targetAmount) <
      unformatCurrency(topUpAmount) + unformatCurrency(editGoalData.monthlyInvestment)
      ) {
        dispatch(displayTargetAmountError("Please enter a target amount that is larger than the top up and recurring amount summed up"));
    } else {
      dispatch(hideTargetAmountError())
    }
  };
}

// withdraw action
function displayWithdrawAmountError(message) {
  return {
    type: DISPLAY_WITHDRAW_AMOUNT_ERROR,
    payload: message
  };
}

function hideWithdrawAmountError() {
  return {
    type: HIDE_WITHDRAW_AMOUNT_ERROR
  };
}

export function changeWithdrawAmount(withdrawAmount) {
  return {
    type: CHANGE_WITHDRAW_AMOUNT,
    payload: withdrawAmount
  };
}

export function updateTotalOneTimeTransfer(totalTransfer) {
  return {
    type: TRANSFER_UPDATE_TOTAL_ONE_TRANSFER,
    payload: totalTransfer
  };
}

export function updateTotalMonthlyTransfer(totalTransfer) {
  return {
    type: TRANSFER_UPDATE_TOTAL_MONTHLY_TRANSFER,
    payload: totalTransfer
  };
}

export function changeStatus() {
  return {
    type: STATUS_ERROR_DISPLAY
  };
}

export function handleWithdrawAmount(withdrawAmount, editGoalData) {
  return dispatch => {
    let topUpAmount = unformatCurrency(editGoalData.topUpInvestmentAmount);
    dispatch(changeWithdrawAmount(withdrawAmount));
    dispatch(hideWithdrawAmountError());
    // Conflicting top-up and withdraw
    if (topUpAmount > 0 && withdrawAmount > 0) {
      return dispatch(
        displayWithdrawAmountError(
          "Please note that you are not able to top-up and withdraw at the same time. Please indicate 0 for either one of the values"
        )
      );
    }
    let currency = editGoalData.currencyCode;
    let minimum = editGoalData.minimumInvestmentAmount;
    // If total invested substract draw > mininum then show error message

    let currentInvestAmount = unformatCurrency(editGoalData.currentValue);
    if (currentInvestAmount - withdrawAmount < minimum) {
      return dispatch(
        displayWithdrawAmountError(
          `Please withdraw the entire investment amount or ensure that the remaining balance is at least ${currency} ${minimum} (minimum investment amount)`
        )
      );
    }
    if (withdrawAmount > currentInvestAmount) {
      return dispatch(
        displayWithdrawAmountError(
          "The withdraw amount can’t be higher than the value of your investment goal"
        )
      );
    }
    let regEx = /^[0-9.,]+$/;
    let decimal = regEx.test(withdrawAmount);
    if (!decimal) {
      if (withdrawAmount.trim() === 0) {
        return dispatch(
          displayWithdrawAmountError("Please enter a withdraw amount")
        );
      }
      dispatch(displayWithdrawAmountError("Please enter a valid number"));
    }
  };
}
