import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withBaseLayout from "../HoC/withBaseLayout";
import changeLanguage from "../../setting/languages/languages";
import CONFIG from "../../setting/config";
import { switchHeader } from "../../actions/Header/HeaderActions";
import { Switch } from "antd";

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.textStore.common.notification
      },
      headerRight: "",
      currentPage: "notification"
    });
  }

  onSave() {
    this.props.history.push(this.routesName.SETTINGS_UPDATED);
  }

  render() {
    const items = [
      { icon: "icn icn--trophy", name: this.textStore.notification.goalMilestones },
      { icon: "icn icn--badge", name: this.textStore.notification.goalAlerts },
      { icon: "icn icn--shield", name: this.textStore.notification.securityTransactions },
      { icon: "icn icn--file", name: this.textStore.notification.newDocuments }
    ];
    return (
      <React.Fragment>
        <section>
          {items.map((el, id) => {
            return (
              <div
                className="notification-item"
                key={id}
                style={{ borderTop: id === 0 ? "none" : "" }}
              >
                <span className="notification-item__left">
                  <span className={el.icon} />
                  {el.name}
                  <span className="tooltip-toggle"/>
                </span>
                <Switch className="notification-toggle"/>
            </div>
            );
          })}
        </section>
        <section className="content-main__footer">
          <div className="form-actions p-t-sm">
            <div className="form-actions__item">
              <button
                className="btn btn-primary btn-no-focus__primary__inverted"
                onClick={this.onSave}
              >
                {this.textStore.common.save}
              </button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const EnhancedNotification = withBaseLayout(Notification);

export default withRouter(
  connect(
    null,
    { switchHeader }
  )(EnhancedNotification)
);
