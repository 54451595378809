import moment from 'moment';


function formatPorfolio(porforlios){
	let arr = [];
	porforlios.forEach(porforlio => {
		let obj = {
			category:porforlio.Product.Name.toLowerCase(),
			title:porforlio.Goal.Name,
			probability:porforlio.Probability,
			dateCreated:porforlio.CreateDate,
			currentPerformance:porforlio.CurrentPerformance * 100,
			currentPerformanceAmount:porforlio.CurrentPerformance * porforlio.CurrentValue,
			currentValue:porforlio.CurrentValue,
			currencyCode:porforlio.Currency.CurrencyCode,
			id:porforlio.Id,
			type: porforlio.Product.Name.toLowerCase(),

		};
		arr.push(obj);
	});

	return arr.sort((a,b) => {
		return moment(a.dateCreated).valueOf() - moment(b.dateCreated).valueOf();
	});
}
export default formatPorfolio;