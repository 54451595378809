import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withBaseLayout from "../HoC/withBaseLayout";
import { invertLayout } from "../../actions/AppMain/AppMainAction";
import { switchHeader } from "../../actions/Header/HeaderActions";
import {
  switchNavBar,
  switchNavBarItem
} from "../../actions/NavBar/NavBarActions";
import { switchScreen, savePreviousScreen } from '../../actions/Screen/ScreenAction';
import changeLanguage from "../../setting/languages/languages";
import CONFIG from "../../setting/config";
import DiscoverMoreSection from "./DiscoverMoreSection/DiscoverMoreSection";
import PotentialSection from "./PotentialSection/PotentialSection";
import AvailableGoal from "./AvailableGoal/AvailableGoal";
import GoalPlanning from "./GoalPlanning/GoalPlanning";
import {getGoalDetail} from '../../actions/GoalDetail/GoalDetailActions';
import {
  calculateProbability,
  updateInitialInvestmentOnboarding,
  getOnBoardingDefaultGoals,
  getDefaultGoalsStrategy,
  getDefaultGoals,
  changeGoalStrategy
} from "../../actions/Goals/GoalActions";
import LoadingScreen from "../common/LoadingScreen/LoadingScreen";
import { getCTB, getTestData, clearPreviousInput, updateFirstLoadOnboarding } from "../../actions/Onboarding/OnboardingActions";
import { resetQuestionnairesStore } from "./containers/QuestionnairesPage/action";
import { clearPersonalFinanceData } from "./containers/PersonalFinancePage/action";
import withAuth from "../HoC/withAuth";

export class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialInvestment: 15000,
      monthlyInvestment: 5000,
      timeHorizon: 0,
      isUpdateChart: false
    }
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.timeout = null;
    this.myRef = React.createRef();
    this.scrollToMyRef = this.scrollToMyRef.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.updateOnboardingChart = this.updateOnboardingChart.bind(this);
    this.updateChart = this.updateChart.bind(this);
    this.renderSlider = this.renderSlider.bind(this);
    this.renderInvestmentStrategy = this.renderInvestmentStrategy.bind(this);
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.clearPreviousInput();
    this.props.clearPersonalFinanceData();
    this.props.resetQuestionnairesStore();
    this.props.getTestData();
    if (this.props.appMainUI.layout !== "viewport layout--inverted") {
      this.props.invertLayout("viewport layout--onboarding landing-page");
    }

    if (!this.props.onboardingData.isFirstLoad) { 
      return; 
    }
    this.props.getCTB(0);
    //this.props.getDefaultGoalsStrategy();
    this.props.getDefaultGoals();
    this.props.calculateProbability();
  }

  componentWillReceiveProps(prevProps) {
    // If back to investment detail then it will call update: default investment strategy & default chart 
    if (!this.props.onboardingData.isFirstLoad && !this.state.isUpdateChart)  {
      this.setState({
        initialInvestment: this.props.onboardingData.CTB.investmentEducation.sliderInitialInvestment,
        monthlyInvestment: this.props.onboardingData.CTB.investmentEducation.sliderMonthlyInvestment,
        timeHorizon: this.props.onboardingData.CTB.investmentEducation.sliderTimeHorizon,
        isUpdateChart: true
      }, () => {
        this.updateChart();
      })
    } else { // If first load then waitting to get CTB before calculate for investment strategy
      // CTB updated
      if (prevProps.goalData.defaultGoals !==  this.props.goalData.defaultGoals)  {
        this.props.updateFirstLoadOnboarding(false);
        this.setState({
          initialInvestment: this.props.onboardingData.CTB.investmentEducation.sliderInitialInvestment,
          monthlyInvestment: this.props.onboardingData.CTB.investmentEducation.sliderMonthlyInvestment,
          timeHorizon: this.props.onboardingData.CTB.investmentEducation.sliderTimeHorizon,
          isUpdateChart: true
        }, () => {
          this.renderInvestmentStrategy();
        })
      }

      // First init then call draw default chart when it has investment strategy
      if (prevProps.goalData.strategy.calculatedRisk !== this.props.goalData.strategy.calculatedRisk) {
        this.props.updateInitialInvestmentOnboarding(this.state.initialInvestment, this.state.monthlyInvestment, this.state.timeHorizon)
      }
    }
  }

  updateChart() {
    this.renderInvestmentStrategy();
    this.props.updateInitialInvestmentOnboarding(this.state.initialInvestment, this.state.monthlyInvestment, this.state.timeHorizon)
  }

  componentWillUnmount() {
    if (this.props.appMainUI.layout !== "viewport layout--default") {
      this.props.invertLayout("viewport layout--default");
    }
  }

  onGoalSelect(goal){
		this.props.selectGoal({
			goal:goal,
			selected:true
		});
	}

  scrollToMyRef = () => {
    this.myRef.current.scrollToMyRef()
  };

  onMouseUp = () => {
    this.updateChart()
  };

  changeValue = (value, keyTitle) => {
    // Key title is 
    if (keyTitle === this.textStore.potentialSection.initialInvestment) {
      this.setState({initialInvestment: value });
    } else if (keyTitle === this.textStore.potentialSection.monthlyInvestment) {
      this.setState({monthlyInvestment: value });
    } else if (keyTitle === this.textStore.potentialSection.timeHorizon) {
      this.setState({timeHorizon: value });
    }
  };

  // Will be refactor
  updateOnboardingChart(value, keyTitle) {
    if (keyTitle === this.textStore.potentialSection.initialInvestment) {
      this.setState({initialInvestment: value }, () => {
        this.updateChart()
      });
    } else if (keyTitle === this.textStore.potentialSection.monthlyInvestment) {
      this.setState({monthlyInvestment: value }, () => {
        this.updateChart()
      })
    } else if (keyTitle === this.textStore.potentialSection.timeHorizon) {
      this.setState({timeHorizon: value }, () => {
        this.updateChart()
      })
    }
  }
  
  renderSlider(data) {
    if (this.state.timeHorizon > 0 
      || !this.props.onboardingData.isFirstLoad) {
      return (
        <PotentialSection
            data={data}
            changeValue={this.changeValue}
            onMouseUp={this.onMouseUp}
            updateOnboardingChart={this.updateOnboardingChart}
            language={this.props.language}
          />
      )
    }
  }

  renderInvestmentStrategy() {
    let investmentHorizon = this.props.onboardingData.CTB.investmentEducation.investmentHorizon;
    if (investmentHorizon) {
      let timeZoneIndex = investmentHorizon.findIndex(x => x.maxTimeHorizone >= this.state.timeHorizon);
      let strategyInvestmentIndex = investmentHorizon[timeZoneIndex].ModelPortfolioArr[this.props.onboardingData.CTB.investmentEducation.assumedInvestorProfile -1]
      this.props.changeGoalStrategy(CONFIG.CONSTANT.STRATEGY_INVESTMENT[strategyInvestmentIndex - 1], this.state.initialInvestment);
    }
  }

  render() {
    const isExponential = this.props.onboardingData.testParams.sliderBehavior !== 'Step';
    
    const data = [
      { 
        title: this.textStore.potentialSection.initialInvestment, 
        rightText: "USD", 
        defaultValue: this.props.onboardingData.CTB.investmentEducation.sliderInitialInvestment, 
        min: 5000, 
        max: 100000, 
        step: this.props.onboardingData.CTB.initialInvestmentSliderStep,
        inputWidth: '36%', 
        isExponential: isExponential
      }, {
        title: this.textStore.potentialSection.monthlyInvestment, 
        rightText: "USD", 
        defaultValue: this.props.onboardingData.CTB.investmentEducation.sliderMonthlyInvestment, 
        min: 0, 
        max: 15000, 
        step: this.props.onboardingData.CTB.monthlyInvestmentSliderStep,
        inputWidth: '30%', 
        isExponential: isExponential
      }, {
        title: this.textStore.potentialSection.timeHorizon, 
        rightText: "Years", defaultValue: this.props.onboardingData.CTB.investmentEducation.sliderTimeHorizon, 
        min: 1, 
        max: 40, 
        step: 1,
        inputWidth: '16%', 
        isExponential: false
      },
    ]

    if (this.props.appMainUI.loading === "loading") {
      return <LoadingScreen></LoadingScreen>;
    }
    return (
      <React.Fragment>
        <div className="content-main__body onboarding">
          <AvailableGoal language={this.props.language} />
          <DiscoverMoreSection language={this.props.language} />
          {this.renderSlider(data)}
          <GoalPlanning
            initialInvestment={this.state.initialInvestment}
            monthlyInvestment={this.state.monthlyInvestment}
            timeHorizon={this.state.timeHorizon}
            language={this.props.language}
          />
        </div>
      </React.Fragment>
    );
  }
}

const OnboardingEnhanced = withAuth(withBaseLayout(Onboarding));
function mapStateToProps(props) {
  return {
    onboardingData: props.onboardingData,
    appMainUI: props.appMainUI,
    goalData: props.goalData,
    goalDetailData:props.goalDetailData,
    screenUI: props.screenUI

  };
}

export default withRouter(
  connect(
    mapStateToProps, {
      getTestData,
      switchHeader,
      switchNavBar,
      switchNavBarItem,
      switchScreen,
      invertLayout,
      getGoalDetail,
      calculateProbability,
      getDefaultGoalsStrategy,
      updateInitialInvestmentOnboarding,
      getDefaultGoals,
      getOnBoardingDefaultGoals,
      getCTB,
      clearPreviousInput,
      resetQuestionnairesStore,
      updateFirstLoadOnboarding,
      clearPersonalFinanceData,
      changeGoalStrategy,
      savePreviousScreen
    }
  )(OnboardingEnhanced)
);

