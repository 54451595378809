import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import GenericListItem from "../../common/GenericList/GenericListItem";
import {
  getTransactionHistoryFunding,
  getTransactionHistoryWithDraw
} from "../../../actions/TransactionHistory/TransactionHistoryAction";
import { formatCurrency } from "../../../helpers/formatCurrency";
import { setSupportQuestion } from "../../../actions/Support/SupportActions";
import CONFIG from "../../../setting/config";

class TransactionHistoryGenericPage extends React.Component {
  constructor() {
    super();
    this.routesName = CONFIG.APP_ROUTE;
    this.onContactClick = this.onContactClick.bind(this);
  }
  componentDidMount() {
    this.selectDataType();
  }
  selectSecondaryData(dataType) {
    switch (dataType) {
      case "funding":
        return { icon: "icn--funding-received", title: "Sender" };
      case "withdrawal":
        return { icon: "icn--withdraw", title: "Recipient" };
      default:
        return {};
    }
  }
  selectDataType() {
    let headerData = {
      headerLeft: "",
      headerMiddle: {},
      headerRight: "",
      currentPage: ""
    };
    switch (this.props.dataType) {
      case "funding":
        this.props.getTransactionHistoryFunding();
        headerData = {
          headerLeft: "back",
          headerMiddle: { type: "text", text: "Funding received" },
          headerRight: "",
          currentPage: "fundingReceived"
        };
        break;
      case "withdrawal":
        this.props.getTransactionHistoryWithDraw();
        this.props.setSupportQuestion({ transactionType: "" });
        headerData = {
          headerLeft: "back",
          headerMiddle: { type: "text", text: "Withdrawal" },
          headerRight: "",
          currentPage: "withdrawalHistory"
        };
        break;
      default:
        return;
    }
    this.props.switchHeader(headerData);
  }
  setSupportQuestion() {
    const data = this.props.transactionHistoryData;
    let currencyAmount = 0;
    switch (this.props.dataType) {
      case "funding":
        currencyAmount = data.totalFundingAmount;
        break;
      case "withdrawal":
        currencyAmount = data.totalWithdrawalAmount;
        break;
      default:
        return;
    }
    this.props.setSupportQuestion({
      transactionType: "'Funding received'",
      date: data.transactionData.transactionDate,
      currencyAmount: currencyAmount
    });
  }
  onContactClick() {
    this.setSupportQuestion();
    this.props.history.push(this.routesName.CONTACT_SUPPORT);
  }
  render() {
    const data = this.props.transactionHistoryData;
    const secondaryData = this.selectSecondaryData(this.props.dataType);
    const listItems = [
      { title: "Name", data: data.transactionData.sender.accountName },
      { title: "Bank name", data: data.transactionData.sender.bankName },
      { title: "BIC/Swift", data: data.transactionData.sender.bicSwift },
      { title: "IBAN", data: data.transactionData.sender.iban }
    ];
    return (
      <React.Fragment>
        <GenericListItem
          containerStyle={{ padding: "0 0 18px 0" }}
          iconClass={secondaryData.icon}
          topLeft={data.transactionData.transactionDate}
          left={data.transactionData.transactionName}
        />
        <GenericListItem
          left="Amount"
          leftStyle={{ fontFamily: "SansSemibold", letterSpacing: "0.25px" }}
          right={formatCurrency(
            this.props.dataType === "funding"
              ? data.totalFundingAmount
              : data.totalWithdrawalAmount
          )}
        />
        {data.transactionData.goals.map((item, index) => (
          <GenericListItem
            key={index}
            iconClass={item.Icon}
            left={item.Name}
            right={formatCurrency(
              this.props.dataType === "funding"
                ? item.FundingAmount
                : item.WithdrawalAmount
            )}
            hasBorder={index !== data.transactionData.goals.length - 1}
          />
        ))}
        <GenericListItem
          containerStyle={{ padding: "16px 0 6px 0" }}
          left={secondaryData.title}
          leftStyle={{
            fontFamily: "SansSemibold",
            fontSize: "20px",
            lineHeight: "36px",
            color: "#4261ff"
          }}
        />
        {listItems.map((item, index) => (
          <GenericListItem
            key={item.title}
            left={item.title}
            right={item.data}
            hasBorder={index !== listItems.length - 1}
          />
        ))}
        <div className="content-main__footer" style={{ paddingTop: "38px" }}>
          <div className="form-actions__item">
            <input
              type="button"
              className="btn btn-default"
              value="Contact support"
              onClick={this.onContactClick}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

TransactionHistoryGenericPage.defaultProps = {
  dataType: ""
};

TransactionHistoryGenericPage.propTypes = {
  dataType: PropTypes.string.isRequired
};

const EnhancedTransactionHistoryGenericPage = withBaseLayout(
  TransactionHistoryGenericPage
);

function mapStateToProps(props) {
  return {
    transactionHistoryData: props.transactionHistoryData
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      switchHeader,
      getTransactionHistoryFunding,
      getTransactionHistoryWithDraw,
      setSupportQuestion
    }
  )(EnhancedTransactionHistoryGenericPage)
);
