import React from 'react';
import ReactHighcharts from 'react-highcharts';
import HighchartsMore from 'highcharts-more';
import HighchartsExporting from 'highcharts-exporting';
import ReactHighStock from 'react-highcharts/ReactHighstock';

import { formatCurrency, formatRoundedCurrency } from '../../../helpers/formatCurrency';

HighchartsMore(ReactHighcharts.Highcharts);
HighchartsExporting(ReactHighcharts.Highcharts);


// import iconCheckbox from './check-square-line.svg';
//TODO: move constant value to separate files for maintainability,

function planningSection({
	isGoalDetail,
	planning,
	statusErrorDisplay,
	textStore,
	screen,
	onStatusClick,
}) {
	function renderStatus(probability) {
		switch (probability) {
			case 2:
				return <span style={{ color: '#00b961' }}>On Track</span>;
			case 3:
				return (
					<span style={{ color: '#f5a623', whiteSpace: 'nowrap' }}>
						Action recommended
						<span className='list-action__link list-action__link--more' />
					</span>
				);
			case 1:
				return (
					<span style={{ color: '#dd0f43', whiteSpace: 'nowrap' }}>
						Action required
						<span className='list-action__link list-action__link--more' />
					</span>
				);
			default:
				return <span />;
		}
	}
	function renderWarningMessage(probability) {
		if (probability === 1) {
			return (
				<tr
					id='borderless'
					style={{
						display: planning.type === 'investment' ? 'none' : '',
					}}
				>
					<td
						colSpan='2'
						style={{
							borderTop: '0px',
							borderBottom: 'none',
						}}
					>
						<p
							style={{
								fontFamily: 'OpenSans',
								fontSize: '13px',
								color: '#a1b0fc',
								marginTop: '-22px',
							}}
						>
							<span>
								{statusErrorDisplay
									? 'Please adjust your investment goal parameters until the goal status indicates “on-track”'
									: 'The probability of reaching your investment goal is low. Please review and adjust your investment plan.'}
							</span>
						</p>
					</td>
				</tr>
			);
		} else if (probability === 3) {
			return (
				<tr
					id='borderless'
					style={{
						display: planning.type === 'investment' ? 'none' : '',
					}}
				>
					<td
						colSpan='2'
						style={{
							borderTop: 'none',
							borderBottom: 'none',
						}}
					>
						<p
							style={{
								fontFamily: 'OpenSans',
								fontSize: '13px',
								color: '#a1b0fc',
								marginTop: '-22px',
							}}
						>
							<span>
								{statusErrorDisplay
									? 'Please adjust your investment goal parameters until the goal status indicates “on-track”'
									: 'There is a high probability of exceeding your investment goal. Please review and adjust your investment plan'}
							</span>
						</p>
					</td>
				</tr>
			);
		}
	}
	function renderDelta(probability, delta) {
		switch (probability) {
			case 2:
				return;
			case 3:
				return (
					<tr
						style={{
							display:
								planning.type === 'investment' ? 'none' : '',
						}}
					>
						<th className='cell-icn'>
							<span className='icn icn--excess'>Excess</span>
						</th>
						<td
							className='text-right'
							style={{
								color: '#f5a623',
							}}
						>
							+{formatCurrency(delta)}
						</td>
					</tr>
				);
			case 1:
				return (
					<tr
						style={{
							display:
								planning.type === 'investment' ? 'none' : '',
						}}
					>
						<th className='cell-icn'>
							<span className='icn icn--shortfall'>
								Shortfall
							</span>
						</th>
						<td
							className='text-right'
							style={{
								color: '#dd0f43',
							}}
						>
							{formatCurrency(delta)}
						</td>
					</tr>
				);
			default:
				return;
		}
	}

	function renderOnboardingContain() {
		return (
			<React.Fragment>
				<tr
					className='borderless-top'
					style={{
						display: planning.type === 'investment' ? 'none' : '',
					}}
				>
					<th className='cell-icn'>
						<span className='icn icn--calculator'>
							{textStore.onBoarding.estimatedFutureValue}
						</span>
					</th>
					<td className='text-right'>
						<span className='total-balance-background'>
							≈ {formatRoundedCurrency(planning.totalBalanceAmount)}
						</span>
					</td>
				</tr>
				<tr>
					<th
						className='cell-icn'
						style={{
							borderBottom: '0px',
						}}
					>
						<span className='icn icn--investment'>
							{textStore.onBoarding.totalInvestment}
						</span>
					</th>
					<td
						className='text-right'
						style={{
							borderBottom: '0px',
						}}
					>
						{formatCurrency(planning.totalInitialInvestmentAmount)}
					</td>
				</tr>
				<tr>
					<th
						className='cell-icn'
						style={{
							borderBottom: '0px',
						}}
					>
						<span className='icn icn--strategy'>
							{textStore.common.investmentStrategy}
						</span>
					</th>
					<td
						className='text-right'
						style={{
							borderBottom: '0px',
						}}
					>
						{planning.investmenStrategy}
					</td>
				</tr>
				{renderChart()}
			</React.Fragment>
		);
	}

	function renderGoalContain() {
		return (
			<React.Fragment>
				<tr
					id='borderless'
					onClick={onStatusClick}
					style={{
						display: planning.type === 'investment' ? 'none' : '',
					}}
				>
					<th className='cell-icn' id='borderless-bottom'>
						<span className='icn icn--target'>Status</span>
					</th>
					<th className='text-right bold' id='borderless-bottom'>
						{renderStatus(planning.probability, planning.delta)}
					</th>
				</tr>
				{renderWarningMessage(planning.probability)}
				<tr
					style={{
						display: planning.type === 'investment' ? 'none' : '',
					}}
				>
					<th className='cell-icn'>
						<span className='icn icn--expected-amount'>
							Expected
						</span>
					</th>
					<td className='text-right'>
						≈ {formatCurrency(planning.expectedTargetAmount)}
					</td>
				</tr>
				{renderDelta(planning.probability, planning.delta)}
				<tr>
					<th className='cell-icn'>
						<span className='icn icn--strategy'>
							Investment strategy
						</span>
					</th>
					<td className='text-right'>
						{screen === textStore.screen.createGoal
							? textStore.common.moderate
							: planning.investmenStrategy}
					</td>
				</tr>
				{renderChart()}
				<tr
					style={{
						display:
							isGoalDetail && planning.type !== 'investment'
								? ''
								: 'none',
					}}
				>
					<th className='cell-icn' id='borderless-top'>
						<span className='icn icn--target-amount'>Target</span>
					</th>
					<td className='text-right' id='borderless-top'>
						{formatCurrency(planning.targetAmount)}
					</td>
				</tr>
				<tr
					style={{
						display:
							isGoalDetail && planning.type !== 'investment'
								? ''
								: 'none',
					}}
				>
					<th className='cell-icn'>
						<span className='icn icn--calendar'>Target date</span>
					</th>
					<td className='text-right'>{planning.targetDate}</td>
				</tr>
				<tr style={{ display: isGoalDetail ? '' : 'none' }}>
					<th className='cell-icn'>
						<span className='icn icn--investment'>
							Total investment
						</span>
					</th>
					<td className='text-right'>
						{formatCurrency(planning.totalInvestment)}
					</td>
				</tr>
				<tr
					style={{
						display: isGoalDetail ? '' : 'none',
						borderBottom: 'none',
					}}
				>
					<th
						className='cell-icn'
						style={{
							borderBottom: '0px',
						}}
					>
						<span className='icn icn--recurring'>
							Monthly investment
						</span>
					</th>
					<td
						className='text-right'
						style={{
							borderBottom: '0px',
						}}
					>
						{formatCurrency(planning.monthlyInvestment)}
					</td>
				</tr>
			</React.Fragment>
		);
	}

	function renderChart() {
		return (
			<tr style={{ borderBottom: 'none' }}>
				<td colSpan='2' style={{ paddingBottom: '0px' }}>
					<div className='chart__container'>
						<div className='chart__element__outer'>
							<div className='chart_element_inner'>
								{screen === textStore.screen.onBoarding ? 
								<ReactHighcharts 
									config={planning.planningChartConfig}
									domProps={{ className: 'chart__element' }} 
								/>:
								<ReactHighStock 	
									config={planning.planningChartConfig}
									domProps={{ className: 'chart__element' }} />
							}
							</div>
						</div>
					</div>
				</td>
			</tr>
		);
	}

	function renderPlanningSelection() {
		if (screen === textStore.screen.onBoarding) {
			return renderOnboardingContain();
		} else {
			return renderGoalContain();
		}
	}

	return <React.Fragment>{renderPlanningSelection()}</React.Fragment>;
}

export default planningSection;
