export default class LogSlider {
  constructor(props) {
    this.minPos = props.minPos || 0;
    this.maxPos = props.maxPos || 100;
    this.minLogVal = Math.log(props.minVal || 1);
    this.maxLogVal = Math.log(props.maxVal || 10000);

    this.scale = (this.maxLogVal - this.minLogVal) / (this.maxPos - this.minPos);
  }

  value = (position) => {
    return Math.exp((position - this.minPos) * this.scale + this.minLogVal);
  }

  position = (value) => {
    return this.minPos + (Math.log(value) - this.minLogVal) / this.scale;
  }
}