import React from 'react';
import moment from "moment";
import { DatePicker } from 'antd';

import './styles.less';

class MyDatePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      openedCalendar: false,
      value: props.defaultValue,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickWindow);
  }

  onClickWindow = (evt) => {
    const currentEle = document.getElementById(`mydatepicker-${this.props.name}`);

    if (!currentEle.contains(evt.target)) {
      this.setState({ openedCalendar: false });
    }
  }

  onToggleCalendar = () => {
    this.setState(({ openedCalendar }) => ({ openedCalendar: !openedCalendar }));
  }

  onPickCalendar = (date, dateString) => {
    this.setState({
      openedCalendar: false,
      value: dateString
    });
  }

  getCalendarContainer = () => {
    return document.getElementById(`mydatepicker-container-${this.props.name}`);
  }

  render() {
    const {
      label,
      className,
      name = 'my',
      dateFormat = 'DD/MM/YYYY',
      defValue = '11/06/2019',
    } = this.props;
    const cusClassName = className || name;
    const { openedCalendar, value } = this.state;

    return (
      <div className="form-group">
        <p className="control-label" >{label}</p>
        <div id={`mydatepicker-${name}`} className={['control-wrapper', `mydatepicker`].join(' ')}>
          <div id={`mydatepicker-container-${name}`} className="mydatepicker__container">
            <input
              className={['mydatepicker__input onboarding-input-field__full__large input-custom', cusClassName, `${cusClassName}-mydatepicker`].join(' ')}
              placeholder={dateFormat}
              id={name}
              name={name}
              type="text"
              maxLength={24}
              autoComplete="off"
              value={value}
              onFocus={this.onToggleCalendar}
            />
            <span className="mydatepicker__button" onClick={this.onToggleCalendar}>
              <span className="mydatepicker__button-icon"></span>
            </span>
          </div>
          <DatePicker
            format={dateFormat}
            className="mydatepicker__calendar"
            popupStyle={{ width: "100%" }}
            getCalendarContainer={this.getCalendarContainer}
            dropdownClassName="mydatepicker__calendar-popup"
            onChange={this.onPickCalendar}
            open={openedCalendar}
            defaultPickerValue={moment(defValue, dateFormat)}
            value={moment(value || defValue, dateFormat)}
          />
        </div>
      </div>
    )
  }
}

export default MyDatePicker;