import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CustomDropdown from '../../common/CustomDropdown';
import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import data from './data.json'

import './styles.less';
import CONFIG from '../../../setting/config';


class FinancialSituation extends React.PureComponent {
  constructor() {
    super()
    this.routesName = CONFIG.APP_ROUTE;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Financial situation' },
      headerRight: '',
      currentPage: 'financialSituation',
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="financial-situation">
          <CustomDropdown
            className="financial-situation-dropdown"
            tooltip="true"
            name="annoualIncome"
            required=""
            label="Annoual income (USD)"
            placeholder="Please select"
            defaultSelected="50000"
            data={data.annoualIncome}
          />
          <CustomDropdown
            className="financial-situation-dropdown"
            tooltip="true"
            name="totalWealth"
            required=""
            label="Total wealth (USD)"
            placeholder="Please select"
            defaultSelected="100000"
            data={data.totalWealth}
          />
          <CustomDropdown
            className="financial-situation-dropdown"
            tooltip="true"
            name="originInvestment"
            required=""
            label="Origin of investment"
            placeholder="Please select"
            defaultSelected="20000"
            data={data.originInvestment}
          />
          <CustomDropdown
            className="financial-situation-dropdown"
            tooltip="true"
            name="politicalExposed"
            required=""
            label="Are you a political exposed person?"
            placeholder="Please select"
            defaultSelected="yes"
            data={data.politicalExposed}
          />
          <CustomDropdown
            className="financial-situation-dropdown"
            tooltip="true"
            name="beneficalOwner"
            required=""
            label="Are you the benefical owner of this portfolio?"
            placeholder="Please select"
            defaultSelected="no"
            data={data.beneficalOwner}
          />
        </div>
        <div className="content-main__footer">
          <div className="form-actions">
            <div className="form-actions__item">
              <button type="submit" className="btn btn-primary btn-no-focus__primary" onClick={() => this.props.history.push(this.routesName.SETTINGS_UPDATED)}>Save</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(connect(null, { switchHeader })(withBaseLayout(FinancialSituation)));