import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withBaseLayout from '../../HoC/withBaseLayout';
import changeLanguage from '../../../setting/languages/languages';
import GoalFooter from '../../Goals/GoalFooter/GoalFooter';
import CONFIG from '../../../setting/config';
import { savePreviousUserInfo, getTestData, getCTB, saveVerifyOnlineStep } from '../../../actions/Onboarding/OnboardingActions';
import { switchHeader } from '../../../actions/Header/HeaderActions';
import Stepper from '../../../components/Onboarding/RiskProfiling/Stepper/Stepper';

import './styles.less';
import customerVerify_2 from './customerVerify_2.jpg';
import customerVerify_3 from './customerVerify_3.jpg';
import navBack from './nav_back.svg';
import navNext from './nav_ok.svg';
import { savePreviousScreen } from '../../../actions/Screen/ScreenAction';
import { toggleAsyncLoadingStatus } from '../../../actions/AppMain/AppMainAction';

const routesName = CONFIG.APP_ROUTE;

class CustomerVerification extends React.Component {
	constructor(props) {
		super(props);
		this.textStore = changeLanguage(props.language);
		this.state = {
			activeStep: 7,
			totalSteps: 8,
			currentStage: 1
		};
		this.routesName = CONFIG.APP_ROUTE;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		this.props.saveVerifyOnlineStep(1);
		this.props.savePreviousScreen(this.textStore.screen.onlineVerification);
	}

	getHeaderUiCssIndex() {
		// To make sure the overlay always be behind the header => Buttons at header is able to be clickable
		let domHeaderMain = document.querySelector('.header-main');
		let compStyles = window.getComputedStyle(domHeaderMain);
		return Number(compStyles.getPropertyValue('z-index')) - 1
	}

	renderStage_1 = () => {
		this.props.switchHeader({
			headerLeft: 'back',
			headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle },
			headerRight: '',
			currentPage: this.textStore.screen.onlineVerification,
		})
		const { pleaseLabel, onlineVerification, countryOfGov, stepOne, stepTwo, proceedToVerify } = this.textStore.customerOnlineVerification;
		return (
			<React.Fragment>
				<div className="online-verification">
					<Stepper
						activeStep={this.state.activeStep}
						totalSteps={this.state.totalSteps}
						textStore={this.textStore}
						headerLeft={this.textStore.customerOnlineVerification.verification}
					/>
					<p className="title">{onlineVerification}</p>
					<div className="label">{countryOfGov}</div>
					{/* TODO */}
					<div className="countryValue">{this.props.onboardingData.personalParticular.nationality}</div>
					<div className="please">{pleaseLabel}</div>
					<div className="guideStep">
						<div className="step">
							<b>1. </b>
							<span>{stepOne}</span>
						</div>
						<div className="step">
							<b>2. </b>
							{stepTwo}
						</div>
					</div>
				</div>
				<GoalFooter
					onClickNext={() => {
						this.setState({ currentStage: 2 }, () => {
							this.props.switchHeader({
								headerLeft: 'back-white',
								headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle, styles: { color: '#ffff' } },
								headerRight: '',
								currentPage: this.textStore.screen.onlineVerification,
							});
							this.props.saveVerifyOnlineStep(this.state.currentStage);
						});
					}}
					renderBackButton={false}
					nextButtonFullWidth={true}
					enableNextButton={this.state.canGoNext}
					nextButtonText={proceedToVerify}
				/>
			</React.Fragment>
		);
	};
	styleOneFoldScreen = `
		html,
		body{
			height:100%;
			overflow:hidden;
			position: fixed;
			top:0;
			left:0;
			right:0
		}
	`
	renderStage_2 = () => {
		return (
			<div className="bgBlack" style={{ zIndex: this.getHeaderUiCssIndex() }}>
				<style>
					{this.styleOneFoldScreen}
				</style>
				<b
					className="simulateApp"
					style={{
						backgroundImage: `url(${customerVerify_2})`
					}}
				/>
				<span className="btnBack" style={{ backgroundImage: `url(${navBack})` }}></span>
				<span className="btnNext" onClick={() => 
					this.setState({ currentStage: 3 }, () => {
						this.props.saveVerifyOnlineStep(this.state.currentStage);
					})
					} style={{ backgroundImage: `url(${navNext})` }}>
				</span>
			</div>
		);
	};

	renderStage_3 = () => {
		return (
			<div className="bgBlack" style={{ zIndex: this.getHeaderUiCssIndex() }}>
				<style>
					{this.styleOneFoldScreen}
				</style>
				<b
					className="simulateApp"
					style={{
						backgroundImage: `url(${customerVerify_3})`
					}}
				/>
				<span className="btnBack" style={{ backgroundImage: `url(${navBack})` }}></span>
				<span className="btnNext" onClick={() => {
					//this.setState({ currentStage: null }, this.callService);
					this.callService()
				}}
					style={{ backgroundImage: `url(${navNext})` }}
				>
				</span>
			</div>
		);
	};

	callService = () => {
		this.props.toggleAsyncLoadingStatus({ status: true, text: 'Verifying' });
		let timerSubmit = window.setTimeout(() => {
			this.props.toggleAsyncLoadingStatus({ status: false, text: 'Verifying' });

			if (this.props.onboardingData.testParams.responsePutOnlineVerification === 504) {
				this.props.history.push({
					pathname: this.routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.PATH,
					state: {}
				});
				return;
			}
			if (this.props.onboardingData.testParams.responsePutOnlineVerification !== 200) {
				this.props.history.push({
					pathname: this.routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.FAILED,
					state: {
						title: this.textStore.customerOnlineVerification.failure.title,
						desc: 'Online verification was not successful. Please try again or contact the customer support for assistance.',
						buttonName: this.textStore.PersonalFinance.failure.buttonName
					}
				});
				return;
			}
			this.props.history.push({
				pathname: this.routesName.ONBOARDING.COMPLIANCE_DECLARATION,
				state: {}
			});
			window.clearTimeout(timerSubmit);
		}, 1000);
	};

	render() {
		const { location } = this.props;
		let renderStage;
			switch (this.props.onboardingData.verifyOnlineStep) {
				case 1:
					renderStage = this.renderStage_1();
					break;
				case 2:
					renderStage = this.renderStage_2();
					break;
				case 3:
					renderStage = this.renderStage_3();
					break;
	
				default:
					break;
			}
		if (location.pathname === `${routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.PATH}`) {
			return <React.Fragment>{renderStage}</React.Fragment>;
		}
	}
}

function mapStateToProps(props) {
	return {
		onboardingData: props.onboardingData
	};
}

const CustomerVerificationEnhanced = withBaseLayout(CustomerVerification);

export default withRouter(
	connect(mapStateToProps, { savePreviousUserInfo, getTestData, switchHeader, getCTB, savePreviousScreen, toggleAsyncLoadingStatus, saveVerifyOnlineStep })(
		CustomerVerificationEnhanced
	)
);
