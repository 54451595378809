import React from 'react';
import Slider from 'react-slick';
import AllocationPieChart from './AllocationPieChart';
import AllocationRegions from './AllocationRegions';
import AllocationCurrencies from './AllocationCurrencies';
import ExpandableListView from '../../common/ExpandableListView/ExpandableListView';

class AllocationSection extends React.Component {
	constructor() {
		super();
		this.state = {
			currentIndex: 0
		}
		this.sliderRef = React.createRef();
	}

	componentDidMount(){
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});
	}

	touchStart(e){
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
	}
	
	preventTouch(e){
    const minValue = 5; // threshold
    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if(Math.abs(this.clientX) > minValue){ 
			e.defaultPrevented && e.preventDefault(); 
			e.returnValue = false;
			return false;
    }
	}

	componentWillUnmount(){
    window.removeEventListener('touchstart', this.touchStart);
		window.removeEventListener('touchmove', this.preventTouch, {passive: false});
	}
	
	renderTitle() {
		if (this.state.currentIndex === 0) {
			return 'Asset Classes'
		} else if (this.state.currentIndex === 1) {
			return 'Regions';
		}
		return 'Currencies';
	}
	render(){
		const settings = {
			speed: 500,
			slidesToScroll: 1,
			swipeToSlide:true,
			arrows:false,
			dots:true,
			infinite:false,
			afterChange: ((index) =>  {
				this.setState({
					currentIndex: index
				});
			})
	};
	

		return(
			<React.Fragment>
				<div className="row-title">{this.renderTitle()}</div>
				<Slider ref={this.sliderRef} {...settings}>
					<AllocationPieChart allocations={this.props.allocations}/>
					<AllocationRegions regions={this.props.allocations.regions} /> 
					<AllocationCurrencies/>
				</Slider>
				<h2 className="section-title">
					Position
				</h2>
				<table className="list-view">
					<tbody>
						<ExpandableListView
							passDataFromPerformanceGoalDetail={this.props.passDataFromPerformanceGoalDetail}
							currentTab={this.props.currentTab}
							savePreviousTab={this.props.savePreviousTab}
							data={this.props.positions}
							defaultView={'Allocation'}
						/>
					</tbody>
				</table>
			</React.Fragment>
		)
	}
}


export default AllocationSection;