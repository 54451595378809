import {
  SWITCH_SCREEN,
  SAVE_PREVIOUS_SCREEN,
  CLEAR_PREVIOUS_SCREEN,

} from './ScreenActionType'

export function switchScreen(type){
  return {
    type:SWITCH_SCREEN,
    payload:type
  }
}


export function savePreviousScreen(type){
  return {
    type: SAVE_PREVIOUS_SCREEN,
    payload:type
  }
}

export function clearPreviousScreen(){
  return {
    type:CLEAR_PREVIOUS_SCREEN,
  }
}

