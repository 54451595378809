import React from 'react';
import { connect } from "react-redux";
import { DatePicker } from 'antd';
import moment from "moment";
import { withRouter } from "react-router-dom";
import withBaseLayout from "../../HoC/withBaseLayout";
import changeLanguage from "../../../setting/languages/languages";
import GoalFooter from "../../Goals/GoalFooter/GoalFooter";
import CustomDropdown from "../../common/CustomDropdown/index";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import Stepper from '../../Onboarding/RiskProfiling/Stepper/Stepper';
import { savePersonalParticular, savePreviousUserInfo } from "../../../actions/Onboarding/OnboardingActions";
import './_personal-particular.less';

import 'antd/lib/date-picker/style/index.css';
import CONFIG from '../../../setting/config';
import TextInput from '../../common/CustomInput/TextInput/TextInput';
import { getPasswordRegex, getInvalidPasswordMessage } from './utils'
import DropdownCountryResidence from './DropdownCountryResidence';

class PersonalParticular extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowDatePicker: true,
      activeExpandType: {
        setting: true,
        information: true
      },
      activeStep: 6,
      totalSteps: 8,
      personInfo: {
        email: props.personalParticular.email || '',
        password: '',
        repeatPassword: '',
        salutationValue: props.personalParticular.salutationValue || '',
        firstName: props.onboardingData.userInfo.firstName || '',
        lastName: props.onboardingData.userInfo.lastName || '',
        nationality: props.personalParticular.nationality || '',
        birthday: props.personalParticular.birthday || '',
        address1: props.personalParticular.address1 || '',
        address2: props.personalParticular.address2 || '',
        postalCode: props.personalParticular.postalCode || '',
        residence: props.personalParticular.residence || '',
        city: props.personalParticular.city || '',
      },
      validationField: {
        email: {
          invalid: false,
          isEmpty: false
        },
        password: {
          invalid: false,
          isEmpty: false
        },
        repeatPass: {
          invalid: false,
          isEmpty: false,
          disabled: true
        },
        salutation: {
          isEmpty: true
        },
        firstName: {
          isEmpty: false
        },
        lastName: {
          isEmpty: false
        },
        nationality: {
          isEmpty: true
        },
        address1: {
          isEmpty: false
        },
        postalCode: {
          isEmpty: false
        },
        residence: {
          isEmpty: true
        },
        city: {
          isEmpty: false
        },
        birthday: {
          invalid: false,
          isEmpty: false,
          investmentAgeValid: false
        }
      },
      canGoNext: false,
      canOpenPicker: false,
      isFirstClick: true
    }
    this.emailRegExp = /^(\s*[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9}\s*)$/;
    this.passRegExp = getPasswordRegex(this.props.passwordStrength);
    this.numberRegExp = /\D/;
    this.dateFormat = 'DD/MM/YYYY';
    this.CONSTANTS_NAME = {
      email: 'email',
      password: 'password',
      repeatPassword: 'repeatPassword',
      firstName: 'firstName',
      lastName: 'lastName',
      address1: 'address1',
      address2: 'address2',
      postalCode: 'postalCode',
      city: 'city',
      birthday: 'birthday',
      salutation: 'salutation',
      nationality: 'nationality',
      residence: 'residence'
    }
    this.textStore = changeLanguage(props.language);
    this.onClickNext = this.onClickNext.bind(this);
    this.routesName = CONFIG.APP_ROUTE;
  }

  componentWillMount() {
    this.fieldsValidation();
  }

  onClickWindow = (evt) => {
    const currentEle = document.getElementById(`wrapper-date-picker`);
    if (evt.target.text) {
      this.setState({ canOpenPicker: true });
    } else {
      if (currentEle && !currentEle.contains(evt.target)) {
        this.setState({ canOpenPicker: false });
      }
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.textStore.common.classicRoboTitle,
      },
      headerRight: "",
      currentPage: this.textStore.screen.personalParticular,
    });
    document.addEventListener('click', this.onClickWindow);
  }

  onClickNext() {
    // TODO Hard code
    this.props.savePersonalParticular(this.state.personInfo);
    this.props.savePreviousUserInfo({ firstName: this.state.personInfo.firstName, lastName: this.state.personInfo.lastName })
    this.props.history.push(this.routesName.ONBOARDING.PERSONAL_FINANCE.PATH)
  }

  onColapseExpand = e => {
    e.preventDefault();
    switch (e.target.id) {
      case 'setting': {
        this.setState((state) => ({ activeExpandType: { setting: !state.activeExpandType.setting, information: state.activeExpandType.information } }));
        break;
      }
      case 'information': {
        this.setState((state) => ({ activeExpandType: { information: !state.activeExpandType.information, setting: state.activeExpandType.setting } }));
        break;
      }
      default:
        break;
    }
  }

  getDatePickerElement = () => {
    return (
      document.getElementById("wrapper-date-picker")
    )
  }

  onBirthdayChange(date, dateString) {
    if (!this.checkValidBirthday(dateString)) {
      this.setState(prevState => ({
        validationField: {
          ...prevState.validationField,
          birthday: {
            invalid: false,
            isEmpty: false,
            investmentAgeValid: true
          }
        }
      }));
    } else {
      this.setState(prevState => ({
        validationField: {
          ...prevState.validationField,
          birthday: {
            invalid: false,
            isEmpty: false,
            investmentAgeValid: false
          }
        }
      }));
    }
    this.setState(prevState => ({
      personInfo: {
        ...prevState.personInfo,
        birthday: dateString
      },
      canOpenPicker: false
    }), () => this.fieldsValidation());
  }

  onChangeValue = evt => {
    evt.preventDefault();
    const { id, value } = evt.target;
    switch (id) {
      case this.CONSTANTS_NAME.email:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            email: value
          }
        }), () => this.fieldsValidation());
        break;
      case this.CONSTANTS_NAME.password:
        // CR4-450
        // A/C: Current behavior is that Repeat Password field become editable only if Password is entered correctly, 
        // Change this to remain editable all the time. 
        const isRepeatPasswordFieldEditable = true;
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            repeatPass: {
              ...prevState.validationField.repeatPass,
              disabled: !isRepeatPasswordFieldEditable
            }
          }
        }));

        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            password: value
          }
        }), () => this.fieldsValidation());
        break;
      case this.CONSTANTS_NAME.repeatPassword:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            repeatPassword: value
          }
        }), () => this.fieldsValidation());
        break;
      case this.CONSTANTS_NAME.firstName:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            firstName: value
          }
        }), () => this.fieldsValidation());
        break;
      case this.CONSTANTS_NAME.lastName:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            lastName: value
          }
        }));
        break;
      case this.CONSTANTS_NAME.address1:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            address1: value
          }
        }), () => this.fieldsValidation());
        break;
      case this.CONSTANTS_NAME.address2:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            address2: value
          }
        }));
        break;
      case this.CONSTANTS_NAME.postalCode:
        if (this.numberRegExp.test(value)) {
          return;
        }
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            postalCode: value
          }
        }));
        break;
      case this.CONSTANTS_NAME.city:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              city: {
                isEmpty: true
              }
            }
          }));
        }
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            city: value
          }
        }), () => this.fieldsValidation());
        break;
      case this.CONSTANTS_NAME.birthday:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            birthday: value
          }
        }), () => this.fieldsValidation());
        break;
      default:
        break;
    }
    this.fieldsValidation();
  }

  formatDropdownData(data, type) {
    let result = [];
    switch (type) {
      case this.CONSTANTS_NAME.nationality:
        for (let item of data) {
          result.push({ value: item.Name, text: item.Name });
        }
        break;
      default:
        for (let item of data) {
          result.push({ value: item, text: item });
        }
        break;
    }
    return result;
  }

  handleSelect = item => {
    switch (item.name) {
      case this.CONSTANTS_NAME.salutation:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            salutationValue: item.value,
          },
          validationField: {
            ...prevState.validationField,
            salutation: {
              isEmpty: false
            }
          }
        }), () => {
          this.fieldsValidation();
        });
        break;
      case this.CONSTANTS_NAME.nationality:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            nationality: item.value
          },
          validationField: {
            ...prevState.validationField,
            nationality: {
              isEmpty: false
            }
          }
        }), () => this.fieldsValidation());
        break;
      case this.CONSTANTS_NAME.residence:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            residence: item.value,
          },
          validationField: {
            ...prevState.validationField,
            residence: {
              isEmpty: false
            }
          }
        }), () => this.fieldsValidation());
        break;
      default:
        break;
    }
  }

  onHandleBlur = e => {
    e.preventDefault();
    const { id, value } = e.target;
    switch (id) {
      case this.CONSTANTS_NAME.email:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              email: {
                isEmpty: true,
                invalid: false
              }
            }
          }));
          return;
        }
        if (!this.emailRegExp.test(value)) {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              email: {
                invalid: true,
                isEmpty: false
              }
            }
          }))
        }
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            email: value.trim()
          },
          isFirstClick: true
        }))
        break;
      case this.CONSTANTS_NAME.password:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              password: {
                isEmpty: true,
                invalid: false
              }
            }
          }));
          return;
        }
        if (!this.passRegExp.test(value)) {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              password: {
                invalid: true,
                isEmpty: false
              }
            }
          }))
        }
        this.setState({ isFirstClick: true })
        break;
      case this.CONSTANTS_NAME.repeatPassword:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              repeatPass: {
                invalid: false,
                isEmpty: true,
              }
            }
          }));
          return;
        }
        if (value !== this.state.personInfo.password) {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              repeatPass: {
                invalid: true,
                isEmpty: false,
              }
            }
          }));
        }
        this.setState({ isFirstClick: true })
        break;
      case this.CONSTANTS_NAME.firstName:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              firstName: {
                isEmpty: true
              }
            }
          }));
        }
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            firstName: value.trim()
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.lastName:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              lastName: {
                isEmpty: true
              }
            }
          }));
        }
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            lastName: value.trim()
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.address1:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              address1: {
                isEmpty: true
              }
            }
          }));
        }
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            address1: value.trim()
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.address2:
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            address2: value.trim()
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.postalCode:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              postalCode: {
                isEmpty: true
              }
            },
            isFirstClick: true
          }));
        }
        break;
      case this.CONSTANTS_NAME.city:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              city: {
                isEmpty: true
              }
            }
          }));
        }
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            city: value.trim()
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.birthday:
        if (value === '') {
          this.setState(prevState => ({
            validationField: {
              ...prevState.validationField,
              birthday: {
                isEmpty: true,
                invalid: false,
                investmentAgeValid: false
              }
            }
          }));
        } else {
          if (!moment(value, this.dateFormat, true).isValid()) {
            this.setState(prevState => ({
              validationField: {
                ...prevState.validationField,
                birthday: {
                  invalid: true,
                  isEmpty: false,
                  investmentAgeValid: false
                }
              }
            }));
          } else {
            if (!this.checkValidBirthday(value)) {
              this.setState(prevState => ({
                validationField: {
                  ...prevState.validationField,
                  birthday: {
                    invalid: false,
                    isEmpty: false,
                    investmentAgeValid: true
                  }
                }
              }));
            }
          }
        }
        this.setState(prevState => ({
          personInfo: {
            ...prevState.personInfo,
            birthday: value.trim()
          },
          isFirstClick: true
        }));
        this.checkValidBirthday(value, this.props.onboardingData.CTB.maxInvestmentAge, this.props.onboardingData.CTB.minInvestmentAge)
        break;
      default:
        break;
    }
  }

  onHandleFocus = e => {
    const { id } = e.target;
    switch (id) {
      case this.CONSTANTS_NAME.email:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            email: {
              isEmpty: false,
              invalid: false
            }
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.password:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            password: {
              isEmpty: false,
              invalid: false
            }
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.repeatPassword:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            repeatPass: {
              invalid: false,
              isEmpty: false,
              isMatched: false
            }
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.firstName:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            firstName: {
              isEmpty: false
            }
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.lastName:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            lastName: {
              isEmpty: false
            }
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.address1:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            address1: {
              isEmpty: false
            }
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.address2:
        this.setState({ isFirstClick: true });
        break;
      case this.CONSTANTS_NAME.postalCode:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            postalCode: {
              isEmpty: false
            }
          },
          isFirstClick: true
        }));
        break;

      case this.CONSTANTS_NAME.city:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            city: {
              isEmpty: false
            }
          },
          isFirstClick: true
        }));
        break;
      case this.CONSTANTS_NAME.birthday:
        this.setState(prevState => ({
          validationField: {
            ...prevState.validationField,
            birthday: {
              isEmpty: false,
              invalid: false,
              investmentAgeValid: false
            }
          },
          canOpenPicker: false,
          isFirstClick: true
        }));
        break;
      default:
        break;
    }
  }

  openDatePicker = () => {
    this.setState(prevState => ({ canOpenPicker: !prevState.canOpenPicker }));
  }

  fieldsValidation() {
    if (
      this.state.personInfo.email &&
      this.emailRegExp.test(this.state.personInfo.email) &&
      this.state.personInfo.password &&
      this.passRegExp.test(this.state.personInfo.password) &&
      this.state.personInfo.repeatPassword &&
      (this.state.personInfo.repeatPassword === this.state.personInfo.password) &&
      this.state.personInfo.salutationValue &&
      this.state.personInfo.firstName &&
      this.state.personInfo.lastName &&
      this.state.personInfo.nationality &&
      this.state.personInfo.address1 &&
      this.state.personInfo.postalCode &&
      this.state.personInfo.residence &&
      this.state.personInfo.city &&
      this.state.personInfo.birthday &&
      moment(this.state.personInfo.birthday, this.dateFormat, true).isValid() &&
      this.checkValidBirthday(this.state.personInfo.birthday)
    ) {
      this.setState({ canGoNext: true });
    } else {
      this.setState({ canGoNext: false });
    }
  }

  checkValidBirthday(birthday) {
    const maxAge = this.props.onboardingData.CTB.maxInvestmentAge ;
    const minAge = this.props.onboardingData.CTB.minInvestmentAge;
    const currentMin = moment();
    currentMin.month(currentMin.month() - (12 * minAge));
    const birthdayFormat = moment(birthday, this.dateFormat);
    const currentMax = moment();
    currentMax.month(currentMax.month() - (12 * maxAge));

    const milMin = new Date(currentMin.year(), currentMin.month(), currentMin.date()).getTime();
    const milMax = new Date(currentMax.year(), currentMax.month(), currentMax.date()).getTime();
    const milBirthday = new Date(birthdayFormat.year(), birthdayFormat.month(), birthdayFormat.date()).getTime();
    if (milBirthday <= milMin && milBirthday >= milMax) {
      return true;
    } else {
      return false;
    }
  }

  onTextClick = (evt) => {
    evt.preventDefault();

    if (!this.state.isFirstClick) { return; }

    const target = evt.target;
    const valLen = evt.target.value.length;

    this.setState({ isFirstClick: false });

    target.setSelectionRange(valLen, valLen);
    target.focus();
  }

  onMitigateCopyPaste = (e) => {
    e.preventDefault();
  }

  render() {
    const { minInvestmentAge, maxInvestmentAge, salutation, countryData } = this.props.onboardingData.CTB;

    let newCountryData = JSON.parse(JSON.stringify(countryData))

    // TODO: Don't use DialCode for 
    newCountryData.forEach(item => {
      item.DialCode && delete item.DialCode;
    })

    const salutationData = salutation.length > 0 ? this.formatDropdownData(salutation, this.CONSTANTS_NAME.salutation) : [];
    const countryDataFormat = newCountryData.length > 0 ? this.formatDropdownData(newCountryData, this.CONSTANTS_NAME.nationality) : [];
    const now = moment();
    now.month(now.month() - (12 * minInvestmentAge));
    const defaultPickerValue = now;
    const {
      personInfo: {
        email,
        salutationValue,
        firstName,
        lastName,
        nationality,
        birthday,
        address1,
        address2,
        postalCode,
        residence,
        city
      }
    } = this.state;
    const invalidPasswordMessage = getInvalidPasswordMessage(this.props.passwordStrength, this.textStore.personalParticular.validPassword, this.textStore.personalParticular.validPasswordWeek);
    return (
      <React.Fragment>
        <section>
          <Stepper
            headerLeft={this.textStore.common.registration}
            activeStep={this.state.activeStep}
            totalSteps={this.state.totalSteps}
            textStore={this.textStore}
          />
        </section>
        <section className="personal-particular-container">
          {/* account-setting */}
          <form>
            <div className={['account', this.state.activeExpandType.setting ? 'expanded' : ''].join(' ')}>
              <div id="setting" className="account-header" onClick={this.onColapseExpand} ref="personal">
                <span id="setting" className="account-header__icon"></span>
                <span id="setting" className="account-header__text">{this.textStore.personalParticular.accountSetting}</span>
              </div>
              <div className="account-content">
                {/* email */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.personalParticular.email}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', this.state.validationField.email.isEmpty || this.state.validationField.email.invalid ? 'error' : ''].join(' ')}>
                    <input
                      className="onboarding-input-field__full__large input-custom"
                      placeholder="Please enter"
                      id={this.CONSTANTS_NAME.email}
                      type="text"
                      value={email}
                      autoComplete="off"
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      onClick={this.onTextClick}
                    />
                  </div>
                  {this.state.validationField.email.isEmpty ? (
                    <span className="error-message">{this.textStore.personalDetail.fieldCannotEmpty}</span>
                  ) : ''}
                  {this.state.validationField.email.invalid ? (
                    <span className="error-message">{this.textStore.personalParticular.validEmail}</span>
                  ) : ''}
                </div>
                {/* password */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.common.password}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', this.state.validationField.password.isEmpty || this.state.validationField.password.invalid ? 'error' : ''].join(' ')}>
                    <input
                      className="onboarding-input-field__full__large input-custom"
                      placeholder="Please enter"
                      id={this.CONSTANTS_NAME.password}
                      type="password"
                      minLength={8}
                      maxLength={24}
                      autoComplete="off"
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      onClick={this.onTextClick}
                      onCopy={this.onMitigateCopyPaste}
                      onPaste={this.onMitigateCopyPaste}
                    />
                  </div>
                  {this.state.validationField.password.isEmpty ? (
                    <span className="error-message">{this.textStore.personalDetail.fieldCannotEmpty}</span>
                  ) : ''}
                  {this.state.validationField.password.invalid ? (
                    <span className="error-message">{invalidPasswordMessage}</span>
                  ) : ''}
                </div>
                {/* repeat password */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.common.repeatPassword}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', this.state.validationField.repeatPass.isEmpty || this.state.validationField.repeatPass.invalid ? 'error' : ''].join(' ')}>
                    <input
                      className="onboarding-input-field__full__large input-custom"
                      placeholder="Please enter"
                      id={this.CONSTANTS_NAME.repeatPassword}
                      type="password"
                      minLength={8}
                      maxLength={24}
                      autoComplete="off"
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      onClick={this.onTextClick}
                      onCopy={this.onMitigateCopyPaste}
                      onPaste={this.onMitigateCopyPaste}
                    />
                  </div>
                  {this.state.validationField.repeatPass.isEmpty ? (
                    <span className="error-message">{this.textStore.personalDetail.fieldCannotEmpty}</span>
                  ) : ''}
                  {this.state.validationField.repeatPass.invalid ? (
                    <span className="error-message">{this.textStore.personalParticular.validRepeatPass}</span>
                  ) : ''}
                </div>
              </div>
            </div>
            {/* Personal Information */}
            <div className={['account', 'last', this.state.activeExpandType.information ? 'expanded' : ''].join(' ')}>
              <div id="information" data-id="1" className="account-header" onClick={this.onColapseExpand} ref="personal">
                <span id="information" className="account-header__icon"></span>
                <span id="information" className="account-header__text">{this.textStore.personalParticular.personalInfo}</span>
              </div>
              <div className="account-content">
                {/* salutation section */}
                <div className="form-group">
                  {salutationData.length > 0 ? (
                    <CustomDropdown
                      data={salutationData}
                      onSelect={this.handleSelect}
                      name={this.CONSTANTS_NAME.salutation}
                      required="true"
                      defaultSelected={salutationValue}
                      className={`${this.CONSTANTS_NAME.salutation}-dropdown`}
                      placeholder="Select"
                      label={this.textStore.personalParticular.salutation}
                    />
                  ) : ''}
                </div>
                {/* firstName section */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.personalParticular.firstName}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', this.state.validationField.firstName.isEmpty ? 'error' : ''].join(' ')}>
                    <input
                      className="onboarding-input-field__full__large input-custom"
                      placeholder="Please enter"
                      id={this.CONSTANTS_NAME.firstName}
                      value={firstName}
                      type="text"
                      maxLength={24}
                      autoComplete="off"
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      onClick={this.onTextClick}
                    />
                  </div>
                  {this.state.validationField.firstName.isEmpty ? (
                    <span className="error-message">{this.textStore.personalDetail.fieldCannotEmpty}</span>
                  ) : ''}
                </div>
                {/* lastName section */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.personalParticular.lastName}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', this.state.validationField.lastName.isEmpty ? 'error' : ''].join(' ')}>
                    <input
                      className="onboarding-input-field__full__large input-custom"
                      placeholder="Please enter"
                      id={this.CONSTANTS_NAME.lastName}
                      value={lastName}
                      type="text"
                      maxLength={24}
                      autoComplete="off"
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      onClick={this.onTextClick}
                    />
                  </div>
                  {this.state.validationField.lastName.isEmpty ? (
                    <span className="error-message">{this.textStore.personalDetail.fieldCannotEmpty}</span>
                  ) : ''}
                </div>
                {/* nationality section */}
                <div className="form-group">
                  {countryDataFormat.length > 0 ? (
                    <CustomDropdown
                      data={countryDataFormat}
                      onSelect={this.handleSelect}
                      defaultSelected={nationality}
                      required="true"
                      name={this.CONSTANTS_NAME.nationality}
                      className={`${this.CONSTANTS_NAME.nationality}-dropdown`}
                      placeholder="Select"
                      label={this.textStore.personalParticular.nationality}
                    />
                  ) : ''}
                </div>
                {/* birthday section */}
                <div className="form-group">
                  <p className="control-label" >
                    {this.textStore.personalParticular.dateOfBirth}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', 'partial-control', (this.state.validationField.birthday.isEmpty
                    || this.state.validationField.birthday.invalid
                    || this.state.validationField.birthday.investmentAgeValid
                  ) ? 'error' : ''].join(' ')}>
                    <div id="wrapper-date-picker">
                      <input
                        className="onboarding-input-field__full__large input-custom"
                        placeholder={this.dateFormat}
                        id={this.CONSTANTS_NAME.birthday}
                        type="text"
                        maxLength={24}
                        autoComplete="off"
                        value={birthday}
                        onChange={this.onChangeValue}
                        onBlur={this.onHandleBlur}
                        onFocus={(e) => { this.onHandleFocus(e); this.openDatePicker() }}
                        onClick={this.onTextClick}
                      />
                      <span className="my-calendar" onClick={this.openDatePicker}>
                        <span className="my-calendar-icon"></span>
                      </span>
                    </div>
                    <DatePicker
                      format={this.dateFormat}
                      className="date-picker-custom"
                      popupStyle={{ width: "100%" }}
                      getCalendarContainer={this.getDatePickerElement}
                      dropdownClassName="custom-popup-calendar"
                      onChange={this.onBirthdayChange.bind(this)}
                      open={this.state.canOpenPicker ? true : false}
                      defaultPickerValue={moment(defaultPickerValue, this.dateFormat)}
                      value={birthday === '' ? moment(defaultPickerValue, this.dateFormat) : moment(birthday, this.dateFormat)}
                    />
                  </div>
                  {this.state.validationField.birthday.isEmpty ? (
                    <span className="error-message">{this.textStore.personalDetail.fieldCannotEmpty}</span>
                  ) : ''}
                  {this.state.validationField.birthday.invalid ? (
                    <span className="error-message">{this.textStore.personalParticular.errorFormatDate}</span>
                  ) : ''}
                  {this.state.validationField.birthday.investmentAgeValid ? (
                    <span className="error-message">
                      {this.textStore.personalParticular.errorMessageInvestmentAge.replace("{minInvestmentAge}", minInvestmentAge)
                        .replace("{maxInvestmentAge}", maxInvestmentAge)}
                    </span>
                  ) : ''}
                </div>
                {/* address1 section */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.personalParticular.residentialAddress.replace('{lineNumber}', 1)}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', this.state.validationField.address1.isEmpty ? 'error' : ''].join(' ')}>
                    <input
                      className="onboarding-input-field__full__large input-custom"
                      placeholder="Please enter"
                      id={this.CONSTANTS_NAME.address1}
                      value={address1}
                      type="text"
                      maxLength={24}
                      autoComplete="off"
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      onClick={this.onTextClick}
                    />
                  </div>
                  {this.state.validationField.address1.isEmpty ? (
                    <span className="error-message">{this.textStore.personalDetail.fieldCannotEmpty}</span>
                  ) : ''}
                </div>
                {/* address2 section */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.personalParticular.residentialAddress.replace('{lineNumber}', 2)}
                  </p>
                  <div className="control-wrapper">
                    <input
                      className="onboarding-input-field__full__large input-custom"
                      id={this.CONSTANTS_NAME.address2}
                      value={address2}
                      type="text"
                      maxLength={24}
                      autoComplete="off"
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      disabled={this.state.personInfo.address1 === '' ? true : false}
                      onClick={this.onTextClick}
                    />
                  </div>
                </div>
                {/* postalCode section */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.personalParticular.postalCode}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', this.state.validationField.postalCode.isEmpty ? 'error' : ''].join(' ')}>
                    <TextInput
                      className="onboarding-input-field__full__large input-custom"
                      placeholder="Please enter"
                      id={this.CONSTANTS_NAME.postalCode}
                      value={this.state.personInfo.postalCode}
                      //value={postalCode}
                      type="text"
                      maxLength={24}
                      autoComplete="off"
                      numericKeyboard={true}
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      onClick={this.onTextClick}
                    />
                  </div>
                  {this.state.validationField.postalCode.isEmpty ? (
                    <span className="error-message">{this.textStore.personalParticular.errorMessPostalCode}</span>
                  ) : ''}
                </div>
                {/* country of residence section */}
                <div className="form-group" style={{ zIndex: 1 }} id="dropdown-country-residence-wrapper">
                  <p className="control-label">
                    {this.textStore.personalParticular.countryOfResidence}<span className="mandatory-symbol">*</span>
                  </p>
                  <DropdownCountryResidence items={newCountryData} placeholder={'Please select'}    
                    selectedResidence={residence}              
                    onSelect={(value)=>this.handleSelect({
                      name: this.CONSTANTS_NAME.residence,
                      value
                    })}
                   />

                </div> 
                {/* city of residence section */}
                <div className="form-group">
                  <p className="control-label">
                    {this.textStore.personalParticular.cityOfResidence}<span className="mandatory-symbol">*</span>
                  </p>
                  <div className={['control-wrapper', this.state.validationField.city.isEmpty ? 'error' : ''].join(' ')}>
                    <input
                      className="onboarding-input-field__full__large input-custom"
                      placeholder="Please enter"
                      id={this.CONSTANTS_NAME.city}
                      value={city}
                      type="text"
                      maxLength={24}
                      autoComplete="off"
                      onChange={this.onChangeValue}
                      onBlur={this.onHandleBlur}
                      onFocus={this.onHandleFocus}
                      onClick={this.onTextClick}
                    />
                  </div>
                  {this.state.validationField.city.isEmpty ? (
                    <span className="error-message">{this.textStore.personalDetail.fieldCannotEmpty}</span>
                  ) : ''}
                </div>
              </div>
            </div>
          </form>
        </section>
        <GoalFooter
          onClickNext={this.onClickNext}
          renderBackButton={false}
          nextButtonFullWidth={true}
          enableNextButton={this.state.canGoNext}
        />
      </React.Fragment>
    )
  }
}
function mapStateToProps(props) {
  return {
    onboardingData: props.onboardingData,
    personalParticular: props.onboardingData.personalParticular,
    passwordStrength: props.onboardingData.testParams.passwordStrength
  }
}

const EnhancedPersonalParticular = withBaseLayout(PersonalParticular);

export default withRouter(
  connect(
    mapStateToProps,
    { switchHeader, savePersonalParticular, savePreviousUserInfo }
  )(EnhancedPersonalParticular)
);

