import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { invertLayout } from "../../../actions/AppMain/AppMainAction";

import emoticonSmile from '../../../css/images/icons/emoticon-smile.svg';
import emoticonFrown from '../../../css/images/icons/emoticon-frown.svg';

import './styles.less';

class SentSuggestion extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.invertLayout('viewport layout--inverted layout--only-title');
  }

  componentWillUnmount() {
    this.props.invertLayout("viewport layout--default");
  }

  render() {
    return (
      <React.Fragment>
        <div className="feedback content-main__body content-main__body--centered text-center">
          <div className="feedback-item feedback__icon">
            <img src={emoticonSmile} alt="smile" />
          </div>
          <div className="feedback-item feedback__title">
            Thank you
          </div>
          <div className="feedback-item feedback__desc">
            Your feedback is important to us. 
            <br />
            The customer satisfaction team will get back to you shortly.
          </div>
        </div>
        <div className="content-main__footer feedback-footer">
          <div className="feedback__link">
            <Link
              id="btnBack"
              to="/login"
              className="btn btn-default btn-block btn-no-focus__inverted"
            >Back to login</Link>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(null, { switchHeader, invertLayout })(withBaseLayout(SentSuggestion));