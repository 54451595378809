import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import shieldDefault from '../../../css/images/icons-png/shield-default.png';

import MessagesDetail from '../MessagesDetail';

import './styles.less';

const data = {
  time: '25/08/2018',
  text: 'Security transaction',
  icon: shieldDefault
}

class SecurityTransaction extends React.PureComponent {
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Security transaction' },
      headerRight: '',
      currentPage: 'SecurityTransaction',
    });
  }

  render() {
    return (
      <MessagesDetail data={data}>
        <div>
          <p>Dear Carlos</p>
          <br/>
          <p>New securities have been traded on your behave. Please navigate to the transaction section to review the transaction details.</p>
          <br/>
          <p>Best regards,</p>
          <p>Classic Robo Operator Support</p>
        </div>
      </MessagesDetail>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(withRouter(SecurityTransaction)));