import React from 'react';

export default class SupportConfirm extends React.PureComponent {
  onClick = (evt) => {
    evt.preventDefault();

    const { onClick } = this.props;

    typeof onClick === 'function' && onClick();
  }

  render() {
    return (
      <div className="support-confirm">
        <span className="support-confirm__close" onClick={this.onClick}></span>
        <div className="support-confirm__title">Announcement</div>
        <div className="support-confirm__desc">Thank you for your feedback!</div>
      </div>
    )
  }
}