import {
  ONBOARDING_SELECTED_GOAL,
  GET_DEFAULT_GOALS,
  SAVE_PREVIOUS_INVESTMENT_INFO,
  SAVE_PREVIOUS_USER_INFO,
  CLEAR_PREVIOUS_INPUT,
  GET_CTB,
  GET_CALCULATE_RISK,
  GET_TEST_DATA,
  SAVE_PERSONAL_PARTICULAR,
  UPDATE_FIRST_LOAD_ONBOARDING,
  VERIFY_ONLINE_STEP,
} from "../../actions/Onboarding/OnboardingActionType";
import onBoardingPlanningChartConfig from "../../components/Charts/config/OnboardingHighChartMultipleLines";
import pieChartConfig from '../../components/Charts/config/PieChartConfig';


const mockData = {
  defaultGoals: [],
  selectedGoal: {},
  investmentInfo: {
    goalName: "",
    targetAmount: "",
    targetYears: "",
    initialAmount: ""
  },
  calculateRisk: {
    score: 1,
    riskCategory: {
      Name: ""
    },
    frontEndRiskMapping: {}
  },
  planning: {
    probability: '',
		type:'',
		investmenStrategy:'',
		expectedTargetAmount:0,
		expReturn: 0,
		totalInitialInvestmentAmount: 0,
		totalBalanceAmount: 0,
    planningConfig: onBoardingPlanningChartConfig
  },
  strategy:{
		advise: '',
		riskCategoryName:'',
		calculatedRisk:0,
		calculatedExpectedReturn:0,
		riskCategoryDescription:'',
		description:'',
		positions:[{
			type:'',
			totalPerformance:0,
			totalAllocation:0,
			security:[{
				name:'',
				allocation:0,
				performance:0,
			}]
		}],
		allocation:{
			fixedIncomeValue:null,
			fixedIncomeAllocation:null,
			equityValue:null,
			equityAllocation:null,
			nonTraditionalValue:null,
			nonTraditionalAllocation:null,
			pieChartConfig: pieChartConfig,
			regions:[]
		}
	},
  userInfo: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    selectedCountryIndex: -1
  },
  testParams: {
    responsePutOnlineVerification: 0,
    responseComplianceGetCalculateRisk: 0,
    responsePreAuthenticateMobile: 0,
    responsePostQuestionnaire: 0,
    responseSubmitOTP: 0,
    responseTermsAndConditions: 0,
    lowerMonthlyInvestment: 0,
    upperMonthlyInvestment: 0,
    responsePostContactPortfolios: 0,
    responsePostRegistration: 0,
    sliderBehavior: '',
  },
  CTB: {
    countryData: [],
    initialInvestmentSliderStep: 100,
    monthlyInvestmentSliderStep: 100,
    timeHorizonSliderStep : 0 ,
    min: {
      targetAmount: 0,
      timeHorizon: 0,
      initialAmount: 0,
      riskProfileScale: 0,
    },
    max: {
      targetAmount: 0,
      timeHorizon: 0,
      initialAmount: 0,
      riskProfileScale: 0,
    },
    salutation: [],
    minInvestmentAge: 0,
    maxInvestmentAge: 0,
    supportMail: "",
    supportSubject: "",
    timeOutOTP: 120,
    investmentEducation: {
      sliderInitialInvestment: 0,
      sliderMonthlyInvestment: 0,
      sliderTimeHorizon: 0,
      assumedInvestorProfile : 4,
      investmentHorizon: [
      ]
    }
  },
  personalParticular: {},
  isFirstLoad: true,
  verifyOnlineStep: 1
};

export default function(data = mockData, actions) {
  switch (actions.type) {
    case ONBOARDING_SELECTED_GOAL:
      return { ...data, selectedGoal: actions.payload };
    case GET_DEFAULT_GOALS:
      return { ...data, defaultGoals: actions.payload };
    case SAVE_PREVIOUS_INVESTMENT_INFO:
      return {...data,
        investmentInfo: {
          ...data.investmentInfo,
          goalName: actions.payload.goalName,
          targetAmount: actions.payload.targetAmount,
          targetYears: actions.payload.targetYears,
          initialAmount: actions.payload.initialAmount
        }
      };
    case SAVE_PREVIOUS_USER_INFO:
      return {...data,
        userInfo: {
          ...data.userInfo, ...actions.payload
        }
      };
    case CLEAR_PREVIOUS_INPUT:
      return {...data,
        userInfo: mockData.userInfo,
        personalParticular: {},
        investmentInfo: mockData.investmentInfo,
        planning: {
          ...data.planning,
          planningConfig: onBoardingPlanningChartConfig
        }
      };
    case GET_CTB:
      return {...data,
        CTB: {
          ...data.CTB,
          initialInvestmentSliderStep: actions.payload.InitialInvestmentSliderStep,
          monthlyInvestmentSliderStep: actions.payload.MonthlyInvestmentSliderStep,
          timeHorizonSliderStep : actions.payload.TimeHorizonSliderStep,
          countryData: actions.payload.CountryData,
          max: {...data.CTB.max, 
            targetAmount: actions.payload.MaxTargetAmount,
            timeHorizon: actions.payload.MaxTimeHorizon,
            initialAmount: actions.payload.MaxInitialInvestment,
            riskProfileScale: actions.payload.MaxRiskProfileScale,
          },
          min: {...data.CTB.min,
            targetAmount: actions.payload.MinTargetAmount,
            timeHorizon: actions.payload.MinTimeHorizon,
            initialAmount: actions.payload.MinInitialInvestment,
            riskProfileScale: actions.payload.MinRiskProfileScale,
          },
          salutation: actions.payload.Salutation,
          minInvestmentAge: actions.payload.MinInvestmentAge,
          maxInvestmentAge: actions.payload.MaxInvestmentAge,
          supportMail: actions.payload.SupportMail,
          supportSubject: actions.payload.SupportSubject,
          timeOutOTP: actions.payload.TimeOutOTP,
          investmentEducation: {
            sliderInitialInvestment: actions.payload.InvestorEducation.SliderInitialInvestment,
            sliderMonthlyInvestment: actions.payload.InvestorEducation.SliderMonthlyInvestment,
            sliderTimeHorizon: actions.payload.InvestorEducation.SliderTimeHorizon,      
            assumedInvestorProfile : actions.payload.InvestorEducation.AssumedInvestorProfile,
            investmentHorizon: actions.payload.InvestorEducation.InvestmentHorizon
          }
        }
      };
    case GET_CALCULATE_RISK:
      return {...data,
        calculateRisk: {
          ...data.calculateRisk,
          score: actions.payload.Score,
          riskCategory: actions.payload.RiskCategory,
          frontEndRiskMapping: actions.payload.FrontendRiskMapping
        }
      }
    case GET_TEST_DATA:
      return {...data,
        testParams: {...data.testParams,
          responsePutOnlineVerification: actions.payload.ResponsePutOnlineVerification,
          responseComplianceGetCalculateRisk: actions.payload.ReponseComplianceGetCalculaterisk,
          responsePreAuthenticateMobile: actions.payload.ResponsePreAuthenticateMobile,
          responsePostQuestionnaire: actions.payload.ResponsePostQuestionnaire,
          responseSubmitOTP: actions.payload.ResponseSubmitOTP,
          responseTermsAndConditions: actions.payload.ResponseTermsAndConditions,
          lowerMonthlyInvestment: actions.payload.LowerMonthlyInvestment,
          upperMonthlyInvestment: actions.payload.UpperMonthlyInvestment,
          responsePostContactPortfolios:  actions.payload.ResponsePostContactPortfolios,
          responsePostRegistration: actions.payload.ResponsePostRegistration,
          passwordStrength: actions.payload.PasswordStrength,
          sliderBehavior: actions.payload.SliderBehavior,
        }
      }
    case SAVE_PERSONAL_PARTICULAR:
      return {...data,
        personalParticular: {
          ...data.personalParticular, ...actions.payload
        }
      };
    case UPDATE_FIRST_LOAD_ONBOARDING:
      return {...data, isFirstLoad: actions.payload };
    
    case VERIFY_ONLINE_STEP:
      return { ...data, 
        verifyOnlineStep: actions.payload
      }
    default:
      return data;
  }
}
