import { 
	SWITCH_TIMELINE, 
	FETCH_DASHBOARD_DATA,
	FETCH_UNREAD_MESSAGE
} from '../../../actions/DashBoard/DashBoardActionType';
import pieChartConfig from '../../../components/Charts/config/PieChartConfig';
import formatPorfolio from '../../../helpers/formatPorfolio';


const mockData = {
	allocation:{
		fixedIncomeValue:null,
		fixedIncomeAllocation:null,
		equityValue:null,
		equityAllocation:null,
		nonTraditionalValue:null,
		nonTraditionalAllocation:null,
		pieChartConfig: pieChartConfig,
	},
	performance:{
		currencyCode:'',
		currentValue:0,
		currentPerformance:0,
		currentPerformanceAmount:0,
		currentPerformance1W:0,
		currentPerformanceAmount1W:0,
		currentPerformance1M:0,
		currentPerformanceAmount1M:0,
		currentPerformance3M:0,
		currentPerformanceAmount3M:0,
		currentPerformance6M:0,
		currentPerformanceAmount6M:0,
		currentPerformanceAll:0,
		currentPerformanceAmountAll:0,
	},
	securityTypesListAcrossInvestmentGoal:[],
	portfolios:{
		list:[],
		iconType:"goal",
		isDefaultGoal: true
	},
	referral: {
		accountAge:0,

	},
	unreadMessage: 0
};

const emptyData = {
	portfolios:{
		list:[]
	}
}

export default function(data = mockData,actions){
	switch(actions.type){
		case SWITCH_TIMELINE:
			switch (actions.payload){
				case '1W':
					return { ...data, 
						performance:{ ...data.performance,
							currentPerformance: data.performance.currentPerformance1W,
							currentPerformanceAmount:data.performance.currentPerformanceAmount1W } 
					};
				case '1M':
					return { ...data, 
						performance:{ ...data.performance,
							currentPerformance: data.performance.currentPerformance1M,
							currentPerformanceAmount:data.performance.currentPerformanceAmount1M } 
					};
				case '3M':
					return { ...data, 
						performance:{ ...data.performance,
							currentPerformance: data.performance.currentPerformance3M,
							currentPerformanceAmount: data.performance.currentPerformanceAmount3M } 
					};
				case '6M':
					return { ...data, 
						performance:{ ...data.performance,
							currentPerformance: data.performance.currentPerformance6M,
							currentPerformanceAmount: data.performance.currentPerformanceAmount6M } 
					};
				case 'ALL':
					return { ...data, 
						performance:{ ...data.performance,
							currentPerformance: data.performance.currentPerformanceAll,
							currentPerformanceAmount: data.performance.currentPerformanceAmountAll } 
					};
				default:
					return data;
			}
		case FETCH_DASHBOARD_DATA:
			let portfolios =  emptyData.portfolios.list;
			let defaultGoal = true;
			if (actions.payload.Portfolios && actions.payload.Portfolios.length > 0) {
				portfolios = formatPorfolio(actions.payload.Portfolios);
				defaultGoal = false;
			} 
			// Refactor
			let sortedAllocations = actions.payload.SecurityTypesListAcrossInvestmentGoal.sort((a, b) => {
				if (a.Name < b.Name)
					return -1;
				if (a.Name > b.Name)
					return 1;
				return 0;
			})
			const fixedIncomeAllocation =  sortedAllocations[1] ? sortedAllocations[1].CurrentPerformance : null
			const equityAllocation = sortedAllocations[0] ? sortedAllocations[0].CurrentPerformance : null
			const nonTraditionalAllocation =  sortedAllocations[2] ? sortedAllocations[2].CurrentPerformance : null
			pieChartConfig.series[0].data[0].y = parseFloat((fixedIncomeAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[1].y = parseFloat((equityAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[2].y = parseFloat((nonTraditionalAllocation * 100).toFixed(2));
			return  { ...data,
				allocation:{
					...data.allocation,
					fixedIncomeValue:sortedAllocations[1] ? sortedAllocations[1].CurrentPerformance * actions.payload.CurrentValue  : null ,
					fixedIncomeAllocation: fixedIncomeAllocation,
					equityValue: sortedAllocations[0] ? sortedAllocations[0].CurrentPerformance * actions.payload.CurrentValue : null,
					equityAllocation: equityAllocation,
					nonTraditionalValue: sortedAllocations[2] ? sortedAllocations[2].CurrentPerformance * actions.payload.CurrentValue : null ,
					nonTraditionalAllocation: nonTraditionalAllocation,
					pieChartConfig: pieChartConfig,
				},
				performance:{
					...data.performance,
					currentPerformance: actions.payload['AllPerformance'],
					currentPerformanceAmount: actions.payload['AllPerformanceAmount'],
					currencyCode:actions.payload.CurrencyCode,
					currentValue:actions.payload.CurrentValue,
					currentPerformance1W:actions.payload['1wPerformance'],
					currentPerformanceAmount1W:actions.payload['1wPerformanceAmount'],
					currentPerformance1M:actions.payload['1mPerformance'],
					currentPerformanceAmount1M:actions.payload['1mPerformanceAmount'],
					currentPerformance3M:actions.payload['3mPerformance'],
					currentPerformanceAmount3M:actions.payload['3mPerformanceAmount'],
					currentPerformance6M:actions.payload['6mPerformance'],
					currentPerformanceAmount6M:actions.payload['6mPerformanceAmount'],
					currentPerformanceAll:actions.payload['AllPerformance'],
					currentPerformanceAmountAll:actions.payload['AllPerformanceAmount'],
				},

				portfolios:{
					...data.portfolios,
					list: portfolios,
					iconType:"goal",
					isDefaultGoal: defaultGoal
				},
				referral:{
					...data.referral,
					accountAge: actions.payload.referral.accountAge,
				},
			}
		case FETCH_UNREAD_MESSAGE:
			 return { ...data,
				unreadMessage: actions.payload.UnreadMessageCount
			}
		default:
			return data;
	}
}