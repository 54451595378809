import {
	SIGN_IN_ERROR,
	PERSIST_USER ,
	SIGN_OUT
 } from './AuthActionType';
 import firebase from 'firebase/app';
 import 'firebase/auth';

function signInError(){
	return {
		type: SIGN_IN_ERROR,
		payload:true
	}
}


function checkUserState(user,isSignIn){
	return {
		type: PERSIST_USER,
		payload:{
			useremail:user.email,
			isSignIn:isSignIn
		}
	}
}

export function signIn(username,password){
	 return (dispatch) => {
		 firebase.auth().signInWithEmailAndPassword(username,password).catch(error => {
				dispatch(signInError());
		 });
	 }
}

export function signOut(){
	firebase.auth().signOut();
	return {
		type: SIGN_OUT,
		payload:{
			useremail:'',
			isSignIn:false
		}
	}
}

export function persistUser(){
	return (dispatch) => {
		firebase.auth().onAuthStateChanged(user => {
			if(user){
				dispatch(checkUserState(user,true));
			}
		})
	}
}