import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import fileSingle from '../../../css/images/icons-png/file-single-alt.png';

import MessagesDetail from '../MessagesDetail';

import './styles.less';

const data = {
  time: '25/08/2018',
  text: 'Document available',
  icon: fileSingle
}

class NewDocument extends React.PureComponent {
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'New document available' },
      headerRight: '',
      currentPage: 'NewDocument',
    });
  }

  render() {
    return (
      <MessagesDetail data={data}>
        <div>
          <p>Dear Carlos</p>
          <br/>
          <p>A new <b>“Monthly account statement”</b> is available in your document store for your kind review.</p>
          <br/>
          <p>Best regards,</p>
          <p>Classic Robo Operator Support</p>
        </div>
      </MessagesDetail>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(withRouter(NewDocument)));