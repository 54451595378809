import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withBaseLayout from "../HoC/withBaseLayout";
import TabBar from "../GoalDetail/TabBar/TabBar";
import { switchTabBar } from "../../actions/TabBar/TabBarActions";
import { switchHeader } from "../../actions/Header/HeaderActions";
import {
  selectGoal,
  goalCompleted,
  topUpCompleted,
  withdrawCompleted,
  transferCompleted,
  instructionCompleted,
  strategyCompleted,
  summaryCompleted,
  confirmGoal,
  passGoalsToSummary,
  getWithDrawAccounts,
  getDefaultGoalTransfer,
  getDefaultGoalBankAccount,
  changeStatus,
  editGoalStrategy,
  changeGoalStrategy
} from "../../actions/Goals/GoalActions";
import GoalSelections from "./GoalSelections/GoalSeclections";
import GoalCreate from "./GoalSelections/GoalCreate";
import GoalFooter from "./GoalFooter/GoalFooter";
import GoalStrategy from "./GoalStrategy/GoalStrategy";
import GoalSummary from "./GoalSummary/GoalSummary";
import StatsSection from "./StatsSection/StatsSection";
import GoalTransfer from "./GoalTransfer/GoalTransfer";
import GoalWithdraw from "../Goals/Withdraw/Withdraw";
import GoalInstructions from "./GoalInstructions/GoalInstruction";
import { unformatCurrency } from "../../helpers/formatCurrency";
import CONFIG from "../../setting/config";
import changeLanguage from "../../setting/languages/languages";


class Goals extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onClickBack = this.onClickBack.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
  }
  
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "close",
      headerMiddle: { type: "text", text: "Create Goal" },
      headerRight: "",
      currentPage: "goal"
    });
    // this.props.getDefaultGoals();
    
    this.props.changeGoalStrategy(this.props.goalData.planning.investmenStrategy, this.props.goalDetailData.currentValue);

    // this.props.editGoalStrategy(this.props.goalDetailData);
    // Fix bug: CR4-176
    if (
      this.props.tabBarUI.currentTab !== "Strategy" &&
      this.props.tabBarUI.currentTab !== "Withdraw" &&
      this.props.tabBarUI.currentTab !== "Summary" &&
      this.props.tabBarUI.currentTab !== "Instructions"
    ) {
      this.props.switchTabBar("Goal");
    }
  }

  onClickNext() {
    // When it has any errors then do nothing
    if (Object.values(this.props.goalUI.goalCreate).indexOf(true) >= 0) {
      return;
    }
    switch (this.props.tabBarUI.currentTab) {
      case "Goal":
        if (this.props.goalData.planning.probability === 2) {
          this.props.goalCompleted({
            current: "complete",
            next: "active"
          });
          this.props.switchTabBar("Strategy");
          this.props.passGoalsToSummary({
            goalName: this.props.goalData.goalName,
            targetAmount: this.props.goalData.targetAmount,
            targetDate: this.props.goalData.targetDate,
            investmenStrategy: this.props.goalData.planning.investmenStrategy,
            initialInvestment: this.props.goalData.initialInvestment,
            monthlyInvestment: this.props.goalData.monthlyInvestment,
            type: this.props.goalData.type,
            typeScreen: this.props.type,
            topUpInvestmentAmount: this.props.goalData.topUpInvestmentAmount,
            withdrawInvestmentAmount: this.props.goalData.withdrawInvestmentAmount
          });
        } else {
          this.props.changeStatus();
        }
        break;
      case "Strategy":
        this.props.strategyCompleted({
          prev: "complete",
          current: "complete",
          next: "active"
        });
        this.props.switchTabBar("Summary");
        this.props.tabBarUI.tabBarRef.current.slickNext();
        break;
      case "Summary":
        this.props.summaryCompleted({
          prev: "complete",
          current: "complete",
          next: "active"
        });
        this.props.getDefaultGoalTransfer({
          goalName: this.props.goalData.summary.goalName,
          initialInvestmentAmount: this.props.goalData.summary
            .initialInvestment,
          monthlyInvestmentAmount: this.props.goalData.summary
            .monthlyInvestment,
          type: this.props.goalData.summary.type,
          typeScreen: this.props.type,
          topUpInvestmentAmount: this.props.goalData.topUpInvestmentAmount,
          withdrawInvestmentAmount: this.props.goalData.withdrawInvestmentAmount
        });
        if (this.props.goalUI.goalScreen === "Edit") {
          if (unformatCurrency(this.props.goalData.topUpInvestmentAmount) > 0) {
            this.props.switchTabBar("TopUp");
          } else if (unformatCurrency(this.props.goalData.withdrawInvestmentAmount) > 0) {
            this.props.getWithDrawAccounts(this.props.goalData);
            this.props.switchTabBar("Withdraw");
          } else {
            this.props.confirmGoal(true);
            this.props.history.push({
              pathname: this.routesName.GOALS.COMPLETE,
              state: {
                icon: "icn icn--confirmation",
								desc: this.textStore.common.aCopyOfTransferInstruction,
              }
            });
          }
        } else {
          this.props.switchTabBar("Transfer");
        }
        this.props.tabBarUI.tabBarRef.current.slickNext();
        break;
      case "TopUp":
        this.props.transferCompleted({
          prev: "complete",
          current: "complete",
          next: "active"
        });
        this.props.switchTabBar("Instructions");
        this.props.getDefaultGoalBankAccount(this.props.goalData.summary);
        this.props.tabBarUI.tabBarRef.current.slickNext();
        break;
      case "Withdraw":
        //this.props.transferCompleted({
          //prev: "complete",
          //current: "complete",
          //next: "active"
        //});
        //this.props.switchTabBar("Instructions");
        //this.props.getDefaultGoalBankAccount(this.props.goalData.summary);
        //this.props.tabBarUI.tabBarRef.current.slickNext();
        this.props.history.push(this.routesName.OTP);
        break;
      case "Transfer":
        this.props.transferCompleted({
          prev: "complete",
          current: "complete",
          next: "active"
        });
        this.props.switchTabBar("Instructions");
        this.props.getDefaultGoalBankAccount(this.props.goalData.summary);
        this.props.tabBarUI.tabBarRef.current.slickNext();
        break;
      case "Instructions":
        this.props.instructionCompleted({
          prev: "complete",
          current: "complete",
          next: "complete"
        });
        this.props.confirmGoal(true);
        this.props.history.push({
          pathname: this.routesName.GOALS.COMPLETE,
          state: {
            icon: "icn icn--right-tick",
            desc: this.props.type === "CreateGoal" ? "Your investment goal has\n been created successfully" : "Your investment goal has\n been updated successfully"
          }
        });
        break;
      default:
        return;
    }
  }

  onClickBack() {
    switch (this.props.tabBarUI.currentTab) {
      case "Goal":
        if (this.onTrack)
          this.props.goalCompleted({
            current: "active",
            next: ""
          });
        this.props.selectGoal(false);
        break;
      case "Strategy":
        this.props.strategyCompleted({
          prev: "active",
          current: "",
          next: ""
        });
        this.props.switchTabBar("Goal");
        this.props.tabBarUI.tabBarRef.current.slickPrev();
        break;
      case "Summary":
        this.props.summaryCompleted({
          prev: "active",
          current: "",
          next: ""
        });
        this.props.switchTabBar("Strategy");
        this.props.tabBarUI.tabBarRef.current.slickPrev();
        break;
      case "TopUp":
        this.props.topUpCompleted({
          prev: "active",
          current: "",
          next: ""
        });
        this.props.instructionCompleted("");
        this.props.switchTabBar("Summary");
        this.props.tabBarUI.tabBarRef.current.slickPrev();
        break;
      case "Withdraw":
        this.props.withdrawCompleted({
          prev: "active",
          current: "",
          next: ""
        });
        this.props.instructionCompleted("");
        this.props.switchTabBar("Summary");
        this.props.tabBarUI.tabBarRef.current.slickPrev();
        break;
      case "Transfer":
        this.props.transferCompleted({
          prev: "active",
          current: "",
          next: ""
        });
        this.props.instructionCompleted("");
        this.props.switchTabBar("Summary");
        this.props.tabBarUI.tabBarRef.current.slickPrev();
        break;
      case "Instructions":
        this.props.instructionCompleted({
          current: "",
          prev: "active"
        });
        if (this.props.goalUI.goalScreen === "Edit") {
          if (unformatCurrency(this.props.goalData.topUpInvestmentAmount) > 0) {
            this.props.switchTabBar("TopUp");
          } else if (unformatCurrency(this.props.goalData.withdrawInvestmentAmount) > 0) {
            this.props.getWithDrawAccounts(this.props.goalData);
            this.props.switchTabBar("Withdraw");
          }
        } else {
          this.props.switchTabBar("Transfer");
        }
        break;
      default:
        return;
    }
  }

  renderFooter() {
    return this.props.goalUI.goalSelected ? (
      <GoalFooter
        renderBackButton={this.props.tabBarUI.currentTab !== "Goal"}
        onClickNext={this.onClickNext}
        onClickBack={this.onClickBack}
      />
    ) : (
      <span />
    );
  }

  renderGoalSection() {
    switch (this.props.tabBarUI.currentTab) {
      case "Goal":
        return this.props.goalUI.goalSelected ? (
          <React.Fragment>
            <GoalCreate
              language={this.props.language}
              type={this.props.type ? this.props.type : ""}
              onWithdrawBlur={this.props.onWithdrawBlur}
              onWithdrawFocus={this.props.onWithdrawFocus}
              onTopUpBlur={this.props.onTopUpBlur}
              onTopUpFocus={this.props.onTopUpFocus}
              onTopUpChange={this.props.onTopUpChange}
              onWithdrawChange={this.props.onWithdrawChange}
            />
            <StatsSection
              textStore={this.textStore}
              statusErrorDisplay={this.props.goalUI.statusErrorDisplay}
              planning={this.props.goalData.planning}
              screen={this.props.screenUI.currentScreen}
            />
          </React.Fragment>
        ) : (
          <fieldset 
				className="form-section"
				style={{
					marginTop:"10px"
				}}
			>
				<legend className="primary-title text-center">
					{this.textStore.common.whatIsYourInvestment}
				</legend>
          <GoalSelections defaultGoals={this.props.goalData.defaultGoals} />
          </fieldset>
        );

      case "Strategy":
        return (
          <React.Fragment>
            <GoalStrategy 
            language={this.props.language}
            screen={this.props.screenUI.currentScreen}
            id={this.props.goalDetailData.id} 
            strategy={this.props.goalData.strategy} 
            />
          </React.Fragment>
        );
      case "Summary":
        return (
          <React.Fragment>
            <GoalSummary textStore={this.textStore} screen={this.props.screenUI.currentScreen} summary={this.props.goalData.summary} />
          </React.Fragment>
        );
      case "TopUp":
        return (
          <React.Fragment>
            <GoalTransfer
              totalInitialInvestmentAmount={
                this.props.goalData.totalInitialInvestmentAmount
              }
              totalMonthlyInvestmentAmount={
                this.props.goalData.totalMonthlyInvestmentAmount
              }
              transfer={this.props.goalData.transfer}
            />
          </React.Fragment>
        );
      case "Withdraw":
        return (
          <React.Fragment>
            <GoalWithdraw withDraws={this.props.goalData.withDraws} />
          </React.Fragment>
        );
      case "Transfer":
        return (
          <React.Fragment>
            <GoalTransfer
              totalInitialInvestmentAmount={
                this.props.goalData.totalInitialInvestmentAmount
              }
              totalMonthlyInvestmentAmount={
                this.props.goalData.totalMonthlyInvestmentAmount
              }
              transfer={this.props.goalData.transfer}
            />
          </React.Fragment>
        );
      case "Instructions":
        return (
          <React.Fragment>
            <GoalInstructions
              totalOneTransferAmount={
                this.props.goalData.totalInitialInvestmentAmount
              }
              totalMonthlyAmount={
                this.props.goalData.totalMonthlyInvestmentAmount
              }
              instructions={this.props.goalData.instructions}
            />
          </React.Fragment>
        );
      default:
        return <div />;
    }
  }

  render() {
    const settings = {
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      slide: "ul",
      swipeToSlide: true,
      infinite: false,
      className: "slick-slider-progress-bar"
    };
    // Fix bug: 177
    if (this.props.tabBarUI.currentTab === "Withdraw") {
      settings.initialSlide = 1;
    } else if (this.props.tabBarUI.currentTab === "Instructions") {
      settings.initialSlide = 5;
    } 

    let tabs = ["Goal", "Strategy", "Summary", "Transfer", "Instructions"];
    return (
      <React.Fragment>
        <div className="content-main__body">
          <TabBar
            tabs={tabs}
            settings={settings}
            type={"goal"}
            screenType={this.props.type}
          />
          {this.renderGoalSection()}
        </div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }
}

Goals.defaultProps = {
  type: "CreateGoal"
}

Goals.propTypes = {
  type: PropTypes.string,
}

const GoalsEnhanced = withBaseLayout(Goals);

function mapStateToProps(props) {
  return {
    goalUI: props.goalUI,
    tabBarUI: props.tabBarUI,
    goalData: props.goalData,
    goalDetailData: props.goalDetailData,
    screenUI: props.screenUI
  };
}

export default withRouter(connect(
  mapStateToProps,
  {
    switchTabBar,
    switchHeader,
    editGoalStrategy,
    selectGoal,
    goalCompleted,
    topUpCompleted,
    withdrawCompleted,
    transferCompleted,
    instructionCompleted,
    strategyCompleted,
    summaryCompleted,
    confirmGoal,
    passGoalsToSummary,
    getWithDrawAccounts,
    getDefaultGoalTransfer,
    getDefaultGoalBankAccount,
    changeStatus,
    changeGoalStrategy
  }
)(GoalsEnhanced));
