import React from 'react';
import { connect } from 'react-redux';
import { switchTimeLine } from '../../../actions/DashBoard/DashBoardActions';
import { swicthDateXAxis, enableAmountChartAnimation, enableRelativeChartAnimation  } from '../../../actions/Chart/ChartActions';

export class ChartRange extends React.Component { 
  constructor(props){
    super(props)
    this.state = {
      activeIndex: 4
    }
    this.updateYAxisData = this.updateYAxisData.bind(this);
  }


  onChartRangeClick(range,index){
    let timeout = null;
    if (this.state.activeIndex === index) {
      return;
    }

    if (this.props.chartIndex === 0) {
      this.props.enableAmountChartAnimation();
    } 

    if (this.props.chartIndex === 1) {
      this.props.enableRelativeChartAnimation();
    } 
    
    this.setState({
      activeIndex:index
    },() => {
      switch(range){
        case '1W':
          this.props.swicthDateXAxis("{value: %a}");
          break;
        case '1M':
          this.props.swicthDateXAxis("{value: %d.%m}");
          break;
        case '3M':
          this.props.swicthDateXAxis("{value: %d.%m}");
          break;
        case '6M':
          this.props.swicthDateXAxis("{value: %b}");
          break;
        case 'ALL':
          this.props.swicthDateXAxis("{value: %d.%m.%y}");
          break;
        default:
          return;
      }
      this.props.changeChartRange(range,index);
      this.props.switchTimeLine(range);

      //prevent switchTimeLine to overlap data graph rendering process
      timeout = setTimeout(() => {
        this.props.stockRef.current.chart.rangeSelector.buttons[index].element.onclick();
        // Relative show in dashboard and index = 1
        if (this.props.chartIndex && this.props.chartIndex === 1) {
          this.updateYAxisData(this.props.stockRef.current.chart.series[0]);
        }
      },0);
    });
    clearTimeout(timeout);
  }

  // TODO: Reto want to start chart from 0
  updateYAxisData(seriesData) {
    let startYPoint = seriesData.dataMin;
    let yDataArr = seriesData.yAxis.series[0].data; //.splice(yDataStartPoint, seriesData.yAxis.series[0].data.length - yDataStartPoint);
   
    let newYdata = yDataArr.map((item) => {
      item.y = item.y - startYPoint;
      return item.y;
    });
    let dataYasix = newYdata;// .splice(yDataStartPoint, newYdata.length - yDataStartPoint);
    seriesData.yAxis.update(dataYasix);
  }

  render(){
    return this.props.chartRange.map( (range,index) => {
      return (
        <div 
          onClick={this.onChartRangeClick.bind(this,range,index)}
          id={index}
          className={this.state.activeIndex === index ? 'active' : ''}
          key={range}
          style={{padding:this.props.padding}}
        >
          {range}
        </div>
      )
    })
  }
}
ChartRange.defaultProps = {
  padding: '4px 10px',
  chartRange:["1W","1M","3M","6M","ALL"],
  changeChartRange:() => {}
}

export default connect(null,{ switchTimeLine,swicthDateXAxis, enableAmountChartAnimation, enableRelativeChartAnimation })(ChartRange);