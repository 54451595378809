import React from "react";
import PropTypes from 'prop-types';

const Stepper = (props) => {
  let percent = props.activeStep * 100/ props.totalSteps;
  return (
    <section>
      <div className="label-section">
        <label>{props.headerLeft}</label>
        <label>
          {props.textStore.riskProfiling.stepOf
            .replace("{currentStep}", props.activeStep)
            .replace("{totalStep}", props.totalSteps)}
        </label>
      </div>
      <div className="stepper-container">
          <div className="stepper-bar">
            <div className="active-percent" style={{ width: `${percent}%` }} />
          </div>
        </div>
    </section>
  )
}

Stepper.propTypes = {
  activeStep: PropTypes.number
};

export default Stepper;