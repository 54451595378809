import React from 'react';
import PlanningSection from '../../GoalDetail/PlanningSection/PlanningSection';
function statsSection({planning , screen, statusErrorDisplay, textStore}){
	return(
		<div className= { textStore.screen.onBoarding ? "" : "m-t-md"}>
			{	textStore.screen.onBoarding? '': <h3 className="section-title">
					{textStore.common.result}
				</h3>
			}
			<table 
				className="list-view"
				style={{
					tableLayout:"fixed"
				}}
			>
				<tbody>
					<PlanningSection textStore={textStore} screen={screen} planning={planning} statusErrorDisplay={statusErrorDisplay}></PlanningSection>
				</tbody>
			</table>
		</div>
	)
}

export default statsSection;