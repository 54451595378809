export const SWITCH_DATE_XAXIS = 'SWITCH_DATE_XAXIS';

export const CHANGE_TO_RELATIVE_CHART = 'CHANGE_TO_RELATIVE_CHART';

export const CHANGE_TO_AMOUNT_CHART = 'CHANGE_TO_AMOUNT_CHART';

export const DASH_BOARD_CHART = 'DASH_BOARD_CHART';

export const DETAIL_CHANGE_TO_AMOUNT_CHART = 'DETAIL_CHANGE_TO_AMOUNT_CHART';

export const RESET_LABEL_DATE = 'RESET_LABEL_DATE';

export const DISABLE_AMOUNT_CHART_ANIMATION = 'DISABLE_AMOUNT_CHART_ANIMATION';

export const ENABLE_AMOUNT_CHART_ANIMATION = 'ENABLE_AMOUNT_CHART_ANIMATION';

export const DISABLE_RELATIVE_CHART_ANIMATION = 'DISABLE_RELATIVE_CHART_ANIMATION';

export const ENABLE_RELATIVE_CHART_ANIMATION = 'ENABLE_RELATIVE_CHART_ANIMATION';

export const INITIATE_GAUGE_CHART_BLUE_CONFIG = 'INITIATE_GAUGE_CHART_BLUE_CONFIG';

export const INITIATE_GAUGE_CHART_WHITE_CONFIG = 'INITIATE_GAUGE_CHART_WHITE_CONFIG';