import React from 'react';
import { connect } from 'react-redux';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { invertLayout } from "../../../actions/AppMain/AppMainAction";

import HelperBanner from '../HelperBanner';
import SupportItem from '../SupportItem';
import LoadMoreButton from '../../common/LoadMoreButton';

import './styles.less';

import { supportCategory } from '../data';

class SupportCategory extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'General questions' },
      headerRight: '',
      currentPage: 'SupportCategory',
    });
  }

  render() {
    return (
      <div className="support">
        <div className="support-list">
          {
            supportCategory.map((datum) => (
              <SupportItem key={datum.id} text={datum.text} link={datum.link}/>
            ))
          }
        </div>
        <LoadMoreButton />
        <HelperBanner />
      </div>
    )
  }
}

export default connect(null, { switchHeader, invertLayout })(withBaseLayout(SupportCategory));