import React from 'react';
import ReactHighStocks from 'react-highcharts/ReactHighstock';
import { connect } from 'react-redux';
import ChartRange from  '../../Charts/ChartRange/ChartRange';

class PerformanceSection extends React.Component {
	constructor(){
		super()
		this.stockRef = React.createRef();
	}
	render(){
		return(
			<React.Fragment>
				<div className="chart__container">
					<div className="chart__element__outer">
						<div className="chart_element_inner">
							<ReactHighStocks 
                config={this.props.chartUI} 
                domProps={{className:"chart__element"}} 
                ref={this.stockRef}
              />
						</div>
					</div>
				</div>
				<div className="chart__range">
					<ChartRange 
						stockRef={this.stockRef} 
						padding={'1px 11px'}
					/>
				</div>
			</React.Fragment>
		)
	}
}


function mapStateToProps(props){
	return {
		chartUI:props.chartUI
	}
}

export default connect(mapStateToProps)(PerformanceSection);