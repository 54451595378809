import React from 'react';
import { formatCurrency } from '../../../helpers/formatCurrency';

class DashBoardTableView extends React.Component {
  render(){
    return this.props.list.map((item,key) => {
      return (
        <tr 
          key={key}
          onClick={this.props.onItemClick.bind(this,item)}
          style={{cursor:"pointer",
          borderBottom: key === this.props.list.length - 1 ? '0px none white' : ''
         }}
        >
          <th
            style={{
              width: 'auto',
              borderBottom: key === this.props.list.length - 1 ? '0px none white' : ''
            }}
          >
            <span className={`icn ${this.props.iconType}--${item.category}`}>
              {item.title}
            </span>
            <span  className="warning" style={{display: (item.probability === 3 || item.probability === 1) && item.title !== 'General investment' ? 'warning' : 'none' ,marginLeft:"5px"}}></span>
          </th>
          <td 
            className="text-right"
            style={{
              borderBottom: key === this.props.list.length - 1 ? '0px none white' : ''
            }}
          >
            <div className="list-action">
              <span className="list-action__test">
                { formatCurrency(item.currentValue)}
              </span>
              <span
                className="list-action__link list-action__link--more"
              >
                <span className="sr-only">Show more</span>
              </span>
            </div>
          </td>
        </tr>
      )
    })
  }
}

export default DashBoardTableView;

