import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import { switchHeader } from '../../../../actions/Header/HeaderActions';

import { getTestData } from "../../../../actions/Onboarding/OnboardingActions";
import { savePreviousScreen } from "../../../../actions/Screen/ScreenAction";
import PersonalFinance from '../../PersonalFinance';
import { updatePersonalFinanceData } from './action';

import { toggleAsyncLoadingStatus } from '../../../../actions/AppMain/AppMainAction';

const mapStateToProps = ({ 
  appMainUI: { CTB: { YearlyIncome, TotalWealth, OriginOfInvestment } },
  personalFinance: { formData },
  onboardingData: { testParams: { responsePostRegistration }},
}) => ({
  YearlyIncome, TotalWealth, OriginOfInvestment, formData, responsePostRegistration
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getTestData: () => dispatch(getTestData()),
  switchHeader: (header) => dispatch(switchHeader(header)),
  savePreviousScreen: (screenName) => dispatch(savePreviousScreen(screenName)),
  toggleAsyncLoadingStatus: (params) => dispatch(toggleAsyncLoadingStatus(params)),
  updatePersonalFinanceData: (data) => dispatch(updatePersonalFinanceData(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalFinance));

