import React from 'react';


function allocationCurrencies(){
	return(
		<section className="tabpanel chart__currency">
			<div className="chart chart--column">
				<div className="chart__container">
					<div className="chart__element_outer">
						<div className="chart__element_inner">
							<div className="chart__element">
								<div className="column-chart__item">
									<div className="column-chart__bar-wrapper">
										<span 
											className="column-chart__bar" 
											style={{
												backgroundColor:"#E64F97",
												height:"100%"												
											}}
										>
										</span>
									</div>
									<div className="column-chart__text">
										<span className="column-chart__item__name">
											USD
										</span>
										<span className="column-chart__item__name text-label__values__small-1">
											100%
										</span>	
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default allocationCurrencies;