import React from "react";
import GenericListItem from "../../common/GenericList/GenericListItem";
import { formatCurrency } from "../../../helpers/formatCurrency";


class TransactionOverview extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const listItems = [
      {title: 'Investments', data: this.props.overview.investments, icon: 'icn--investment'},
      {title: 'Withdrawals', data: this.props.overview.withdrawals, icon: 'icn--withdraw'},
    ]
    const balance = this.props.overview.investments + this.props.overview.withdrawals + this.props.overview.performance
    return (
      <React.Fragment>
        <GenericListItem
          left="Overview"
          leftClass="transaction-text-left"
        />
        <GenericListItem
          left="Account balance"
          leftStyle={{ fontFamily: "SansSemibold" }}
          right={formatCurrency(balance)}
        />
        {listItems.map(item => (
          <GenericListItem
            key={item.title}
            iconClass={item.icon}
            left={item.title}
            right={formatCurrency(item.data)}
          />
        ))}
        <GenericListItem
          containerStyle={{borderBottom: 'none'}}
          iconClass="icn--performance"
          left="Performance"
          right={`${this.props.overview.performance > 0 ? "+" : "-"} ${formatCurrency(this.props.overview.performance)}`}
          rightStyle={{color: this.props.overview.performance > 0 ? '#00b961' : '#4261ff'}}
        />
      </React.Fragment>
    );
  }
}

export default TransactionOverview;
