import { CONTACT_SUPPORT_QUESTION } from "../../actions/Support/SupportActionType";

const supportData = {
  transactionType: "",
  date: "",
  currencyAmount: ""
};

export default function(data = supportData, actions) {
  switch (actions.type) {
    case CONTACT_SUPPORT_QUESTION:
      return {
        ...data,
        transactionType: actions.payload.transactionType,
        date: actions.payload.date,
        currencyAmount: actions.payload.currencyAmount
      };
    default:
      return data;
  }
}
