import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import trophy from '../../../css/images/icons-png/trophy.png';

import MessagesDetail from '../MessagesDetail';

import './styles.less';

const data = {
  time: '25/08/2018',
  text: 'Milestone achieved',
  icon: trophy
}

class GoalMilestone extends React.PureComponent {
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Milestone achieved' },
      headerRight: '',
      currentPage: 'GoalMilestone',
    });
  }

  render() {
    return (
      <MessagesDetail data={data}>
        <div>
          <p>Dear Carlos</p>
          <br/>
          <p>Congratulations, your ‘buy home’ goal reached <b>50% (USD 80,000)</b> of it’s target value.</p>
          <br/>
          <p>This is an important milestone and we’d like to take this opportunity to thank you for being a loyal Classic Robo customer.</p>
          <br/>
          <p>Best regards,</p>
          <p>Classic Robo Operator Support</p>
        </div>
      </MessagesDetail>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(withRouter(GoalMilestone)));