export function addChartIcon(xCoordinator,yCoordinator,chartIcon,width,height){
  let dataMarker = [{
    x:xCoordinator,
    y:yCoordinator
  }]


  // let marker = {
  //   //data: dataMarker,
  //   marker:{
  //     "enabled":true,
  //     "symbol": `url(${chartIcon})`,
  //     "width":width,
  //     "height":height
  //   },
  //   //showInLegend: false
  // }
  let obj = {};
  obj.x = xCoordinator;
  obj.y = yCoordinator;
  obj.marker = {
    "enabled":true,
    "symbol": `url(${chartIcon})`,
    "width": width,
    "height":height
  };
 // obj.marker = marker;
  return obj;
}