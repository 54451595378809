//wil be remove when intergrate with dfs4

import React from 'react';
import firebase from 'firebase/app';
import {  withRouter } from 'react-router-dom';
import 'firebase/auth';
import CONFIG from '../../setting/config';
class LogOut extends React.Component {
	constructor() {
		super();
		this.routesName = CONFIG.APP_ROUTE;
	}
	componentDidMount(){
		firebase.auth().signOut();
		this.props.history.push(this.routesName.LOG_IN);
	}
	render(){
		return(
			<div>
			</div>
		)
	}
}

export default withRouter(LogOut)