import React from "react";
import { connect } from "react-redux";
import ReactHighChart from "react-highcharts";
import PropTypes from "prop-types";

class ChartGauge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
    this.chartRef = React.createRef();
    this.timer = null;
    this.interval = null;
    this.config = {
      color: props.type === 'white' ? "rgba(255, 255, 255, 0.6)" : "rgba(66,97,255,0.6)"
    }
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.chartRef.current.chart.series[0].data[0].update(this.props.data);
      this.chartRef.current.chart.yAxis[0].update({
        min: this.props.min, 
        max: this.props.max, 
        minorTickInterval: (this.props.max / 100) * 2,
      });
      this.chartRef.current.chart.yAxis[1].update({
        min: this.props.min,
        max: this.props.max,
        minorTickInterval: (this.props.max / 100) * 2,
      });
      this.chartRef.current.chart.yAxis[0].removePlotBand("inactive");
      this.chartRef.current.chart.yAxis[0].addPlotBand({
        from: this.props.min,
        to: this.props.max,
        color: this.config.color,
        outerRadius: '102%',
        thickness: 18,
        zIndex: 2,
        id: "inactive"
      });
      this.bandColorAnimation();
    }, 500);
  }

  componentWillUnmount() {
    // TODO: Quan refactor
    this.chartRef.current.chart.series[0].data[0].update(0);
    this.chartRef.current.chart.yAxis[0].removePlotBand("inactive");
    this.chartRef.current.chart.yAxis[0].addPlotBand({
      from: this.props.min,
      to: this.props.max,
      color: this.config.color,
      outerRadius: '102%',
      thickness: 18,
      zIndex: 2,
      id: "inactive"
    });
    clearTimeout(this.timer);
    clearInterval(this.interval);
  }

  bandColorAnimation() {
    this.interval = setInterval(() => {
      this.setState({ progress: this.state.progress + (this.props.data / 100) }, () => {
        if (this.state.progress >= this.props.data) {
          clearInterval(this.interval);
          return;
        }
        //this.chartRef.current.chart.series[0].data[0].update(Math.round(this.state.progress * 10) / 10);
        this.chartRef.current.chart.yAxis[0].removePlotBand("inactive");
        this.chartRef.current.chart.yAxis[0].addPlotBand({
          from: this.state.progress,
          to: this.props.max,
          color: this.config.color,
          outerRadius: '102%',
          thickness: 18,
          zIndex: 2,
          id: "inactive"
        });
      });
    }, 1);
  }

  render() {
    return (
      <React.Fragment>
        <ReactHighChart
          config={this.props.chartUIGauge}
          neverReflow={true}
          ref={this.chartRef}
          domProps={{
            className: `chart__gauge ${this.props.customClass}`,
          }}
        />
      </React.Fragment>
    );
  }
}

ChartGauge.defaultProps = {
  customClass: "",
  data: 0,
  min: 0,
  max: 10,
  type: 'blue',
};

ChartGauge.propsTypes = {
  customClass: PropTypes.string,
  data: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.string,
};

function mapStateToProps(props) {
  return {
    chartUIGauge: props.chartUIGauge,
  };
}

export default connect(
  mapStateToProps,
  {  }
)(ChartGauge);
