import React from 'react';
import { connect } from 'react-redux';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import DocumentList from '../List';
import Tabs from '../../common/Tabs';
import SearchForm from '../../common/SearchForm';

import { data } from '../data';

import './styles.less';

const filterData = (arr, searchStr) => (
  arr.filter((ele) => (ele.text.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1))
)

class Documents extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      openConfirm: false,
      searchStr: '',
    }
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Search' },
      headerRight: '',
      currentPage: 'DocumentSearch',
    })
  }

  onOpenSearchForm = () => {
    const { openSearchForm } = this.state; 
    this.setState({
      openSearchForm: !openSearchForm,
      searchStr: '',
    });
  }

  onSearch = (searchStr) => {
    this.setState({ searchStr });
  }

  render() {
    const { searchStr } = this.state;
    const tabs = [
      { 
        id: 'all', 
        header: 'All', 
        comp: () => <DocumentList list={filterData(data.all, searchStr)}/>
      }, { 
        id: 'statements', 
        header: 'Statements', 
        comp: () => <DocumentList list={filterData(data.statements, searchStr)}/> 
      }, { 
        id: 'others', 
        header: 'Others', 
        comp: () => <DocumentList list={filterData(data.others, searchStr)}/>
      }
    ]

    return (
      <div className="document">
        <SearchForm onSearch={this.onSearch} />
        <Tabs tabs={tabs} headerFluid="true" defaultTab="all"/>
      </div>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(Documents));