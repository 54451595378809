import {
	SWITCH_TIMELINE,
	FETCH_DASHBOARD_DATA,
	FETCH_UNREAD_MESSAGE
} from './DashBoardActionType';

import firebase from 'firebase/app';
import 'firebase/firestore'
import {loadingLayout} from '../AppMain/AppMainAction';

export function switchTimeLine(timeline){
	return {
		payload:timeline,
		type:SWITCH_TIMELINE
	};
}

function fetchDashBoardDataSuccess(data){
	return {
		type: FETCH_DASHBOARD_DATA,
		payload:data
	}
}

function fetchUnreadMessageSuccess(message){
	return {
		type: FETCH_UNREAD_MESSAGE,
		payload: message
	}
}
export function fetchDashBoardData(callback){
	return (dispatch => {
		dispatch(loadingLayout('loading'))
		firebase.firestore().collection('PortfolioManagement_GetInvestmentOverview').doc("1").onSnapshot(doc => {
			dispatch(loadingLayout(''));
			dispatch(fetchDashBoardDataSuccess(doc.data()));
		})
	})
}

export function fetchUnreadMessage(){
	return (dispatch => {
		dispatch(loadingLayout('loading'))
		firebase.firestore().collection('Communication_CountUnreadMessagesCount').doc('1').onSnapshot(doc => {
			dispatch(loadingLayout(''));
			dispatch(fetchUnreadMessageSuccess(doc.data()))
		})
	})
}