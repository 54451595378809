import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUp from "../common/Popup/PopUp";
import { editGoal } from "../../actions/Goals/GoalActions";
import CONFIG from "../../setting/config";
// import { editGoalStrategy } from "../../actions/GoalDetail/GoalDetailActions";

class NavBarGoalDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      active: false,
      message: "",
      rightButtonText: "",
      secondMessage: "",
      activeClass: ""
    };
    this.routesName = CONFIG.APP_ROUTE;
    this.onGoalDelete = this.onGoalDelete.bind(this);
    this.onGoalEdit = this.onGoalEdit.bind(this);
    this.onEditStrategy = this.onEditStrategy.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.goalDeleteConfirm = this.goalDeleteConfirm.bind(this);
    this.onNavbarClose = this.onNavbarClose.bind(this);
  }

  goalDeleteConfirm() {
    this.setState(
      {
        active: !this.state.active
      },
      () => {
        this.props.onCloseNavBar();
        this.props.history.push(this.routesName.DELETED);
        this.props.moveHeader();
      }
    );
  }
  onGoalDelete() {
    if (this.props.goalDetailData.currentValue !== 0) {
      this.setState({
        active: !this.state.active,
        message:
          "Please withdraw the investment amount of this goal first before deleting it.",
        rightButtonText: "Withdraw"
      });
    } else {
      this.setState({
        active: !this.state.active,
        message: "Are you sure you want to delete your goal?",
        rightButtonText: "Confirm",
        secondMessage:
          "Data such as historical performance and transaction history will get deleted permanently."
      });
    }
  }

  onGoalEdit() {
    this.props.onCloseNavBar(() => {
      this.props.history.push(`/goaldetail/${this.props.id}/edit`);
      this.props.editGoal(this.props.goalDetailData);
      this.props.moveHeader();
    });
  }

  onEditStrategy() {
    this.props.onCloseNavBar(() => {
      this.props.history.push(`/goaldetail/${this.props.id}/editstrategy`);
      this.props.moveHeader();
    });
  }

  onNavbarClose() {
    this.props.onCloseNavBar();
    this.props.moveHeader();
  }

  closePopUp() {
    this.setState({
      active: !this.state.active
    });
  }

  renderPopUp() {
    if (this.state.active) {
      return (
        <PopUp
          language={this.props.language}
          closePopUp={this.closePopUp}
          message={this.state.message}
          rightButtonText={this.state.rightButtonText}
          secondMessage={this.state.secondMessage}
          onConfirmClick={this.goalDeleteConfirm}
        />
      );
    }
  }
  render() {
    return (
      <nav
        className="nav-offcanvas nav-offcanvas--top nav-offcanvas--more"
        id="NavMore"
      >
        {this.renderPopUp()}
        <div className="nav-offcanvas__inner" style={{boxShadow: 'none'}}>
          <div className="nav__actions">
            <span className="toggle-offcanvas--title">Options</span>
            <button
              onClick={this.onNavbarClose}
              className="toggle-offcanvas--close"
              id="CloseNavMain"
              type="button"
            >
              <span className="sr-only">Hide navigation</span>
              <span
                className="toggle-icon toggle-icon--nav"
                role="presentation"
              >
                <div className="nav-menu-close-icon" />
              </span>
            </button>
          </div>

          <ul className="nav__more list-nav">
            <li 
              onClick={this.onGoalEdit} 
              className="nav__more--edit-goal"
            >
              <span>Edit goal</span>
            </li>
            <li 
              onClick={this.onEditStrategy} 
              className="nav__more--strategy"
            >
              <span>Change strategy</span>
            </li>
            <li 
              className="nav__more--delete-goal" 
              onClick={this.onGoalDelete}
            >
              <span>Delete Goal </span>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
function mapStateToProps(props) {
  return {
    goalDetailData: props.goalDetailData
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { editGoal }
  )(NavBarGoalDetail)
);
