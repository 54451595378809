import Slider from 'react-slick';
import React from 'react';
import { connect } from 'react-redux';
import { switchTabBar } from '../../../actions/TabBar/TabBarActions';
import { setTabBar } from '../../../actions/TabBar/TabBarActions';
import {getTransactionHistoryOverview}  from '../../../actions/TransactionHistory/TransactionHistoryAction';
import {unformatCurrency} from '../../../helpers/formatCurrency';



// :TODO: Finish Error Page
class TabBar extends React.Component {

  constructor(){
    super()
    this.slider = React.createRef();
  }

  componentDidMount(){
    this.props.setTabBar(this.slider);
  }

  onTabClick(type) {
    if (this.props.screenUI.currentScreen === 'transactionHistory' || this.props.screenUI.currentScreen === 'search') {
      let defaultItems = 10;
      this.props.getTransactionHistoryOverview(defaultItems);
    }
    this.props.switchTabBar(type);
  }

  renderTabbar() {
    let addTab = [];
    // Edit screen
    if (this.props.screenUI.currentScreen === 'goalEditScreen') {
      // Top up is show instruction
      if (unformatCurrency(this.props.goalData.topUpInvestmentAmount) > 0) {
        addTab = [
          {
            name: 'Top-up',
            className: this.props.goalUI.transferCompleted
          },
          {
            name: 'Instructions',
            className: this.props.goalUI.instructionsCompleted
          },
        ]
      } else if (unformatCurrency(this.props.goalData.withdrawInvestmentAmount) > 0) { // Withdraw is not show instructions
        addTab = [
          {
            name: 'Withdraw',
            className: this.props.goalUI.transferCompleted
          }
        ]
      }
    } else { // Create screen
      addTab = [
        {
          name: 'Transfer',
          className: this.props.goalUI.transferCompleted
        },
        {
          name: 'Instructions',
          className: this.props.goalUI.instructionsCompleted
        }
      ]
    }

    const tabbarList = [
      {
        name: 'Goal',
        className: this.props.goalUI.goalCompleted
      },
      {
        name: 'Strategy',
        className: this.props.goalUI.strategyCompleted
      },
      {
        name: 'Summary',
        className: this.props.goalUI.summaryCompleted
      },
      ...addTab
    ];
    
    const tabbarListUI = tabbarList.map((item) => 
      <li key={item.name} className={item.className} >
        <span>
            {item.name}
          <span className="icon"
            style={{display: item.className === 'complete' ? '' : 'none'}}
          >
          </span>
        </span>
      </li>
    ); 
    return tabbarListUI;
  }
  

  renderTabBarType(slide){
    if(this.props.type === 'goaldetail'){
      return (
        <div className="tablist_wrapper">
          <Slider {...this.props.settings}  ref={this.slider}>
            {slide}
          </Slider>
        </div>
      )
    } else {
      return(
        <nav className="progressbar">
          <div className="progressbar__wrapper">
            <Slider 
              {...this.props.settings}           
              ref={this.slider}
            >
            {this.renderTabbar()}
            </Slider>
          </div>
        </nav>
      )
    }
  }
  render(){
    const slide = this.props.tabs.map((value,key) => {
      return(
        <li 
          onClick={this.onTabClick.bind(this,value)} 
          key={value} 
        >
          <span
            style={{
              color:this.props.tabBarUI.currentTab === value ? '#2c2c39' : '',
              fontFamily:this.props.tabBarUI.currentTab === value ? 'SansSemibold' : '',
              borderBottomColor:this.props.tabBarUI.currentTab === value ? '#4261ff' : '',
              boxShadow: this.props.tabBarUI.currentTab === value ?  '0 4px 2px -2px #4261ff' : '',
              position: "relative",
              top: "2px",
            }}
          >
            {value}
          </span> 
        </li>
      )
    })
    return (
      this.renderTabBarType(slide)
    )
  }
}

function mapStateToProps(props){
  return {
    tabBarUI:props.tabBarUI,
    goalUI:props.goalUI,
    goalData: props.goalData,
    screenUI: props.screenUI
  }
}

TabBar.defaultProps = {
  type:'goaldetail',
  settings: {
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide:true,
    infinite:false
  }
}


export default connect(mapStateToProps,{ switchTabBar,setTabBar, getTransactionHistoryOverview })(TabBar);