import React from 'react';
import { formatCurrency } from '../../../helpers/formatCurrency';
class GoalTransfer extends React.Component{	
	renderOneTimeTransfer(){
		return this.props.transfer.map((value,index) => {
			return (
				<React.Fragment key={index}>

					<tr id="borderless-bottom">
						<th id="borderless-bottom">
							<span className={`icn goal--${value.type}`}>
								{value.goalName}
							</span>
						</th>
						<td 
							id="borderless-bottom" 
							className="text-right"
						>
						{
							value.typeScreen === 'EditGoal' && value.topUpInvestmentAmount ? 
							formatCurrency(value.topUpInvestmentAmount) : 
							formatCurrency(value.initialInvestmentAmount)
						}
						</td>
					</tr>
				</React.Fragment>
			)
		})
	}
	renderMonthlyTransfer(){
		return this.props.transfer.map((value,index) => {
			return (
				<React.Fragment key={index}>
					<tr id="borderless-bottom">
						<th id="borderless-bottom">
							<span className={`icn goal--${value.type}`}>
								{value.goalName}
							</span>
						</th>
						<td 
							id="borderless-bottom" 
							className="text-right"
						>
							{formatCurrency(value.monthlyInvestmentAmount)}
						</td>
					</tr>
				</React.Fragment>
			)
		})
	}
	render(){
		return (
			<React.Fragment>
				<h2 className="section-title">One-time transfer</h2>
				<table className="list-view">
					<tbody>
						<tr className="border-top"
							style={{display:this.props.transfer.length > 1 ? '' :'none' }}
						>
							<th
								className="text-bold"
								style={{ borderTop: 'none' }}
							>
								Total
							</th>
							<td className="text-right">
									{formatCurrency(this.props.totalInitialInvestmentAmount)}
							</td>
						</tr>
						{this.renderOneTimeTransfer()}
					</tbody>
				</table>
				<h2 className="section-title">Monthly transfer</h2>
				<table className="list-view">
					<tbody>
						<tr className="border-top"
							style={{display:this.props.transfer.length > 1 ? '' :'none' }}
						>
							<th
								className="text-bold"
								style={{ borderTop: 'none' }}
							>
								Total
							</th>
							<td className="text-right">
								{formatCurrency(this.props.totalMonthlyInvestmentAmount)}
							</td>
						</tr>
						{this.renderMonthlyTransfer()}
					</tbody>
				</table>
			</React.Fragment>
		)
	}
}

export default GoalTransfer;