import React from 'react';

import './styles.less';

const getCurrentText = (arr, value) => (
  arr.filter((ele) => (ele.value === value))[0] || {}
);

const wHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

export default class CustomDropdown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dropdownID = `custom-dropdown-${props.name}`;

    this.state = {
      value: '',
      text: '',
      isOpen: false,
      isFog: false,
    }

  }

  componentWillMount() {
    const { placeholder, data, defaultSelected } = this.props;
    const firstEle = data[0] || {};
    const value = !placeholder ? firstEle.value : (defaultSelected || '');
    const text = getCurrentText(data, value).text || placeholder;
    this.setState({ value, text, isFog: !value });
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickWindow);
  }

  onClickWindow = (evt) => {
    const currentEle = document.getElementById(`custom-dropdown-${this.props.name}`);

    if (!currentEle.contains(evt.target)) {
      this.setState({ isOpen: false });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickWindow);
  }

  onClick = (evt) => {
    evt.preventDefault();

    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  onSelect = (evt) => {
    evt.preventDefault();

    const { onSelect, name } = this.props;
    const currentTarget = evt.currentTarget;
    const value = currentTarget.getAttribute('data-value') || '';
    const text = currentTarget.innerText || '';

    this.setState({ value, text, isOpen: false, isFog: false });

    typeof onSelect === 'function' && onSelect({ name, value });
  }

  calcIsTop = () => {
    const ele = document.getElementById(this.dropdownID);

    if (!ele) { return false; }
    
    const box = ele.getBoundingClientRect();

    return (Math.round(box.top) > (wHeight/2))
  }

  render() {
    const { value, text, isOpen, isFog } = this.state;
    const { label, required, data, className, name, tooltip } = this.props;
    const openClass = this.calcIsTop() ? 'open top' : 'open bottom';
    
    return (
      <div id={this.dropdownID} className={['custom-dropdown', className].join(' ')}>
        <div className={['custom-dropdown__label', required ? 'required' : ''].join(' ')}>{label}</div>
        {!tooltip ? '' : <span className="custom-dropdown__tooltip-icon"></span>}
        <div className={['custom-dropdown__input', isOpen ? openClass : ''].join(' ')}>
          <div
            className={['custom-dropdown__input-header', isFog ? 'fog' : ''].join(' ')}
            onClick={this.onClick}
          >{text}</div>
          <div className="custom-dropdown__input-body">
            {
              data.map((datum) => (
                <div
                  className={["custom-dropdown__input-option", datum.value === value ? 'active' : ''].join(' ')}
                  key={datum.value}
                  data-value={datum.value}
                  onClick={this.onSelect}
                >
                  <span className="custom-dropdown__input-option__text">{datum.text}</span>
                  {
                    datum.value === value ? (
                      <span className="custom-dropdown__input-option__icon"></span>
                    ) : ''
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }
}