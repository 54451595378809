import React from "react";
import withBaseLayout from "../HoC/withBaseLayout";
import { connect } from "react-redux";
import { signIn } from "../../actions/Auth/AuthActions";
import { switchHeader } from "../../actions/Header/HeaderActions";
import { Redirect } from "react-router-dom";
import CONFIG from "../../setting/config";
import { invertLayout } from "../../actions/AppMain/AppMainAction";
import TextInput from "../common/CustomInput/TextInput/TextInput";
import changeLanguage from "../../setting/languages/languages";

//subject to change when integrate with dfs4
class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onUserNameChange = this.onUserNameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
	}

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "",
      headerMiddle: {
        type: "",
        text: ""
      },
      headerRight: "",
      currentPage: ""
    });
    this.props.invertLayout("viewport layout--inverted");
  }

  componentWillUnmount() {
    this.props.invertLayout("viewport layout--default");
  }

  onUserNameChange(e) {
    this.setState({
      username: e.target.value
    });
  }

  onPasswordChange(e) {
    this.setState({
      password: e.target.value
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
   // this.props.signIn(this.state.username, this.state.password);
  }

  render() {
    // if (this.props.authData.isSignIn) {
    //   return <Redirect to={CONFIG.APP_ROUTE.DASHBOARD} />;
    // }

    return (
      <form className="content-main__inner" onSubmit={this.onFormSubmit}>
        <div className="content-main__body content-main__body--centered">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/AppIcons%2FClassicRobo-Logo.png?alt=media&token=fb28054e-07a9-49f9-831f-5465db021744"
            alt="Advisor"
            className="m-b-lg app-logo"
          />
          <div className="form-section">
            <div className="input-wrapper">
              <span className="icn icn--username" />
              <TextInput
                className="form-control cockpit-input-field__full__small__inverted"
                id="username"
                value={this.state.username}
                placeholder={this.textStore.common.emailAddress}
                onChange={this.onUserNameChange}
                required
              />
            </div>
            <div className="input-wrapper">
              <span className="icn icn--password" />
              <TextInput
                className="form-control cockpit-input-field__full__small__inverted"
                id="password"
                type="password"
                value={this.state.password}
                placeholder={this.textStore.common.password}
                onChange={this.onPasswordChange}
                required
              />
            </div>
          </div>
          <div
            className="text-danger"
            style={{
              display: this.props.authUI.passwordError ? "" : "none"
            }}
          >
            Password or Email is incorrect
          </div>
          <a className="forgot-password-link" href={this.routesName.RESET_PASSWORD.EMAIL_INPUT}>
            {this.textStore.login.forgotPassword}
          </a>
        </div>
        <div className="content-main__footer login-footer">
          <div className="form-actions">
            <div className="form-actions__item">
              <button className="btn btn-primary btn-no-focus__primary">
                Login
              </button>
            </div>
            <div className="sign-up_text">
              {this.textStore.login.dontHaveAnAccount}
              <a className="sign-up_link" href="/">
                {this.textStore.common.signUp}
              </a>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const AuthBaseLayout = withBaseLayout(Auth);

function mapStateToProps(props) {
  return {
    authUI: props.authUI,
    authData: props.authData
  };
}
export default connect(
  mapStateToProps,
  { signIn, switchHeader, invertLayout }
)(AuthBaseLayout);
