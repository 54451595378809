import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import changeLanguage from "../../setting/languages/languages";
import CONFIG from "../../setting/config";
import ChartGauge from "../Charts/ChartRange/ChartGauge";
import withBaseLayout from "../HoC/withBaseLayout";
import { invertLayout } from "../../actions/AppMain/AppMainAction";
import { switchHeader } from "../../actions/Header/HeaderActions";
import { getCalculateRisk } from "../../actions/Onboarding/OnboardingActions";
import { initiateGaugeChartWhiteConfig } from "../../actions/Chart/ChartActions";

class RiskProfile extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentWillMount() {
    this.props.initiateGaugeChartWhiteConfig();
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.textStore.common.yourRiskProfile
      },
      headerRight: "",
      currentPage: "riskProfile"
    });
    this.props.getCalculateRisk();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.invertLayout("viewport layout--default");
  }

  handleConfirm() {
    this.props.history.push(this.routesName.SETTINGS_UPDATED);
  }

  render() {
    const riskCategoryName = this.props.onboardingData.calculateRisk
      .riskCategory.Name;
    //const riskScore = this.props.onboardingData.calculateRisk.frontEndRiskMapping[riskCategoryName]
    return (
      <React.Fragment>
        <section className="risk-profile-container">
          <section className="risk-profile-title">
            <div className="risk-profile-title__name">{riskCategoryName}</div>
            <div className="risk-profile-title__status">Expired</div>
          </section>
          <section className="risk-profile-chart">
            <ChartGauge
              data={3}
              //min={this.props.onboardingData.CTB.min.riskProfileScale}
              //max={this.props.onboardingData.CTB.max.riskProfileScale}
              min={0}
              max={5}
              type="white"
            />
          </section>
          <section className="risk-profile-status">
            <span>{riskCategoryName}</span>
          </section>
          <section className="risk-profile-cap">
            <span>{this.textStore.riskProfiling.riskScoreCap}</span>
          </section>
          <section className="risk-profile-date">
            <span className="risk-profile-date__left">
              <span className="icn icn--time-small" />
              {this.textStore.common.expiryDate}
            </span>
            <span className="risk-profile-date__right">30/08/2018</span>
          </section>
        </section>
        <section className="content-main__footer">
          <div className="form-actions p-t-sm">
            <div className="form-actions__item">
              <button
                className="btn btn-primary btn-no-focus__primary__inverted"
                onClick={this.handleConfirm}
              >
                {this.textStore.common.renew}
              </button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(props) {
  return {
    goalData: props.goalData,
    appMainUI: props.appMainUI,
    onboardingData: props.onboardingData
  };
}

const EnhancedRiskProfile = withBaseLayout(RiskProfile);

export default withRouter(
  connect(
    mapStateToProps,
    {
      invertLayout,
      switchHeader,
      getCalculateRisk,
      initiateGaugeChartWhiteConfig
    }
  )(EnhancedRiskProfile)
);
