import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withBaseLayout from '../../HoC/withBaseLayout';
import changeLanguage from '../../../setting/languages/languages';
import CONFIG from '../../../setting/config';
import { switchHeader } from '../../../actions/Header/HeaderActions';
import Stepper from "../../Onboarding/RiskProfiling/Stepper/Stepper";
import ExpandableView from '../../common/ExpandableView/ExpandableView';
import GoalFooter from '../../Goals/GoalFooter/GoalFooter';
import { savePreviousScreen } from '../../../actions/Screen/ScreenAction';

import { toggleAsyncLoadingStatus } from '../../../actions/AppMain/AppMainAction';

class ComplianceDeclaration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canGoNext: false,
      activeStep: 8,
      totalSteps: 8
    }
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onCheckBoxClick = this.onCheckBoxClick.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.textStore.common.classicRoboTitle,
      },
      headerRight: "",
      currentPage: "complianceDeclaration",
    })
  }

  onCheckBoxClick(e) {
    if (e.target.checked) {
      this.setState({canGoNext: true});
    } else {
      this.setState({canGoNext: false});
    }
  }

  onClickNext() {
    this.props.toggleAsyncLoadingStatus({ status: true, text: this.textStore.verification.verifying });
    let timerSubmit = window.setTimeout(() => {
      window.clearTimeout(timerSubmit);
      this.props.toggleAsyncLoadingStatus({ status: false, text: this.textStore.verification.verifying });

      if (this.props.responseTermsAndConditions === 504) {
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.VERIFY.OTP.TIMED_OUT,
          state: {}
        });
        return;
      }
      if (this.props.responseTermsAndConditions !== 200) {
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.VERIFY.COMPLIANCE_DECLARATION.FAILED,
          state: {
            title: this.textStore.complianceDeclaration.onlineVerificationFailed,
            desc: this.textStore.complianceDeclaration.onlineVerificationNotSuccess,
            buttonName: this.textStore.common.backToVerification
          }
        });
        return;
      } 
      this.props.history.push({
        pathname: this.routesName.ONBOARDING.GREETING,
        state: {}
      });
    }, 1000);
    // this.props.history.push({
    //   pathname: this.routesName.ONBOARDING.VERIFY.COMPLIANCE_DECLARATION.VERIFYING,
    //   state: {
    //     type: "termsAndConditions",
    //     loadingText: this.textStore.verification.verifying,
    //   },
    // })
  }

  render() {
    const terms = [
      {title: this.textStore.complianceDeclaration.localRestrictions, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.websiteOwnerShip, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.copyrightAndTrademarks, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.noOfferOrAdvice, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.exclusionOfLiability, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.disclaimerOfWarranty, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.links, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.materialInterests, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.confidentialityAndEncryption, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.confidentialityInRespect, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.properUseOfTheWebsite, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.registrationAndUseOf, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.feeBasedServices, description: this.textStore.complianceDeclaration.theWebsiteIs},
      {title: this.textStore.complianceDeclaration.premium, description: this.textStore.complianceDeclaration.theWebsiteIs},
    ]
    return (
      <React.Fragment>
        <Stepper
          activeStep={this.state.activeStep}
          totalSteps={this.state.totalSteps}
          textStore={this.textStore}
          headerLeft={this.textStore.complianceDeclaration.terms}
        />
        <p className="compliance-declaration__text-title  ">{this.textStore.complianceDeclaration.termsAndConditions}
        </p>
        <p className="text-label__descriptions compliance-declaration-introduction">
          {this.textStore.complianceDeclaration.classicRoboOperator}
        </p>
        <div className="compliance-declaration-terms-section">
          <ExpandableView
            data={terms}
            firstElementHasBorderTop={true}
            fixedRowHeight="53px"
          />
        </div>
        <div className="compliance-declaration-agreement">
          <input type="checkbox" id="compliance-declaration-agreement__checkbox" className="input--check-box" onClick={this.onCheckBoxClick}/>
          <label htmlFor="compliance-declaration-agreement__checkbox" className="text-label">
            {this.textStore.complianceDeclaration.iHaveRead}
          </label>
        </div>
        <GoalFooter
          onClickNext={this.onClickNext}
          enableNextButton={this.state.canGoNext}
          renderBackButton={false}
          nextButtonFullWidth={true}
          nextButtonText={this.textStore.common.accept}
        />
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this.props.savePreviousScreen(this.textStore.screen.complianceDeclaration)
  }
} 

const mapStateToProps = ({ 
  onboardingData: { testParams: { responseTermsAndConditions }}
}) => ({
  responseTermsAndConditions
});

const EnhancedComplianceDeclaration = withBaseLayout(ComplianceDeclaration)

export default withRouter(
  connect(
    mapStateToProps,
    { switchHeader, savePreviousScreen, toggleAsyncLoadingStatus }
  )(EnhancedComplianceDeclaration)
);