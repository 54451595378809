import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import GenericListItem from "../../common/GenericList/GenericListItem";
import { getTransactionHistorySecurity } from "../../../actions/TransactionHistory/TransactionHistoryAction";
import { formatCurrency } from "../../../helpers/formatCurrency";
import { setSupportQuestion } from "../../../actions/Support/SupportActions";
import CONFIG from "../../../setting/config";

class TransactionHistorySecurity extends React.Component {
  constructor() {
    super();
    this.state = {
      expandList: []
    };
    this.routesName = CONFIG.APP_ROUTE;
    this.onContactClick = this.onContactClick.bind(this);
  }

  componentDidMount() {
    this.props.getTransactionHistorySecurity();
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: { type: "text", text: "Security transaction" },
      headerRight: "",
      currentPage: "securityTransaction"
    });
  }

  onItemClick(index) {
    if (
      this.state.expandList[index] === false ||
      this.state.expandList[index] === undefined
    ) {
      let arr = this.state.expandList.slice();
      arr[index] = true;
      this.setState({
        expandList: arr
      });
    } else {
      let arr = this.state.expandList.slice();
      arr[index] = false;
      this.setState({
        expandList: arr
      });
    }
  }


  renderAmountTransactions(goal,transactionType){
    if(goal.Details !== undefined){
      let arr = goal.Details.filter(value => value.TransactionType === transactionType);
      return arr.map((value,index) => {
        return (
          <GenericListItem
            key={index}
            containerStyle={{ marginLeft: "30px" }}
            left={value.Name}
            bottomLeft={value.ISIN}
            right={formatCurrency(value.Amount)}
            bottomRight={`${value.Unit} Units`}
            hasBorder={index !== arr.length - 1}
          />
        );
      });
    }
  }
  setSupportQuestion() {
    const data = this.props.transactionHistoryData.security;
    this.props.setSupportQuestion({
      transactionType: "'Security transaction'",
      date: data.transactionDate,
      currencyAmount: data.netAmount === 0 ? data.buyAmount : data.netAmount
    });
  }
  onContactClick() {
    this.setSupportQuestion();
    this.props.history.push(this.routesName.CONTACT_SUPPORT);
  }

  renderGoals() {
    const securities = this.props.transactionHistoryData.security;
    return securities.goals.map((goal, index) => {
      return (
        <React.Fragment key={index}>
          <span onClick={this.onItemClick.bind(this, index)}>
            <GenericListItem
              iconClass={`list-action__link--expand 
              ${
                !this.state.expandList[index] ? "icn-open open" : "icn-closed"
              }`}
              left="Goal"
              leftStyle={{ fontFamily: "SansSemibold" }}
              right={goal.Name}
            />
          </span>
          <span
            style={{
              display: !this.state.expandList[index] ? "" : "none"
            }}
          >
            <GenericListItem
              left="Net amount"
              leftStyle={{ fontFamily: "SansSemibold" }}
              right={formatCurrency(goal.NetAmount)}
            />
            <GenericListItem
              iconClass="icn--buy-amount"
              left="Buy amount"
              right={formatCurrency(goal.BuyAmount)}
            />
            {this.renderAmountTransactions(goal, 1)}
            <GenericListItem
              iconClass="icn--sell-amount"
              left="Sell amount"
              right={formatCurrency(goal.SellAmount)}
            />
            {this.renderAmountTransactions(goal, 2)}
          </span>
        </React.Fragment>
      );
    });
  }
  render() {
    const security = this.props.transactionHistoryData.security;
    const listItems = [
      {
        title: "Buy amount",
        data: security.buyAmount,
        icon: "icn--buy-amount"
      },
      {
        title: "Sell amount",
        data: security.sellAmount,
        icon: "icn--sell-amount"
      }
    ];
    return (
      <React.Fragment>
        <GenericListItem
          containerStyle={{ padding: "0 0 18px 0" }}
          iconClass="icn--security"
          topLeft={security.transactionDate}
          left={security.transactionName}
        />
        <GenericListItem
          left="Net amount"
          leftStyle={{ fontFamily: "SansSemibold", letterSpacing: "0.25px" }}
          right={formatCurrency(security.netAmount)}
        />
        {listItems.map((item, index) => (
          <GenericListItem
            key={index}
            iconClass={item.icon}
            left={item.title}
            right={formatCurrency(item.data)}
            hasBorder={index !== listItems.length - 1}
          />
        ))}
        <GenericListItem
          containerStyle={{ padding: "16px 0 6px 0" }}
          left="Details"
          leftStyle={{
            fontFamily: "SansSemibold",
            fontSize: "20px",
            lineHeight: "36px",
            color: "#4261ff"
          }}
        />
        {this.renderGoals()}
        <div className="content-main__footer" style={{ paddingTop: "38px" }}>
          <div className="form-actions__item">
            <input
              type="button"
              className="btn btn-default"
              value="Contact support"
              onClick={this.onContactClick}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const EnhancedTransactionHistorySecurity = withBaseLayout(
  TransactionHistorySecurity
);

function mapStateToProps(props) {
  return {
    transactionHistoryData: props.transactionHistoryData
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { switchHeader, getTransactionHistorySecurity, setSupportQuestion }
  )(EnhancedTransactionHistorySecurity)
);
