import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../actions/Auth/AuthActions';
import { switchNavBarItem } from '../../actions/NavBar/NavBarActions';
import CONFIG from '../../setting/config';
class NavBarLinks extends React.Component {
  constructor(){
    super()
    this.state = {
      version: '',
    }
    this.routesName = CONFIG.APP_ROUTE;
    this.handleLogOut = this.handleLogOut.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }
  onItemClick(e) {
    this.props.switchNavBarItem(e.target.innerText);
    this.props.onCloseNavBar();
    e.style={opacity: '1'}
  }

  handleLogOut(){
    this.props.signOut();
    this.props.onCloseNavBar();
    this.props.history.push(this.routesName.LOG_IN);
  }

  render(){
    const listItems = [
      {icon: 'nav__primary--dashboard', link: this.routesName.DASHBOARD, text: 'Dashboard'},
      {icon: 'nav__primary--messages', link: this.routesName.MESSAGES.MAIN, text: 'Messages'},
      {icon: 'nav__primary--documents', link: this.routesName.DOCUMENTS.MAIN, text: 'Documents'},
      {icon: 'nav__primary--history', link: this.routesName.TRANSACTION_HISTORY.OVERVIEW, text: 'Transaction history'},
      {icon: 'nav__primary--deposit', link: this.routesName.PLANNED_INVESTMENT, text: 'Planned investments'},
      {icon: 'nav__primary--withdraw', link: this.routesName.IN_PROGRESS, text: 'Withdrawal'},
    ]
    const secondListItems = [
      {icon: 'nav__secondary--settings', link: this.routesName.SETTINGS.MAIN, text: 'Settings'},
      {icon: 'nav__secondary--support', link: this.routesName.SUPPORT.MAIN, text: 'Support'},
      {icon: 'nav__secondary--refer', link: this.routesName.REFERRAL, text: 'Refer a friend'},
      {icon: 'nav__secondary--feedback', link: this.routesName.FEEDBACK.MAIN, text: 'Give feedback'},
    ]
    return (
      <React.Fragment>
        <span className="nav__profile">
        John Smith
        </span>
        <ul className="nav__primary">
          {listItems.map((item) => (
            <li
              key={item.text}
              className={item.icon}
              onClick={this.onItemClick}
              style={{opacity: this.props.navBarUI.selectedItem === item.text ? '1' : ''}}
            >
              <Link to={item.link}>
                {item.text}
              </Link>
            </li>
          ))}
        </ul>   
        <ul className="nav__secondary">  
          {secondListItems.map((item) => (
            <li 
              key={item.text}
              className={item.icon}
              onClick={this.onItemClick}
              style={{opacity: this.props.navBarUI.selectedItem === item.text ? '1' : ''}}
            >
              <Link to={item.link}>
                {item.text}
              </Link>
            </li> 
          ))}
          <li className="nav__secondary--signout" >
            <Link to={this.routesName.SIGN_OUT} onClick={this.handleLogOut}>
              Sign out
            </Link>
          </li>
        </ul>
        <span style={{position: 'absolute', bottom: '4%', right: '4%', opacity: '0.4'}}>v{this.props.version}</span>
      </React.Fragment>
    ) 
  }
}

function mapStateToProps(props) {
  return {
    navBarUI: props.navBarUI,
  }
}


export default withRouter(connect(mapStateToProps, { signOut, switchNavBarItem })(NavBarLinks));