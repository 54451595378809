import React from 'react';
import TabBar from '../../GoalDetail/TabBar/TabBar';
import { switchTabBar,savePreviousTab } from '../../../actions/TabBar/TabBarActions';
import { switchHeader } from '../../../actions/Header/HeaderActions';
import { connect } from 'react-redux';
import GoalStrategyOverview from './GoalStrateyOverview';
import withBaseLayout from '../../HoC/withBaseLayout';
import { withRouter } from 'react-router-dom';
import AllocationSection from '../../GoalDetail/AllocationSection/AllocationSection';
import { strategyCompleted } from '../../../actions/Goals/GoalActions';
import { passDataFromPerformanceGoalDetail } from '../../../actions/Positions/PostionActions';
import CONFIG from '../../../setting/config';
import changeLanguage from '../../../setting/languages/languages';

class GoalStrategyDetail extends React.Component {
	constructor(props){
		super(props)
		this.textStore = changeLanguage(props.language);
		this.routesName = CONFIG.APP_ROUTE;
		this.onContinueClick = this.onContinueClick.bind(this);
	}

	componentDidMount(){
		window.scroll(0,0);
		let middleText = 'Strategy';
		if (this.props.screenUI.currentScreen === this.textStore.screen.investmentProposal) {
			middleText = this.textStore.common.classicRoboTitle;
		}
		if(this.props.tabBarUI.currentTab !== 'Allocation'){
			this.props.switchTabBar('Overview')
		}
		this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: { type: "text", text: middleText },
      headerRight: "",
      currentPage: "strategyDetail"
    });
	}


	onContinueClick(){
		this.props.strategyCompleted({
			prev:'complete',
			current:'complete',
			next:'active'
		});
		let link = '';
		switch(this.props.screenUI.currentScreen) {
			case this.textStore.screen.createGoal:
				link = this.routesName.GOALS.CREATE;
				break;
			case this.textStore.screen.editGoal:
				let index = 1;
				link = `/goaldetail/${index}/edit`;
				break;
			case this.textStore.screen.investmentProposal:
				link = this.routesName.ONBOARDING.INVESTMENT_PROPOSAL;
				break;
			default:
				break;
		}
		this.props.history.push(link);
		this.props.switchTabBar('Summary');
	}

	renderContinueButton() {
		let text = this.textStore.common.continue;
		if (this.props.screenUI.currentScreen === this.textStore.screen.investmentProposal) {
			text = this.textStore.common.backToStrategy;
		}
		if (!this.props.location.state.isEditStrategy) {
			return (
				<div className="form-actions__item">
					<div
						className="btn btn-primary btn-no-focus__primary"
						onClick={this.onContinueClick}
					>
						{text}
					</div>
				</div>
			)
		}
		return;
	}

	renderRecommendText() {
		if (this.props.location.state.adviseText !== '')
			return (
				<p className="text text--recommendation">
					{this.props.location.state.adviseText}
				</p>
			)
		return <div />
	}

	renderSections(){
		switch(this.props.tabBarUI.currentTab){
			case 'Overview':
				return (
					<GoalStrategyOverview description={this.props.goalData.strategy.description}/>
				)
			case 'Allocation':
				return(
					<AllocationSection 
						passDataFromPerformanceGoalDetail={this.props.passDataFromPerformanceGoalDetail}
						positions={this.props.goalData.strategy.positions}
						currentTab={this.props.tabBarUI.currentTab}
						savePreviousTab={this.props.savePreviousTab}
						allocations={this.props.goalData.strategy.allocation}
					/>	
				)
			default:
				return (
					<GoalStrategyOverview description={this.props.goalData.strategy.description} />
				)
		}
	}

	render(){
		const tabs = ['Overview','Allocation']
		const settings = {
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
			slide:"ul",
      swipeToSlide:true,
      infinite:false
		};
	
		return(
			<React.Fragment>
				<div className="content-main__body">
					<div className="content__header">
						<p className="title">
							{this.props.goalData.strategy.riskCategoryName}
						</p>
						{this.renderRecommendText()}
					</div>
					<nav className="tablist">
						<TabBar
							tabs={tabs}
							type={'goaldetail'}
							settings={settings}
						/>

					</nav>
					{this.renderSections()}

				</div>
				<div className="content-main__footer">
					<div
						className="form-actions"
						style={{ paddingTop: "20px" }}
					>
						{this.renderContinueButton()}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const GoalStrategyDetailEnhanced = withBaseLayout(GoalStrategyDetail);

function mapStateToProps(props){
	return {
		tabBarUI:props.tabBarUI,
		goalUI: props.goalUI,
		goalData:props.goalData,
		screenUI: props.screenUI
	}
}

export default withRouter(connect(mapStateToProps,{ switchTabBar,strategyCompleted,switchHeader,passDataFromPerformanceGoalDetail,savePreviousTab, changeLanguage })(GoalStrategyDetailEnhanced));