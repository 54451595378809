import { firebaseGet } from '../../services';
import { loadCTBDataFailure, loadCTBDataSuccess } from './AppMainAction';

export const FIREBASE_INFO = {
  CTB: {
    collection: 'New_CTB',
    doc: '1',
  }
}

export const loadCTBDataRequest = () => {
  return (dispatch) => {
    firebaseGet(FIREBASE_INFO.CTB).then((ctbData) => {
      dispatch(loadCTBDataSuccess(ctbData));
    }).catch((error) => {
      dispatch(loadCTBDataFailure(error));
    })
  }
}