import {
	SIGN_IN_ERROR
} from '../../actions/Auth/AuthActionType';

export default function(data = { passwordError:false },actions){
	switch(actions.type){
		case SIGN_IN_ERROR:
			return { ...data,passwordError:actions.payload };
		default:
			return data;
	}
} 