import React from 'react';

import Question from './Question';

import { scrollTo } from '../../../../utils/scrollTo';
 
import './styles.less';

const wHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

const fullVisibleEle = (id) => {
  const ele = document.getElementById(id);
  const { top, height } = ele.getBoundingClientRect();
  const wScrollY = window.scrollY;
  
  if (height > (wHeight - top)) {
    const posY = (top + height - wHeight) + wScrollY;
    
    scrollTo(posY, 240);
  }

  if (top < 0) {
    const posY = wScrollY - Math.abs(top);
    
    scrollTo(posY, 240);
  }
};

class Questionnaires extends React.PureComponent {
  timer = null;

  onClick = (expandID) => {
    this.props.updateExpandQuestionIndex(expandID);
  }

  onAnswer = ({ key, value }) => {
    this.props.updateAnsweredIndexes({ expandID: key, answer: { [key]: value } });
  }

  componentDidUpdate() {
    const { expandID, isScroll } = this.props;
    
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      if (expandID && !isScroll) {
        fullVisibleEle(expandID);
      }
      if (expandID && isScroll) {
        const posY = document.getElementById(expandID).getBoundingClientRect().y + window.scrollY;
        scrollTo(posY, 240);
      }
    }, 240);
  }

  render() {
    const { questions, expandID, answers, answeredIndexes } = this.props;
 		if (!(questions && questions.length === 9)) {
			return null;
		}
    return (
      <div className="questionnaires">
        {
          questions.map((quest) => (
            <Question
              key={quest.renderKey}
              isAnswered={(answeredIndexes.indexOf(quest.renderKey) >= 0)}
              data={quest}
              isExpand={(expandID === quest.renderKey)}
              selected={answers[quest.renderKey]}
              onClick={this.onClick}
              onAnswer={this.onAnswer}
            />
          ))
        }
      </div>
    )
  }
}

export default Questionnaires;