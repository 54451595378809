import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import Tabs from '../../common/Tabs';
import MessageList from '../MessageList';
import SearchForm from '../../common/SearchForm';
import MessageBoxItem from '../MessageBoxItem';
import { messages } from '../data';
import {myAdvisorContent, supportContent} from '../Messages/data/messages-data';

import './styles.less';

const filterData = (arr, queryStr) => (
  arr.filter((ele) => (ele.text.toLowerCase().indexOf(queryStr.toLowerCase()) > -1))
);

class MessagesSearch extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      openConfirm: false,
      openSearchForm: false,
      searchStr: '',
    }
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Search' },
      headerRight: '',
      currentPage: 'MessagesSearch',
    })
  }

  onSearch = (searchStr) => {
    this.setState({ searchStr });
  }

  render() {
    const { searchStr } = this.state;
    const data = filterData(messages, searchStr);
    const tabs = [
      { 
        id: 'inbox', 
        header: 'Notifications', 
        comp: () => <MessageList messages={messages} />
      }, { 
        id: 'sent', 
        header: 'MyAdvisor', 
        comp: () => <MessageBoxItem data={myAdvisorContent} />
      }, { 
        id: 'archived', 
        header: 'Support', 
        comp: () => <MessageBoxItem data={supportContent} />
      }
    ];

    return (
      <div className="messages">
        <SearchForm onSearch={this.onSearch} />
        <Tabs tabs={tabs} headerFluid="true" defaultTab="inbox" />
      </div>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(withRouter(MessagesSearch)));