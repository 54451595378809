import React from 'react';
import HighchartsReact from 'react-highcharts';
import { formatCurrency } from '../../../helpers/formatCurrency';
import Popup from '../../common/Popup/PopUp';
import './_overViewSection.less'
class OverviewSection extends React.PureComponent {
  constructor(){
    super()
    this.state = {
      probability:0
    }
    this.closePopUp = this.closePopUp.bind(this);
    
  }

  closePopUp(){
    this.setState({
      probability:2
    })
  }

  renderStatus(probability){
    switch(probability){
      case 2:
        return <span style={{color:"#00b961"}}>On Track</span>;
      case 3:
        return <span style={{color:"#f5a623", whiteSpace: 'nowrap'}}>Action recommended<span className="list-action__link list-action__link--more"/></span>;
      case 1:
        return <span style={{color:"#dd0f43", whiteSpace: 'nowrap'}}>Action required<span className="list-action__link list-action__link--more"/></span>;
      default:
        return <span></span>;
    }
  }
  renderWarningMessage(){
    if(this.props.overview.probability === 1){
      return (
        <tr id="borderless"
          style={{
            display: this.props.overview.type === 'investment' ? 'none': ''
          }}
        >
          <td 
            colSpan="2"
            id="borderless-top"
          >
            <p className="text-status">
              The probability of reaching your investment goal is low. Please review and adjust your investment plan.
            </p>
          </td>
        </tr>
      )
    }else if(this.props.overview.probability === 3){
      return (
        <tr id="borderless"
          style={{
            display: this.props.overview.type === 'investment' ? 'none': ''
          }}
        >
          <td 
            colSpan="2" 
            id="borderless-top"
          >
            <p className="text-status">
               There is a high probability of exceeding your investment goal. Please review and adjust your investment plan.
            </p>
          </td>
        </tr>
      )
    }
  }

  componentDidUpdate(){
    if(this.state.probability !== 2){
      this.setState({
        probability:this.props.overview.probability
      })
    }
  }
 
  renderPopUp(){
    if (this.props.goalDetailData.title !== 'General investment') {
      if (this.state.probability !== 2 && this.state.probability !== 0) {
        return this.state.probability === 3 ?
          <Popup
            //language={this.props.language}
            onConfirmClick={this.props.onStatusClick}
            closePopUp={this.closePopUp}
            message={"Action recommended: There is a high probability of exceeding your investment goal. Please review and adjust your investment plan."}
          /> :
          <Popup
            //language={this.props.language}
            onConfirmClick={this.props.onStatusClick}
            closePopUp={this.closePopUp}
            message={"Action required: The probability of reaching your investment goal is low. Please review and adjust your investment plan."}
          />
      }
    }
  }

  renderChart(type){
    if (type !== 'investment') {
      return (<tr>
      <td colSpan="2" style={{borderTop:"0px"}}>
        <div className="chart chart--gauge"
          style={{
            marginTop:"-31px"
          }}
        >
          <div className="chart__container">
            <div className="chart__element__outer">
              <div className="chart_element__inner">
                <HighchartsReact 
                  config={this.props.overview.chartConfig}    
                  domProps={{className:"chart__element"}} 
                />
                <div className="gauge-label-left-date">
                  {this.props.overview.firstInvestmentDay}
                </div>
                <div className="gauge-label-left">
                  USD {formatCurrency(this.props.overview.expectedTargetAmount)}
                </div>
                <div className="gauge-label-right-date">
                  {this.props.overview.targetDate}
                </div>
                <div className="gauge-label-right" >
                  USD {formatCurrency(this.props.overview.targetAmount)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>)
    } 
  }

  render(){
    return (
      <section className="tabpanel">
        {this.renderPopUp()}
        <table 
          className="list-view"
          style={{
            tableLayout:"fixed"
          }}
        >
          <tbody>
            <tr 
              onClick={this.props.onStatusClick}
              id={this.props.overview.probability === 2 ? "borderless-top": "borderless"}
              style={{
                display: this.props.overview.type === 'investment' ? 'none': ''
              }}
            >
              <th 
                id={this.props.overview.probability === 2 ? "borderless-top": "borderless"} 
                className="cell-icn"
              >
                <span className="icn icn--target">  
                  Status
                </span>
              </th>
              <td 
                className="text-right"
                id={this.props.overview.probability === 2 ? "borderless-top": "borderless"}
              >
                {this.renderStatus(this.props.overview.probability)}
              </td>
            </tr>
            {this.renderWarningMessage(this.props.overview.probability)}
            <tr
              style={{
                display: this.props.overview.type === 'investment' ? 'none': ''
              }}
            >
              <th className="cell-icn" style={{borderBottom:"0px"}}>
                <span className="icn icn--completion">  
                  Level of completion
                </span>
              </th>
            </tr>
            {this.renderChart(this.props.overview.type)}
            <tr>
              <th className="cell-icn">
                <span className="icn icn--strategy">  
                  Investment strategy
                </span>
              </th>
              <td className="text-right">
        
                {this.props.overview.investmenStrategy}
              </td>
            </tr>
            <tr>
              <th className="cell-icn">
                <span className="icn icn--investment">  
                  Total investment
                </span>
              </th>
              <td className="text-right"> 
                {formatCurrency(this.props.overview.totalInvestment)}
              </td>
            </tr>
            <tr style={{borderBottom: '0px'}}>
              <th 
                className="cell-icn"
                style={{
                  borderBottom:"0px"
                }}
              >
                <span className="icn icn--recurring">  
                  Monthly investment
                </span>
              </th>
              <td 
                className="text-right"
                style={{
                borderBottom:"0px"
              }}
              >  
                { formatCurrency(this.props.overview.monthlyInvestment)}
              </td>
            </tr>
        
          </tbody>
        </table>
      </section>
    ) 
  }
}


export default OverviewSection;