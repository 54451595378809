import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import exclamation from '../../../css/images/icons-png/exclamation-triangle-line.png';

import MessagesDetail from '../MessagesDetail';

import './styles.less';

const data = {
  time: '25/08/2018',
  text: 'Document available',
  icon: exclamation
}

class RiskProfileExpired extends React.PureComponent {
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Risk profile expired' },
      headerRight: '',
      currentPage: 'RiskProfileExpired',
    });
  }

  render() {
    return (
      <MessagesDetail data={data}>
        <div>
          <p>Dear Carlos</p>
          <br/>
          <p>Your <b>risk profile is expiring</b> on the 30th of August 2018. In order to avoid any delay in executing new trades execution <b>please renew your risk profile</b> until 27th of August 2018. </p>
          <br/>
          <p>If you have any questions check out the <Link to="/inprogress"><b>frequently asked questions</b></Link> or if you need assistance reply to this message.</p>
          <br/>
          <p>Best regards,</p>
          <p>Classic Robo Operator Support</p>
        </div>
      </MessagesDetail>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(withRouter(RiskProfileExpired)));