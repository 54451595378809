const PASSWORD_STRENGTH = {
  WEEK: 'Weak',
  STRONG: 'Strong'
};
const getPasswordRegex = mode => {
  let rs;
  switch (mode) {
    case PASSWORD_STRENGTH.STRONG:
      rs = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[\W_])[0-9a-zA-Z\W_]{8,24}$/;
      break;
    default:
      rs = /^.{8,24}$/;
      break;
  }
  return rs;
};

const getInvalidPasswordMessage = (mode, strongMessage, weekMessage) => {
  let rs;
  switch (mode) {
    case PASSWORD_STRENGTH.STRONG:
      rs = strongMessage;
      break;
    default:
      rs = weekMessage;
      break;
  }
  return rs;
};
export { PASSWORD_STRENGTH, getPasswordRegex, getInvalidPasswordMessage };
