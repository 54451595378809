import React  from 'react';
import AmountValue from '../AmountValue/AmountValue';
import ChartRange from '../../Charts/ChartRange/ChartRange';
import ReactHighStocks from 'react-highcharts/ReactHighstock';
import AllocationPieChart from '../../GoalDetail/AllocationSection/AllocationPieChart';
import { changeToRelativeChart,changeToAmountChart, disableAmountChartAnimation, disableRelativeChartAnimation } from '../../../actions/Chart/ChartActions';
import { switchTimeLine } from '../../../actions/DashBoard/DashBoardActions';
import { connect } from 'react-redux';
import Slider from 'react-slick';

export class InvestSummary extends React.Component {
  constructor(){
    super()
    this.state = {
      currentIndex:0,
      oldIndex: 0,
      chartRange: {
        type: 'ALL',
        index: 4
      },
      chartRangeRelative: {
        type: 'ALL',
        index: 4
      }
    }
    this.stockRefAmount = React.createRef();
    this.stockRefRelative = React.createRef();
    this.renderContain = this.renderContain.bind(this);
    this.changeChartRange = this.changeChartRange.bind(this);
    this.changeChartRangeRelative = this.changeChartRangeRelative.bind(this);
    this.timeout = null;
    this.updateYAxisData = this.updateYAxisData.bind(this);
  }

  componentDidMount(){
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});
    this.props.changeToAmountChart();
    this.props.changeToRelativeChart();
  }

  touchStart(e){
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e){
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if(Math.abs(this.clientX) > minValue){ 
        e.defaultPrevented && e.preventDefault(); 
        e.returnValue = false;
        return false;
    }
  }

  changeChartRange(range, index){
    // let arr = this.state.chartRange.slice();
    // arr[this.state.currentIndex].type = range;
    // arr[this.state.currentIndex].index = index;
    this.setState({
      chartRange: {type: range, index: index}
    });
  }

  changeChartRangeRelative(range, index){
    // let arr = this.state.chartRangeRelative.slice();
    // arr[this.state.currentIndex].type = range;
    // arr[this.state.currentIndex].index = index;
    this.setState({
      chartRangeRelative: {type: range, index: index}
    });
  }

  // TODO: Reto want to start chart from 0
  updateYAxisData(seriesData) {
    let startYPoint = seriesData.dataMin;
    let yDataArr = seriesData.yAxis.series[0].data; //.splice(yDataStartPoint, seriesData.yAxis.series[0].data.length - yDataStartPoint);
   
    let newYdata = yDataArr.map((item) => {
      item.y = item.y - startYPoint;
      return item.y;
    });
    let dataYasix = newYdata;// .splice(yDataStartPoint, newYdata.length - yDataStartPoint);
    seriesData.yAxis.update(dataYasix);
  }

  renderContain(settings) {
    // Show funding when it has not no goal
    if (this.props.dashBoardData.portfolios.isDefaultGoal) {
      return (<React.Fragment>
        <div className="funding-screen">
          <span className="icn icn--time"></span>
          <div className="container text">{'Waiting to receive \n your funding -'}
          <span> Read more</span>
          </div>
        </div>
      </React.Fragment>)
    } else {
      return(
        <React.Fragment>
          <AmountValue 
          amountValue={this.props.dashBoardData.performance} 
          />
          <Slider {...settings}>
            <section>
              <div className="chart chart--performance">
                <div className="chart__container">
                  <div className="chart__element__outer">
                    <div 
                      className="chart__element__inner" 
                    >
                      <ReactHighStocks 
                        config={this.props.chartUI} 
                        domProps={{className:"chart__element"}} 
                        ref={this.stockRefAmount}
                      />
                    </div>
                  </div>
                </div>
                <div className="chart__range">
                  <ChartRange 
                    chartIndex={0}
                    changeChartRange={this.changeChartRange}
                    stockRef={this.stockRefAmount} 
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="chart chart--performance">
                <div className="chart__container">
                  <div className="chart__element__outer">
                    <div 
                      className="chart__element__inner" 
                    >
                      <ReactHighStocks 
                        config={this.props.chartUIRelative} 
                        domProps={{className:"chart__element"}} 
                        ref={this.stockRefRelative}
                      />
                    </div>
                  </div>
                </div>
                <div className="chart__range">
                  <ChartRange 
                    chartIndex={1}
                    changeChartRange={this.changeChartRangeRelative}
                    stockRef={this.stockRefRelative} 
                  />
                </div>
              </div>
            </section>
            <AllocationPieChart allocations={this.props.dashBoardData.allocation} />
          </Slider>
        </React.Fragment>
        
      )
      
    }
  }
  render(){
    const settings = {
      speed: 500,
			slidesToScroll: 1,
			swipeToSlide:true,
			arrows:false,
			dots:true,
      infinite:false,
			afterChange: ((index) =>  {
				this.setState({
					currentIndex: index
				},() => {
          switch(this.state.currentIndex){
            case 0:
              this.props.disableAmountChartAnimation();
              //this.props.changeToAmountChart();
              this.props.switchTimeLine(this.state.chartRange.type);
              this.timeout = setTimeout(() => {
                // Default do not set animation for chart when swipping
                this.stockRefAmount.current.chart.options.chart.animation = false;
                this.stockRefAmount.current.chart.rangeSelector.buttons[this.state.chartRange.index].element.onclick();
              },0)
              break;
            case 1:
              this.props.disableRelativeChartAnimation();
              this.props.switchTimeLine(this.state.chartRangeRelative.type);
              //this.props.changeToRelativeChart();
              this.timeout = setTimeout(() => {
                // Default do not set animation for chart when swipping
                this.stockRefRelative.current.chart.options.chart.animation = false;
                this.stockRefRelative.current.chart.rangeSelector.buttons[this.state.chartRangeRelative.index].element.onclick();
                this.updateYAxisData(this.stockRefRelative.current.chart.series[0]);
              },0)
              break;
            default:
              return;
          }
        });
			})
	  };
    return (
      <section className="invest-summary">
        {this.renderContain(settings)}
      </section>
    )
  }
  componentWillUnmount(){
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    clearTimeout(this.timeout)
  }
}

function mapStateToProps(props){
  return{
    dashBoardData:props.dashBoardData,
    chartUI:props.chartUI,
    chartUIRelative: props.chartUIRelative,
  }
}
export default  connect(mapStateToProps,{ changeToAmountChart, changeToRelativeChart,switchTimeLine, disableAmountChartAnimation, disableRelativeChartAnimation })(InvestSummary);