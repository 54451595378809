import {
	CHANGE_GOAL_NAME,
	CHANGE_INITIAL_INVESTMENT,
	CHANGE_SLIDER_VALUE_ONBOARDING,
	CHANGE_MONTHLY_INVESTMENT,
	CHANGE_TARGET_AMOUNT,
	CHANGE_TOPUP_AMOUNT,
	CHANGE_WITHDRAW_AMOUNT,
	CHANGE_TARGET_DATE,
	GET_DEFAULT_GOALS,
	GET_DEFAULT_GOAL,
	CALCULATE_PROBABILITY,
	GET_DEFAULT_GOALS_STRATEGY,
	PASS_GOALS_TO_SUMMARY,
	EDIT_GOAL,
	GET_DEFAULT_GOALS_TRANSFER,
	GET_DEFAULT_GOALS_BANK_ACCOUNT,
	GET_CONFIGURATION,
	GET_WITHDRAW_ACCOUNT,
	TRANSFER_UPDATE_TOTAL_ONE_TRANSFER,
	TRANSFER_UPDATE_TOTAL_MONTHLY_TRANSFER,
	CHANGE_GOALS_STRATEGY,
	EDIT_GOAL_STRATEGY,
	RESET_GOAL,
	GET_GOAL_CALCULATE
} from '../../../actions/Goals/GoalActionType';
import formatPositions from '../../../helpers/formatPositions';
import formatAllocation from '../../../helpers/formatAllocation';
import pieChartConfig from '../../../components/Charts/config/PieChartConfig';
import { formatCurrency, unformatCurrency } from '../../../helpers/formatCurrency';
import planningChartConfig from '../../../components/Charts/config/HighChartMultipleLines';
import onBoardingPlanningChartConfig from '../../../components/Charts/config/OnboardingHighChartMultipleLines';
import requireMarker from '../../../data/marker_required';
import onTrackMarker from '../../../data/marker_on_track';
import recommendedMarker from '../../../data/marker_recommended';
import onboardingMarker from '../../../data/marker_onboarding';
import { getLastestValueOfBoarding, getTotalInvestmentChart, getTotalBalanceChart, getTwoPlusDeviationChart, getTwoMinusDeviationChart, getOnePlusDeviationChart, getOneMinusDeviationChart } from '../../../helpers/onBoardingFormula';
import { addChartIcon } from '../../../helpers/addChartIcon';

import { adjustAreaRange } from './adjustAreaRange';

const mockData = {
	defaultGoals:[],
	goalName:'',
	initialInvestment:0,
	currentValue:0,
	monthlyInvestment:0,
	timeHorizon: 0,
	targetAmount:0,
	totalInitialInvestmentAmount: 0,
	totalMonthlyInvestmentAmount: 0,
	topUpInvestmentAmount: 0,
	withdrawInvestmentAmount: 0,
	type:'',
	targetDate:'',
	minimumInvestmentAmount: 0,
	Goals: {
		initialInvestment: 0,
		monthlyInvestment: 0,
		timeHorizon: 0
	},
	planning:{
		probability: '',
		type:'',
		investmenStrategy:'',
		expectedTargetAmount:0,
		expReturn: 0,
		totalInitialInvestmentAmount: 0,
		totalBalanceAmount: 0,
		planningChartConfig: {}
	},
	strategy:{
		advise: '',
		riskCategoryName:'',
		calculatedRisk:0,
		calculatedExpectedReturn:0,
		riskCategoryDescription:'',
		description:'',
		positions:[{
			type:'',
			totalPerformance:0,
			totalAllocation:0,
			security:[{
				name:'',
				allocation:0,
				performance:0,
			}]
		}],
		allocation:{
			fixedIncomeValue:null,
			fixedIncomeAllocation:null,
			equityValue:null,
			equityAllocation:null,
			nonTraditionalValue:null,
			nonTraditionalAllocation:null,
			pieChartConfig: pieChartConfig,
			regions:[]
		}
	},
	summary:{
		goalName:'',
		targetAmount:0,
		targetDate:'',
		investmenStrategy:'',
		initialInvestment:0,
		monthlyInvestment:0,
		type:''
	},
	transfer:[],
	instructions:{
		accountNumber:'',
		bicSwift:'',
		bankName:'',
		currencyCode:'',
		iban:'',
		message:'',
		receiptName:'',
		initialInvestment:0,
		monthlyInvestment:0
	},
	withDraws: {
		goalName: '',
		investestAmount: 0,
		userBankAccount: []
	},
	goalCalculate: {
		calculatedInvestmentStrategy: '',
		expectedTargetAmount: 0,
		probability: 0,
	}
}

export default function(data= mockData,actions){
	switch(actions.type){
		case CHANGE_GOAL_NAME:
			return { ...data,goalName:actions.payload };
		case CHANGE_INITIAL_INVESTMENT:
			return { ...data,initialInvestment:actions.payload };
		case CHANGE_SLIDER_VALUE_ONBOARDING:
			let adjustData = actions.payload;
			let totalInvestChart = getTotalInvestmentChart(unformatCurrency(adjustData.initialInvestment), unformatCurrency(adjustData.monthlyInvestment), unformatCurrency(adjustData.timeZone));
			let expReturn = data.strategy.calculatedExpectedReturn;
			const standardDev = data.strategy.calculatedRisk;

			// console.warn('Expected return', expReturn)
			// console.warn('standardDev', standardDev)

			// const standardDev = 0.0813;
			// const expReturn = 0.0577;

			let totalBalanceChart = getTotalBalanceChart(unformatCurrency(adjustData.initialInvestment), unformatCurrency(adjustData.monthlyInvestment), unformatCurrency(adjustData.timeZone), expReturn);
			// TODO: standard dev is (calculatedRisk)
			let twoPlusDeviationChart = getTwoPlusDeviationChart(unformatCurrency(adjustData.initialInvestment), unformatCurrency(adjustData.monthlyInvestment), unformatCurrency(adjustData.timeZone), standardDev, totalBalanceChart);
			let twoMinusDeviationChart = getTwoMinusDeviationChart(unformatCurrency(adjustData.initialInvestment), unformatCurrency(adjustData.monthlyInvestment), unformatCurrency(adjustData.timeZone), standardDev, totalBalanceChart);
			let onePlusDeviationChart = getOnePlusDeviationChart(unformatCurrency(adjustData.initialInvestment), unformatCurrency(adjustData.monthlyInvestment), unformatCurrency(adjustData.timeZone), standardDev, totalBalanceChart);
			let oneMinusDeviationChart = getOneMinusDeviationChart(unformatCurrency(adjustData.initialInvestment), unformatCurrency(adjustData.monthlyInvestment), unformatCurrency(adjustData.timeZone), standardDev, totalBalanceChart);
			
			onBoardingPlanningChartConfig.series[0].data = onePlusDeviationChart;
			onBoardingPlanningChartConfig.series[1].data = totalBalanceChart; //Expexted 
			onBoardingPlanningChartConfig.series[2].data = oneMinusDeviationChart;
			onBoardingPlanningChartConfig.series[3].data = totalInvestChart;
			// onBoardingPlanningChartConfig.series[4].data = twoPlusDeviationChart;
			// onBoardingPlanningChartConfig.series[5].data = twoMinusDeviationChart;

			onBoardingPlanningChartConfig.series[4].data = adjustAreaRange({topArr: onePlusDeviationChart, botArr: oneMinusDeviationChart});
			onBoardingPlanningChartConfig.series[5].data = adjustAreaRange({topArr: twoPlusDeviationChart, botArr: twoMinusDeviationChart});

			//let markerOnboarding = onboardingMarker;
			//------------ ICON --------------//
			let xChartIcon = 'https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint.png?alt=media&token=c98c4e1a-4793-4b58-bbb4-0f8007e03edc';
			onboardingMarker[0] = addChartIcon(totalInvestChart[0].x, totalInvestChart[0].y, xChartIcon, 15, 15);
			// Today chart icon
			let lengthOfSeriesOnboarding = onBoardingPlanningChartConfig.series.length - 1;			
			onBoardingPlanningChartConfig.series[lengthOfSeriesOnboarding - 1].data = onboardingMarker;
			//------------- END ICON -------------//

			//------------ TODAY ICON --------------//
			let xTodayIcon = 'https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint_Timeline.png?alt=media&token=10ea51f8-fe0e-40d5-973f-8534fd354ae5';
			let yTodayIcon = 'https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint_Timeline.png?alt=media&token=10ea51f8-fe0e-40d5-973f-8534fd354ae5';
			onBoardingPlanningChartConfig.series[lengthOfSeriesOnboarding].data[0] = addChartIcon(totalInvestChart[0].x, 0, xTodayIcon, 10, 10);
			onBoardingPlanningChartConfig.series[lengthOfSeriesOnboarding].data[1] = addChartIcon(totalInvestChart[totalInvestChart.length - 1].x, 0, yTodayIcon, 10, 10);
			onBoardingPlanningChartConfig.series[lengthOfSeriesOnboarding].data[1].x = totalInvestChart[totalInvestChart.length - 1].x;

			//------------ TODAY --------------//

			let sliderAllocations = formatAllocation(data.strategy.positions, data.currentValue ? data.currentValue: adjustData.initialInvestment);			
			return { ...data,
				Goals:actions.payload,
				planning: {
					...data.planning, 
					planningChartConfig:onBoardingPlanningChartConfig,
					totalInitialInvestmentAmount: getLastestValueOfBoarding(totalInvestChart),
					totalBalanceAmount: getLastestValueOfBoarding(totalBalanceChart)
				},
				strategy: {
					...data.strategy, 
					allocation: {
						...data.strategy.allocation,
						fixedIncomeValue: sliderAllocations.fixedIncomeValue ? sliderAllocations.fixedIncomeValue : 0  ,
						equityValue:sliderAllocations.equityValue ? sliderAllocations.equityValue : 0,
						nonTraditionalValue:sliderAllocations.nonTraditionalValue ? sliderAllocations.nonTraditionalValue : 0
					}					
				}
			};
		case CHANGE_MONTHLY_INVESTMENT:
			return { ...data,monthlyInvestment:actions.payload };
		case CHANGE_TARGET_AMOUNT:
			return { ...data,targetAmount:actions.payload };
		case CHANGE_TOPUP_AMOUNT:
			return { ...data,topUpInvestmentAmount:actions.payload };
		case CHANGE_WITHDRAW_AMOUNT:
			return { ...data,withdrawInvestmentAmount:actions.payload };
		case CHANGE_TARGET_DATE:
			return { ...data,targetDate:actions.payload };
		case GET_DEFAULT_GOALS:
			return { ...data,
				defaultGoals:actions.payload,
				planning: {
					...data.planning, 
					planningChartConfig:planningChartConfig
				}
			};
		case TRANSFER_UPDATE_TOTAL_ONE_TRANSFER:
			return { ...data,
				 totalInitialInvestmentAmount:actions.payload 
			};
		case TRANSFER_UPDATE_TOTAL_MONTHLY_TRANSFER:
			return { ...data,
				totalMonthlyInvestmentAmount:actions.payload 
			};
			
		case EDIT_GOAL: 
			//	REFACTOR @QUAN
			let markerObjEdit = [];
			if (data.planning.probability === 1) { // Action required
				markerObjEdit = requireMarker;
			} else if (data.planning.probability === 2) { // On Track
				markerObjEdit = onTrackMarker;
			} else { // Action required
				markerObjEdit = recommendedMarker;
			}

			// First marker
			markerObjEdit[0].marker = {
					"enabled":true,
					"symbol": "url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint.png?alt=media&token=c98c4e1a-4793-4b58-bbb4-0f8007e03edc)",
					"width":"15",
					"height":"15"
			}
		

			let seriesEditGoal = [...planningChartConfig.series];
			seriesEditGoal[seriesEditGoal.length - 2].data = markerObjEdit;
			if (actions.payload.title === 'General investment') {
				seriesEditGoal.pop();
				markerObjEdit[1].marker = {
					"enabled":true,
					"symbol": `url()`,
					"width":50,
					"height":50
				};
			} else {
				let marketItemLength = seriesEditGoal.length - 1;
				markerObjEdit[1].marker = {
					"enabled":true,
					"symbol": `url(${actions.payload.goalIcon})`,
					"width":50,
					"height":50
				};
				seriesEditGoal[marketItemLength ].data[0].x = markerObjEdit[0].x
				seriesEditGoal[marketItemLength ].data[0].marker ={
					"enabled":true,
					"symbol": 'url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint_Timeline.png?alt=media&token=10ea51f8-fe0e-40d5-973f-8534fd354ae5)',
					"width":10,
					"height":10
					};
					seriesEditGoal[marketItemLength	 ].data[1].marker ={
					"enabled":true,
					"symbol": 'url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint_Timeline.png?alt=media&token=10ea51f8-fe0e-40d5-973f-8534fd354ae5)',
					"width":10,
					"height":10
				};
				seriesEditGoal[marketItemLength ].data[1].x =  data.title === 'General investment' ? seriesEditGoal[0].data[seriesEditGoal[0].data.length - 1].x   :markerObjEdit[1].x	

			}

			let chartConfigEditGoal = {...planningChartConfig};
			chartConfigEditGoal.series = seriesEditGoal;
			const newDataEdit = { ...data,
				goalName: actions.payload.title, 
				currentValue:actions.payload.currentValue,
				initialInvestment: formatCurrency(actions.payload.overview.expectedTargetAmount),
				monthlyInvestment: formatCurrency(actions.payload.overview.monthlyInvestment),
				totalInvestAmount: formatCurrency(actions.payload.overview.totalInvestment),
				targetAmount: formatCurrency(actions.payload.overview.targetAmount),
				targetDate: actions.payload.overview.targetDate,
				type:actions.payload.type,
				currencyCode: actions.payload.currencyCode,
				planning:{
					...data.planning,
					type: actions.payload.type,
					planningChartConfig: chartConfigEditGoal,
					investmenStrategy: actions.payload.planning.investmenStrategy
				}
			}
			// add "today" on highchart multiline only if the goal is not general investment. Refactor @Quan
			return newDataEdit;
	
		case EDIT_GOAL_STRATEGY: 
			pieChartConfig.series[0].data[0].y = parseFloat((actions.payload.allocation.fixedIncomeAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[1].y = parseFloat((actions.payload.allocation.equityAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[2].y = parseFloat((actions.payload.allocation.nonTraditionalAllocation * 100).toFixed(2));
			return {
			...data,
			strategy:{
				...data.strategy,
				allocation: actions.payload.allocation,
				positions: actions.payload.performance.positions
			}
		}
		case GET_DEFAULT_GOAL:
			let markerObj = [];
			if (data.planning.probability === 1) { // Action required
				markerObj = requireMarker;
			} else if (data.planning.probability === 2) { // On Track
				markerObj = onTrackMarker;
			} else { // Action required
				markerObj = recommendedMarker;
			}
			// First marker
			markerObj[0].marker = {
					"enabled":true,
					"symbol": "url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint.png?alt=media&token=c98c4e1a-4793-4b58-bbb4-0f8007e03edc)",
					"width":"15",
					"height":"15"
			}
			let series = [...planningChartConfig.series];
			series[series.length - 2].data = markerObj;
			if (actions.payload.Name === 'General investment') {
				series.pop();
				markerObj[1].marker = {
					"enabled":true,
					"symbol": `url()`,
					"width":50,
					"height":50
				};
			} else {
				let marketItemLength = series.length - 1;
				markerObj[1].marker = {
					"enabled":true,
					"symbol": `url(${actions.payload.ChartIcon})`,
					"width":50,
					"height":50
				};
					// add "today" on highchart multiline only if the goal is not general investment. Refactor @Quan
					series[marketItemLength ].data[0].x = markerObj[0].x
					series[marketItemLength ].data[0].marker ={
					"enabled":true,
					"symbol": 'url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint_Timeline.png?alt=media&token=10ea51f8-fe0e-40d5-973f-8534fd354ae5)',
					"width":10,
					"height":10
					};
					series[marketItemLength	 ].data[1].marker ={
					"enabled":true,
					"symbol": 'url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint_Timeline.png?alt=media&token=10ea51f8-fe0e-40d5-973f-8534fd354ae5)',
					"width":10,
					"height":10
				};
				series[marketItemLength ].data[1].x =  actions.payload.Name === 'General investment' ? series[0].data[series[0].data.length - 1].x   :markerObj[1].x
			}

			let chartConfig = {...planningChartConfig};
			chartConfig.series = series;
			const newData = { ...data,
				goalName: actions.payload.Name, 
				initialInvestment: formatCurrency(actions.payload.InitialInvestmentAmount),
				monthlyInvestment: formatCurrency(actions.payload.MontlyInvestmentAmount),
				targetAmount: formatCurrency(actions.payload.TargetAmount),
				targetDate: actions.payload.TargetDate,
				timeHorizon: actions.payload.TimeHorizon,
				type:actions.payload.type,
				planning:{
					...data.planning,
					type: actions.payload.type,
					planningChartConfig:chartConfig,
					expectedTargetAmount: actions.payload.TargetAmount
				}
			}				
			return newData;
		case CALCULATE_PROBABILITY:
			return {
				...data, 
				planning:{
					...data.planning,
					probability: actions.payload.Probability,
					expectedTargetAmount: data.targetAmount,
					delta: actions.payload.ExpectedTargetAmount - unformatCurrency(data.targetAmount)
				}
			}
		case GET_DEFAULT_GOALS_STRATEGY:
			let positions = formatPositions(actions.payload.Positions);
			let currentValue = data.currentValue ? data.currentValue 
					: data.Goals.initialInvestment ? data.Goals.initialInvestment 
					: data.initialInvestment ? data.initialInvestment : 0;
			let allocations = formatAllocation(positions, unformatCurrency(currentValue));
			pieChartConfig.series[0].data[0].y = parseFloat((allocations.fixedIncomeAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[1].y = parseFloat((allocations.equityAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[2].y = parseFloat((allocations.nonTraditionalAllocation * 100).toFixed(2));
			return {
				...data,
				strategy:{
					...data.strategy,
					advise: actions.payload.RiskCategory.Advise,
					riskCategoryName: actions.payload.RiskCategory.Name,
					riskCategoryDescription: actions.payload.RiskCategory.Description,
					calculatedRisk: actions.payload.CalculatedRisk,
					calculatedExpectedReturn: actions.payload.CalculatedExpectedReturn,
					positions:positions,
					description:actions.payload.RiskCategory.StrategyOverview,
					allocation:{
						...data.strategy.allocation,
						fixedIncomeValue: allocations.fixedIncomeValue ? allocations.fixedIncomeValue : 0  ,
						fixedIncomeAllocation: allocations.fixedIncomeAllocation ? allocations.fixedIncomeAllocation : null,
						equityValue:allocations.equityValue ? allocations.equityValue : 0,
						equityAllocation:allocations.equityAllocation ? allocations.equityAllocation : null,
						nonTraditionalValue:allocations.nonTraditionalValue ? allocations.nonTraditionalValue : 0,
						nonTraditionalAllocation:allocations.nonTraditionalAllocation ? allocations.nonTraditionalAllocation : 0,
						regions: allocations.regions
					}
				}
			}
		case CHANGE_GOALS_STRATEGY:
			let positionsStrategy = formatPositions(actions.payload.Positions);
			let allocationsStrategy = formatAllocation(positionsStrategy, unformatCurrency(actions.payload.currentValue));
			pieChartConfig.series[0].data[0].y = parseFloat((allocationsStrategy.fixedIncomeAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[1].y = parseFloat((allocationsStrategy.equityAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[2].y = parseFloat((allocationsStrategy.nonTraditionalAllocation * 100).toFixed(2));
			return {
				...data,
				strategy: {
					...data.strategy,
					advise: actions.payload.RiskCategory.Advise,
					riskCategoryName: actions.payload.RiskCategory.Name,
					riskCategoryDescription: actions.payload.RiskCategory.Description,
					calculatedRisk: actions.payload.CalculatedRisk,
					calculatedExpectedReturn: actions.payload.CalculatedExpectedReturn,
					description: actions.payload.RiskCategory.StrategyOverview,
					positions: positionsStrategy,
					allocation:{
						...data.strategy.allocation,
						fixedIncomeValue: allocationsStrategy.fixedIncomeValue ? allocationsStrategy.fixedIncomeValue : 0  ,
						fixedIncomeAllocation: allocationsStrategy.fixedIncomeAllocation ? allocationsStrategy.fixedIncomeAllocation : null,
						equityValue:allocationsStrategy.equityValue ? allocationsStrategy.equityValue : 0,
						equityAllocation:allocationsStrategy.equityAllocation ? allocationsStrategy.equityAllocation : null,
						nonTraditionalValue:allocationsStrategy.nonTraditionalValue ? allocationsStrategy.nonTraditionalValue : 0,
						nonTraditionalAllocation:allocationsStrategy.nonTraditionalAllocation ? allocationsStrategy.nonTraditionalAllocation : null,
						regions: allocationsStrategy.regions
					}
				},
				planning: {
					...data.planning,
					investmenStrategy: actions.payload.RiskCategory.Name,
				}
			};
		case PASS_GOALS_TO_SUMMARY:
			return {
				...data,
				summary:{
					...data.summary,
					goalName:actions.payload.goalName,
					targetAmount: formatCurrency(actions.payload.targetAmount),
					targetDate:actions.payload.targetDate,
					investmenStrategy:actions.payload.investmenStrategy,
					initialInvestment:actions.payload.initialInvestment,
					monthlyInvestment:actions.payload.monthlyInvestment,
					topUpInvestmentAmount: actions.payload.topUpInvestmentAmount,
					withdrawInvestmentAmount: actions.payload.withdrawInvestmentAmount,
					type:actions.payload.type,
					typeScreen:actions.payload.typeScreen,
				}
			}
		case GET_DEFAULT_GOALS_TRANSFER:
			return {
				...data,
				transfer:actions.payload
			}
		case GET_DEFAULT_GOALS_BANK_ACCOUNT:
			return {
				...data,
				instructions: { 
					...data.instructions, 
					accountNumber: actions.payload.AccountNumber,
					bicSwift: actions.payload.BICSwift,
					bankName: actions.payload.BankName,
					currencyCode: actions.payload.CurrencyCode,
					iban: actions.payload.IBAN,
					message: actions.payload.Message,
					receiptName: actions.payload.ReceiptName,
					initialInvestment: actions.payload.initialInvestment,
					monthlyInvestment: actions.payload.monthlyInvestment
				}
			}
		case GET_WITHDRAW_ACCOUNT:
			return {
				...data,
				withDraws: {
					type:actions.payload.goalData.type,
					goalName: actions.payload.goalData.goalName,
					initialInvestment: actions.payload.goalData.withdrawInvestmentAmount,
					userBankAccount: actions.payload.UserBankAccount,
				}
			}
		case GET_CONFIGURATION:
			return {
				...data,
					minimumInvestmentAmount: actions.payload.Goal.MinimumInvestmentAmount,
			}
		case GET_GOAL_CALCULATE:
			return {
				...data,
				goalCalculate: {
					...data.goalCalculate,
					calculatedInvestmentStrategy: actions.payload.CaculatedInvestmentStrategy,
					expectedTargetAmount: unformatCurrency(data.targetAmount),
					probability: actions.payload.Probability,
				}
			}
		case RESET_GOAL:
			return {
				...data,
					topUpInvestmentAmount: 0,
					withdrawInvestmentAmount: 0,
					planning:{
						...data.planning,
						expectedTargetAmount: 0,
					}
				
			}
		default:
			return data;
	}
}