import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import CONFIG from "../../../setting/config";
import GenericPageWrapper from "../GenericPage/GenericPageWrapper";

class VerificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.routesName = CONFIG.APP_ROUTE;
    this.timer = null;
  }

  componentDidMount() {
    window.scrollTo(0,0);
    //TODO: Handle status codes
    this.timer = setTimeout(() => {
      if (this.props.location.state) {
        switch (this.props.location.state.type) {
          case "putOnlineVerification":
            this.verification([
              this.props.onboardingData.testParams.responsePutOnlineVerification
            ]);
            break;
          case "preAuthenticationMobile":
            this.verification([
              this.props.onboardingData.testParams.responsePreAuthenticateMobile
            ]);
            break;
          case "responsePostContactPortfolios":
            this.verification([
              this.props.onboardingData.testParams.responsePostContactPortfolios
            ]);
            break;
          case "postQuestionnaire":
            this.verification([
              this.props.onboardingData.testParams.responsePostQuestionnaire,
              this.props.onboardingData.testParams.responseComplianceGetCalculateRisk
            ]);
            break;
          case "submitOTP":
            this.verification([
              this.props.onboardingData.testParams.responseSubmitOTP
            ]);
            break;
          case "termsAndConditions":
            this.verification([
              this.props.onboardingData.testParams.responseTermsAndConditions
            ])
            break;
          case "postRegistration":
            this.verification([
              this.props.onboardingData.testParams.responsePostRegistration
            ])
            break;
          default:
            this.verification(this.props.location.state.type);
            return;
        }
      }
    }, 3000);
  }

  verification(responseCodes) {
    for (let el of responseCodes) {
      if (el === 504) {
        this.props.history.push({
          pathname: this.props.timedoutLink,
          state: this.props.timedoutStates
        });
        return;
      } else if (el !== 200) {
        this.props.history.push({
          pathname: this.props.failedLink,
          state: this.props.failedStates
        });
        return;
      }
    }
    this.props.history.push({
      pathname: this.props.successLink,
      state: this.props.successStates
    });
  }

  render() {
    return (
      <React.Fragment>
        <GenericPageWrapper language={this.props.language} page="loading" />
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
}

VerificationPage.defaultProps = {
  successStates: {},
  failedStates: {}
};

VerificationPage.propTypes = {
  successStates: PropTypes.object,
  failedStates: PropTypes.object
};

function mapStateToProps(props) {
  return {
    onboardingData: props.onboardingData
  };
}

export default withRouter(connect(mapStateToProps)(VerificationPage));
