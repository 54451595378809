import React from "react";
import { connect } from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress";

class LoadingIndicator extends React.PureComponent {
  render() {
    const { asyncLoadingStatus, asyncLoadingText } = this.props;

    return (
      <div className={['loading-icon__container', asyncLoadingStatus ? 'open' : ''].join(' ')}>
        <div className="loading-icon__inner">
          <div className="loading-icon__text">{asyncLoadingText}</div>
          <CircularProgress
            classes={{ root: "loading-icon__loading" }}
            size={120}
          />
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({ 
  appMainUI: { asyncLoadingStatus, asyncLoadingText } 
}) => ({
  asyncLoadingStatus, 
  asyncLoadingText,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingIndicator);
