import React, { Component } from 'react';
import DropDown, {
  fnSortCountriesByName
} from '../../common/Dropdown/Dropdown';
import './DropdownCountryResidence.less';
const calcIsTop = () => {
  const ele = document.getElementById('dropdown-country-residence-wrapper');
  const wHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  if (!ele) {
    return false;
  }
  const box = ele.getBoundingClientRect();
  return (Math.round(box.top) > (wHeight/2))
};

class DropdownCountryResidence extends Component {
  cityResidenceRenderDropdown = props => {
    return (
      <React.Fragment>
        <div
          className={`${
            props.errorPhoneCondition
              ? 'phone-number-drop-down border-error__bottom'
              : 'phone-number-drop-down dropdown-country-residence'
          }`}
          onClick={props.selectDropdown.bind(this)}
        >
          {props.countrySelected.FlagIcon ? (
            <div className="country-residence-flag__container">
              <div
                className={`phone-number-flag ${
                  props.countrySelected.FlagIcon
                }-flag`}
              />
            </div>
          ) : (
            <span className="placeholder">{this.props.placeholder}</span>
          )}
          <span className="dial-code">{props.countrySelected.Name}</span>
        </div>
        {props.items.length >= 8 ? (
          props.renderListItems()
        ) : (
          <div>
            {props.renderShortItems()}
            {calcIsTop() && (
              <style>{`.dropdown-container {top:auto; bottom: 100%}`}</style>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };
  render() {
    if (!(this.props.items && this.props.items.length > 0)) {
      return null;
    }
    const sortedItems = fnSortCountriesByName(this.props.items);

    let selectedIndex = -1;
    for (let i = 0; i < sortedItems.length; i++) {
      if (sortedItems[i].Name === this.props.selectedResidence) {
        selectedIndex = i;
      }
    }

    return (
      <DropDown
        renderDropdown={this.cityResidenceRenderDropdown}
        updateSelectedCountryIndex={index => {
          this.props.onSelect(sortedItems[index].Name);
        }}
        selectedIndex={selectedIndex}
        language={this.props.language}
        items={sortedItems}
        hasIcon={true}
        errorDialCode={() => {}}
      />
    );
  }
}

DropdownCountryResidence.propTypes = {};

export default DropdownCountryResidence;
