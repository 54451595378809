import React from 'react';

import withBaseLayout from "../../HoC/withBaseLayout";
import changeLanguage from "../../../setting/languages/languages";
import Stepper from '../RiskProfiling/Stepper/Stepper';
import './styles.less';
import CONFIG from '../../../setting/config';

class VisitBranch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.state = {
      activeStep: 7,
      totalSteps: 8,
    };
    this.onClickNext = this.onClickNext.bind(this);
  }

  componentWillMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text:  this.textStore.common.classicRoboTitle },
      headerRight: '',
      currentPage: this.textStore.screen.visitBranch,
    });
  }

  componentWillUnmount() {
    window.scrollTo(0,0);
    this.props.savePreviousScreen(this.textStore.screen.visitBranch);
  }

  onClick = (evt) => {
    evt.preventDefault();
    this.props.getPDFLink();
  }

  onClickNext() {
    this.props.history.push(CONFIG.APP_ROUTE.ONBOARDING.COMPLIANCE_DECLARATION);
  }

  render() {
    const mapLink = `https://maps.google.com/?q=${CONFIG.CONSTANT.VISIT_BRANCH.LAT},${CONFIG.CONSTANT.VISIT_BRANCH.LONG}`
    return (
      <React.Fragment>
        <Stepper 
          activeStep={this.state.activeStep} 
          totalSteps={this.state.totalSteps}
          textStore={this.textStore} 
          headerLeft={this.textStore.visitBranch.visitBranchLabel}
        />
        <div className="visit_branch">
          <h2 className="text-title__large visit_branch__title">{this.textStore.visitBranch.pageTitle}</h2>
          <div className="visit_branch__question-wrapper">
            <a href="#" className="visit_branch__question download" onClick={this.onClick}>
              <span className="visit_branch__question--icon"></span>
              <span className="visit_branch__question--text">
                {this.textStore.visitBranch.step1}
              </span>
            </a>
            <a href={mapLink} target="_blank" className="visit_branch__question direction-multiple">
              <span className="visit_branch__question--icon"></span>
              <span className="visit_branch__question--text">
                {this.textStore.visitBranch.step2}
              </span>
            </a>
          </div>
          <div className="visit_branch__disclaimer">
            {this.textStore.visitBranch.disclaimer}
          </div>
          <div className="visit_branch__footer">
            <div className="form-actions">
              <div className="form-actions__item">
                <button type="submit" onClick={this.onClickNext} className="btn btn-primary btn-no-focus__primary">Next</button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withBaseLayout(VisitBranch);