import {
  SELECT_GOAL,
  TRANSFER_COMPLETED,
  WITHDRAW_COMPLETED,
  TOP_UP_COMPLETED,
  GOAL_COMPLETED,
  INSTRUCTIONS_COMPLETED,
  STRATEGY_COMPLETED,
  SUMMARY_COMPLETED,
  GOAL_CONFIRM,
  RESET_GOAL,
  EDIT_GOAL,
  STATUS_ERROR_DISPLAY,
  DISPLAY_GOAL_NAME_ERROR,
  HIDE_GOAL_NAME_ERROR,
  DISPLAY_TARGET_AMOUNT_ERROR,
  HIDE_TARGET_AMOUNT_ERROR,
  DISPLAY_TOPUP_AMOUNT_ERROR,
  HIDE_TOPUP_AMOUNT_ERROR,
  DISPLAY_WITHDRAW_AMOUNT_ERROR,
  HIDE_WITHDRAW_AMOUNT_ERROR,
  DISPLAY_INITIAL_AMOUNT_ERROR,
  HIDE_INITIAL_AMOUNT_ERROR,
  DISPLAY_MONTHLY_AMOUNT_ERROR,
  HIDE_MONTHLY_AMOUNT_ERROR,
  DISPLAY_TARGET_DATE_ERROR,
  HIDE_TARGET_DATE_ERROR
} from '../../../actions/Goals/GoalActionType';

const mockData = {
  goalScreen: '',
  goalSelected:false,
  goalCompleted:'active',
  strategyCompleted:'',
  summaryCompleted:'',
  topUpCompleted: '',
  withdrawCompleted: '',
  transferCompleted:'',
  instructionsCompleted:'',
  goalConfirmed:false,
  statusErrorDisplay:false,
  goalCreate:{
    goalNameError:false,
    targetAmountError:false,
    targetAmountErrorMessage:'',
    initialAmountError:false,
    initialAmountErrorMessage:'',
    monthlyAmountError:false,
    monthlyAmountErrorMessage:'',
    targetDateError:false,
    targetDateErrorMessage:'',
    withdrawAmountError: false,
    withdrawAmountErrorMessage: '',
    topUpAmountError: false,
    topUpAmountErrorMessage: ''
  }
  
};
export default function(data = mockData,actions){
  switch(actions.type){
    case SELECT_GOAL:
      return { ...data,goalSelected:actions.payload,
        goalScreen: 'Create' };
    case GOAL_COMPLETED:
      return { ...data,goalCompleted:actions.payload.current,strategyCompleted:actions.payload.next };
    case STRATEGY_COMPLETED:
      return { ...data,
        goalCompleted:actions.payload.prev,
        strategyCompleted:actions.payload.current
        ,summaryCompleted:actions.payload.next };
    case SUMMARY_COMPLETED:
      return { ...data,
        strategyCompleted:actions.payload.prev,
        summaryCompleted:actions.payload.current,
        transferCompleted:actions.payload.next };
    case TOP_UP_COMPLETED:
      return { ...data,
        summaryCompleted:actions.payload.prev,
        topUpCompleted:actions.payload.current,
        instructionsCompleted:actions.payload.next};
    case EDIT_GOAL:
      return { ...data,
        goalScreen: 'Edit'};
    case WITHDRAW_COMPLETED:
      return { ...data,
        summaryCompleted:actions.payload.prev,
        withdrawCompleted:actions.payload.current,
        instructionsCompleted:actions.payload.next};
    case TRANSFER_COMPLETED:
      return { ...data,
        summaryCompleted:actions.payload.prev,
        transferCompleted:actions.payload.current,
        instructionsCompleted:actions.payload.next};
    case INSTRUCTIONS_COMPLETED:
      return { ...data,
        transferCompleted:actions.payload.prev,
        instructionsCompleted:actions.payload.current };
    case GOAL_CONFIRM:
      return { ...data,
        goalConfirmed:actions.payload,
      };
    case DISPLAY_GOAL_NAME_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,goalNameError:true
        }
      }
    case HIDE_GOAL_NAME_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,goalNameError:false
        }
      }
    case DISPLAY_TARGET_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,targetAmountError:true,targetAmountErrorMessage:actions.payload
        }
      }
    case HIDE_TARGET_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,targetAmountError:false,targetAmountErrorMessage:""
        }
      }
    case DISPLAY_TOPUP_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,topUpAmountError:true,topUpAmountErrorMessage:actions.payload
        }
      }
    case HIDE_TOPUP_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,topUpAmountError:false,topUpAmountErrorMessage:""
        }
      }
    case DISPLAY_WITHDRAW_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,withdrawAmountError:true,withdrawAmountErrorMessage:actions.payload
        }
      }
    case HIDE_WITHDRAW_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,withdrawAmountError:false,withdrawAmountErrorMessage:""
        }
      }
    case DISPLAY_INITIAL_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,initialAmountError:true,initialAmountErrorMessage:actions.payload
        }
      }
    case HIDE_INITIAL_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,initialAmountError:false,initialAmountErrorMessage:""
        }
      }
    case DISPLAY_MONTHLY_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,monthlyAmountError:true,monthlyAmountErrorMessage:actions.payload
        }
      }
    case HIDE_MONTHLY_AMOUNT_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,monthlyAmountError:false,monthlyAmountErrorMessage:''
        }
      }
    case DISPLAY_TARGET_DATE_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,targetDateError:true,targetDateErrorMessage:actions.payload
        }
      }
    case HIDE_TARGET_DATE_ERROR:
      return {
        ...data,
        goalCreate:{
          ...data.goalCreate,targetDateError:false,targetDateErrorMessage:''
        }
      }
    case STATUS_ERROR_DISPLAY:
      return { 
        ...data,statusErrorDisplay:true,
      }
    case RESET_GOAL:
      return { ...data,  
        goalScreen: '',
        goalSelected:false,
        goalCompleted:'active',
        strategyCompleted:'',
        summaryCompleted:'',
        transferCompleted:'',
        withdrawCompleted:'',
        statusErrorDisplay:false,
        topUpCompleted:'',
        instructionsCompleted:'',
        goalConfirmed:false,
        goalCreate: {
          ...data.goalCreate,
          goalNameError:false,
          targetAmountError:false,
          targetAmountErrorMessage:'',
          initialAmountError:false,
          initialAmountErrorMessage:'',
          monthlyAmountError:false,
          monthlyAmountErrorMessage:'',
          targetDateError:false,
          targetDateErrorMessage:'',
          withdrawAmountError: false,
          withdrawAmountErrorMessage: '',
          topUpAmountError: false,
          topUpAmountErrorMessage: ''
        }
       }
    default:
      return data;
  }
}

