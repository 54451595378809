import React from 'react';
import { connect } from 'react-redux';
import { passDataFromDashBoard } from '../../actions/GoalDetail/GoalDetailActions';
import { withRouter } from 'react-router-dom';
import { switchHeader } from '../../actions/Header/HeaderActions';


const withTableViewData = (WrappedComponent,data) => {
  class IncomeData extends React.Component {
    constructor(){
      super()
      this.onItemClick = this.onItemClick.bind(this);
    }

    onItemClick(item){
      // Do nothing when It is default goal
      if (data.isDefaultGoal) {
        return;
      }
      this.props.switchHeader({
        headerLeft: 'back',
        headerMiddle: {
          type: 'text',
          text: item.title
        },
        headerRight: { type: 'menu' },
        currentPage: ''
      });
      this.props.history.push({pathname: `/goaldetail/${item.id}`, state: {id: item.id}})
      this.props.passDataFromDashBoard(item)
    }

    render(){
      return (
        <WrappedComponent 
          {...this.props} 
          list={data.list}
          iconType={data.iconType}
          onItemClick={this.onItemClick}
        />
      )
    }
  }
  return withRouter(connect(null,{ passDataFromDashBoard, switchHeader })(IncomeData));
}

export default withTableViewData;