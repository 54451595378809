import {
    SWITCH_DATE_XAXIS,
    CHANGE_TO_RELATIVE_CHART,
    CHANGE_TO_AMOUNT_CHART,
    DETAIL_CHANGE_TO_AMOUNT_CHART,
    DASH_BOARD_CHART,
    RESET_LABEL_DATE,
    DISABLE_AMOUNT_CHART_ANIMATION,
    ENABLE_AMOUNT_CHART_ANIMATION,
    DISABLE_RELATIVE_CHART_ANIMATION,
    ENABLE_RELATIVE_CHART_ANIMATION,
    INITIATE_GAUGE_CHART_BLUE_CONFIG,
    INITIATE_GAUGE_CHART_WHITE_CONFIG,
} from './ChartActionType';


export function swicthDateXAxis(format){
  return {
    type:SWITCH_DATE_XAXIS,
    payload:format
  };
}


export function resetLabelDate(){
  return {
    type:RESET_LABEL_DATE
  }
}


export function changeToRelativeChart(){
  return {
    type: CHANGE_TO_RELATIVE_CHART
  }
}

export function changeToAmountChart(){
  return {
    type: CHANGE_TO_AMOUNT_CHART
  }
}

export function changeDetailToAmountChart(type) {
  return {
    type: DETAIL_CHANGE_TO_AMOUNT_CHART,
    payload: type
  }
}

export function dashboardChart() {
  return {
    type: DASH_BOARD_CHART
  }
}

export function disableAmountChartAnimation() {
  return {
    type: DISABLE_AMOUNT_CHART_ANIMATION
  }
}

export function enableAmountChartAnimation() {
  return {
    type: ENABLE_AMOUNT_CHART_ANIMATION
  }
}

export function disableRelativeChartAnimation() {
  return {
    type: DISABLE_RELATIVE_CHART_ANIMATION
  }
}

export function enableRelativeChartAnimation() {
  return {
    type: ENABLE_RELATIVE_CHART_ANIMATION
  }
}

export function initiateGaugeChartBlueConfig() {
  return {
    type: INITIATE_GAUGE_CHART_BLUE_CONFIG,
  }
}

export function initiateGaugeChartWhiteConfig() {
  return {
    type: INITIATE_GAUGE_CHART_WHITE_CONFIG,
  }
}