import { apiGet, firebaseGet } from '../../../../services';
import { FETCH_GROUP_QUESTION_PATH, FIREBASE_INFO } from './constants';
import { generateAnswer } from './utils';

import {
  fetchQuestionsDataSuccess,
  fetchQuestionsDataFailure
} from './action';

import {  toggleAsyncLoadingStatus } from '../../../../actions/AppMain/AppMainAction';
const getFirstQuestionInGroup = (questions) => (
  questions.sort((a, b) => (parseInt(a.Order) - parseInt(b.Order)))[0]
);

const getQuestionInGroup = ([{ Groups: [tmp, curGroup] }]) => {
  const question = getFirstQuestionInGroup(curGroup.Steps[0].Questions);
  question.renderKey = `q_${question.Id}`;

  return [question];
};

const sortQuestions = (questions, groupID) => (
  questions
    .sort((a, b) => (parseInt(a.Order) - parseInt(b.Order)))
    .map((ele) => ({ ...ele, renderKey: `q_${groupID}_${ele.Id}_${ele.Order}` }))
);

const getAllQuestions = ({ Groups = [] }) => {
  const sortGroup = Groups.sort((a, b) => (parseInt(a.Id) - parseInt(b.Id)));
  const questionPerGroup = sortGroup.map((group) => (
    sortQuestions(group.Steps[0].Questions, group.Id)
  ));

  return questionPerGroup.reduce((acc, cur) => ([...acc, ...cur]), []);
};

const getQuestionFromApi  = () => {
  // Call question from proxy api until get 200 response, maximum 5 time. Each call have a delay of 1 second
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const fetch_retry = async (  n) => {
      try {
          return await  apiGet({ path: FETCH_GROUP_QUESTION_PATH })
      } catch(err) {
          if (n === 1) throw err;
          await sleep(1000);
          return await fetch_retry(  n - 1);
      }
  };
 
  return fetch_retry(5);
}
export const fetchQuestionsDataRequest = (callback) => {
  let timer = null;
  return (dispatch) => {
    Promise.all([
      getQuestionFromApi(), 
      firebaseGet(FIREBASE_INFO.questionnaires),
    ])
    .then(([{ data }, questionnaires]) => {
      const questions = [ ...getQuestionInGroup(data), ...getAllQuestions(questionnaires)];
      dispatch(fetchQuestionsDataSuccess({
        questions,
        questionnaires,
        groupQuestions: data,
        answers: generateAnswer(questions),
        expandID: questions[0].renderKey
      }));
      if(callback) {
        // Hope that store finished setState before run this callback
        // Should use another way better later
        setTimeout(callback(),500);        
      }    
    }).catch((error) => {
      // Timer to make the loading alway appear at least 1 second
      timer = window.setTimeout(() => {
        dispatch(fetchQuestionsDataFailure(error));
        dispatch(toggleAsyncLoadingStatus({ status: false, text: 'Loading'}));  
        window.clearTimeout(timer);
      }, 1000);
    });
  }
};
