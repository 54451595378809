import {
  SWITCH_NAVBAR,
  NAVBAR_ITEM_SELECT
} from '../../actions/NavBar/NavBarActionType';



export default function(data = {
  currentNavBar: '',
  selectedItem: '',
},actions){
  switch(actions.type){
    case SWITCH_NAVBAR:
      return { ...data,currentNavBar: actions.payload };
    case NAVBAR_ITEM_SELECT:
      return { ...data, selectedItem: actions.payload };
    default:
      return data
  }
}