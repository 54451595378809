import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import VisitBranch from '../../VisitBranch';

import { getPDFLink } from './async';
import { switchHeader } from '../../../../actions/Header/HeaderActions';
import { savePreviousScreen } from "../../../../actions/Screen/ScreenAction";

const mapStateToProps = ({}) => ({
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getPDFLink,
  savePreviousScreen: (screenName) => dispatch(savePreviousScreen(screenName)),
  switchHeader: (header) => dispatch(switchHeader(header)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VisitBranch));

