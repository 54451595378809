import React from "react";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";
import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { formatCurrency } from "../../../helpers/formatCurrency";
import CONFIG from "../../../setting/config";

class ContactSupport extends React.Component {
  constructor() {
    super();
    this.state = {
      title: ""
    };
    this.routesName = CONFIG.APP_ROUTE;
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
  }
  componentDidMount() {
    document.getElementById("ContentMain").style = 'padding-top: 10px'
    const supportData = this.props.supportData;
    this.props.switchHeader({
      headerLeft: "close",
      headerMiddle: {
        type: "text",
        text: "Customer support",
      },
      headerRight: { type: "send" },
      currentPage: "contactSupport",
    });
    this.setState({
      title: `My ${supportData.transactionType} transaction from the ${supportData.date} of ${formatCurrency(supportData.currencyAmount)}`
    });
  }
  onTitleChange(e) {
    const { value } = e.target;
    this.setState({ title: value });
  }
  onSendClick() {
    this.props.history.push(this.routesName.MESSAGE_SUCCESS)
  }
  render() {
    return (
      <React.Fragment>
        <div className="support-title-text">Subject:</div>
        <textarea
          type="text"
          className="support-title-input"
          value={this.state.title}
          rows={4}
          maxLength={97}
          onChange={this.onTitleChange}
        />
        <div className="support-title-text">Message:</div>
        <textarea className="support-message" placeholder="Type message here"/>
        {/* <div 
          className="content-main__footer" 
          style={{ paddingTop: "20px" }}
        >
          <div className="form-actions__item">
            <input
              type="button"
              className="btn btn-primary btn-send"
              value="Send"
              onClick={this.onSendClick}
            />
          </div>
        </div> */}
      </React.Fragment>
    );
  }
  componentWillUnmount() {
    document.getElementById("ContentMain").style = ''
  }
}

const EnhancedContactSupport = withBaseLayout(ContactSupport);

function mapStateToProps(props) {
  return {
    supportData: props.supportData
  };
}

export default withRouter(connect(
  mapStateToProps,
  { switchHeader }
)(EnhancedContactSupport));
