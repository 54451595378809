import { connect } from 'react-redux';
import Questionnaires from '../../RiskProfiling/Questionnaires';
import { 
  updateExpandQuestionIndex, 
  updateAnsweredIndexes,
  resetQuestionnairesStore,
} from './action';

const mapStateToProps = ({ 
  questionnaires: { questionnaires, questions, expandID, answers, answeredIndexes, isScroll } 
}) => ({
  answeredIndexes,
  questionnaires,
  expandID,
  questions,
  answers,
  isScroll,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updateAnsweredIndexes: (payload) => dispatch(updateAnsweredIndexes(payload)),
  updateExpandQuestionIndex: (index) => dispatch(updateExpandQuestionIndex(index)),
  resetQuestionnairesStore: () => dispatch(resetQuestionnairesStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaires);

