import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { DatePicker } from 'antd';
import moment from "moment";

import CustomDropdown from '../../common/CustomDropdown';
import MyDatePicker from '../MyDatePicker';
import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import data from './data.json';

import './styles.less';
import CONFIG from '../../../setting/config';

class PersonalInformation extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      openDatePicker: false,
    };
    this.routesName = CONFIG.APP_ROUTE;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Personal information' },
      headerRight: '',
      currentPage: 'personalInformation',
    })
  }

  openDatePicker = () => {
    this.setState(({ openDatePicker }) => ({ openDatePicker: !openDatePicker }));
  }

  render() {
    return (
      <React.Fragment>
        <div className="personal-information">
          <CustomDropdown
            data={data.salutation}
            name="Salutation"
            defaultSelected="Mr"
            className="personal-information-dropdown"
            placeholder="Select"
            label="Salutation"
          />
          <div className="form-group">
            <p className="control-label">First name</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                id="firstName"
                type="text"
                defaultValue="Carlos"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <p className="control-label">Last name</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                id="lastName"
                type="text"
                defaultValue="Jakob"
                autoComplete="off"
              />
            </div>
          </div>
          <CustomDropdown
            data={data.nationality}
            name="nationality"
            defaultSelected="singapore"
            className="personal-information-dropdown"
            placeholder="Select"
            label="Nationality"
          />
          <MyDatePicker
            label="Date of birth"
            name="birthday"
            defaultValue="06/06/1960"
          />
          <div className="form-group">
            <p className="control-label">Mobile number</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                id="mobileNumber"
                type="text"
                pattern="[0-9\+]*" 
                inputmode="numeric" 
                defaultValue="+84 909 521 593"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <p className="control-label">Residential address, line 1</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                id="address1"
                type="text"
                defaultValue="138 Market Street Singapore 048946"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <p className="control-label">Residential address, line 2 (Optional)</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                id="address2"
                type="text"
                defaultValue="200 Street 100 Singapore 048946"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <p className="control-label">Postal code</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                id="postalCode"
                pattern="[0-9]*" 
                inputmode="numeric" 
                type="text"
                defaultValue="840043"
                autoComplete="off"
              />
            </div>
          </div>
          <CustomDropdown
            data={data.nationality}
            name="cityOfResidence"
            defaultSelected="singapore"
            className="personal-information-dropdown"
            placeholder="Select"
            label="City of residence"
          />
          <CustomDropdown
            data={data.nationality}
            name="countryOfResidence"
            defaultSelected="singapore"
            className="personal-information-dropdown"
            placeholder="Select"
            label="Country of residence"
          />
          <br></br>
          <br></br>
        </div>
        <div className="content-main__footer">
          <div className="form-actions">
            <div className="form-actions__item">
              <button type="submit" className="btn btn-primary btn-no-focus__primary" onClick={() => this.props.history.push(this.routesName.SETTINGS_UPDATED)}>Save</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(connect(null, { switchHeader })(withBaseLayout(PersonalInformation)));