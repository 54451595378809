import React from 'react';
import './styles.less';


export default class Tabs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.defaultTab,
    }
  }

  onClick = (evt) => {
    evt.preventDefault();
    
    this.setState({ activeTab: evt.currentTarget.rel });
  }

  render() {
    const { name, headerFluid, tabs, noBorder } = this.props;
    const tabWidth = 100 / (tabs.length);
    const { activeTab } = this.state;

    return (
      <div className={`tabs default-tabs tabs-${name}`}>
        <div className={["tabs__header", headerFluid ? 'fluid' : '', noBorder ? 'bo-border' : ''].join(' ')}>
          <div className="tabs__header-inner">
            {
              tabs.map((tab) => (
                <a
                  key={tab.id}
                  href="#"
                  rel={tab.id}
                  style={{ width: `${tabWidth}%` }}
                  className={['tabs__header-item', activeTab === tab.id ? 'active' : ''].join(' ')}
                  onClick={this.onClick}
                >
                  <span className="tabs__header-item--inner">{tab.header}</span>
                </a>
              ))
            }
          </div>
        </div>
        <div className="tabs__content">
          {
            tabs.map((tab) => (
              <div key={tab.id} className={['tabs__content-item', activeTab === tab.id ? 'active' : ''].join(' ')}>
                <tab.comp />
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}