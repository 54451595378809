import React from 'react';
import { Link } from 'react-router-dom'
import  withBaseLayout from '../HoC/withBaseLayout';
import { switchNavBar } from '../../actions/NavBar/NavBarActions';
import { getGoalDetail } from '../../actions/GoalDetail/GoalDetailActions';
import {editGoal} from '../../actions/Goals/GoalActions';
import { switchTabBar,savePreviousTab,clearPreviousTab } from '../../actions/TabBar/TabBarActions';
import { passDataFromPerformanceGoalDetail } from '../../actions/Positions/PostionActions';
import { switchHeader } from '../../actions/Header/HeaderActions';
import AmountValue from '../DashBoard/AmountValue/AmountValue';
import OverviewSection from './OverviewSection/OverviewSection';
import PerformanceSection from './PerformanceSection/PerformanceSection';
import ExpandableListView from '../common/ExpandableListView/ExpandableListView';
import AllocationSection from '../GoalDetail/AllocationSection/AllocationSection';
import PlanningSection from './PlanningSection/PlanningSection';
import TabBar from './TabBar/TabBar';
import { connect } from 'react-redux';
import { resetLabelDate,  changeDetailToAmountChart, changeToAmountChart} from '../../actions/Chart/ChartActions';
import changeLanguage from '../../setting/languages/languages';
import CONFIG from '../../setting/config';


class GoalDetail extends React.Component {
	constructor(props) {
		super(props);
		this.textStore = changeLanguage(props.language);
		this.routesName = CONFIG.APP_ROUTE;
		this.onStatusClick = this.onStatusClick.bind(this);
	}

	onStatusClick() {
		document.body.className = "";
		this.props.history.push(`/goaldetail/${this.props.goalDetailData.id}/edit`);
		this.props.editGoal(this.props.goalDetailData);
	}

	componentDidMount(){
		// if (this.props.goalDetailData.currentPerformance === 0) {
			// this.props.getAmountValue(this.props.goalDetailData.id);
			this.props.getGoalDetail(this.props.goalDetailData.id);
			this.props.changeDetailToAmountChart(this.props.goalDetailData.id);
		//}
		if(this.props.goalDetailData.title !== ''){
			this.props.switchHeader({
					headerLeft: 'back',
					headerMiddle: {
						type: 'text',
						text: this.props.goalDetailData.title
					},
					headerRight: { type: 'menu' },
					currentPage: ''
				
			})
		}
		this.props.switchNavBar("goaldetail");
		if((this.props.tabBarUI.currentTab !== 'Performance' && this.props.tabBarUI.currentTab !== 'Allocation')){
			this.props.switchTabBar("Overview");
		}
		window.scrollTo(0, 0);
	}

	componentWillUnmount() {
		// Clear tabbar
		this.props.switchTabBar('');
		this.props.switchTabBar("Overview")
		this.props.resetLabelDate()
	}

	renderSections(){
		switch(this.props.tabBarUI.currentTab){
			case 'Overview':{
				return (
					<OverviewSection 
						//language={this.props.language}
						onStatusClick={this.onStatusClick} 
						history={this.props.history} 
						goalDetailData={this.props.goalDetailData} 
						editGoal={this.props.editGoal} 
						overview={this.props.goalDetailData.overview} />
				)
			}
			case 'Performance':
				this.props.tabBarUI.tabBarRef.current.slickPrev()				
				if (this.props.goalDetailData.currentValue === 0) {
					return (
						<section className="tabpanel">
							<div className="funding-screen">
								<span className="icn icn--time"></span>
								<div className="container text">{'Waiting to receive \n your funding -'}
									<span> Read more</span>
								</div>
							</div>
						</section>
					)
				}
				//this.props.changeToAmountChart();
				return (
					<section className="tabpanel">
						<div 
							className="chart chart--performance"
							style={{
								marginTop:"15px"
							}}
						>
							<PerformanceSection/>
						</div>
						<h2 className="section-title">
							Positions
						</h2>
						<table className="list-view">
							<tbody>
								<ExpandableListView
									passDataFromPerformanceGoalDetail={this.props.passDataFromPerformanceGoalDetail}
									data={this.props.goalDetailData.performance.positions}
									currentTab={this.props.tabBarUI.currentTab}
									savePreviousTab={this.props.savePreviousTab}
									color={'#00b961'}
								/>
							</tbody>
						</table>
					</section>
				)
			case 'Planning':
				this.props.tabBarUI.tabBarRef.current.slickNext()
				return(
					<section className="tabpanel">
						<table 
							className="list-view"
							style={{
								tableLayout:"fixed"
							}}
						>
							<tbody>
								<PlanningSection 
									textStore={this.textStore}
									onStatusClick={this.onStatusClick}
									isGoalDetail={true} 
									planning={this.props.goalDetailData.planning}
									screen={this.textStore.screen.editGoal}
								/>
							</tbody>
						</table>
					</section>
				)
			case 'Allocation':
				if (this.props.goalDetailData.currentValue === 0) {
					return (
						<section className="tabpanel">
							<div className="funding-screen">
								<span className="icn icn--time"></span>
								<div className="container text">{'Waiting to receive \n your funding -'}
									<span> Read more</span>
								</div>
							</div>
						</section>
					)
				}
				return (
					<section className="tabpanel">
						<AllocationSection
							passDataFromPerformanceGoalDetail={this.props.passDataFromPerformanceGoalDetail}
							positions={this.props.goalDetailData.performance.positions}
							currentTab={this.props.tabBarUI.currentTab}
							savePreviousTab={this.props.savePreviousTab}
							allocations={this.props.goalDetailData.allocation}
						/>
					</section>
				)
			default:
				return null;
		}
	}

	componentDidUpdate(){
		switch(this.props.tabBarUI.previousTab){
			case 'Allocation':
				this.props.tabBarUI.tabBarRef.current.slickNext();
				this.props.clearPreviousTab()
				break;
			default:
				return;
		}
	}
	render(){
		const data = {
			currencyCode:this.props.goalDetailData.currencyCode,
			currentValue:this.props.goalDetailData.currentValue,
			currentPerformanceAmount:this.props.goalDetailData.currentPerformanceAmount,
			currentPerformance:this.props.goalDetailData.currentPerformance,
			type:this.props.goalDetailData.type
		}
		const tabs = ["Overview","Performance","Planning","Allocation"]
		return(
			<React.Fragment>
				<div className="content-main__body">
					<section className="invest-summary">
						<AmountValue 
							display={''} 
							amountValue={data} 
						/>
					</section>
					<nav className="tablist sticky">
						<TabBar tabs={tabs}/>
					</nav>
					<div className="tabs">
						<div className="tabpanels">
							{this.renderSections()}
						</div>
					</div>
				</div>
				<div className="content-main__footer">
					<div className="button-group"
						style={{
							display:this.props.tabBarUI.currentTab === 'Overview' ? '' : 'none',
						}}
					>
						<Link
							to={this.routesName.IN_PROGRESS}
							className="btn btn-primary btn-no-focus__primary"
						>
							Top up investment
						</Link>
					</div>

				</div>
			</React.Fragment>
		)
	}
}

const GoalDetailEnhanced = withBaseLayout(GoalDetail);
function mapStateToProps(props){
	return {
		goalDetailData:props.goalDetailData,
		tabBarUI:props.tabBarUI,
		appMainUI:props.appMainUI,
		chartUI:props.chartUI
	}
}

export default connect(mapStateToProps,{ 
	switchNavBar,
	getGoalDetail,
	switchTabBar,
	savePreviousTab,
	clearPreviousTab,
	editGoal,
	switchHeader,
	passDataFromPerformanceGoalDetail,
	resetLabelDate,
	changeDetailToAmountChart,
	changeToAmountChart,
 })(GoalDetailEnhanced); 
