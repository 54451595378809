import React from 'react';

import MessageItem from '../MessageItem';

import './styles.less';
import LoadMoreButton from '../../common/LoadMoreButton';

export default class MessageList extends React.PureComponent {
  render() {
    const { messages } = this.props;

    return (
      <React.Fragment>
         <div className="messages-list">
          {
            messages.map((msg) => (
              <MessageItem key={msg.id} data={msg} />
            ))
          }
        </div>
        <LoadMoreButton />
      </React.Fragment>
    )
  }
}