function withDecimal(amount, decimalPoint) {
  let decimal = 2;
  if (decimalPoint >= 0) {
    decimal = decimalPoint
  }
  return formatStringToInt(amount).toLocaleString('en-US', { minimumFractionDigits: decimal, maximumFractionDigits: decimal });
}

export function formatCurrency (amount, decimalPoint) {
  if (amount || formatStringToInt(amount) === 0) {
    return withDecimal(amount, decimalPoint)
  } 
  return amount;
}

export function formatRoundedCurrency(amount, decimalPoint) {
  if (amount || formatStringToInt(amount) === 0) {
    if (formatStringToInt(amount) < 100) {
      return withDecimal(amount - (amount % 10), decimalPoint)
    }
    else {
      return withDecimal(amount - (amount % 100), decimalPoint)
    }
  } 
  return amount;
}

export function formatStringToInt (value) {
  if (value !== 0 && typeof(value) == 'string') {
    return parseFloat(value.replace(/[',']/g, ''));
  }
  return value;
};

export function unformatCurrency(amount){
  if (amount && formatStringToInt(amount) >= 0) {
    return formatStringToInt(amount);
  }
  return amount;
}