import React from 'react';

export default class LoadMoreButton extends React.PureComponent {
  onClick = (evt) => {
    evt.preventDefault();
    const { onClick } = this.props;

    typeof onClick === 'function' && onClick();
  }

  render() {
    return (
      <div style={styles.wrapper} className="load-more-button-wrapper">
        <button style={styles.button} onClick={this.onClick} className="load-more-button">Load more</button>
      </div>
    )
  }
}

const styles = {
  wrapper: {
    paddingBottom: '20px'
  },
  button: {
    width: '100%',
    padding: '11px 0',
    lineHeight: '22px',
    textAlign: 'center',
    fontSize: '16px',
    border: '1px solid #4261ff',
    borderRadius: '5px',
    background: '#ffffff',
    color: '#4261ff'
  }
}