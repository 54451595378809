import React from 'react';
import { connect } from 'react-redux';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { invertLayout } from "../../../actions/AppMain/AppMainAction";

import HelperBanner from '../HelperBanner';
import SupportItem from '../SupportItem';

import './styles.less';

import { support } from '../data';

class Support extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.switchHeader({
      headerLeft: 'menu',
      headerMiddle: { type: 'text', text: 'Support' },
      headerRight: { type: 'search', onOpenSearch: this.onOpenSearchForm },
      currentPage: 'Support',
    });
  }

  onOpenSearchForm = () => {

  }

  render() {
    return (
      <div className="support">
        <div className="support-list">
          {
            support.map((datum) => (
              <SupportItem key={datum.id} text={datum.text} link={datum.link} />
            ))
          }
        </div>
        <HelperBanner />
      </div>
    )
  }
}

export default connect(null, { switchHeader, invertLayout })(withBaseLayout(Support));