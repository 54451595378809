import React from "react";

class TabBarItem extends React.Component {
  render() {
    return (
      <li className={this.props.currentTabItemState}>
        <span>
          {this.props.title}
          <span
            className="icon"
            style={{ display: this.props.currentTabItemState === 'complete' ? "" : "none", marginTop: "-11px" }}
          />
        </span>
      </li>
    );
  }
}

export default TabBarItem;