import React from 'react';

export default class Answer extends React.PureComponent {
  onClick = (evt) => {
    evt.preventDefault();
    
    this.props.onClick(this.props.value);
  }

  render() {
    const { data, layout, selected } = this.props;

    return (layout) ? (
      <div 
        className={['questionnaires__answer questionnaires__answer--fullrow', selected ? 'selected' : ''].join(' ')}
        onClick={this.onClick}
      >
        {
          !data.Icon ? (
            <span className="questionnaires__answer-checkbox">
              <span className="questionnaires__answer-checkmark"></span>
            </span>
          ) : (
            <span className="questionnaires__answer-icon">
              <img alt="" src={!selected ? data.Icon : data.IconWhite} />
            </span>
          )
        }
        <span className="questionnaires__answer-desc">{data.Text}</span>
      </div>
    ) : (
      <div 
        className={['questionnaires__answer questionnaires__answer--one-three', selected ? 'selected' : ''].join(' ')}
        onClick={this.onClick}
      >
        <div className="questionnaires__answer--one-three--inner">
          {
            !data.Icon ? (
              <span className="questionnaires__answer-checkbox">
                <span className="questionnaires__answer-checkmark"></span>
              </span>
            ) : (
              <span className="questionnaires__answer-icon">
                <img alt="" src={!selected ? data.Icon : data.IconWhite} />
              </span>
            )
          }
          <span className="questionnaires__answer-desc">{data.Text}</span>
        </div>
      </div>
    )
  }
}