import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";


import './styles.less';

class LeaveSuggestion extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.switchHeader({
      headerLeft: 'close',
      headerMiddle: { type: 'text', text: 'Customer satisfaction' },
      headerRight: '',
      currentPage: 'LeaveSuggestion',
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="leave-suggestion">
          <div className="form-group">
            <p className="control-label">Subject:</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                defaultValue="Reporting of the performance"
                type="text"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="leave-suggestion--desc">
            <p>Dear Sir or Madam,</p>
            <p>It is not clear to me that the performance shown under the goal detail view is the weighted average performance. I'd propose to state that in a comment or so. </p>
            <p>Best, Jakob</p>
          </div>
        </div>
        <div className="content-main__footer">
          <div className="form-actions">
            <div className="form-actions__item">
              <Link to="/feedback/sent-suggestion" className="btn btn-primary btn-no-focus__primary">Send</Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(LeaveSuggestion));