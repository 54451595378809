import { 
  UPDATE_PERSONAL_FINANCE_DATA, 
  CLEAR_PERSONAL_FINANCE_DATA
} from "./constants";

// _____________________________________________________________________________
//                                                           SC_CONTACT_REGISTER
export const updatePersonalFinanceData = (data) => ({
	type: UPDATE_PERSONAL_FINANCE_DATA, data,
});


export const clearPersonalFinanceData = () => ({
	type: CLEAR_PERSONAL_FINANCE_DATA,
});
