import {
  SWITCH_DATE_XAXIS,
  CHANGE_TO_AMOUNT_CHART,
  //CHANGE_TO_RELATIVE_CHART,
  DETAIL_CHANGE_TO_AMOUNT_CHART,
  DASH_BOARD_CHART,
  RESET_LABEL_DATE,
  DISABLE_AMOUNT_CHART_ANIMATION,
  //DISABLE_RELATIVE_CHART_ANIMATION,
  ENABLE_AMOUNT_CHART_ANIMATION,
  //ENABLE_RELATIVE_CHART_ANIMATION
} from '../../actions/Chart/ChartActionType';
import amountConfig from '../../components/Charts/config/HighStockConfigAmount';
//import relativeConfig from '../../components/Charts/config/HighStockConfigRelative';
import detailGeneralConfig from '../../data/performance_general_investment.json';
import detailKidEducation from '../../data/performance_kid_education.json';
import detailSavingForRanning from '../../data/performance_saving_for_raining_day.json'


export default function(states = amountConfig,actions){
  switch(actions.type){
    case SWITCH_DATE_XAXIS:
      return { ...states,
          xAxis:{ ...states.xAxis,
            labels: { ...states.xAxis.labels,format:actions.payload } } };
    // case CHANGE_TO_RELATIVE_CHART:
    //   return states = relativeConfig;
    case DISABLE_AMOUNT_CHART_ANIMATION:
        amountConfig.plotOptions.series.animation = false;
        return states = amountConfig;
    case ENABLE_AMOUNT_CHART_ANIMATION:
        amountConfig.plotOptions.series.animation = true;
        return states = amountConfig;
    // case DISABLE_RELATIVE_CHART_ANIMATION:
    //     relativeConfig.plotOptions.series.animation = false;
    //     return states = relativeConfig;
    // case ENABLE_RELATIVE_CHART_ANIMATION:
    //     relativeConfig.plotOptions.series.animation = true;
    //     return states = relativeConfig;
    case DETAIL_CHANGE_TO_AMOUNT_CHART:
          // Genera investment
          let data = {};
          let title = '';
          if (actions.payload === "1")  { // General config
            data = detailGeneralConfig;
            title = "detailGeneralConfig";  
          } else if (actions.payload === "2")  { // Kid education
            data = detailKidEducation;
            title = "detailKidEducation"; 
          } else if (actions.payload === "3")  { // Saving for ranning day
            data = detailSavingForRanning;
            title = "detailSavingForRanning"; 
          }
          let obj = {
            data: data,
            name: title,
            type: 'area',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, '#506dff'],
                [1, 'white ']
            ]
            },
          }
          return { ...states,
            series:[obj] 
          }
    case CHANGE_TO_AMOUNT_CHART:
    case DASH_BOARD_CHART:
      return states = amountConfig;
    case RESET_LABEL_DATE:
      return { ...states,
          xAxis:{ ...states.xAxis,
            labels: { ...states.xAxis.labels,format:"{value: %d.%m.%y}" } } };   
    default:
      return states;
  }
}