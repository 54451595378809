import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  switchTabBar,
  clearPreviousTab
} from "../../../actions/TabBar/TabBarActions";
import PopUp from "../../common/Popup/PopUp";
import changeLanguage from "../../../setting/languages/languages";
import CONFIG from "../../../setting/config";

class LocalBank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      warning: false
    };
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onClosePopUp = this.onClosePopUp.bind(this);
  }

  onSaveClick() {
    if (!this.props.config.deleteButton) {
      this.props.history.goBack();
      this.props.clearPreviousTab();
      this.props.switchTabBar("Withdraw");
    } else {
      this.props.history.push(this.routesName.SETTINGS_UPDATED);
    }
  }

  onDeleteClick() {
    this.setState({ warning: true });
  }

  onClosePopUp() {
    this.setState({ warning: false });
  }

  renderDeleteButton() {
    if (this.props.config.deleteButton)
      return (
        <input
          className="btn btn-default btn-no-focus bank-account-button"
          type="button"
          value={this.props.config.deleteButton}
          onClick={this.onDeleteClick}
        />
      );
  }

  renderPopUp() {
    if (this.state.warning) {
      return (
        <PopUp
          language={this.props.language}
          onConfirmClick={() => {
            this.props.history.push(this.routesName.SETTINGS_UPDATED);
          }}
          closePopUp={this.onClosePopUp}
          message={this.textStore.popUp.bankDeleteWarning}
          leftButtonText={this.textStore.common.cancel}
          rightButtonText={this.textStore.common.confirm}
        />
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderPopUp()}
        <table className="list-view">
          <tbody>
            <tr id="borderless">
              <th className="cell-icn" id="borderless-bottom">
                Account Holder Name
              </th>
              <td className="text-right" id="borderless-bottom">
                Carlos Jakob
              </td>
            </tr>
            <tr id="borderless">
              <td
                colSpan="2"
                style={{
                  borderTop: "0px"
                }}
              >
                <p
                  style={{
                    fontSize: "13px",
                    color: "#a1b0fc",
                    marginTop: "-22px",
                    fontFamily: "OpenSans"
                  }}
                >
                  Please ensure that your existing bank account has been opened
                  under the same name (in the exact order, sequence and form) as
                  the name which has been used to open your ‘Robo Classic Wealth
                  Platform’, account.
                </p>
              </td>
            </tr>
            <tr>
              <td className="bank-account-input" colSpan="2">
                <span className="list-view-row title">Bank name </span>
                <input
                  className="cockpit-input-field__full__small__no-border"
                  type="text"
                  value={this.props.config.bankName}
                />
              </td>
            </tr>
            <tr>
              <td className="bank-account-input" colSpan="2">
                <span className="list-view-row title">
                  Account number (IBAN){" "}
                </span>
                <input
                  className="cockpit-input-field__full__small__no-border"
                  type="text"
                  value={this.props.config.iban}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="content-main__footer" style={{ paddingTop: "20px" }}>
          <div className="form-actions">
            <div className="form-actions__item bank-account-footer">
              {this.renderDeleteButton()}
              <input
                className="btn btn-primary btn-no-focus__primary bank-account-button"
                type="button"
                value={this.props.config.saveButton}
                onClick={this.onSaveClick}
              />
              {/* <Link className="btn btn-primary btn-no-focus__primary" to="/">
                Save bank account
              </Link> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    null,
    { switchTabBar, clearPreviousTab }
  )(LocalBank)
);
