import React from 'react';
import { Link } from 'react-router-dom';
import './styles.less';


export default class HelperBanner extends React.PureComponent {
  render() {
    return (
      <div className="helper-banner">
        <div className="helper-banner__title">
          Couldn’t find what you’re looking for?
        </div>
        <div className="helper-banner__desc">
          We’re here to help! Don’t hesitate contacting us directly if you have any questions.
        </div>
        <div className="helper-banner__btn">
          <Link to="/support/send-mail" className="btn btn-default btn-block btn-no-focus__primary__inverted">Contact support</Link>
        </div>
      </div>
    )
  }
}