import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import withBaseLayout from "../../HoC/withBaseLayout";
import changeLanguage from "../../../setting/languages/languages";
import { formatCurrency, unformatCurrency } from "../../../helpers/formatCurrency";
import PopUp from "../../common/Popup/PopUp";
import GoalFooter from "../../Goals/GoalFooter/GoalFooter";
import CONFIG from "../../../setting/config";
import { invertLayout } from "../../../actions/AppMain/AppMainAction";
import { savePreviousInvestmentInfo, getCTB } from "../../../actions/Onboarding/OnboardingActions";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import Stepper from '../../Onboarding/RiskProfiling/Stepper/Stepper';
import { savePreviousScreen } from "../../../actions/Screen/ScreenAction";
import TextInput from "../../common/CustomInput/TextInput/TextInput";
// import { getDefaultGoals } from "../../actions/Goals/GoalActions";


class InvestmentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goalName: this.props.onboardingData.investmentInfo.goalName || this.props.location.state.defaultGoal.Name,
      targetAmount: this.props.onboardingData.investmentInfo.targetAmount || formatCurrency(this.props.location.state.defaultGoal.TargetAmount, 0),
      targetYears: this.props.onboardingData.investmentInfo.targetYears || this.props.location.state.defaultGoal.TimeHorizon,
      initialAmount: this.props.onboardingData.investmentInfo.initialAmount || formatCurrency(this.props.location.state.defaultGoal.InitialInvestmentAmount, 0),
      activeMessage: -1,
      invalidFields: [],
      isInvalid: false,
      canGoNext: true,
      cursorMoveToEnd: true,
      activeStep: 1,
      totalSteps: 8
    };
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    //TODO: Placeholder for currency, wait for currency implementation
    this.currency = "USD";
    this.inputCount = 4;
    this.numberPatternOnly = /\D/;
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onClosePopUp = this.onClosePopUp.bind(this);
    this.closeErrorMessages = this.closeErrorMessages.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle },
      headerRight: '',
      currentPage: 'investmentDetail'
    })
    if (this.props.appMainUI.layout !== "viewport layout--default") {
      this.props.invertLayout("viewport layout--default");
    }
  }

  fieldsValidation() {
    if (
      this.state.goalName !== "" &&
      unformatCurrency(this.state.targetAmount) > unformatCurrency(this.state.initialAmount) &&
      this.state.targetYears >= this.props.onboardingData.CTB.min.timeHorizon &&
      this.state.targetYears <= this.props.onboardingData.CTB.max.timeHorizon &&
      unformatCurrency(this.state.initialAmount) >= this.props.onboardingData.CTB.min.initialAmount
    ) {
      this.setState({ canGoNext: true });
    } else {
      this.setState({ canGoNext: false });
    }
  }

  closeErrorMessages() {
    this.setState({ activeMessage: -1 });
  }

  clearSelectedError(position) {
    let invalidFields = this.state.invalidFields;
    invalidFields[position] = false;
    this.setState({
      activeMessage: -1,
      invalidFields: invalidFields,
    })
  }

  onChangeValue(e) {
    const { id, value } = e.target;
    this.closeErrorMessages();
    switch (id) {
      case "goalName":
        this.setState({ goalName: value }, () => {
          this.fieldsValidation();
        });
        break;
      case "targetAmount":
        if (this.numberPatternOnly.test(value)) {
          return;
        }
        this.setState({ targetAmount: value }, () => {
          this.fieldsValidation();
        });
        break;
      case "targetYears":
        if(this.numberPatternOnly.test(value)) {
          return;
        }
        this.setState({ targetYears: value }, () => {
          this.fieldsValidation();
        });
        break;
      case "initialAmount":
        if(this.numberPatternOnly.test(value)) {
          return;
        }
        this.setState({ initialAmount: value }, () => {
          this.fieldsValidation();
        });
        break;
      default:
        break;
    }
  }

  onInputBlur(e) {
    const { id, value } = e.target;
    let invalidFields = this.state.invalidFields;
    this.closeErrorMessages();
    switch (id) {
      case "goalName":
        if (value === "") {
          invalidFields[0] = true;
          this.setState({
            activeMessage: 0,
            invalidFields: invalidFields
          });
        } else {
          this.clearSelectedError(0);
        }
        this.setState({ goalName: value.trim() });
        break;
      case "targetAmount":
        if (
          value <= unformatCurrency(this.state.initialAmount) ||
          value === "" ||
          value === "0"
        ) {
          invalidFields[1] = true;
          this.setState({
            activeMessage: 1,
            invalidFields: invalidFields
          });
        } else {
          this.clearSelectedError(1);
        }
        this.setState({ targetAmount: formatCurrency(value, 0) });
        break;
      case "targetYears":
        if (
          value < this.props.onboardingData.CTB.min.timeHorizon ||
          value > this.props.onboardingData.CTB.max.timeHorizon
        ) {
          invalidFields[2] = true;
          this.setState({
            activeMessage: 2,
            invalidFields: invalidFields
          });
        } else {
          this.clearSelectedError(2);
        }
        this.setState({
          targetYears: value.trim() === "" ? "" : parseInt(value)
        });
        break;
      case "initialAmount":
        if (value < this.props.onboardingData.CTB.min.initialAmount) {
          invalidFields[3] = true;
          this.setState({
            activeMessage: 3,
            invalidFields: invalidFields
          });
        } else if (value >= unformatCurrency(this.state.targetAmount)) {
          this.clearSelectedError(3);
          invalidFields[1] = true;
          this.setState({
            activeMessage: 1,
            invalidFields: invalidFields
          });
        } else {
          this.clearSelectedError(1);
          this.clearSelectedError(3);
        }
        this.setState({ initialAmount: formatCurrency(value, 0) });
        break;
      default:
        break;
    }
    this.fieldsValidation();

  }

  onInputFocus(e) {
    const { id, value } = e.target;
    e.preventDefault();
    switch (id) {
      case "goalName":
        break;
      case "targetAmount":
        this.setState({ targetAmount: unformatCurrency(value) });
        break;
      case "targetYears":
        break;
      case "initialAmount":
        this.setState({ initialAmount: unformatCurrency(value) });
        break;
      default:
        return;
    }
  }

  onClosePopUp() {
    this.setState({ isInvalid: false });
  }

  onClickNext() {
    this.props.history.push(this.routesName.ONBOARDING.PERSONAL_DETAIL);
  }

  onInputClick(e) {
    e.preventDefault();
    if (!this.state.cursorMoveToEnd) {
      return;
    }
    this.setState({ cursorMoveToEnd: false })
    e.target.setSelectionRange(e.target.value.length,e.target.value.length);
  }

  errorCondition(messagePosition) {
    return (
      this.state.activeMessage === messagePosition ||
      this.state.invalidFields[messagePosition] ||
      this.state.isInvalid
    );
  }

  renderErrorMessage(title, content, id) {
    let positionObj = { top: "", arrowLeft: "" };
    switch (id) {
      case 0:
        positionObj = { top: "120px", arrowLeft: "46%" }
        break;
      case 1:
        positionObj = { top: "160px", arrowLeft: "72%" };
        break;
      case 2:
        positionObj = { top: "215px", arrowLeft: "13%" };
        break;
      case 3:
        positionObj = { top: "330px", arrowLeft: "30%" };
        break;
      default:
        return;
    }
    return (
      <div
        className="investment-detail--error-message"
        style={{
          top: positionObj.top,
          display: this.state.activeMessage === id ? "inline-block" : "none"
        }}
      >
        <div className="error-message-title">
          <span className="investment-detail--error-message__title">
            {title}
          </span>
          <span
            className="icn icn--error-message-close"
            onClick={this.closeErrorMessages}
          />
        </div>
        <p className="investment-detail--error-message__content">{content}</p>
        <div
          className="error-message-arrow"
          style={{ left: positionObj.arrowLeft }}
        />
      </div>
    );
  }

  renderErrorPopUp() {
    if (this.state.isInvalid) {
      return (
        <PopUp
          message={this.textStore.errorMessage.fillBlanks}
          rightButtonText="Confirm"
          onConfirmClick={this.onClosePopUp}
          closePopUp={this.onClosePopUp}
          renderLeftButton={false}
        />
      );
    }
  }

  render() {
    const warningText = this.textStore.common.warning;
    // const defaultGoal = this.props.location.state 
    //   ? this.props.location.state.defaultGoal : this.props.onboardingData.selectedGoal;

    return (
      <React.Fragment>
        <Stepper 
            activeStep={this.state.activeStep} 
            totalSteps={this.state.totalSteps}
            textStore={this.textStore} 
            headerLeft={this.textStore.riskProfiling.goalSetting}
            />
        <div className="investment-detail-container">
          {this.renderErrorPopUp()}
          <p className="text-title__large">
            {this.textStore.investmentDetail.tellUs}
          </p>
          <div className="investment-detail--description">
            <span className="text-one-line">
              {this.textStore.investmentDetail.iWantTo.split("{goalName}")[0]}
            </span>
            {this.renderErrorMessage(warningText, this.textStore.errorMessage.goalNameNotBlank, 0)}
            <TextInput
              className={`investment-detail--input goal-input__name ${this.errorCondition(0) ? "invalid-input" : ""}`}
              id="goalName"
              type="text"
              maxLength={24}
              value={this.state.goalName}
              autoComplete="off"
              onChange={this.onChangeValue}
              onBlur={this.onInputBlur}
              onFocus={this.onInputFocus}
            />
            <span className="text-one-line">
              {this.textStore.investmentDetail.iWantTo.split("{goalName}")[1]}
            </span>
            <span>
              {this.textStore.investmentDetail.andINeed.replace("{currency}", this.currency).split("{targetAmount}")[0]}
            </span>
            {
              this.renderErrorMessage(
                warningText,
                (unformatCurrency(this.state.targetAmount) <= 0) ?
                  this.textStore.errorMessage.targetAmountNotZero :
                  unformatCurrency(this.state.targetAmount) <= unformatCurrency(this.state.initialAmount) ?
                    this.textStore.errorMessage.pleaseEnterTargetAmount : "",
                1
              )
            }
            <TextInput
              className={`investment-detail--input goal-input__target-amount ${this.errorCondition(1) ? "invalid-input" : ""}`}
              id="targetAmount"
              type="text"
              maxLength={8}
              value={this.state.targetAmount}
              autoComplete="off"
              numericKeyboard={true}
              onChange={this.onChangeValue}
              onFocus={this.onInputFocus}
              onClick={this.onInputClick}
              onBlur={this.onInputBlur}
            />
            <span>
              {this.textStore.investmentDetail.andINeed.replace("{currency}", this.currency).split("{targetAmount}")[1]}
            </span>
            <span>
              {this.textStore.investmentDetail.inYears.split("{targetYears}")[0]}
              {
                this.renderErrorMessage(
                  warningText,
                  this.textStore.errorMessage.timeHorizon
                    .replace("{min}", this.props.onboardingData.CTB.min.timeHorizon)
                    .replace("{max}", this.props.onboardingData.CTB.max.timeHorizon),
                    2
                )
              }
              <TextInput
                className={`investment-detail--input goal-input__target-years ${this.errorCondition(2) ? "invalid-input" : ""}`}
                id="targetYears"
                type="text"
                maxLength={2}
                value={this.state.targetYears}
                autoComplete="off"
                numericKeyboard={true}
                onChange={this.onChangeValue}
                onFocus={this.onInputFocus}
                onClick={this.onInputClick}
                onBlur={this.onInputBlur}
              />
            </span>
            <span>
              {this.textStore.investmentDetail.inYears.split("{targetYears}")[1]}
            </span>
            <span>
              {this.textStore.investmentDetail.iWouldLike.replace("{currency}", this.currency).split("{initialAmount}")[0]}
            </span>
            {
              this.renderErrorMessage(
                warningText,
                this.textStore.errorMessage.pleaseEnterLargerThan.replace(
                  "{inputType}",
                  this.textStore.investmentDetail.initialInvestment) + this.props.onboardingData.CTB.min.initialAmount + ".",
                3
              )
            }
            <TextInput
              className={`investment-detail--input goal-input__initial-amount ${this.errorCondition(3) ? "invalid-input" : ""}`}
              id="initialAmount"
              type="text"
              maxLength={6}
              value={this.state.initialAmount}
              autoComplete="off"
              numericKeyboard={true}
              onChange={this.onChangeValue}
              onFocus={this.onInputFocus}
              onClick={this.onInputClick}
              onBlur={this.onInputBlur}
            />
            <span>
              {this.textStore.investmentDetail.iWouldLike.replace("{currency}", this.currency).split("{initialAmount}")[1]}
            </span>
          </div>
        </div>
        <GoalFooter
          onClickNext={this.onClickNext}
          enableNextButton={this.state.canGoNext}
          renderBackButton={false}
          nextButtonFullWidth={true}
        />
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this.props.savePreviousScreen(this.textStore.screen.investmentDetail);
    this.props.savePreviousInvestmentInfo({
      goalName: this.state.goalName,
      targetAmount: this.state.targetAmount,
      targetYears: this.state.targetYears,
      initialAmount: this.state.initialAmount
    });
  }
}

InvestmentDetail.defaultProps = {
  defaultGoalName: "Goal name",
  defaultTargetAmount: 80000,
  defaultTargetYears: 5,
  defaultInitialAmount: 10000
};

InvestmentDetail.propTypes = {
  defaultGoalName: PropTypes.string,
  defaultTargetAmount: PropTypes.number,
  defaultTargetYears: PropTypes.number,
  defaultInitialAmount: PropTypes.number
};

function mapStateToProps(props) {
  return {
    goalData: props.goalData,
    appMainUI: props.appMainUI,
    onboardingData: props.onboardingData,

  };
}

const EnhancedInvestmentDetail = withBaseLayout(InvestmentDetail);

export default withRouter(
  connect(
    mapStateToProps,
    { invertLayout, savePreviousInvestmentInfo, getCTB, switchHeader, savePreviousScreen }
  )(EnhancedInvestmentDetail)
);
