export const myAdvisorContent = [
  {
    contentType: 'question',
    message: 'Hi There, I transferred USD 5,000 \n yesterday to my account but cant \n see it yet. Can you check?',
    status: 'Delivered'
  },
  {
    contentType: 'answer',
    message: 'Good Morning Mr. Jakob, good to \n hear back from you. I can see a \n planned top-up amount of USD 5,000 for your “Kid’s education” investment goal, however we haven’t yet received confirmation from the deposit taking bank on the incoming funding. Please note that incoming tranfer usually takes 2-3 business days to process. Please let me know if there is anything else that I can assist you. ',
    status: '3:00pm'
  },
  {
    contentType: 'question',
    message: 'Ah ok understood, many thanks'
  }
];

export const supportContent = [
  {
    contentType: 'answer',
    message: `Dear Carlos

    Thank you for reaching out to us.
    
    Our team noticed that the changes for your “Kid’s education” investment goal haven’t been processed yet. Please allow 2-3 business day processing time. We appreciate your patience.  
    
    If you have any further questions or concerns, let us know. We are here 24/7 and always happy to help. Thanks for being a loyal Classic Robo’s customer.
    
    Best regards,
    Classic Robo Operator Support`,
    status: '3:00pm'

  }
];
