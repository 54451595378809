import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import withBaseLayout from "../HoC/withBaseLayout";
import { switchHeader } from "../../actions/Header/HeaderActions";
import changeLanguage from "../../setting/languages/languages";
import CONFIG from "../../setting/config";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE
  }
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.textStore.passwordReset.resetPassword,
        styles: { fontFamily: 'SansSemibold' }
      },
      headerRight: "",
      currentPage: "resetPassword"
    });
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="text-title__small">
            {this.textStore.common.password}
          </div>
          <input
            className="form-control cockpit-input-field__full__small password-input-field"
            id="password"
            type="password"
          />
          <div className="text-title__small new-password__title">
            {this.textStore.common.repeatPassword}
          </div>
          <input
            className="form-control cockpit-input-field__full__small password-input-field"
            id="repeat-password"
            type="password"
          />
        </div>
        <div className="content-main__footer">
          <div className="form-actions">
            <div className="form-actions__item">
              <Link
                id="btnBack"
                to={this.routesName.RESET_PASSWORD.COMPLETE}
                className="btn btn-primary btn-block btn-no-focus"
              >
                {this.textStore.common.save}
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const EnhancedResetPassword = withBaseLayout(ResetPassword);

export default withRouter(
  connect(
    null,
    { switchHeader }
  )(EnhancedResetPassword)
);
