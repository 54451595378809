import React from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { selectGoal } from '../../../actions/Goals/GoalActions';
import { connect } from 'react-redux';
import CONFIG from '../../../setting/config';
import { updateOnBoardingSelectedGoal } from '../../../actions/Onboarding/OnboardingActions';


class GoalSelections extends React.Component {
	constructor(props) {
		super(props);
		this.routesName = CONFIG.APP_ROUTE;
	}
	onGoalSelect(goal){
		if (this.props.type !== "onboarding") {
			this.props.selectGoal({
				goal: goal,
				selected: true
			});
		} else {
			this.props.updateOnBoardingSelectedGoal(goal);
			this.props.history.push({ 
				pathname: this.routesName.ONBOARDING.INVESTMENT_DETAIL, 
				state: { 
					defaultGoal: goal,
				} 
			})
		}
	}

	renderDefaultGoals(){
		if (this.props.type === 'onboarding') {
			return this.props.defaultGoals.map(goal => {
				return (
					<div 
						className="goal" 
						id={goal.id}
						key={goal.id}
						onClick={this.onGoalSelect.bind(this,goal)}
					>
						<label htmlFor={`goal_${goal.id}`}>
							<input type="radio" name="goal" id={`goal_${goal.id}`} />
							<span className={`goal__icon icon--${goal.type}`} role="presentation"> </span>
							<span className="goal__name">{goal.Name}</span>							
						</label>
					</div>
				)
			})
		}
		return this.props.defaultGoals.map(goal => {
			return (
				<div 
					className="goal" 
					id={goal.id}
					key={goal.id}
					onClick={this.onGoalSelect.bind(this,goal)}
				>
					<label htmlFor={`goal_${goal.id}`}>
						<input type="radio" name="goal" id={`goal_${goal.id}`} />
						<span className="goal__icon" role="presentation">
							<img src={goal.GoalIcon} alt=""/>
						</span>
						<span className="goal__name">{goal.Name}</span>
					</label>
				</div>
			)
		})
	}

	render(){
		return (
			<div className="goal-selector goal-selector--cockpit">
				{this.renderDefaultGoals()}
			</div>
		)
	}
}

GoalSelections.defaultProps = {
	defaultGoals: [],
	isOnboarding: false,
}

GoalSelections.propTypes = {
	defaultGoals: PropTypes.array,
	isOnboarding: PropTypes.bool,
}

export default withRouter(connect(null, { 
	selectGoal, 
	updateOnBoardingSelectedGoal
})(GoalSelections));