import { download, firebaseGet } from '../../../../services';

const FIREBASE_INFO = {
  PDFLink: {
    collection: 'New_AccountOpeningForm',
    doc: 'ZHH3SfLtDZxiVgLW3BnD',
  }
}

export const downloadPDFFile = (url) => {
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('target', 'blank'); //or any other extension
  document.body.appendChild(link);
  link.click();
}

export const getPDFLink = () => {
  firebaseGet(FIREBASE_INFO.PDFLink).then((data) => {
    downloadPDFFile(data.Template);
  }).catch((error) => {
    console.log('error ', error);
  })
}