import React from 'react';
import changeLanguage from '../../../setting/languages/languages';
import CONFIG from "../../../setting/config";

class HeadersOnboarding extends React.Component {

  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;

  }

  render(){
    return (
      <React.Fragment>
        <header 
          className={`header-main header-onboarding `}
          id="HeaderMain" 
          role="banner"
        >
          <div className="left">
            <span>{this.textStore.common.classicRoboTitle}</span>
          </div>

          {this.props.landingPage ? <div className="right">
            <a href={this.routesName.DASHBOARD}>{this.textStore.common.login}</a>
          </div>: ''}
				</header>
      </React.Fragment>
    )
  }
}

export default HeadersOnboarding;