import React from "react";
import PropTypes from "prop-types"

class GenericListItem extends React.Component {
  renderIcons() {
    if (this.props.iconClass !== '')
      return (
        <div
          className={`icn ${this.props.iconClass}`}
          style={this.props.iconStyle}
        />
      )
  }
  renderArrow() {
    if (this.props.hasArrow) {
      return (
        <span 
          className="list-action__link list-action__link--more icn--inbox-more"
          style={this.props.arrowStyle}
        >
          <span className="sr-only">Show more</span>
        </span>
      )
    }
  }
  render() {
    return (
      <div onClick={this.props.onItemClick} className={`generic-item ${this.props.hasBorder ? '' : 'borderless'}`} style={this.props.containerStyle}>
        {this.renderIcons()}
        <div className="generic-info">
          <div className="generic-info--left">
            <div className="generic-subtext">{this.props.topLeft}</div>
            <div className={this.props.leftClass} style={this.props.leftStyle}>{this.props.left}</div>
            <div className="generic-subtext">{this.props.bottomLeft}</div>
          </div>
          <div className="generic-info-right">
            
            <div className="generic-right-text" style={this.props.rightStyle}>
              {this.props.right}
              {this.renderArrow()}
            </div>
            <div className="generic-subtext">{this.props.bottomRight}</div>
          </div>
        </div>
      </div>
    );
  }
}

GenericListItem.defaultProps = {
  iconClass: '',
  topLeft: '',
  left: '',
  bottomLeft: '',
  right: '',
  bottomRight: '',
  hasArrow: false,
  hasBorder: true,
  containerStyle: {},
  arrowStyle: {},
  iconStyle: {},
  leftStyle: {},
  leftClass: '',
  rightStyle: {},
  onItemClick: () => {}
}

GenericListItem.propTypes = {
  iconClass: PropTypes.string,
  topLeft: PropTypes.string,
  left: PropTypes.any,
  bottomLeft: PropTypes.string,
  right: PropTypes.any,
  bottomRight: PropTypes.string,
  hasArrow: PropTypes.bool,
  hasBorder: PropTypes.bool,
  containerStyle: PropTypes.object,
  arrowStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  leftStyle: PropTypes.object,
  leftClass: PropTypes.string,
  rightStyle: PropTypes.object,
  onItemClick: PropTypes.func
}

export default GenericListItem;
