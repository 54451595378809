import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withBaseLayout from "../../HoC/withBaseLayout";
import ExpandableView from "../../common/ExpandableView/ExpandableView";
import { switchHeader } from "../../../actions/Header/HeaderActions";

class FundingTerms extends React.Component {
  constructor() {
    super();
    this.state = {
      activeIndex: []
    };
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: { type: "text", text: "Terms & Conditions" },
      headerRight: "",
      currentPage: "fundingTerms"
    });
    window.scrollTo(0, 0);
  }

  onItemClick() {}

  render() {
    const data = [
      {
        title: "Request for Proof of Remittance",
        description:
          "Please note that we are required under the Anti-Money Laundering rules and regulations to request and verify proof of remittance from our clients. " +
          "Although such a request may not be made immediately upon making the bank transfer or credit/debit card payment, " +
          "Classic Robo Capital reserves the right to request such proof at a later date. " +
          "If the client is unable to furnish such proof of remittance within a reasonable period of time, " +
          "we are entitled to limit or restrict the client's access to payment via internet banking transfer or credit/debit card funding."
      },
      {
        title: "Third Party Funding is Not Accepted",
        description:
          "Classic Robo does not accept incoming funds from any third party. " +
          "We will only accept funds originating from a bank account held in the same name registered with Classic Robo. " +
          "This rule applies to both individual accounts as well as corporate accounts." +
          "\n\nFunds received from any third party accounts will be returned (less bank charges) to the sender. " +
          "The funds should typically reach the sender’s account within 5 to 10 working days, " +
          "however, there may sometimes be a delay depending on the banks involved."
      },
      {
        title: "Unaccepted Forms of Payment",
        description:
          "Classic Robo does not accept Banker’s Drafts, Cash Deposits and remittances from Exchange Houses/Money Changers. " +
          "Funds received from these payment channels will be rejected and returned to the sender (less bank charges, if any). "
      },
      {
        title: "Currency Conversions",
        description:
          "If you transfer funds in a different currency from the currency of our Additiv Bank account, " +
          "your funds will be accepted and automatically converted to the appropriate currency by Additiv Bank who will charge a non-refundable currency conversion fee." +
          "\n\nFor example, if you send USD to our SGD account 808-88880-008 at Additiv Bank, Additiv Bank will convert your USD to SGD and credit our account." +
          "\n\nIf your funds are subsequently rejected by us (for example, for Third Party Funding or remittances from Exchange Houses/Money Changers), " +
          "any currency conversion already carried out on your funds will not be reversed." +
          "\n\nIf you do not have a Additiv Bank account denominated in the same currency as our Additiv Bank account that your funds have been transferred to, " +
          "we will convert the funds to the appropriate currency when crediting your Additiv Bank account, " +
          "and you will be charged a non-refundable currency conversion fee, as described in our Commissions, " +
          "Charges and Margin Schedule." +
          "\n\nFor example, if you send SGD to our SGD account 808-88880-008 at Additiv Bank, " +
          "and you only have a USD-denominated Additiv Bank account, " +
          "we will convert your SGD to USD and credit your Additiv Bank account."
      }
    ];
    return (
      <React.Fragment>
        <p className="funding-terms-header-title">Important to note:</p>
        <ExpandableView data={data} />
      </React.Fragment>
    );
  }
}

const EnhancedFundingTerms = withBaseLayout(FundingTerms);

export default withRouter(connect(null, { switchHeader })(EnhancedFundingTerms));
