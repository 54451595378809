
// function calculateFixIncomeValue(securities){
// 	let fixedIncomeValue = 0;
// 	securities.forEach(security => {
// 		fixedIncomeValue += (security.price * security.quantity);
// 	});
// 	return fixedIncomeValue;
// }


// function calculateNonTraditionalValue(securities){
// 	let nonTraditionalValue = 0;
// 	securities.forEach(security => {
// 		nonTraditionalValue += (security.price * security.quantity);
// 	});
// 	return nonTraditionalValue;
// }


// function calculateEquityValue(securities){
// 	let equityValue = 0;
// 	securities.forEach(security => {
// 		equityValue += (security.price * security.quantity);
// 	});
// 	return equityValue;
// }


function formatRegionName(securities,regions){
	let continent = securities[0].continent;
	regions.push({
		continent:continent,
		totalAllocation:0
	})
	securities.forEach(security => {
		if(security.continent !== continent){
			regions.push({
				continent:continent,
				totalAllocation:0
			});
			continent = security.continent;
		}
	});
	return regions

}


function calculateRegionAllocation(regions,securities){
	regions.forEach(region => {
		securities.forEach(security => {
			if(region.continent === security.continent){
				region.totalAllocation += security.allocation;
			}
		})
	});
	return regions.sort((a,b) => {
		return b.totalAllocation - a.totalAllocation;
	});
}


function limitNumberOfRegions(regions){
	let arr = [];
	let othersAllocation = 0;
	for(let i = 0;i < regions.length; i++){
		if (i < 5) { 
			arr.push(regions[i]);
		}
		if(i >= 5){
			othersAllocation += regions[i].totalAllocation;
		}
	}
	if(othersAllocation  !== 0) arr.push({
		continent:'Others',
		totalAllocation:othersAllocation
	}) 
	return arr;
}



function formatAllocation(positions, currentValue){
	let obj = {
		fixedIncomeValue:null,
		fixedIncomeAllocation:null,
		equityValue:null,
		equityAllocation:null,
		nonTraditionalValue:null,
		nonTraditionalAllocation:null,
		regions:[]
	};
	positions.forEach(position => {
		if(position.type === 'Fixed income'){
			obj.fixedIncomeValue = position.totalAllocation * currentValue;
			obj.fixedIncomeAllocation = position.totalAllocation;
		}else if(position.type === 'Non-traditional'){
			obj.nonTraditionalValue = position.totalAllocation * currentValue;
			obj.nonTraditionalAllocation = position.totalAllocation;
		}else{
			obj.equityValue = position.totalAllocation * currentValue;
			obj.equityAllocation = position.totalAllocation;
		}
		obj.regions = formatRegionName(position.security,obj.regions);
	})
	obj.regions = obj.regions.reduce((prev,next) => {
		let obj = prev.filter(obj => obj.continent === next.continent);
		if (obj.length === 0) {
			prev.push(next);
		}
		return prev;
	},[])
	positions.forEach(position => {
		obj.regions = calculateRegionAllocation(obj.regions,position.security);
	})
	obj.regions = limitNumberOfRegions(obj.regions);
	return obj;
}


export default formatAllocation;