import {
  TRANSACTION_HISTORY_SECURITY,
  TRANSACTION_HISTORY_OVERVIEW,
  TRANSACTION_HISTORY_FUNDING,
  TRANSACTION_HISTORY_MONTHLY_FEE,
  TRANSACTION_HISTORY_WITHDRAWAL
} from "../../../actions/TransactionHistory/TransactionHistoryType";

const mockData = {
  overview: {
    investments: "",
    performance: "",
    withdrawals: ""
  },
  details: [],
  offSet: 0,
  isLoadMore: true,
  totalFundingAmount: 0,
  totalWithdrawalAmount: 0,
  security: {
    goals: [],
    netAmount: 0,
    sellAmount: 0,
    buyAmount: 0,
    transactionDate: "",
    transactionName: ""

  },
  transactionData: {
    goals: [],
    sender: {
      accountName: "",
      bankName: "",
      bicSwift: "",
      iban: ""
    },
    transactionDate: "",
    transactionName: "",
  },
  monthly: {
    calculatedTotalMonthlyFee: 0,
    portfolioManagementFee: 0,
    productFee: 0,
    referralDiscount: 0,
    totalAuMAmount: 0,
    totalFee: 0,
    totalMonthlyFee: 0,
    transactionDate: "",
    transactionName: ""
  }
};

export default function(data = mockData, actions) {
  switch (actions.type) {
    case TRANSACTION_HISTORY_OVERVIEW:
      return {
        ...data,
        details: actions.payload.Details,
        isLoadMore: actions.payload.isLoadMore,
        offSet: actions.payload.offSet,
        overview: {
          ...data.overview,
          investments: actions.payload.Investments,
          performance: actions.payload.Performance,
          withdrawals: actions.payload.Withdrawals
        }
      };
    case TRANSACTION_HISTORY_SECURITY:
      return {
        ...data,
        security: {
          ...data.security,
          goals: actions.payload.Goals,
          netAmount: actions.payload.NetAmount,
          sellAmount: actions.payload.SellAmount,
          buyAmount: actions.payload.BuyAmount,
          transactionDate: actions.payload.TransactionDate,
          transactionName: actions.payload.TransactionName
        }
      };
    case TRANSACTION_HISTORY_FUNDING:
      return {
        ...data,
        transactionData: {
          ...data.transactionData,
          goals: actions.payload.Goals,
          sender: {
            ...data.transactionData.sender,
            accountName: actions.payload.Sender.AccountName,
            bankName: actions.payload.Sender.BankName,
            bicSwift: actions.payload.Sender.BicSwift,
            iban: actions.payload.Sender.Iban
          },
          transactionDate: actions.payload.TransactionDate,
          transactionName: actions.payload.TransactionName,
        },
        totalFundingAmount: actions.payload.TotalFundingAmount
      };
    case TRANSACTION_HISTORY_MONTHLY_FEE:
      return {
        ...data,
        monthly: {
          ...data.monthly,
          calculatedTotalMonthlyFee: actions.payload.CalculatedTotalMonthlyFee,
          portfolioManagementFee: actions.payload.PortfolioManagementFee,
          productFee: actions.payload.ProductFee,
          referralDiscount: actions.payload.ReferralDiscount,
          totalAuMAmount: actions.payload.TotalAuMAmount,
          totalFee: actions.payload.TotalFee,
          totalMonthlyFee: actions.payload.TotalMonthlyFee,
          transactionDate: actions.payload.TransactionDate,
          transactionName: actions.payload.TransactionName
        }
      };
    case TRANSACTION_HISTORY_WITHDRAWAL:
      return {
        ...data,
        transactionData: {
          ...data.transactionData,
          goals: actions.payload.Goals,
          sender: {
            ...data.transactionData.sender,
            accountName: actions.payload.Sender.AccountName,
            bankName: actions.payload.Sender.BankName,
            bicSwift: actions.payload.Sender.BicSwift,
            iban: actions.payload.Sender.Iban
          },
          transactionDate: actions.payload.TransactionDate,
          transactionName: actions.payload.TransactionName,
        },        
        totalWithdrawalAmount: actions.payload.TotalWithdrawalAmount
      };
    default:
      return data;
  }
}
