import React from 'react';
import { withRouter } from "react-router-dom";

import withBaseLayout from '../../HoC/withBaseLayout';
import { connect } from "react-redux";
import { getDefaultGoalsStrategy, getOnBoardingDefaultGoals } from '../../../actions/Goals/GoalActions';
import GoalStrategy from '../../Goals/GoalStrategy/GoalStrategy';
import { switchHeader } from '../../../actions/Header/HeaderActions';
import changeLanguage from '../../../setting/languages/languages';
import CONFIG from '../../../setting/config';
import Stepper from '../RiskProfiling/Stepper/Stepper';
import GoalFooter from '../../Goals/GoalFooter/GoalFooter';
import { switchScreen, savePreviousScreen } from '../../../actions/Screen/ScreenAction';
import { toggleAsyncLoadingStatus } from '../../../actions/AppMain/AppMainAction';


class InvestmentProposal extends React.Component {

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.state = {
      activeStep: 5,
      totalSteps: 8
    };
    this.onClickNext = this.onClickNext.bind(this);
  }

  onClickNext() {
    this.props.toggleAsyncLoadingStatus({ status: true, text: 'Loading' });
    
    let timerSubmit = window.setTimeout(() => {
      window.clearTimeout(timerSubmit);
      this.props.toggleAsyncLoadingStatus({ status: false, text: 'Loading' });

      if (this.props.onboardingData.testParams.responsePostContactPortfolios === 504) {
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.GOAL_CALCULATOR,
          state: {}
        });
        return;
      }
      if (this.props.onboardingData.testParams.responsePostContactPortfolios !== 200) {
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.VERIFY.INVESTMENT_PROPOSAL.FAILED,
          state: {
            title: this.textStore.investmentProposal.goalCreateFailed,
            desc: this.textStore.investmentProposal.goalCreatationNotSuccess,
            buttonName: this.textStore.common.backToVerification
          }
        });
        return;
      } 
      this.props.history.push({
        pathname: this.routesName.ONBOARDING.PERSONAL_PARTICULAR,
        state: {}
      });
    }, 1000);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.switchScreen(this.textStore.screen.investmentProposal);
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: { type: "text", text: this.textStore.common.classicRoboTitle },
      headerRight: "",
      currentPage: this.textStore.screen.InvestmentProposal
    });
    this.props.getDefaultGoalsStrategy();
  }

  componentWillMount() {
    this.props.savePreviousScreen(this.textStore.screen.investmentProposal);
  }

  render() {
    return (
      <React.Fragment>
         <Stepper 
            activeStep={this.state.activeStep} 
            totalSteps={this.state.totalSteps}
            textStore={this.textStore} 
            headerLeft={this.textStore.common.investmentStrategy}
            />
        <div className='investment-proposal'>
         <GoalStrategy
          language={this.props.language}
          strategy={this.props.goalData.strategy}
          id={this.props.goalDetailData.id}
          screen={this.textStore.screen.InvestmentProposal}
          advisedText={this.props.onboardingData.calculateRisk.riskCategory.Name}
         ></GoalStrategy>
           <GoalFooter
            nextButtonText={this.textStore.common.continue}
            onClickNext={this.onClickNext}
            renderBackButton={false}
            nextButtonFullWidth={true}
          />
        </div>

      </React.Fragment>
    )
  }

  
}

const InvestmentProposalEnhanced = withBaseLayout(InvestmentProposal);

function mapStateToProps(props) {
  return {
    onboardingData: props.onboardingData,
    // appMainUI: props.appMainUI,
    goalData: props.goalData,
	  goalDetailData:props.goalDetailData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getDefaultGoalsStrategy  ,
    getOnBoardingDefaultGoals,
    switchHeader,
    changeLanguage,
    switchScreen,
    savePreviousScreen,
    toggleAsyncLoadingStatus,
  })(InvestmentProposalEnhanced)
);