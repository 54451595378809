import React from 'react';
import { Link } from 'react-router-dom';

import angleBoldRight from '../../../css/images/icons/arrow-list.svg';

import './styles.less';

export default class DocumentList extends React.PureComponent {
  render() {
    const { list } = this.props;
    
    return (
      <div className="document__list">
        {
          list.map((ele) => (
            <Link key={ele.id} to="/settings/account" className="document__list-item">
              <img className="document__list-item-icon" src={ele.icon} alt="email" />
              <span className="document__list-item-text">{ele.text}</span>
              <img className="document__list-item-goto-icon" src={angleBoldRight} alt="goto" />
            </Link>
          ))
        }
      </div>
    )
  }
}