import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withBaseLayout from "../../HoC/withBaseLayout";
import GoalFooter from "../../Goals/GoalFooter/GoalFooter";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import GoalTransfer from "../../Goals/GoalTransfer/GoalTransfer";
import TabBarProgress from "../../GoalDetail/TabBar/TabBarProgress";
import { formatTabBarItems } from "../../../helpers/formatTabBarItems";
import { getDefaultFundingPlanned, getDefaultFundingInstructions } from "../../../actions/Funding/FundingActions";
import GoalInstructions from "../../Goals/GoalInstructions/GoalInstruction";
import CONFIG from "../../../setting/config";
import changeLanguage from "../../../setting/languages/languages";

class PlannedTransfer extends React.Component {
  constructor(props){
    super(props)
    this.textStore = changeLanguage(props.language);
    this.state = {
      tabs: formatTabBarItems([this.textStore.common.planned, this.textStore.common.instructions]),
      tabIndexer:0,
      currentTab: formatTabBarItems([this.textStore.common.planned, this.textStore.common.instructions])[0].title
    }
    this.routesName = CONFIG.APP_ROUTE;
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
  }
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "menu",
      headerMiddle: { type: "text", text: this.textStore.plannedTransfer.plannedInvestment },
      headerRight: "",
      currentPage: "funding"
    });
    this.props.getDefaultFundingPlanned();
    this.props.getDefaultFundingInstructions();
  }

  renderSections() {
    switch (this.state.currentTab) {
      case this.textStore.common.planned:
        return (
          <React.Fragment>
            <GoalTransfer
              totalInitialInvestmentAmount={
                this.props.fundingData.totalInitialInvestmentAmount
              }
              totalMonthlyInvestmentAmount={
                this.props.fundingData.totalMonthlyInvestmentAmount
              }
              transfer={this.props.fundingData.transfer}
            />
          </React.Fragment>
        );
      case this.textStore.common.instructions:
        return (
          <React.Fragment>
            <GoalInstructions
              totalOneTransferAmount={
                this.props.fundingData.totalInitialInvestmentAmount
              }
              totalMonthlyAmount={
                this.props.fundingData.totalMonthlyInvestmentAmount
              }
              instructions={this.props.fundingData.instructions}
            />
          </React.Fragment>
        )
      default:
        return;
    }
  }

  onClickBack(){
    switch(this.state.currentTab){
      case this.state.tabs[this.state.tabIndexer].title:
        let newTabs = [...this.state.tabs];
        if(this.state.tabIndexer !== 0){
          newTabs[this.state.tabIndexer].currentTabItemState = ''
          newTabs[this.state.tabIndexer - 1].currentTabItemState = 'active'
          this.setState({
            currentTab:this.state.tabs[this.state.tabIndexer - 1].title,
            tabIndexer: this.state.tabIndexer - 1,
            tabs: newTabs
          })
        }
        break;
      default:
        return;
  }
}

  onClickNext() {
    switch(this.state.currentTab){
      case this.state.tabs[this.state.tabIndexer].title:
        let newTabs = [...this.state.tabs];
        if(this.state.tabIndexer !== this.state.tabs.length - 1){
          newTabs[this.state.tabIndexer].currentTabItemState = 'complete'
          newTabs[this.state.tabIndexer + 1].currentTabItemState = 'active'
          this.setState({
            currentTab:this.state.tabs[this.state.tabIndexer + 1].title,
            tabIndexer: this.state.tabIndexer + 1,
            tabs: newTabs
          })
        }else{
          this.props.history.push({
            pathname: this.routesName.GOALS.COMPLETE,
            state: {
              icon: "icn icn--confirmation",
              desc: this.textStore.common.aCopyOfTransferInstruction,
            }
          });
        }
        break;
      default:
        return;
    }
  }
  render() {
    return (
      <React.Fragment>
        <nav className="tablist sticky">
          <TabBarProgress tabs={this.state.tabs}/>
        </nav>
        <div className="tabs">{this.renderSections()}</div>
        <GoalFooter renderBackButton={this.state.tabIndexer !== 0} onClickBack={this.onClickBack} onClickNext={this.onClickNext} />
      </React.Fragment>
    );
  }
}

const EnhancedPlannedTransfer = withBaseLayout(PlannedTransfer);

function mapStateToProps(props) {
  return {
    tabBarUI: props.tabBarUI,
    fundingData: props.fundingData,
  };
}

export default withRouter(connect(
  mapStateToProps,
  { switchHeader, getDefaultFundingPlanned, getDefaultFundingInstructions }
)(EnhancedPlannedTransfer));
