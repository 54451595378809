import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import GoalStrategy from "../Goals/GoalStrategy/GoalStrategy";
import { switchHeader } from "../../actions/Header/HeaderActions";
import RangeSlider from "../common/RangeSlider/RangeSlider";
import withBaseLayout from "../HoC/withBaseLayout";
import PopUp from "../common/Popup/PopUp";
import {
  changeGoalStrategy,
  getDefaultGoalsStrategy,
  editGoalStrategy,
  getGoalCalculate
} from "../../actions/Goals/GoalActions";
import GoalFooter from "../Goals/GoalFooter/GoalFooter";
import changeLanguage from "../../setting/languages/languages";
import { switchScreen } from "../../actions/Screen/ScreenAction";

class EditStrategy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeValue: 0,
      warning: false,
      riskName: ''
    };
    this.textStore = changeLanguage(props.language)
    this.handleUnfocused = this.handleUnfocused.bind(this);
    this.onclosePopUp = this.onclosePopUp.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.handleValue = this.handleValue.bind(this);
    this.getStrategy = this.getStrategy.bind(this);
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "close",
      headerMiddle: {
        type: "text",
        text: this.textStore.editStrategy.changeStrategy,
      },
      headerRight: "",
      currentPage: "editStrategy"
    });
    if(this.props.goalData.strategy.calculatedExpectedReturn === 0) {
      this.props.getDefaultGoalsStrategy();
    } 
    if (this.props.tabBarUI.currentTab !== 'Strategy') {
      this.getStrategy(this.props.goalDetailData.planning.investmenStrategy);
    } else {
      this.setState({rangeValue: sessionStorage.getItem('rangeValue') ?  sessionStorage.getItem('rangeValue'): 0})
    }
    this.props.getGoalCalculate();
    this.props.switchScreen(this.textStore.screen.editStrategy);
  }

  getStrategy(investmentStrategy) {
    switch(investmentStrategy) {
      case 'Very conservative':
        this.setState({rangeValue: 0})
        break;
      case 'Conservative':
        this.setState({rangeValue: 30})
        break;
      case 'Moderate':
        this.setState({rangeValue: 50})
        break;
      case 'Aggressive':
        this.setState({rangeValue: 70})
        break;
      case 'Very aggressive':
        this.setState({rangeValue: 90})
        break;
      default:
        break;
    }
    this.props.changeGoalStrategy(investmentStrategy, this.props.goalDetailData.currentValue);
  }

  getRiskName(value) {
    if (value < 20) {
      return 'Very conservative';
    } if (20 <= value && value < 40) {
      return 'Conservative';
    } if (40 <= value && value < 60) {
      return 'Moderate';
    } if (60 <= value && value < 80) {
      return 'Aggressive';
    }
    return 'Very aggressive';
  }

  handleValue(value) {
    const goalType = this.getRiskName(value);
    this.setState({rangeValue: value})
    if (goalType !== this.state.riskName) {
      this.setState({riskName: goalType}, () => {
        this.props.changeGoalStrategy(goalType, this.props.goalDetailData.currentValue);
      });
    }
  }

  onclosePopUp() {
    this.setState({ warning: false });
  }

  onClickNext() {
    let index = this.props.goalDetailData.id;
    this.props.history.push({ 
      pathname: `/goaldetail/${index}/editstrategy/completed`, 
      state: { 
        icon: 'icn icn--right-tick', 
        desc: this.textStore.common.goalUpdatedSuccess,
      } 
    })
  }

  handleUnfocused(warning, value) {
    const goalType = this.getRiskName(value);
    let rangeValue = 50;
      switch (goalType) {
        case "Very conservative":
          rangeValue = 0;
          break;
        case "Conservative":
          rangeValue = 30;
          break;
        case "Moderate":
          rangeValue = 50;
          break;
        case "Aggressive":
          rangeValue = 70;
          break;
        case "Very aggressive":
          rangeValue = 100;
          break;
        default:
          rangeValue = 50;
          break;
      }
      this.setState({rangeValue: rangeValue})
    if (goalType !== this.state.riskName) {
      this.props.changeGoalStrategy(goalType, this.props.goalDetailData.currentValue);
    }
    this.setState({ warning: warning });
  }

  renderPopUp() {
    if (this.state.warning) {
      return (
        <PopUp
          language={this.props.language}
          onConfirmClick={this.onclosePopUp}
          closePopUp={this.onclosePopUp}
          message={this.textStore.editStrategy.riskToleranceExceed}
          rightButtonText={this.textStore.common.confirm}
        />
      );
    }
  }
  render() {
    const riskName = this.props.goalData.strategy.riskCategoryName;
    const advise = this.props.goalData.goalCalculate.calculatedInvestmentStrategy;
    return (
      <React.Fragment>
        <div className="content-main__body">
          {this.renderPopUp()}
          <RangeSlider
            language={this.props.language}
            highRiskThreshold={80}
            value={this.state.rangeValue}
            renderPopUp={this.renderPopUp}
            handleUnfocused={this.handleUnfocused}
            handleValue={this.handleValue}
            risk={riskName}
            advise={advise === riskName ? this.textStore.common.recommended : ''}
          />
          <GoalStrategy
            language={this.props.language}
            strategy={this.props.goalData.strategy}
            id={this.props.goalDetailData.id}
            screen={this.props.screenUI.currentScreen}
            isEditStrategy={true}
            advisedText={advise === riskName ? this.textStore.editStrategy.recommendedStrategy : ''}
          />
        </div>
        <div className="content-main__footer">
          <GoalFooter 
            renderBackButton={false} 
            onClickNext={this.onClickNext}
          />
        </div>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    // TODO: Will remove when integrate with API
    sessionStorage.setItem('rangeValue', this.state.rangeValue);
  }
}

function mapStatesToProps(props) {
  return {
    goalData: props.goalData,
    goalDetailData: props.goalDetailData,
    tabBarUI:props.tabBarUI,
    screenUI: props.screenUI
  };
}

const EnhancedEditStrategy = withBaseLayout(EditStrategy);

export default withRouter(connect(
  mapStatesToProps,
  { switchHeader, changeGoalStrategy, getDefaultGoalsStrategy, editGoalStrategy, getGoalCalculate, switchScreen }
)(EnhancedEditStrategy));
