import React from 'react';
import { connect } from 'react-redux';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { invertLayout } from "../../../actions/AppMain/AppMainAction";

import HelperBanner from '../HelperBanner';
import SupportHelpful from '../SupportHelpful';
import SupportConfirm from '../SupportConfirm';

import './styles.less';

class SupportDetail extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      openConfirm: false,
      confirmClick: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Goal-based investing' },
      headerRight: '',
      currentPage: 'SupportDetail',
    });
  }

  onAnswer = (ans) => {
    this.setState({ openConfirm: true });
  }

  onConfirm = () => {
    this.setState({ confirmClick: true })
  }

  render() {
    const { openConfirm, confirmClick } = this.state;

    return (
      <React.Fragment>
        {
          openConfirm && !confirmClick ? <SupportConfirm onClick={this.onConfirm}/> : ''
        }
        <div className="support">
          <div className="support-detail">
            <div className="support-detail__title">What is goal-based investing?</div>
            <div className="support-detail__desc">Goals-based investing is an approach which aims to help people meet their personal and lifestyle goals, whatever they may be, in a straightforward and simple way. It does this by placing people’s goals right at the centre of the advice process and aims to build investment products that do the same.</div>
          </div>
        </div>
        <div className="content-main__footer feedback-footer">
          {
            !openConfirm ? (
              <SupportHelpful onClick={this.onAnswer} />
            ) : ''
          }
          <HelperBanner />
        </div>
      </React.Fragment>
    )
  }
}

export default connect(null, { switchHeader, invertLayout })(withBaseLayout(SupportDetail));