import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import userMultiple from '../../../css/images/icons-png/user-multiple.png';
import dotsDefault from '../../../css/images/icons/dots-default.svg';

import MessagesDetail from '../MessagesDetail';

import './styles.less';

const data = {
  time: 'Yesterday',
  text: 'RE: Infomation about my goal',
  icon: userMultiple
}

class CustomerSupport extends React.PureComponent {
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Customer support' },
      headerRight: '',
      currentPage: 'CustomerSupport',
    });
  }

  render() {
    return (
      <MessagesDetail data={data} hideReview="true">
        <div>
          <p>Dear Carlos</p>
          <br />
          <p>Thank you for reaching out to us.</p>
          <br />
          <p>Our team noticed that the changes for your <b>“Kid’s education”</b> investment goal haven’t been processed yet. Please allow 2-3 business day processing time. We appreciate your patience.</p>
          <br />
          <p>If you have any further questions or concerns, let us know. We are here 24/7 and always happy to help. Thanks for being a loyal <b>Classic Robo’s</b> customer.</p>
          <br />
          <p>Best regards,</p>
          <p>Classic Robo Operator Support</p>

          <div className="messages-detail__quote">
            <img className="messages-detail__quote-dots" src={dotsDefault} />
            <div className="messages-detail__quote-content">
              <p>Dear Classic Robo Operator Support,</p>
              <br />
              <p>I got trouble when processing for the goal: <b>“Kid’s education”</b>. Please kindly help me to check this case. </p>
              <br />
              <p>Best regards,</p>
              <p>Classic Robo Operator Support</p>
            </div>
          </div>
        </div>
      </MessagesDetail>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(withRouter(CustomerSupport)));