import {
	PASS_DATA_FROM_PERFORMANCE_GOALDETAIL
} from './PositionActionType'


export function passDataFromPerformanceGoalDetail(data){
	return {
		type: PASS_DATA_FROM_PERFORMANCE_GOALDETAIL,
		payload: data
	}
}