import exclamation from '../../css/images/icons-png/exclamation-triangle-line.png';
import support from '../../css/images/icons-png/support.png';
import trophy from '../../css/images/icons-png/trophy.png';
import arrowInbox from '../../css/images/icons-png/arrow-in-box.png';
import fileSingle from '../../css/images/icons-png/file-single-alt.png';

export const messages = [
  {
    id: '1',
    time: '11:47 AM',
    text: 'Action required: Your risk profile has been expired',
    icon: exclamation,
    link: '/messages/risk-profile-expired'
  }, {
    id: '3',
    time: 'Yesterday',
    text: 'Congratulations, your "Kid\'s education" goal reached USD 80,000',
    icon: trophy,
    link: '/messages/goal-milestone'
  }, {
    id: '4',
    time: '02/11/2018',
    text: 'Action required: Review and adjust your "Kid\'s education" goal',
    icon: exclamation,
    link: '/messages/goal-alert'
  }, {
    id: '5',
    time: '12/10/2018',
    text: 'USD 10,000 funding received',
    icon: arrowInbox,
    link: '/messages/security-transaction'
  }, {
    id: '6',
    time: '30/09/2018',
    text: 'New tax statement is available',
    icon: fileSingle,
    link: '/messages/new-document'
  }
];