import React from "react";
import PropTypes from "prop-types";
import changeLanguage from "../../../setting/languages/languages";

export const fnSortCountriesByName = contries => {
  return contries.sort((a, b) => {
    const nameF = a.Name.toUpperCase();
    const nameL = b.Name.toUpperCase();
    if (nameF < nameL) {
      return -1;
    }
    if (nameF > nameL) {
      return 1;
    }
    return 0;
  });
}
class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flagClass: "",
      isShowDropdownModal: false,
      countrySelected: {},
      searchText: '',
      errorPhoneCondition: false
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.findIndexOfItem = this.findIndexOfItem.bind(this);
    this.getElementByIndex = this.getElementByIndex.bind(this);
    this.textStore = changeLanguage(props.language);

  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (this.props.selectedIndex >= 0) {
      let selectedItem = this.getElementByIndex(this.props.selectedIndex);
      this.onSelectItem(selectedItem);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  findIndexOfItem(obj) {
    return this.props.items.indexOf(obj);
  }

  getElementByIndex(index) {
    return this.props.items[index];
  }

  onSelectItem(element) {
    if (this.props.hasIcon) {
      this.setState(() => ({
        countrySelected: {
          ...element
        },
        isShowDropdownModal: false
      }));

      if (this.state.errorPhoneCondition) {
        this.setState({ errorPhoneCondition: false });
        this.props.errorDialCode(false);
      }
      let index = this.findIndexOfItem(element);
      this.props.updateSelectedCountryIndex(index);
    }
  }

  selectDropdown() {
    if (this.props.hasIcon) {
      if (Object.keys(this.state.countrySelected).length === 0) {
        this.props.errorDialCode(true);
      } else {
        this.props.errorDialCode(false);
      }
      this.setState({ isShowDropdownModal: true });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(e) {
    if (this.props.hasIcon) {
      if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
        this.setState({ isShowDropdownModal: false, searchText: "" });
      }

      if (Object.keys(this.state.countrySelected).length === 0) {
        if (this.props.activeError && this.props.activeError[3]) {
          this.setState({ errorPhoneCondition: true });
        }
      }
    }
  }

  onHandleSearch = (e) => {
    this.setState({ searchText: e.target.value.toLowerCase() });
  }

  sortCountriesByName = fnSortCountriesByName;

  renderListItems() {
    const { searchText } = this.state;
    const items = this.sortCountriesByName(this.props.items);
    const countries = !searchText.length ? items : items.filter(item => {
      if (item.Name.toLowerCase().indexOf(searchText) !== -1) {
        return item.Name.toLowerCase().indexOf(searchText) !== -1;
      } else {
        return item.DialCode && item.DialCode.indexOf(searchText) !== -1;
      }
    });

    return (
      this.state.isShowDropdownModal && (
        <React.Fragment>
          <div
            className="modal"
            id="country-code">
            <div className="modal-container" ref={this.setWrapperRef}>
              <div className="modal-content-main">
                <div className="search-header">
                  <div className="search-group">
                    <div className="search-content">
                      <span className="icn icn--search--default" />
                    </div>
                    <input
                      className="onboarding-input-field__full__large input-search"
                      id="phoneNumber"
                      type="text"
                      maxLength={13}
                      autoComplete="off"
                      placeholder="Search ..."
                      onChange={this.onHandleSearch}
                    />
                  </div>
                </div>
                {this.renderModalBody(countries)}
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade in" />
        </React.Fragment>
      )
    )
  }

  renderModalBody = coutries => {
    const items = this.sortCountriesByName(coutries);
    return (
      items.length !== 0 ? (
        <div className="country-body">
          {items.map(el => (
            <div className="country-row" key={el.FlagIcon} onClick={this.onSelectItem.bind(this, el)}>
              <div className="line">
                <div className="flag-content">
                  <span className={`flag ${el.FlagIcon}-flag`} />
                </div>
                {this.state.countrySelected && this.state.countrySelected.FlagIcon !== el.FlagIcon ? (
                  <div className="country-name none-active-name">{el.Name} {el.DialCode ? `(${el.DialCode})`: ''} </div>
                ) : <div className="country-name active-name">{el.Name} {el.DialCode ? `(${el.DialCode})`: ''}</div>}
                <div className="icon-content">
                  {
                    this.state.countrySelected.FlagIcon === el.FlagIcon ? (
                      <span className="icon check-sticker" />
                    ) : null
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (<div className="country-body">
        <div className="country-row-no-result-message">{this.textStore.common.noMatchingResult}</div>
      </div>)
    )
  }

  renderShortItems() {
    const items = this.sortCountriesByName(this.props.items);
    return (
      this.state.isShowDropdownModal ? (
        <div className="dropdown-container" ref={this.setWrapperRef}>
          {this.renderModalBody(items)}
        </div>
      ) : null
    )
  }

  renderNoIconItems() {
    if (this.props.fullWidth) {
      return this.props.items.map(opt => {
        return (
          <option key={opt.FlagIcon} value={opt.FlagIcon}>
            {opt.Name}
          </option>
        )
      });
    } else {
      return this.props.items.map((el, id) => (
        <option key={id} value={id}>
          {el}
        </option>
      ));
    }
  }

  renderDropdown() {
    if (this.props.renderDropdown) {
      const { errorPhoneCondition, countrySelected } = this.state;
      return this.props.renderDropdown({
        errorPhoneCondition,
        countrySelected,
        selectDropdown: this.selectDropdown.bind(this),
        renderListItems: () => this.renderListItems(),
        renderShortItems: () => this.renderShortItems(),
        items: this.props.items

      })
    } else if (!this.props.hasIcon) {
      return (
        <React.Fragment>
          <select
            className={this.props.styledClass}
            onChange={this.props.onItemClick}
          >
            {this.renderNoIconItems()}
          </select>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className={`${
              this.state.errorPhoneCondition
                ? "phone-number-drop-down border-error__bottom"
                : "phone-number-drop-down"
              }`}
            onClick={this.selectDropdown.bind(this)}
          >
            {this.state.countrySelected.FlagIcon ? (
              <div className="phone-number-flag__container">
                <div
                  className={`phone-number-flag ${
                    this.state.countrySelected.FlagIcon
                    }-flag`}
                />
              </div>
            ) : (
                this.textStore.common.dropdownSelect
              )}
            <span className="dial-code">
              {this.state.countrySelected.DialCode}
            </span>
          </div>
          {
            this.props.items.length >= 8
              ? this.renderListItems()
              : this.renderShortItems()
          }
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDropdown()}
      </React.Fragment>
    );
  }
}

DropDown.defaultProps = {
  flagClass: "",
  styleClass: "",
  errorClass: "",
  hasIcon: false,
  renderDropdown: null
};

DropDown.propTypes = {
  flagClass: PropTypes.string,
  styleClass: PropTypes.string,
  errorClass: PropTypes.string,
  hasIcon: PropTypes.bool,
  errorDialCode: PropTypes.func,
  renderDropdown: PropTypes.func
};

export default DropDown;
