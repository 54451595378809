import React from 'react';


class HeaderMiddleIcon extends React.Component {

  renderMiddleIcon(){
    switch(this.props.headerMiddle.type){
      case 'text':
        return (
          <h1 className="header-title" style={this.props.headerMiddle.styles}>
            {this.props.headerMiddle.text}
          </h1>
        )
      case 'logo':
        return (
          <h1 className="header-title">
            <span
              className="icn icn--dashboard-title"
              style={{
                backgroundImage: sessionStorage.getItem("logos") ? `url(${sessionStorage.getItem("logos")})` :
                  "url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/config%2Flogos%2FCR_Black.png?alt=media&token=005d19dc-7740-4fdc-b8b1-3f46edaf1215)"
              }}
            >
            </span>
          </h1>
        )
      default:
        return;
    }
  }
  render(){
    return (
      <div className="header-item header-item--header-title">
        {this.renderMiddleIcon()}
      </div>
    )
  }
}

export default HeaderMiddleIcon;