const data = 
[
	{
		"data": [
			{
				"color": "#E64F97",
				"name": "Fixed income",
				"y": 40.5,
			}, {
				"color": "#5AB1FB",
				"name": "Equities",
				"y": 53.5,
			}, {
				"color": "#6D41B8",
				"name": "Non-traditional",
				"y":null,
			}
		],
		"innerSize": "70%",
		"dataLabels": {
			"enabled": false
		}
	}
]
const config = {
	exporting: { enabled: false },
	chart: {
		type: 'pie',
		margin: [0, 0, 0, 0],
		backgroundColor: "transparent",
		zoomType : false,
    animation: false
	},
	title: {
		text: ''
	},
	subtitle: {
		text: ''
	},
	legend: {
		enabled: false
	},
	credits: {
		enabled: false
	},
	tooltip: {
		enabled: false
	},
	plotOptions: {
		pie: {
			slicedOffset: 0,
			size: '100%',
			dataLabels: {
				enabled: false
			}
		},
		series: {
			animation: false,
			states: {
				hover: {
					enabled: false
				}
			}
		}
	},
	series: data
}

export default config

