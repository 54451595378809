import React from "react";
import PropTypes from "prop-types";
import {
  formatCurrency,
  unformatCurrency
} from "../../../helpers/formatCurrency";
import Slider from '@material-ui/lab/Slider';
import LogSlider from "./LogSlider";
import changeLanguage from "../../../setting/languages/languages";
import TextInput from "../CustomInput/TextInput/TextInput";

const rangeRounded =  0.4999;

class RangeSliderWithInput extends React.Component {
  constructor(props) {
    super(props);
    
    const { value, minValue, maxValue, isExponential, name, language } = props;

    this.increaseRangeValue = minValue < 1000 && isExponential ? 1000 : 0;

    this.logSlider = new LogSlider({
      maxPos: 1000,
      minVal: minValue + this.increaseRangeValue,
      maxVal: maxValue + this.increaseRangeValue,
    });

    const tmpValue = value || minValue;
    this.step = !props.isExponential ? props.step : 100;
    const position = props.isExponential ? this.logSlider.position(tmpValue + this.increaseRangeValue) : tmpValue;
      
    this.state = {
      inputWidth:'40px',
      value: formatCurrency(value, 0) || formatCurrency(minValue, 0),
      tmpValue: value || minValue,
      position: position,
      isFirstClick: true,
    };

    this.currency = "USD";
    this.textStore = changeLanguage(language);
    this.inputName = name || Math.random();
    this.numberPatternOnly = /\D/;
  }
  
  componentDidMount() {
    this.adjustWidth(this.props.value || this.props.minValue);
  }

  onMouseUp = () => {
    this.props.onMouseUp();
  }

  onSliderChange = (e, position) => {
    let tmpValue = this.props.isExponential ? this.logSlider.value(position) : position;

    if (tmpValue < this.increaseRangeValue) {
      tmpValue = 0;
    } 

    const value = Math.round((tmpValue / this.step) + rangeRounded) * this.step;
    
    this.setState({
      value: formatCurrency(value, 0),
      tmpValue: formatCurrency(value, 0),
      position: position,
    }, () => {
      this.props.changeValue(value, this.props.title);
      this.adjustWidth(value);
    });
  }

  onTextChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const tmpValue = Math.round((value / this.step) + rangeRounded) * this.step;
    const position = this.props.isExponential 
      ? this.logSlider.position(tmpValue + this.increaseRangeValue) 
      : (tmpValue + this.increaseRangeValue);

    if (!this.numberPatternOnly.test(value)) {
      this.setState({
        value: value,
        tmpValue: tmpValue,
        position: position,
      }, () => {
        this.adjustWidth(this.state.value);
      });
    }
  }

  onTextBlur = (e) => {
    const { tmpValue } = this.state;
    const value = unformatCurrency(tmpValue);

    let newValue = value;
    if (value !== "") {
      if (parseInt(value) > this.props.maxValue) {
        newValue = this.props.maxValue;
      } else if (parseInt(value) < this.props.minValue) {
        newValue = this.props.minValue;
      }
    } else {
      newValue = this.props.minValue;
    }
    this.updateChangeValue(newValue);

    this.setState({ isFirstClick: true });
  }

  onTextClick = (evt) => {
    evt.preventDefault();

    if (!this.state.isFirstClick) { return; }

    const { value } = this.state;

    this.setState({ 
      isFirstClick: false, 
      tmpValue: unformatCurrency(value), value: ''
    }, () => {
      this.adjustWidth(this.state.value);
    });
  }

  updateChangeValue = (newValue) => {
    this.setState({ value: formatCurrency(newValue, 0) }, () => {
      this.props.updateOnboardingChart(this.state.value, this.props.title);
      this.adjustWidth(this.state.value);
    });
  };

  renderRightText() {
    switch (this.props.rightText) {
      case "Years":
        return <div className="input-slider--title__date">Year(s)</div>;
      default:
        return (
          <div className="input-slider--title__currency">{this.currency}</div>
        );
    }
  }


  adjustWidth = (text) => {
    const hidden = document.getElementById(`${this.inputName}-hidden`);
    hidden.innerHTML = formatCurrency(text, 0);

    const inputEle = document.getElementById(this.inputName);
    const hiddenWidth = hidden.clientWidth < 40 ? 40 : hidden.clientWidth;
    inputEle.style.width = `${hiddenWidth}px`;
  }

  render() {
    const { value } = this.state;
    
    return (
      <React.Fragment>
        <div className="input-slider--title__container">
          <span className="input-slider--title__text">{this.props.title}</span>
          <div className="input-slider--right" style={{ width: this.props.inputWidth, textAlign: 'center' }}>
            <span className="range-slider-input-hidden-text" id={`${this.inputName}-hidden`}>{value}</span>
            <TextInput
              className="input-slider--title__input"
              type="text"
              value={value}
              style={{ width: this.state.inputWidth }}
              id={this.inputName}
              maxLength={this.props.maxValue.length}
              numericKeyboard={true}
              onChange={this.onTextChange}
              onFocus={this.onTextFocus}
              onBlur={this.onTextBlur}
              onClick={this.onTextClick}
              onKeyUp={this.onKeyUp}
            />
            {
              this.renderRightText()
            }
          </div>
        </div>
        <Slider
          classes={
            {
              root: 'range-slider__init',
              trackBefore: 'range-slider__track before',
              trackAfter: 'range-slider__track after',
              thumb: 'range-slider__thumb',
              thumbIcon: 'range-slider__thumb icon',
              activated: 'range-slider__activated'
            }
          }
          style={{
            marginTop: "10px",
            touchAction: 'none'
          }}
          // value={this.props.isExponential ? this.state.position : this.state.value || this.state.tmpValue}
          value={this.state.position}
          min={this.props.isExponential ? 0 : this.props.minValue}
          max={this.props.isExponential ? 1000 : this.props.maxValue}
          onChange={this.onSliderChange}
          onDragEnd={this.onMouseUp}
          onTouchEnd={this.onMouseUp}
        />
        <div className="input-slider--range-value">
          <span>{formatCurrency(this.props.minValue, 0)}</span>
          <span>{formatCurrency(this.props.maxValue, 0)}</span>
        </div>
      </React.Fragment>
    );
  }
}

RangeSliderWithInput.defaultProps = {
  value: 0,
  position: 0,
  title: "Title",
  currency: "",
  dateTime: "",
  minValue: 5000,
  maxValue: 100000,
  isExponential: false,
};

RangeSliderWithInput.propTypes = {
  value: PropTypes.number,
  position: PropTypes.number,
  title: PropTypes.string,
  currency: PropTypes.string,
  dateTime: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  inputWidth: PropTypes.string,
  isExponential: PropTypes.bool,
};

export default RangeSliderWithInput;
