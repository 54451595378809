import {
	PERSIST_USER,
	SIGN_OUT
} from '../../actions/Auth/AuthActionType';

//subjected to change
const data = {
	useremail:'',
	isSignIn:false
}

export default function(states = data,actions){
	switch(actions.type){
		case PERSIST_USER:
			return { ...states,isSignIn:actions.payload.isSignIn,useremail:actions.payload.useremail };
		case SIGN_OUT:
			return { ...states,isSignIn:actions.payload.isSignIn };
		default:
			return states;
	}
}
