import React from 'react';
import firebase from 'firebase/app';
import withBaseLayout from '../HoC/withBaseLayout';
import 'firebase/storage';

/*
  Admin interface for sales team. It will be removed in the future
*/

class Admin extends React.Component {
  constructor(){
    super()
    this.state = {
      uploadPercentage:0,
      name:'',
      file:null,
      messageDisplay:'none',
      message:''
    }
    this.onFileUpload = this.onFileUpload.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onButtonSubmit = this.onButtonSubmit.bind(this);
  }

  onFileUpload(e){
    let file = e.target.files[0];
    this.setState({
      file:file
    })
  }

  onButtonSubmit(){
    if(this.state.file && this.state.name.trim().length !== ''){
      let path = `config/logos/${this.state.name}/${this.state.file.name}`;
      firebase.storage().ref(path).put(this.state.file).on(firebase.storage.TaskEvent.STATE_CHANGED,(snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadPercentage:progress
        });
      },(error) => {
        this.setState({
          messageDisplay:'',
          message:"Oops Something Went Wrong"
        });
      },() => {
        firebase.storage().ref(path).getDownloadURL().then(res => {
          sessionStorage.setItem("logos",res);
          this.setState({
            messageDisplay:"",
            message:"Upload Completed, Do not close the browser or the tab,just reload the page and the new settings will be applied."
          })
        })
      })
    }else{
      this.setState({
        messageDisplay:'',
        message:"Please select a file and enter the client name !"
      })
    }
  }

  onInputChange(e){
    this.setState({
      name:e.target.value
    })
  }

  render(){
    return(
      <React.Fragment>
        <div className="form-group">
          <input 
            type="text"
            placeholder="Name of the client"
            className="form-control"
            value={this.state.name}
            onChange={this.onInputChange}
            style={{
              border:"solid"
            }}
          />
        </div>
        <div className="form-group">
          <input 
            type="file" 
            onChange={this.onFileUpload} 
            accept="image/*" 
          />
        </div>
        <div className="form-group">
          <button
            className="btn btn-primary btn-no-focus__primary"
            onClick={this.onButtonSubmit}
          >
            Submit
          </button>
        </div>
        <div>
          <div>
            Upload Percentage
          </div>
          {this.state.uploadPercentage}
        </div>
        <div 
          style={{
            color:"red",
            display:this.state.messageDisplay
          }}
        >
          {this.state.message}
        </div>
      </React.Fragment>
    )
  }
}

const EnhancedAdmin = withBaseLayout(Admin);


export default EnhancedAdmin;