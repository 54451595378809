import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {editGoalStrategy} from '../../../actions/Goals/GoalActions';
import { connect } from "react-redux";
import changeLanguage from '../../../setting/languages/languages';

/*
	TODO:refactor table list component for goal-detail-screen and goal-create components
*/

class GoalStrategy extends React.Component {
	constructor(props){
		super(props)
		this.textStore = changeLanguage(props.language);
		this.onStrategyDetailClick = this.onStrategyDetailClick.bind(this);
		this.renderRiskCategory = this.renderRiskCategory.bind(this);
	}

	onStrategyDetailClick(){
    let index = 0;
    if (this.props.id) {
      index = this.props.id;
    }
		let pathName = `/goals/strategy/detail/${index}`;
		if (this.props.isEditStrategy) {
			this.props.history.push({pathname: pathName, state: {isEditStrategy: true, adviseText: this.props.advisedText}});
		} else {
			this.props.history.push({pathname: pathName, state: {isEditStrategy: false, adviseText: 'Recommended strategy'}});
		}
	}

	renderRiskCategory() {
		if (this.props.screen === this.textStore.screen.editStrategy) {
			return '';
		} else if (this.props.screen === this.textStore.screen.InvestmentProposal) {
			return this.props.advisedText
		}
		return this.props.strategy.riskCategoryName;
	}
	render(){
		return(
			<React.Fragment>
				<h3 className="primary-title"
					style={{
						fontSize:"20px"
					}}
				>
				{this.renderRiskCategory()}
				</h3>
				<table className="list-view">
					<tbody>
						<tr
							className="border-top description"
						>
							<td colSpan="2">
								<p
									style={{
										fontFamily:"OpenSans"										
									}}
								>
									{
										this.props.strategy.riskCategoryDescription === '' ? 
											"You seek to achieve optimal risk-adjusted returns over the long-term within a balanced risk profile." : 
											this.props.strategy.riskCategoryDescription
									}
								</p>
							</td>
						</tr>
						<tr>
							<th style={{borderTop: 'none'}}>
								<span className="icn icn--return">
									Expected return
									<span className="tooltip-toggle" role="button" tabIndex="-1"></span>

								</span>
							</th>
							<td className="text-right">
								≈ {(this.props.strategy.calculatedExpectedReturn * 100).toFixed(2)}%
							</td>
						</tr>
						<tr>
							<th>
								<span className="icn icn--market">
									Expected risk
									<span className="tooltip-toggle" role="button" tabIndex="-1"></span>
								</span>
							</th>
							<td className="text-right">
								≈ {(this.props.strategy.calculatedRisk * 100).toFixed(2)}%
							</td>
						</tr>
						<tr>
							<th>
								<span className="icn icn--allocation">
								Equity allocation	
								<span className="tooltip-toggle" role="button" tabIndex="-1"></span>
								</span>
							</th>
							<td className="text-right">
								≈ {(this.props.strategy.allocation.equityAllocation * 100).toFixed(2)}%
							</td>
						</tr>
						<tr
							onClick={this.onStrategyDetailClick}
						>
							<th>
								<span className="icn icn--document">
									Details
								</span>
							</th>
							<td className="text-right">
								<div className="list-action" style={{cursor:"pointer"}}>
									<span className="list-action__text" role="presentation">
										Read more
									</span>
									<span
										className="list-action__link list-action__link--more"
									>
										<span className="sr-only">Read more</span>
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<p className="disclaimer">
					Disclaimer: Please refer to the iShares Fund prospectus for more details, including the risk disclosure. For illustrative purposes only. It serves as a general summary, is not exhaustive. The target risk can be changed at the discretion of the investment manager, and there is no guarantee that the target will be met. Assumptions on which targets were calculated may not materialize.
				</p>
			</React.Fragment>
		)
	}
}

GoalStrategy.defaultProps = {
	isEditStrategy: false,
}

GoalStrategy.propTypes = {
	isEditStrategy: PropTypes.bool,
}

function mapStateToProps(props) {
  return {
    goalUI: props.goalUI,
    tabBarUI: props.tabBarUI,
    goalData: props.goalData,
    goalDetailData: props.goalDetailData
  };
}

export default withRouter(connect(mapStateToProps,
    {editGoalStrategy}
  )(GoalStrategy));