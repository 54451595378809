import React from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import withGenericLayout from '../../HoC/withGenericLayout';
import GenericPage from './GenericPage';
import { checkBrowser } from '../../../helpers/checkBrowser';
import { changeGoalStrategy } from '../../../actions/Goals/GoalActions';
import { fetchQuestionsDataRequest } from '../../../components/Onboarding/containers/QuestionnairesPage/async';
import CONFIG from '../../../setting/config';
import changeLanguage from '../../../setting/languages/languages';
import { toggleAsyncLoadingStatus } from "../../../actions/AppMain/AppMainAction";
import TextInput from '../CustomInput/TextInput/TextInput';

class GenericPageWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: 'hidden',
      mail: {
        to: "",
        subject: "",
        body: ""
      },
      activated: false,
    }
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.linkBox = React.createRef();
    this.onLinkBoxClick = this.onLinkBoxClick.bind(this);
    this.onVerifySuccessClick = this.onVerifySuccessClick.bind(this);
    this.onVerifyFailedClick = this.onVerifyFailedClick.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.timer = setTimeout(() => {
      this.setState({activated: true});
    }, 1500);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  renderReferralText() {
    return (
      <div className="referral-text">
        {this.textStore.genericPage.learnMore}<Link className="text-link-normal" to={this.routesName.IN_PROGRESS}>{this.textStore.genericPage.checkFAQ}</Link>
      </div>
    )
  }

  renderDashBoardButton(padding) {
    return (
      <div className="form-actions">
        <div className={`form-actions__item ${padding ? "generic-page-footer__padding" : ""} `}>
          <Link
            id="btnBack"
            to={this.routesName.DASHBOARD}
            className="btn btn-default btn-block btn-no-focus__inverted"
          >
            {this.textStore.common.backToDashboard}
          </Link>
        </div>
      </div>
    )
  }

  renderLogInButton(padding) {
    return (
      <div className="form-actions">
        <div className={`form-actions__item ${padding ? "generic-page-footer__padding" : ""} `}>
          <Link
            id="btnBack"
            to={this.routesName.LOG_IN}
            className="btn btn-default btn-block btn-no-focus__inverted"
          >
            {this.textStore.common.backToLogin}
          </Link>
        </div>
      </div>
    )
  }

  renderLinkBox() {
    return (
      <span style={{ position: 'relative', bottom: '25px' }}>
        <div className="pop-up-box" style={{ visibility: this.state.visibility }}>
          <span className="pop-up-text">
            {this.textStore.common.copied}
          </span>
        </div>
        <input
          ref={this.linkBox}
          className="inverted-input"
          type="text"
          value="cr4.additiv.com/13xe37aa"
          onClick={this.onLinkBoxClick}
          readOnly={true}
        />
      </span>
    )
  }

  renderFailedFooterText(type) {
    switch (type) {
      case this.textStore.common.backToVerification:
      case this.textStore.PersonalFinance.failure.buttonName:
      case this.textStore.common.requestNewVerificationCode:
        return (
          <p className="generic-page-footer__text">
            {this.textStore.verification.ifVerification}
            <a href={`mailto:${this.props.onboardingData.CTB.supportMail}?subject=${this.props.onboardingData.CTB.supportSubject}&body=${this.state.mail.body}`}>
              {this.textStore.verification.contactSupport}
            </a>
          </p>
        );
      case this.textStore.common.tryAgain:
        return (
          <div className="generic-page-footer__text">
            <p>
              {this.textStore.riskProfiling.inTheMeantime}
              <span className="text-label__link" onClick={() => this.props.history.push(this.routesName.ONBOARDING.RISK_PROFILING.QUESTIONNAIRE)}>
                {this.textStore.riskProfiling.reassess}
              </span>
            </p>
            <p>
              {this.textStore.riskProfiling.ifReassess}
              <a href={`mailto:${this.props.onboardingData.CTB.supportMail}?subject=${this.props.onboardingData.CTB.supportSubject}&body=${this.state.mail.body}`}>
                {this.textStore.riskProfiling.contactSupport}
              </a>
            </p>
          </div>
        )
      default:
        return;
    }
  }

  renderVerifyFailedFooter(buttonName) {
    return (
      <div className="form-actions">
        <div className="form-actions__item generic-page-footer__padding">
          <input
            id="btnBack"
            className={`btn ${this.state.activated ? "btn-primary" : ""} btn-block btn-no-focus__primary__inverted`}
            type="button"
            value={buttonName}
            onClick={() => this.onVerifyFailedClick(buttonName)}
            disabled={!this.state.activated}
          />
        </div>
        {this.renderFailedFooterText(buttonName)}
        {/* <p className="generic-page-footer__text">{this.textStore.verification.ifVerification}<a href={`mailto:${this.props.onboardingData.CTB.supportMail}?subject=${this.props.onboardingData.CTB.supportSubject}&body=${this.state.mail.body}`}>{this.textStore.verification.contactSupport}</a></p> */}
      </div>
    )
  }

  renderSuccessFooterText(willRender, buttonName) {
    if (willRender) {
      switch (buttonName) {
        case this.textStore.common.continueToOTP:
          return (
            <p className="generic-page-footer__text">
              {this.textStore.verification.ifVerification}
              <a
                href={`mailto:${this.props.onboardingData.CTB.supportMail}?subject=${this.props.onboardingData.CTB.supportSubject}&body=${this.state.mail.body}`}
              >
                {this.textStore.verification.contactSupport}
              </a>
            </p>
          );
        case this.textStore.PersonalFinance.verify.buttonName:
          return (
            <p className="generic-page-footer__text">
              {this.textStore.PersonalFinance.verify.footer.alternatively}
              <Link
                to={this.routesName.ONBOARDING.VISIT_BRANCH.PATH}
              >
                {this.textStore.PersonalFinance.verify.footer.visitABank}
              </Link>
              {this.textStore.PersonalFinance.verify.footer.instead}
              <Link
                to={this.routesName.ONBOARDING.PERSONAL_PARTICULAR}
              >
                {this.textStore.PersonalFinance.verify.footer.clickHere}
              </Link>
              {this.textStore.PersonalFinance.verify.footer.toRevise}
            </p>
          )
        default:
          return;
      }
    }
  }

  renderVerifySuccessFooter(buttonName, hasBottomText) {
    return (
      <div className="form-actions">
        <div className="form-actions__item generic-page-footer__padding">
          <input
            id="btnBack"
            className={`btn ${this.state.activated ? "btn-primary" : ""} btn-block ${this.state.activated ? "btn-no-focus__primary__inverted" : "btn-no-focus__disabled"}`}
            type="button"
            value={buttonName}
            onClick={() => this.onVerifySuccessClick(buttonName)}
            disabled={!this.state.activated}
          />
        </div>
        {this.renderSuccessFooterText(hasBottomText, buttonName)}
      </div>
    )
  }

  renderReviewBankTransferInstructionButton() {
    return (
      <div className="form-actions">
        <div className="form-actions__item generic-page-footer__padding">
          <input
            id="btnBack"
            className={`btn ${this.state.activated ? "btn-primary" : ""} btn-block ${this.state.activated ? "btn-no-focus__primary__inverted" : "btn-no-focus__disabled"}`}
            type="button"
            value={this.textStore.common.reviewBank}
            onClick={() => this.props.history.push(this.routesName.DASHBOARD)}
            disabled={!this.state.activated}
          />
        </div>
      </div>
    )
  }

  renderResetPasswordButton() {
    return (
      <div className="form-actions">
        <div className="form-actions__item">
          <input
            id="btnBack"
            className="btn btn-primary btn-block btn-no-focus__primary__inverted"
            type="button"
            value={this.textStore.passwordReset.resetMyPassword}
            onClick={() => this.props.history.push(this.routesName.RESET_PASSWORD.EMAIL_SENT)}
          />
        </div>
      </div>
    )
  }

  renderEmailInput() {
    return (
      <div className="input-wrapper generic-page-email-input__container">
        <span className="icn icn--mail"/>
        <TextInput
          className="form-control cockpit-input-field__full__small__inverted"
          id="email"
          placeholder={this.textStore.common.emailAddress}
        />
      </div>
    );
  }

  onVerifySuccessClick(buttonName) {
    switch(buttonName) {
      case this.textStore.common.continueToOTP:
        this.props.history.push(this.routesName.ONBOARDING.VERIFY.OTP.INPUT)
        break;
      case this.textStore.common.continueToRiskProfiling:
        this.props.preloadQuestionaires(()=>{
          let loadQuestionTimer = window.setTimeout(() => {
            window.clearTimeout(loadQuestionTimer);
            this.props.toggleAsyncLoadingStatus({ status: false, text: 'Loading' });
            this.props.history.push(this.routesName.ONBOARDING.RISK_PROFILING.QUESTIONNAIRE);
          }, 1000);
        }, this.props.questionnaires);
         break;
      case this.textStore.PersonalFinance.verify.buttonName:
        this.props.history.push(this.routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.PATH)
        break;
      default:
        return;
    }
  }

  onVerifyFailedClick(buttonName) {
    if (this.props.location.state.title && this.props.location.state.title === this.textStore.investmentProposal.goalCreateFailed) {
      return this.props.history.push(this.routesName.ONBOARDING.INVESTMENT_PROPOSAL) ;
    }
    
    switch (buttonName) {
      case this.textStore.common.backToVerification:   
      case this.textStore.common.requestNewVerificationCode:
        if (
          this.props.location.state.title ===
          this.textStore.complianceDeclaration.onlineVerificationFailed
        ) {
          this.props.history.push(this.routesName.ONBOARDING.COMPLIANCE_DECLARATION);
        } else if (this.props.location.state.title === this.textStore.customerOnlineVerification.failure.title) {
          this.props.history.push(this.routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.PATH)
        } else {
          this.props.history.push(this.routesName.ONBOARDING.PERSONAL_DETAIL);
        }
        break;
      case this.textStore.common.tryAgain:
        this.props.history.goBack();
        break;
      default:
        return;
    }
  }

  onLinkBoxClick(e) {
    this.setState({ visibility: 'visible' })
    if (checkBrowser()) {
      let editable = e.target.contentEditable;
      let readOnly = e.target.readOnly;
      editable = true;
      readOnly = false;
      var range = document.createRange();
      range.selectNodeContents(e.target)
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      e.target.setSelectionRange(0, 999999);
      e.target.contentEditable = editable;
      e.target.readOnly = readOnly;
    } else {
      this.linkBox.current.select();
    }
    document.execCommand('copy');
  }

  render() {
    const progressIcon = <span className="icn icn--hour-glass"/>
    const errorIcon = <span className="icn icn--not-found"/>
    const referralIcon = <span className="icn icn--referral"/>
    const rightTickIcon = <span className="icn icn--right-tick"/>
    const highlightedRightTickIcon = <span className="icn icn--right-tick-highlighted"/>
    const notePenIcon = <span className="icn icn--note-pen"/>
    const frownIcon = <span className="icn icn--frown"/>
    const highlightedFrownIcon = <span className="icn icn--frown-highlighted"/>
    const unlockedLockIcon = <span className="icn icn--reset-password"/>
    const envelopeIcon = <span className="icn icn--confirmation"/>
    const loadingIndicator = (
      <div className="generic-page-icon__container">
        <div className="generic-page-icon__text">{this.props.location.state ? this.props.location.state.loadingText : ''}</div>
        <CircularProgress
          classes={{root: "generic-page-icon__loading"}}
          size={120}
        />
      </div>
    )
    const dynamicIcon = <span className={this.props.location.state ? this.props.location.state.icon: ''}/>
    const description = this.props.location.state ? this.props.location.state.desc : '';
    const buttonName = this.props.location.state ? this.props.location.state.buttonName : '';
    let headerData = {headerLeft: '', headerMiddle: {}, headerRight: '', currentPage: this.props.page};
    let titleData = {style: {}, text: ''};
    let Page = null;
    switch(this.props.page) {
      case 'inProgress':
        titleData.text = this.textStore.genericPage.workInProgress;
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.genericPage.inDevelopment,
            '',
            this.renderDashBoardButton(),
            progressIcon
          )
        break;
      case 'error':
         Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.genericPage.notAvailable,
            '',
            this.renderDashBoardButton(),
            errorIcon
          )
          break;
      case 'referral':
        headerData = {headerLeft: 'menu', headerMiddle: {type: 'text', text: this.textStore.genericPage.classicRoboTitle}, headerRight: ''};
        titleData = {style: {color: '#4261ff'}, text: this.textStore.genericPage.getYourFundInvested};
        Page =
          withGenericLayout(
            GenericPage,
            false,
            headerData,
            titleData,
            this.textStore.genericPage.shareYourReferralLink,
            this.renderLinkBox(),
            this.renderReferralText(),
            referralIcon
          )
        break;
      case 'delete':
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.genericPage.investmentDeletedSuccess,
            '',
            this.renderDashBoardButton(),
            rightTickIcon
          )
        break;
      case 'complete':
        Page = 
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            description,
            '',
            this.renderDashBoardButton(),
            dynamicIcon,
          )
        break;
      case 'message':
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.genericPage.messageSent,
            '',
            this.renderDashBoardButton(),
            rightTickIcon,
          )
        break;
      case 'signOut':
        titleData = {style: {marginBottom: '15px'}, text: this.textStore.genericPage.seeYou}
        headerData = {headerLeft: 'appName', headerMiddle: {}, headerRight: '', currentPage: this.props.page}
        Page = 
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.genericPage.signOutSuccess,
            '',
            this.renderLogInButton(),
            rightTickIcon,
          )
        break;
      case 'loading':
        headerData = {headerLeft: 'back-white', headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle }, headerRight: '', currentPage: this.props.page};        
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            '',
            '',
            '',
            loadingIndicator,
          )
        break;
      case 'loadingSuccess':
        titleData = {style: {}, text: this.props.location.state.title}
        headerData = {headerLeft: 'back-white', headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle }, headerRight: '', currentPage: this.props.page};        
        let icon = rightTickIcon;
        if (this.state.activated) { icon = highlightedRightTickIcon; }
        if (this.props.location.state.buttonName === this.textStore.PersonalFinance.verify.buttonName) { 
          icon = notePenIcon; 
        }
        let renderFooter = false;
        if (
          this.props.location.state.buttonName === this.textStore.common.continueToOTP ||
          this.props.location.state.buttonName === this.textStore.PersonalFinance.verify.buttonName
        ) {
          renderFooter = true;
        }

        Page = 
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            description,
            '',
            this.renderVerifySuccessFooter(buttonName, renderFooter),
            icon,
          )
        break;
      case 'loadingFailed':
        titleData = {style: {margin: "10px 0"}, text: this.props.location.state.title}
        headerData = {headerLeft: 'back-white', headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle }, headerRight: '', currentPage: this.props.page};        
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            description,
            '',
            this.renderVerifyFailedFooter(buttonName),
            this.state.activated ? highlightedFrownIcon : frownIcon,
          )
        break;
      case 'timedout':
        titleData = {style: {}, text: this.props.location.state.title}
        headerData = {headerLeft: 'back-white', headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle }, headerRight: '', currentPage: this.props.page};        
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            description,
            '',
            this.renderVerifyFailedFooter(buttonName),
            this.state.activated ? highlightedFrownIcon : frownIcon,
          )
        break;
      case "greeting":
        titleData = {style: {}, text: this.textStore.common.congratulations}
        headerData = {headerLeft: 'back-white', headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle}, headerRight: '', currentPage: this.props.page}
        Page = 
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.complianceDeclaration.youSuccessfullyOpened,
            '',
            this.renderReviewBankTransferInstructionButton(),
            this.state.activated ? highlightedRightTickIcon : rightTickIcon,
          )
        break;
      case "resetPassword": 
        titleData = {style: {marginTop: '5px'}, text: this.textStore.passwordReset.passwordReset}
        headerData = {
          headerLeft: "back-white",
          headerMiddle: {
            type: "text",
            text: this.textStore.common.classicRoboTitle,
            styles: {fontFamily: "OpenSans", fontSize: "13px"}
          },
          headerRight: "",
          currentPage: this.props.page
        };
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.passwordReset.pleaseEnterEmail,
            this.renderEmailInput(),
            this.renderResetPasswordButton(),
            unlockedLockIcon,
          )
        break;
      case "emailSent":
        titleData = {style: {}, text: this.textStore.passwordReset.emailSent}
        headerData = {headerLeft: "appName", headerMiddle: {}, headerRight: "", currentPage: this.props.page}
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.passwordReset.weHaveSent,
            '',
            this.renderLogInButton(true),
            envelopeIcon,
          )
        break;
      case "passwordUpdated": 
        titleData = {style: {}, text: this.textStore.passwordReset.newPassword}
        headerData = {headerLeft: "appName", headerMiddle: {}, headerRight: "", currentPage: this.props.page}
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.passwordReset.passwordUpdateSuccess,
            '',
            this.renderDashBoardButton(true),
            rightTickIcon,
          )
        break;
      case "settingsCompleted":
          Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.common.settingsUpdated,
            '',
            this.renderDashBoardButton(),
            rightTickIcon,
          )
        break;
      case "verifyEmail":
        titleData = {style: {}, text: this.textStore.passwordReset.newPassword}
        headerData = {headerLeft: "appName", headerMiddle: {}, headerRight: "", currentPage: this.props.page}
        Page =
          withGenericLayout(
            GenericPage,
            true,
            headerData,
            titleData,
            this.textStore.common.settingsUpdated,
            '',
            this.renderDashBoardButton(),
            envelopeIcon,
          )
        break;
      default:
        return;
    }
    
    return (
      <Page/>
    )
  }
}

function mapStateToProps(props){
  return {
    onboardingData: props.onboardingData,
    appMainUI: props.appMainUI,
    questionnaires: props.questionnaires && props.questionnaires.questions
  }
}

const mapDispatchToProps = dispatch => ({
  changeGoalStrategy,
  toggleAsyncLoadingStatus: (params) => dispatch(toggleAsyncLoadingStatus(params)),
  preloadQuestionaires: (callback, currentQuestionaires) => {
    dispatch(toggleAsyncLoadingStatus({ status: true, text: 'Loading'}));
    (currentQuestionaires && currentQuestionaires.length >0) ? callback() : dispatch(fetchQuestionsDataRequest(callback))    
   },
 });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenericPageWrapper));
