import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { invertLayout } from "../../../actions/AppMain/AppMainAction";

import emoticonSmile from '../../../css/images/icons/emoticon-smile.svg';
import emoticonFrown from '../../../css/images/icons/emoticon-frown.svg';

import './styles.less';

class Feedback extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.switchHeader({
      headerLeft: 'menu-white',
      headerMiddle: { type: 'text', text: 'Classic Robo' },
      headerRight: '',
      currentPage: 'Feedback',
    });

    this.props.invertLayout('viewport layout--inverted');
  }

  componentWillUnmount() {
    this.props.invertLayout("viewport layout--default");
  }

  render() {
    return (
      <React.Fragment>
        <div className="feedback content-main__body content-main__body--centered text-center">
          <div className="feedback-item feedback__icon">
            <img src={emoticonSmile} alt="smile" />
          </div>
          <div className="feedback-item feedback__desc">
            Enjoying the experience and investment services provided by ‘Operator of Classic Robo’? We would be thrilled if you could give us a five-star rating!
            </div>
          <div className="feedback-item feedback__link">
            <Link
              id="btnBack"
              to="/inprogress"
              className="btn btn-default btn-block btn-no-focus__primary__inverted"
            >Rate our App</Link>
          </div>
        </div>
        <div className="content-main__footer feedback-footer">
          <div className="feedback-item feedback__icon">
            <img src={emoticonFrown} alt="smile" />
          </div>
          <div className="feedback-item feedback__desc">
            Not having a good experience? Please let us know where we need to improve
            </div>
          <div className="feedback__link">
            <Link
              id="btnBack"
              to="/feedback/leave-suggestion"
              className="btn btn-default btn-block btn-no-focus__inverted"
            >Leave a suggestion</Link>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(null, { switchHeader, invertLayout })(withBaseLayout(Feedback));