import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";


import './styles.less';

class ChangeEmail extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0,0);
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Change email address' },
      headerRight: '',
      currentPage: 'changeEmail',
    })
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="account-change-email">
          <div className="form-group">
            <p className="control-label">Current Password</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                value="********"
                type="password"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <p className="control-label">Email</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                placeholder="Please enter"
                value="carlos.jakob@gmail.com"
                type="text"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group text-center forgot-password-link">
            <Link to="#" className="text-link-normal"> Forgot password?</Link>
          </div>
        </div>
        <div className="content-main__footer">
          <div className="form-actions">
            <div className="form-actions__item">
              <button type="submit" className="btn btn-primary btn-no-focus__primary">Save</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(ChangeEmail));