import { SWITCH_HEADER } from "../../../actions/Header/HeaderActionType";

const headerData = {
  headerLeftIcon: "default",
  headerMiddleIcon: {
    type: "default",
    text: ""
  },
  headerRightIcon: {
    type: "default",
    url: "",
    text: "",
  },
  currentPage: ""
};

export default function(data = headerData, actions) {
  switch (actions.type) {
    case SWITCH_HEADER:
      return {
        ...data,
        headerLeftIcon: { ...data.headerLeftIcon, type: actions.payload.headerLeft },
        headerMiddleIcon: {
          ...data.headerMiddleIcon,
          type: actions.payload.headerMiddle.type,
          text: actions.payload.headerMiddle.text,
          styles: actions.payload.headerMiddle.styles
        },
        headerRightIcon: { 
          ...data.headerRightIcon, 
          type: actions.payload.headerRight.type,
          url: actions.payload.headerRight.url,
          text: actions.payload.headerRight.text,
          styles: actions.payload.headerRight.styles,
          ...actions.payload.headerRight
        },
        currentPage: actions.payload.currentPage
      };
    default:
      return data;
  }
}
