import React from 'react';
import NavBarDashBoard from './NavBarDashBoard';
import { connect } from 'react-redux';
import NavBarGoalDetail from './NavBarGoalDetail';
import { closeNavBar } from '../../actions/AppMain/AppMainAction';
import firebase from 'firebase';
import 'firebase/firestore';

class NavBar extends React.Component {
  constructor(){
    super()
    this.state = {
      version: ''
    }
    this.onCloseNavBar = this.onCloseNavBar.bind(this);
  }

  onCloseNavBar(callback){
    this.props.closeNavBar(callback);
  }

  componentDidMount() {
    firebase.firestore().collection("AppVersion").doc('1').onSnapshot(snapshot => this.setState({ version: snapshot.data().Version }))
  }

  render(){
    switch(this.props.navBarUI.currentNavBar){
      case 'goaldetail':
        return(
          <NavBarGoalDetail id={this.props.goalDetailData.id} moveHeader={this.props.moveHeader} onCloseNavBar={this.onCloseNavBar}/>
        )
      default:
        return(
          <NavBarDashBoard version={this.state.version} onCloseNavBar={this.onCloseNavBar} />
        )
    }
  }
}

function mapStateToProps(props){
  return {
    navBarUI:props.navBarUI,
    goalDetailData: props.goalDetailData
  }
}

export default connect(mapStateToProps,{ closeNavBar })(NavBar);