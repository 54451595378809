import React from "react";
import { withRouter } from "react-router-dom"
import { connect } from "react-redux";

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import CONFIG from "../../../setting/config";

import './styles.less';

class SupportSendMail extends React.Component {
  constructor() {
    super();
    this.state = {
      title: ""
    };
    this.routesName = CONFIG.APP_ROUTE;
  }
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "close",
      headerMiddle: {
        type: "text",
        text: "Customer support",
      },
      headerRight: { type: "send" },
      currentPage: "SupportSendMail",
    });
  }
  
  onSendClick() {
    this.props.history.push(this.routesName.MESSAGE_SUCCESS)
  }
  render() {
    return (
      <div className="support-send-mail">
        <div className="support-send-mail__title">
          <div className="support-send-mail__label">Subject:</div>
          <input className="support-send-mail__input" />
        </div>
        <div className="support-send-mail__body">
          <div className="support-send-mail__label">Message:</div>
          <textarea className="support-send-mail__textarea" placeholder="Type message here"/>
        </div>
      </div>
    );
  }
  componentWillUnmount() {
    document.getElementById("ContentMain").style = ''
  }
}

const EnhancedSupportSendMail = withBaseLayout(SupportSendMail);

function mapStateToProps(props) {
  return {
    supportData: props.supportData
  };
}

export default withRouter(connect(
  mapStateToProps,
  { switchHeader }
)(EnhancedSupportSendMail));
