import React from 'react';
import Answer from '../Answer';

export default class Question extends React.PureComponent {
  onClick = (evt) => {
    evt.preventDefault();

    const { isExpand, data, onClick } = this.props;
    
    onClick(!isExpand ? data.renderKey : -1);
  }

  onAnswer = (value) => {
    const { onAnswer, data: { renderKey } } = this.props;
    
    onAnswer({ key: renderKey, value});
  }

  render() {
    const { data, isExpand, isAnswered, selected } = this.props;

    return (
      <div id={data.renderKey} className={['questionnaires__question', isExpand ? 'expanded' : '', isAnswered ? 'answered' : ''].join(' ')}>
        <div className="questionnaires__question-header" onClick={this.onClick}>
          <span className="questionnaires__question-header__icon"></span>
          <span className="questionnaires__question-header__text">{data.Text}</span>
        </div>
        <div className="questionnaires__question-content">
          {
            !data.Image ? '' : (<img className="questionnaires__question-content__img" alt="" src={data.Image} />)
          }

          <div className="questionnaires__question-content__answer">
            {
              data.Answers.map((answer) => (
                <Answer
                  key={answer.Id}
                  data={answer}
                  selected={selected === `${data.renderKey}-${answer.Id}`}
                  layout={!data.Orientation}
                  value={`${data.renderKey}-${answer.Id}`}
                  onClick={this.onAnswer}
                />
              ))
            }
          </div>

        </div>
      </div>
    )
  }
}