import {
  NAVBAR_OPEN,
  NAVBAR_CLOSE,
  INVERT_LAYOUT,
  LOADING_LAYOUT,

  LOAD_CTB_DATA_SUCCESS,
  LOAD_CTB_DATA_FAILURE,
  TOGGLE_LOADING,
  TOGGLE_ASYNC_LOADING_STATUS,
} from './AppMainActionType'


export function openNavBar(navBarClass) {
  return {
    type: NAVBAR_OPEN,
    payload: navBarClass,
  }
}

export function closeNavBar(callback) {
  if (typeof callback === 'function')
    callback();
  return {
    type: NAVBAR_CLOSE,
  }
}

export function invertLayout(layoutClass) {
  return {
    type: INVERT_LAYOUT,
    payload: layoutClass,
  }
}

export function loadingLayout(layoutClass) {
  return {
    type: LOADING_LAYOUT,
    payload: layoutClass,
  }
}

export function toggleLoading(state) {
  return {
    type: TOGGLE_LOADING,
    payload: state,
  }
}


export const loadCTBDataSuccess = (CTB) => ({
  type: LOAD_CTB_DATA_SUCCESS, CTB,
});


export const loadCTBDataFailure = (error) => ({
  type: LOAD_CTB_DATA_FAILURE, error,
});

export const toggleAsyncLoadingStatus = (params) => ({
  type: TOGGLE_ASYNC_LOADING_STATUS, params,
});
