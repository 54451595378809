import React from 'react';
import { Link } from 'react-router-dom';

export default class SupportHelpful extends React.PureComponent {
  onClick = (evt) => {
    const { title } = evt.target;
    const { onClick } = this.props;

    typeof onClick === 'function' && onClick(title);
  }

  render() {
    return (
      <div className="support-helpful">
        <div className="support-helpful__quest">Was this helpful?</div>
        <div className="support-helpful__ans">
          <span className="support-helpful__ans--item" title="yes" onClick={this.onClick}>Yes</span>
          <span className="support-helpful__ans--item" title="no" onClick={this.onClick}>No</span>
        </div>
      </div>
    )
  }
}