

function calculateTotalPerformance(securities){
	let totalPerformance = 0;
	let totalAllocation = 0;

	securities.forEach(security => {
		totalAllocation += security.allocation;
	})

	securities.forEach(security => {
		totalPerformance +=  security.performance/totalAllocation * security.allocation;
	})
	return {
		totalPerformance: totalPerformance,
		totalAllocation: totalAllocation
	};
}




function formatTotalPerformance(positions){
	positions.forEach(position => {
		let total = calculateTotalPerformance(position.security);
		position.totalPerformance += total.totalPerformance
		position.totalAllocation += total.totalAllocation
	})
	return positions;
}

function formatType(positions){
	let type = positions[0].Security.Type.Name;
	let types = [];
	types.push({
		type:positions[0].Security.Type.Name,
		id:positions[0].Security.Type.Id,
		totalPerformance:0,
		totalAllocation:0,
		security:[]
	});
	positions.forEach(position => {
		if(position.Security.Type.Name !== type){
			types.push({
				type:position.Security.Type.Name,
				id:position.Security.Type.Id,
				totalPerformance:0,
				totalAllocation:0,
				security:[]
			});
			type = position.Security.Type.Name
		}
	})
	return types.sort((a,b) => {
		return a.type.toLowerCase().localeCompare(b.type.toLowerCase());
	}).reduce((prev,next) => {
		let obj = prev.filter(obj => obj.type === next.type);
		if (obj.length === 0) {
			prev.push(next);
		}
		return prev;
	},[])
}


function formatSecurity(types,positions){
	// Sort array object by key
	types.sort((a, b) => 
		a.id < b.id ? -1 : 1
	);
	for(let i = 0;i < types.length;i++){
		positions.forEach(position => {
			if(types[i].type === position.Security.Type.Name){
				types[i].security.push({
					allocation:position.Allocation,
					performance:position.Performance ,
					name:position.Security.Name,
					currencyCode: position.Security.Currency.CurrencyCode,
					performanceAmount: position.InvestmentValueSecurityCurrency * position.Performance,
					currentValue: position.InvestmentValueSecurityCurrency,
					country: position.Security.Country.Name,
					continent: position.Security.Country.Continent.Name,
					price: position.ValuationPrice,
					sector:position.Security.Sector.Name,
					industry: position.Industry,
					market: position.Market,
					marketCap: position.MarketCap,
					quantity: position.Quantity ,
					isin: position.ISIN,
				})	
				types[i].security.sort((a,b) => {
					return b.allocation - a.allocation;
				});
			} 
		})
	}
	return types;
}



function formatPositions(positions){
	let types = formatType(positions);
	return formatTotalPerformance(formatSecurity(types,positions));
}

export default formatPositions;