import React from 'react';
import { formatCurrency } from '../../../helpers/formatCurrency';

class AmountValue extends React.Component {

  checkPerformanceValue(currentPerformanceAmount,currentPerformance){
    if(currentPerformance < 0){
      return(
        <p 
          className="amount-change amount-change--decrease" 
        >
          {formatCurrency(currentPerformanceAmount)} {`(${formatCurrency(currentPerformance*100)}%)` }
        </p>
      )
    }
    return (
      <p className="amount-change amount-change--increase">
        {formatCurrency(currentPerformanceAmount)} {`(${formatCurrency(currentPerformance*100)}%)`}
      </p>
    ) 
  }

  render(){
    return(
      <React.Fragment>
        <span className="invest-summary__icon"
          style={{
            display: this.props.display
          }}
        >
					<span className={`icn-main goal--${this.props.amountValue.type}`}></span>
				</span>
        <p 
          className="amount-value" 
        >
          <span className="currency">
            {this.props.amountValue.currencyCode}
          </span> 
          { formatCurrency(this.props.amountValue.currentValue)}
        </p>
        {this.checkPerformanceValue(this.props.amountValue.currentPerformanceAmount,this.props.amountValue.currentPerformance)}
      </React.Fragment>
    )
  }
} 

AmountValue.defaultProps = {
  display:'none',
  amountValue:{
    currencyCode:'',
    currentValue:0,
    currentPerformanceAmount:0,
    currentPerformance:0,
    isNewUser:false
  }
}
export default AmountValue;