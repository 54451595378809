export function formatPhoneNumber(number) {
  let result = number;
  if (number.length > 4) {
    result = result.slice(0, 4) + ' ' + result.slice(4);
  }
  if (number.length > 8) {
    result = result.slice(0, 9) + ' ' + result.slice(9);
  }
  return result;
}

export function unFormatPhoneNumber(number) {
  return number.replace(/ /g,'');
}