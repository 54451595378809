import React from "react";
import PropTypes from "prop-types";

class TextInput extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      cursorMoveToEnd: true
    };
    this.onInputClick = this.onInputClick.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
  }

  onInputClick(e) {
    this.props.onClick(e);
    e.preventDefault();
    if (!this.state.cursorMoveToEnd) {
      return;
    }
    this.setState({ cursorMoveToEnd: false });
    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
  }

  onInputBlur(e) {
    this.props.onBlur(e);
    this.setState({ cursorMoveToEnd: true });
  }

  render() {
    const conditionalProps = {
      pattern: this.props.numericKeyboard ? "[0-9]*" : this.props.pattern,
      inputMode: this.props.numericKeyboard ? "numeric" : this.props.inputMode,
    };
    const { numericKeyboard, type, ...props } = this.props;
    return (
      <React.Fragment>
        <input
          {...props}
          {...conditionalProps}
          type={this.props.type}
          onClick={this.onInputClick}
          onBlur={this.onInputBlur}
        />
      </React.Fragment>
    );
  }
}

TextInput.defaultProps = {
  onClick: () => {},
  onBlur: () => {},
  numericKeyboard: false,
  type: "text",
};

TextInput.propTypes = {
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  numericKeyboard: PropTypes.bool,
  type: PropTypes.string,
};

export default TextInput;
