import {
  NAVBAR_OPEN,
  NAVBAR_CLOSE,
  INVERT_LAYOUT,
  LOADING_LAYOUT,
  LOAD_CTB_DATA_SUCCESS,
  LOAD_CTB_DATA_FAILURE,
  TOGGLE_ASYNC_LOADING_STATUS,
} from '../../actions/AppMain/AppMainActionType'

export default function (data = {
  layout: 'viewport layout--default',
  navbar: '',
  loading: '',
  asyncLoadingStatus: false,
  asyncLoadingText: 'Loading',
  CTB: {},
}, actions) {
  switch (actions.type) {
    case NAVBAR_OPEN:
      return { ...data, navbar: actions.payload };

    case NAVBAR_CLOSE:
      return { ...data, navbar: '' };

    case INVERT_LAYOUT:
      return { ...data, layout: actions.payload };

    case LOADING_LAYOUT:
      return { ...data, loading: actions.payload };

    case LOAD_CTB_DATA_SUCCESS:
      return { ...data, CTB: actions.CTB };

    case LOAD_CTB_DATA_FAILURE:
      return { ...data, CTB: {} };

    case TOGGLE_ASYNC_LOADING_STATUS:
      return { 
        ...data, 
        asyncLoadingStatus: actions.params.status,
        asyncLoadingText: actions.params.text,
      };

    default:
      return data;
  }
}