export const SELECT_GOAL = 'SELECT_GOAL';
export const EDIT_GOAL = 'EDIT_GOAL';

export const CHANGE_GOAL_NAME = 'CHANGE_GOAL_NAME';
export const CHANGE_TARGET_AMOUNT = 'CHANGE_TARGET_AMOUNT';
export const CHANGE_SLIDER_VALUE_ONBOARDING = 'CHANGE_SLIDER_VALUE_ONBOARDING';
export const CHANGE_MONTHLY_INVESTMENT_ONBOARDING = 'CHANGE_MONTHLY_INVESTMENT_ONBOARDING';
export const CHANGE_TIME_HORIZON_ONBOARDING = 'CHANGE_TIME_HORIZON_ONBOARDING';
export const CHANGE_INITIAL_INVESTMENT = 'CHANGE_INITIAL_INVESTMENT';
export const CHANGE_MONTHLY_INVESTMENT = 'CHANGE_MONTHLY_INVESTMENT';
export const CHANGE_TARGET_DATE = 'CHANGE_TARGET_DATE';
export const CHANGE_TOPUP_AMOUNT = 'CHANGE_TOPUP_AMOUNT';
export const CHANGE_WITHDRAW_AMOUNT = 'CHANGE_WITHDRAW_AMOUNT';

export const DISPLAY_GOAL_NAME_ERROR = 'DISPLAY_GOAL_NAME_ERROR';
export const HIDE_GOAL_NAME_ERROR = 'HIDE_GOAL_NAME_ERROR'

export const DISPLAY_TARGET_AMOUNT_ERROR = 'DISPLAY_TARGET_AMOUNT_ERROR';
export const HIDE_TARGET_AMOUNT_ERROR = 'HIDE_TARGET_AMOUNT_ERROR'

export const DISPLAY_INITIAL_AMOUNT_ERROR = 'DISPLAY_INITIAL_AMOUNT_ERROR';
export const HIDE_INITIAL_AMOUNT_ERROR = 'HIDE_INITIAL_AMOUNT_ERROR';


export const DISPLAY_MONTHLY_AMOUNT_ERROR = 'DISPLAY_MONTHLY_AMOUNT_ERROR';
export const HIDE_MONTHLY_AMOUNT_ERROR = 'HIDE_MONTHLY_AMOUNT_ERROR';


export const DISPLAY_TARGET_DATE_ERROR = 'DISPLAY_TARGET_DATE_ERROR';
export const HIDE_TARGET_DATE_ERROR = 'HIDE_TARGET_DATE_ERROR';

export const DISPLAY_TOPUP_AMOUNT_ERROR = 'DISPLAY_TOPUP_AMOUNT_ERROR';
export const HIDE_TOPUP_AMOUNT_ERROR = 'HIDE_TOPUP_AMOUNT_ERROR'

export const DISPLAY_WITHDRAW_AMOUNT_ERROR = 'DISPLAY_WITHDRAW_AMOUNT_ERROR';
export const HIDE_WITHDRAW_AMOUNT_ERROR = 'HIDE_WITHDRAW_AMOUNT_ERROR'

export const GOAL_COMPLETED = 'GOAL_COMPLETED';
export const SUMMARY_COMPLETED = 'SUMMARY_COMPLETED';
export const STRATEGY_COMPLETED = 'STRATEGY_COMPLETED';
export const TOP_UP_COMPLETED = 'TOP_UP_COMPLETED';
export const WITHDRAW_COMPLETED = 'WITHDRAW_COMPLETED';

export const TRANSFER_UPDATE_TOTAL_ONE_TRANSFER = 'TRANSFER_UPDATE_TOTAL_ONE_TRANSFER';
export const TRANSFER_UPDATE_TOTAL_MONTHLY_TRANSFER = 'TRANSFER_UPDATE_TOTAL_MONTHLY_TRANSFER';
export const TRANSFER_COMPLETED = 'TRANSFER_COMPLETED';
export const INSTRUCTIONS_COMPLETED = 'INSTRUCTIONS_COMPLETED';


export const EDIT_GOAL_STRATEGY = 'EDIT_GOAL_STRATEGY';

export const GOAL_CONFIRM = 'GOAL_CONFIRM';

export const RESET_GOAL = 'RESET_GOAL';

export const GET_DEFAULT_GOALS = 'GET_DEFAULT_GOALS';
export const GET_DEFAULT_GOALS_ONBOARDING = 'GET_DEFAULT_GOALS_ONBOARDING';

export const GET_DEFAULT_GOALS_STRATEGY = 'GET_DEFAULT_GOALS_STRATEGY';

export const CHANGE_GOALS_STRATEGY = 'CHANGE_GOALS_STRATEGY';

export const GET_DEFAULT_GOALS_TRANSFER = 'GET_DEFAULT_GOALS_TRANSFER';

export const GET_DEFAULT_GOAL = 'GET_DEFAULT_GOAL';
export const CALCULATE_PROBABILITY = 'CALCULATE_PROBABILITY';
export const GET_GOAL_CALCULATE = 'GET_GOAL_CALCULATE';

export const PASS_GOALS_TO_SUMMARY = 'PASS_GOALS_TO_SUMMARY';

export const GET_DEFAULT_GOALS_BANK_ACCOUNT = 'GET_DEFAULT_GOALS_BANK_ACCOUNT';
export const GET_CONFIGURATION = 'GET_CONFIGURATION';

export const GET_WITHDRAW_ACCOUNT = 'GET_WITHDRAW_ACCOUNT';

export const STATUS_ERROR_DISPLAY = 'STATUS_ERROR_DISPLAY';

// History transaction
export const HISTORY_TRANSACTION_OVERVIEW = 'HISTORY_TRANSACTION_OVERVIEW';

