//import data from '../../../data/performance_general_investment';
import data from "../../../data/performance-instrument-amount";
import { formatCurrency } from "../../../helpers/formatCurrency";

const moment = require("moment");

const fontStyle = {
  color: "#B3C0FF",
  font: "OpenSans"
};
const config = {
  exporting: { enabled: false },

  credits: {
    enabled: false
  },
  navigator: {
    enabled: false,
  },
  scrollbar: {
    enabled: false
  },
  title: {
    enabled: false
  },
  chart: {
    style: fontStyle,
    spacing: 0,
    animation: true,
    zoomType: '',
    pinchType: '',
    panning: false,
  },
  series: [
    {
      data: data,
      type: "area",
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [[0, "#92a5ff"], [1, "white "]] //old color: #506dff
      },
      name: "Investment"
    }
  ],
  colors: ["#4261FF"],
  xAxis: {
    visible: true,
    //tickInterval:0,
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: "transparent",
    minorTickLength: 0,
    tickLength: 0,
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%a"
    },
    labels: {
      format: "{value: %d.%m.%y}",
      style: fontStyle
    },
    // ordinal: false,
  },
  yAxis: {
    opposite: false,
    gridLineDashStyle: "dot",
    gridLineColor: "#a1b0fc",
    gridLineWidth: 1.6,
    labels: {
      formatter: function() {
        if (this.value >= 1000000) {
          return this.value / 1000000 + "M";
        }
        return this.value / 1000 + "K";
      },

      style: fontStyle,
      // x: 20,
    },
  },
  useHTML: true,
  tooltip: {
    valueDecimals: 2,
    followTouchMove: false,
    crosshair: false,
    formatter: function() {
      if (this.y >= 1000000) {
        return `<strong>USD ${formatCurrency(this.y)}</strong>
        <br/>
        ${moment(this.x).format("LL")}
        `;
      }
      return `<strong>USD ${formatCurrency(this.y)}</strong>
      <br/>
      ${moment(this.x).format("LL")}`;
    }
  },
  plotOptions: {
    series: {
      turboThreshold: 10000,
      animation: true
    },
    line: {
      lineWidth: 0.8,
      shadow: {
        color: "#4261FF",
        width: 3.5,
        offsetX: 0,
        offsetY: 0
      }
    }
  },
  rangeSelector: {
    selected: 4,
    inputEnabled: false,
    height: 0,
    buttons: [
      {
        type: "week",
        count: 1,
        text: "1W"
      },
      {
        type: "month",
        count: 1,
        text: "1M"
      },
      {
        type: "month",
        count: 3,
        text: "3M"
      },
      {
        type: "month",
        count: 6,
        text: "6M"
      },
      {
        type: "all",
        text: "All"
      }
    ],
    buttonTheme: {
      fill: "none",
      stroke: "none",
      style: {
        color: "transparent"
      },
      states: {
        hover: {
          fill: "none",
          stroke: "none",
          style: {
            color: "transparent"
          }
        },
        select: {
          fill: "none",
          stroke: "none",
          style: {
            color: "transparent"
          }
        },
        disabled: {
          fill: "none",
          stroke: "none",
          style: {
            color: "transparent"
          }
        }
      }
    }
  }
};

export default config;
