import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withBaseLayout from "../../HoC/withBaseLayout";
import changeLanguage from "../../../setting/languages/languages";
import CONFIG from "../../../setting/config";
import { switchHeader } from "../../../actions/Header/HeaderActions";

class LinkedBankAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "2",
    }
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onRadioClick = this.onRadioClick.bind(this);
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.textStore.bankAccount.linkedBankAccount
      },
      headerRight: "",
      currentPage: "linkedBankAccount"
    });
  }

  onRadioClick(e) {
    this.setState({ value: e.target.value})
  }

  render() {
    const data = [
      { iban: "CH50 0048 0679 5014 3258 7", value: "1", checked: false },
      { iban: "CH30 0067 1356 2245 6780 9", value: "2", checked: true },
      { iban: "CH50 0034 1890 0980 4456 3", value: "3", checked: false }
    ];
    return (
      <React.Fragment>
        <section>
          {data.map((el, id) => {
            return (
              <div className="linked-bank-account__container" key={id}>
                <span className="text-title__small">
                  {this.textStore.bankAccount.accountNumber} (IBAN)
                  <div className="text-label__values__small-2">{el.iban}</div>
                </span>
                <input
                  className="linked-bank-account__radio"
                  type="radio"
                  name="bankAccounts"
                  value={el.value}
                  checked={this.state.value === el.value}
                  onClick={this.onRadioClick}
                />
              </div>
            );
          })}
        </section>
        <section>
          <div className="linked-bank-account__button-container">
            <label
              className="linked-bank-account__add-button"
              onClick={() =>
                this.props.history.push(this.routesName.BANK_ACCOUNT_EDIT.NEW)
              }
            >
              {this.textStore.bankAccount.addAccount}
            </label>
            <label
              className="linked-bank-account__update-button"
              onClick={() =>
                this.props.history.push(
                  this.routesName.BANK_ACCOUNT_EDIT.UPDATE
                )
              }
            >
              {this.textStore.common.update}
            </label>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const EnhancedLinkedBankAccount = withBaseLayout(LinkedBankAccount);

export default withRouter(
  connect(
    null,
    { switchHeader }
  )(EnhancedLinkedBankAccount)
);
