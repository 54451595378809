import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { formatCurrency } from "../../../helpers/formatCurrency";
import CONFIG from "../../../setting/config";

class GoalInstruction extends React.Component {
  constructor() {
    super();
    this.routesName = CONFIG.APP_ROUTE;
  }
  render() {
    return (
      <React.Fragment>
        <h3 className="section-title">
          You're one step away from starting your investment
        </h3>
        <p>
          Please arrange the transfer of required funding as indicated below via
          your preferred bank account.
        </p>
        <table className="list-view">
          <tbody>
            <tr>
              <th>
                <span className="icn icn--investment icn-instruction-adjust" />
                One-time transfer
              </th>
              <td className="text-right">
                {formatCurrency(this.props.totalOneTransferAmount)}
              </td>
            </tr>
            <tr>
              <th style={{ whiteSpace: "nowrap" }}>
                <span className="icn icn--recurring icn-instruction-adjust" />
                Monthly transfer
                <span 
									className="tooltip-toggle" 
									role="button" 
									tabIndex="-1" 
								/>
              </th>
              <td className="text-right">
                {formatCurrency(this.props.totalMonthlyAmount)}
              </td>
            </tr>
            <tr>
              <th>Currency</th>
              <td className="text-right">
                {"US Dollar (" + this.props.instructions.currencyCode + ")"}
              </td>
            </tr>
            <tr>
              <th>Receipt name</th>
              <td className="text-right">{this.props.instructions.receiptName}</td>
            </tr>
            <tr>
              <th>Bank name</th>
              <td className="text-right">{this.props.instructions.bankName}</td>
            </tr>
            <tr>
              <th>Account number</th>
              <td className="text-right">{this.props.instructions.accountNumber}</td>
            </tr>
            <tr>
              <th>IBAN</th>
              <td 
								className="text-right" 
								style={{ whiteSpace: "nowrap" }}
							>
                {this.props.instructions.iban}
              </td>
            </tr>
            <tr>
              <th>BIC/Swift</th>
              <td className="text-right">{this.props.instructions.bicSwift}</td>
            </tr>
            <tr id="borderless">
              <th id="borderless-bottom">Message</th>
              <td 
								id="borderless-bottom" 
								className="text-right"
							>
                {this.props.instructions.message}
              </td>
            </tr>
            <tr id="borderless">
              <td 
								colSpan="2" 
								className="text-byline"
							>
                <span
                  style={{
                    position: "relative",
                    top: "-8px"
                  }}
                >
                  Depending on your bank sometimes referred to as ‘Initials’,
                  ‘Description’, ‘Payment Id’ or ‘Message’. It is important to
                  include the shown key inside the appropriate field in order to
                  avoid a delay in processing your transfer.
                </span>
              </td>
            </tr>
            <tr 
							id="borderless-bottom"
							onClick={() => this.props.history.push(this.routesName.FUNDING_TERMS)}
						>
              <th 
								id="borderless-bottom" 
								style={{ borderTop: "none" }}
							>
                <span className="icn icn--details">Terms & Conditions</span>
              </th>
              <td 
								className="text-right" 
								id="borderless-bottom"
							>
                <div className="list-action">
                  <span 
										className="list-action__text" 
										role="presentation"
									>
                    Read more
                  </span>
                  <a
                    href="ex-goal-create-2.3.1.html"
                    className="list-action__link list-action__link--more"
                  >
                    <span className="sr-only">Read more</span>
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

GoalInstruction.defaultProps = {
	totalOneTransferAmount: 0,
	totalMonthlyAmount: 0,
	instructions: {},
}

GoalInstruction.propTypes = {
	totalOneTransferAmount: PropTypes.number,
	totalMonthlyAmount: PropTypes.number,
	instructions: PropTypes.object,
}

export default withRouter(GoalInstruction);
