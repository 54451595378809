import firebase from "firebase/app"
import "firebase/firestore";
import { GET_FUNDING_PLANNED, GET_FUNDING_INSTRUCTIONS } from "./FundingActionsType";
import { unformatCurrency } from "../../helpers/formatCurrency";
import { loadingLayout } from "../AppMain/AppMainAction";

function getFundingPlanned(data) {
  return {
    type: GET_FUNDING_PLANNED,
    payload: data,
  }
}

function getFundingInstructions(data, investments) {
  // data.monthlyInvestment = investments.monthlyInvestment;
  // data.initialInvestment = investments.initialInvestment;
  return {
    type: GET_FUNDING_INSTRUCTIONS,
    payload: data,
  }
}

export function getDefaultFundingPlanned() {
  let arr = [];
  return dispatch => {
    firebase
      .firestore()
      .collection("New_CreateGoalFundingOverview")
      .onSnapshot(docs => {
        docs.forEach(doc => {
          arr.push({
            goalName: doc.data().GoalName,
            initialInvestmentAmount: doc.data().InitialInvestmentAmount,
            monthlyInvestmentAmount: doc.data().MonthlyInvestmentAmount,
            type: doc.data().Type
          });
        });
        let totalOneTimeTransfer = 0;
        let totalMonthlyInvestmentAmount = 0;
        arr.forEach(el => {
          totalOneTimeTransfer +=
            el.typeScreen === "EditGoal"
              ? unformatCurrency(el.topUpInvestmentAmount)
              : unformatCurrency(el.initialInvestmentAmount);
          totalMonthlyInvestmentAmount += unformatCurrency(
            el.monthlyInvestmentAmount
          );
        });
        let obj = { totalOneTimeTransfer: totalOneTimeTransfer, totalMonthlyInvestmentAmount: totalMonthlyInvestmentAmount, transfer: arr}
        dispatch(getFundingPlanned(obj));
      });
  };
}

export function getDefaultFundingInstructions(investments) {
  return dispatch => {
    //dispatch(loadingLayout('loading'));
    firebase
      .firestore()
      .collection("New_BankAccount")
      .onSnapshot(snapshots => {
        dispatch(loadingLayout(""));
        dispatch(
          getFundingInstructions(snapshots.docs[0].data())//, investments)
        );
      });
  };
}