import fileSingleMoney from '../../css/images/icons-png/file-single-money-alt.png';
import fileSingleAlt from '../../css/images/icons-png/file-single-alt.png';

export const data = {
  all: [
    { id: '1', icon: fileSingleMoney, text: 'Monthly statement 07/2018' },
    { id: '2', icon: fileSingleMoney, text: 'Monthly statement 06/2018' },
    { id: '3', icon: fileSingleMoney, text: 'Monthly statement 05/2018' },
    { id: '4', icon: fileSingleAlt, text: 'Account opening' },
  ],
  statements: [
    { id: '5', icon: fileSingleMoney, text: 'Monthly statement 07/2018' },
    { id: '6', icon: fileSingleMoney, text: 'Monthly statement 06/2018' },
    { id: '7', icon: fileSingleMoney, text: 'Monthly statement 05/2018' },
  ],
  others: [
    { id: '8', icon: fileSingleAlt, text: 'Account opening' },
  ]
};