import { UPDATE_PERSONAL_FINANCE_DATA, CLEAR_PERSONAL_FINANCE_DATA } from './constants';

const initialState = {
  formData: {},
};

function PersonalFinanceReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PERSONAL_FINANCE_DATA:
      return { ...state, formData: action.data }
    case CLEAR_PERSONAL_FINANCE_DATA:
      return { ...state, formData: initialState.formData }
      
    default:
      return state;
  }
}

export default PersonalFinanceReducer;
