import React from "react";
import PropTypes from "prop-types"
//import changeLanguage from "../../../setting/languages/languages";

class PopUp extends React.Component {
  constructor(props){
    super(props);
    //this.textStore = changeLanguage(props.language);
    this.onRightButtonClick = this.onRightButtonClick.bind(this);
  }

  onRightButtonClick(){
    this.props.onConfirmClick()
  }

  renderSecondMessage() {
    if (this.props.secondMessage !== undefined && this.props.secondMessage !== '') {
      return (
        <p style={{
          color:"#a1b0fc",
          paddingBottom: '12px',
          position: "relative",
          top: "-2px",
          fontSize: "13px",
        }}
      >
        {this.props.secondMessage}
      </p>
      )
    }
    return;
  }

  renderLeftButton() {
    if(this.props.renderLeftButton) {
      return (
        <button
          className="btn btn-default btn-cancel btn-no-focus__white"
          data-dismiss="modal"
          onClick={this.props.closePopUp}
        >
          Cancel
          {/* {this.textStore.common.cancel} */}
        </button>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
        className="modal"
        id="warningModal"
        role="dialog"
        style={{ display: "flex" }}
      >
        <div className="modal-dialog pop-up__container">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Warning
                {/* {this.textStore.common.warning} */}
                <span 
                  className="icn icn--close-blue"
                  onClick={this.props.closePopUp}
                >
                </span>
              </h5>
            </div>
            <div className="modal-body pop-up__message">
              <p>
               {this.props.message}
              </p>
              {this.renderSecondMessage()}
            </div>
            <div className="modal-footer pop-up__footer">
              {this.renderLeftButton()}
              <button
                className="btn btn-default btn-review"
                style={{ backgroundColor: "#4261ff", color: "white", width: this.props.renderLeftButton ? "" : "100%"}}
                onClick={this.onRightButtonClick}
              >
                {this.props.rightButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
      </React.Fragment>
    );
  }
}

PopUp.defaultProps = {
  message: "",
  rightButtonText: 'Review',
  secondMessage: "",
  onConfirmClick: () => {},
  closePopUp: () => {},
  renderLeftButton: true,
}

PopUp.propTypes = {
  message: PropTypes.string,
  rightButtonText: PropTypes.string,
  secondMessage: PropTypes.string,
  onConfirmClick: PropTypes.func.isRequired,
  closePopUp: PropTypes.func.isRequired,
  renderLeftButton: PropTypes.bool,
}

export default PopUp;
