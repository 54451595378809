import {
	FETCH_GOAL_DETAIL,
	PASS_DATA_FROM_DASHBOARD
} from '../../../actions/GoalDetail/GoalDetailActionType'
import formatPositions from '../../../helpers/formatPositions';
import formatAllocation from '../../../helpers/formatAllocation';
import config from '../../../components/Charts/config/HighChartConfig';
import pieChartConfig from '../../../components/Charts/config/PieChartConfigDetails';
import planningChartConfig from '../../../components/Charts/config/HighChartMultipleLines';
import requireMarker from '../../../data/marker_required';
import onTrackMarker from '../../../data/marker_on_track';
import recommendedMarker from '../../../data/marker_recommended';


const mockData = {
	currentValue: 0,
	currentPerformance:0,
	currentPerformanceAmount:0,
	title:'',
	currencyCode: '',
	type:'',
	goalIcon:'',
	id: null,
	overview:{
		probability: '',
		investmenStrategy:'',
		type:'',
		totalInvestment: '',
		monthlyInvestment: '',
		firstInvestmentDay:'',
		expectedTargetAmount:0,
		targetAmount:1,
		targetDate: '',
		chartConfig:config
	},
	performance:{
		positions:[{
			type:'',
			totalPerformance:0,
			totalAllocation:0,
			security:[{
				name:'',
				allocation:0,
				performance:0,
			}]
		}]
	},
	planning:{
		probability: '',
		investmenStrategy:'',
		totalInvestment: 0,
		monthlyInvestment: 0,
		type:'',
		expectedTargetAmount:0,
		targetAmount:0,
		targetDate:'',
		planningChartConfig:planningChartConfig
	},
	allocation:{
		fixedIncomeValue:null,
		fixedIncomeAllocation:null,
		equityValue:null,
		equityAllocation:null,
		nonTraditionalValue:null,
		nonTraditionalAllocation:null,
		pieChartConfig: pieChartConfig,
		regions:[]
	}
}



export default function( data = mockData,actions){
	switch(actions.type){
		case FETCH_GOAL_DETAIL:
			let dataPoint = ((1/(actions.payload.Goal.TargetAmount)) * data.currentValue ) * 100;
			config.series[0].data = [dataPoint]
			let positions = formatPositions(actions.payload.Positions);
			let allocations = formatAllocation(positions, data.currentValue);
			pieChartConfig.series[0].data[0].y = parseFloat((allocations.fixedIncomeAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[1].y = parseFloat((allocations.equityAllocation * 100).toFixed(2));
			pieChartConfig.series[0].data[2].y = parseFloat((allocations.nonTraditionalAllocation * 100).toFixed(2));

			let markerObj = [];
			if (data.planning.probability === 1) { // Action required
				markerObj = requireMarker;
			} else if (data.planning.probability === 2) { // On Track
				markerObj = onTrackMarker;
			} else { // Action required
				markerObj = recommendedMarker;
			}
			// First marker
			markerObj[0].marker = {
					"enabled":true,
					"symbol": "url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint.png?alt=media&token=c98c4e1a-4793-4b58-bbb4-0f8007e03edc)",
					"width":"15",
					"height":"15"
			}
		
			let series = [...planningChartConfig.series];
			series[series.length - 2].data = markerObj;
			// Do not show today line when it is general investment
			if (data.title === 'General investment') {
				series.pop();
				markerObj[1].marker = {
					"enabled":true,
					"symbol": `url()`,
					"width":50,
					"height":50
				};
			} else {
				// add "today" on highchart multiline only if the goal is not general investment. Refactor @Quan
				let marketItemLength = series.length - 1;
				markerObj[1].marker = {
					"enabled":true,
					"symbol": `url(${actions.payload.GoalIcon})`,
					"width":50,
					"height":50
				};
				// add "today" on highchart multiline only if the goal is not general investment. Refactor @Quan
				series[marketItemLength ].data[0].x = markerObj[0].x;
				series[marketItemLength ].data[0].marker ={
					"enabled":true,
					"symbol": 'url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint_Timeline.png?alt=media&token=10ea51f8-fe0e-40d5-973f-8534fd354ae5)',
					"width":10,
					"height":10
					};
				series[marketItemLength	 ].data[1].marker ={
					"enabled":true,
					"symbol": 'url(https://firebasestorage.googleapis.com/v0/b/cr40-14375.appspot.com/o/charts%2FPoint_Timeline.png?alt=media&token=10ea51f8-fe0e-40d5-973f-8534fd354ae5)',
					"width":10,
					"height":10
				};
				series[marketItemLength ].data[1].x =  data.title === 'General investment' ? series[0].data[series[0].data.length - 1].x   :markerObj[1].x	
			}
			
			let chartConfig = {...planningChartConfig};
			chartConfig.series = series;
			
			let currentPerformance = 0;
			positions.forEach(el => {
				currentPerformance += el.totalPerformance
			});
			let currentPerformanceAmount = data.currentValue  - (data.currentValue / (1+ currentPerformance))
			return { ...data,
				currentPerformanceAmount: currentPerformanceAmount,
				currentPerformance: currentPerformance,
				goalIcon:actions.payload.GoalIcon,
				overview:{ ...data.overview,
					investmenStrategy:actions.payload.RiskCategory.Name,
					monthlyInvestment:actions.payload.MonthlyInvestmentAmount,
					totalInvestment: actions.payload.TotalInvestedAmount,
					firstInvestmentDay: actions.payload.FirstInvestmentDate,
					expectedTargetAmount: actions.payload.Goal.TargetAmount,
					targetAmount:actions.payload.Goal.TargetAmount,
					targetDate:  actions.payload.Goal.TargetDate,
					chartConfig:config
				},
				performance:{
					...data.performance,
						positions:positions
				},
				planning:{
					...data.planning,
					expectedTargetAmount:actions.payload.Goal.TargetAmount,
					investmenStrategy:actions.payload.RiskCategory.Name,
					monthlyInvestment:actions.payload.MonthlyInvestmentAmount,
					totalInvestment: actions.payload.TotalInvestedAmount,
					targetAmount:actions.payload.Goal.TargetAmount,
					targetDate:  actions.payload.Goal.TargetDate,
					planningChartConfig: chartConfig,
					delta: actions.payload.ExpectedTargetAmount - actions.payload.Goal.TargetAmount
				},
				allocation:{
					...data.allocation,
					fixedIncomeValue: allocations.fixedIncomeValue ? allocations.fixedIncomeValue : null  ,
					fixedIncomeAllocation: allocations.fixedIncomeAllocation ? allocations.fixedIncomeAllocation : null,
					equityValue:allocations.equityValue ? allocations.equityValue : null,
					equityAllocation:allocations.equityAllocation ? allocations.equityAllocation : null,
					nonTraditionalValue:allocations.nonTraditionalValue ? allocations.nonTraditionalValue : null,
					nonTraditionalAllocation:allocations.nonTraditionalAllocation ? allocations.nonTraditionalAllocation : null,
					regions: allocations.regions
				}
			};
		case PASS_DATA_FROM_DASHBOARD:
			return {
				...data,
					currentValue: actions.payload.currentValue,
					//currentPerformance: actions.payload.currentPerformance,
					//currentPerformanceAmount: actions.payload.CurrentValue - (actions.payload.CurrentValue / (1+ actions.payload.CurrentPerformance)),
					title:actions.payload.title,
					currencyCode: actions.payload.currencyCode,
					type:actions.payload.type,
					id: actions.payload.id.toString(),
					overview:{
						...data.overview,
						type:actions.payload.type,
						probability:actions.payload.probability
					},
					planning: {
						...data.planning,
						probability:actions.payload.probability,
						type:actions.payload.type,
					}
			}
		default:
			return data;
	}
}