import React, { Component } from 'react';
import Headers from './Headers/Headers';
import { BrowserRouter,Route,Switch,Redirect } from 'react-router-dom';
import DashBoard from './DashBoard/DashBoard';
import Goals from './Goals/Goals';
import Auth from './Auth/Auth';
import LogOut from './Auth/LogOut';
import GoalDetail from './GoalDetail/GoalDetail';
import BankAccount from './Goals/BankAccount/BankAccount';
import GoalStrategyDetail from './Goals/GoalStrategyDetails/GoalStrategyDetail';
import GoalEdit from './Goals/GoalEdit/GoalEdit';
import { connect } from 'react-redux';
import '../css/less/main.less';
import HighChart from 'highcharts';
import TransactionHistory from '../components/TransactionHistory/TransactionHistory';
import PositionDetail from './GoalDetail/PositionDetail/PositionDetail';
import Admin from './Admin/Admin';
import EditStrategy from './EditStrategy/EditStrategy';
import GenericPageWrapper from './common/GenericPage/GenericPageWrapper';
import TransactionHistoryGenericPage from './TransactionHistory/TransactionHistoryGenericPage/TransactionHistoryGenericPage';
import TransactionHistorySecurity from './TransactionHistory/TransactionHistorySecurity/TransactionHistorySecurity';
import MonthlyFee from './TransactionHistory/TransactionHistoryMonthlyFee/TransactionHistoryMonthlyFee';
import ContactSupport from './Support/ContactSupport/ContactSupport';
import OTP from './common/OTP/OTP';
import PlannedTransfer from './Funding/PlannedTransfer/PlannedTransfer';
import FundingTerms from './Goals/FundingTerms/FundingTerms';
import CONFIG from '../setting/config';
import Onboarding from './Onboarding/Onboarding';
import DiscoverMorePage from './Onboarding/DiscoverMorePage/DiscoverMorePage';
import InvestmentDetail from './Onboarding/InvestmentDetail/InvestmentDetail';
import RiskProfiling from './Onboarding/RiskProfiling/RiskProfling';
import RiskScore from './Onboarding/RiskProfiling/RiskScore/RiskScore';

import HeaderOnlyTitle from './Headers/HeaderOnlyTitle';
import HeadersOnboarding from './Headers/HeaderOnboarding/HeadersOnboarding';
import PersonalDetail from './Onboarding/PersonalDetail/PersonalDetail';
import VerificationPage from './common/VerificationPage/VerificationPage';
import changeLanguage from '../setting/languages/languages';
import InvestmentProposal from './Onboarding/InvestmentProposal/InvestmentProposal';
import GoalCalculator from './Onboarding/GoalCalculator/GoalCalculator';
import ComplianceDeclaration from './Onboarding/ComplianceDeclaration/ComplianceDeclaration';
import PersonalParticular from './Onboarding/PersonalParticular/personal-particular';
import PersonalFinancePage from './Onboarding/containers/PersonalFinancePage';
import VisitBranchPage from './Onboarding/containers/VisitBranchPage';
import CustomerVerification from './Onboarding/CustomerVerification/CustomerVerification';
import LoadingIndicator from '../components/common/LoadingIndicator/LoadingIndicator';

import Settings from '../components/Settings/Main';
import SettingsSeconds from '../components/Settings/SecoundPopup';
import AccountDetails from '../components/Settings/AccountDetails';
import ChangeEmail from '../components/Settings/ChangeEmail';
import ChangePassword from '../components/Settings/ChangePassword';
import FinancialSituation from '../components/Settings/FinancialSituation';
import PersonalInformation from '../components/Settings/PersonalInformation';
import RiskProfile from './RiskProfile/RiskProfile';
import Notification from './Notification/Notification';
import ResetPassword from './Auth/ResetPassword';

import Documents from '../components/Documents/Main';
import DocumentSearch from '../components/Documents/DocumentSearch';

import Support from '../components/Support/Main';
import SupportCategory from './Support/SupportCategory';
import SupportDetail from './Support/SupportDetail';
import SupportSendMail from './Support/SupportSendMail';

import Engagement from './Messages/Engagement';
import Messages from './Messages/Messages';
import MessagesArchive from './Messages/MessagesArchive';
import MessagesSearch from './Messages/MessagesSearch';
import GoalAlert from './Messages/GoalAlert';
import GoalMilestone from './Messages/GoalMilestone';
import SecurityTransaction from './Messages/SecurityTransaction';
import RiskProfileExpired from './Messages/RiskProfileExpired';
import CustomerSupport from './Messages/CustomerSupport';
import NewDocument from './Messages/NewDocument';

import Feedback from '../components/Feedback/Main';
import LeaveSuggestion from '../components/Feedback/LeaveSuggestion';
import SentSuggestion from '../components/Feedback/SentSuggestion';

import { loadCTBDataRequest } from '../actions/AppMain/async';
import LinkedBankAccount from './BankAccountEdit/LinkedBankAccount/LinkedBankAccount';
import FirstAuth from './Auth/FirstAuth';

require('highcharts/highcharts-more')(HighChart);
require('highcharts/modules/solid-gauge')(HighChart);

const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

class App extends Component {  
  constructor() {
    super();
    this.state = {
      languageId: 0,
    }
    this.textStore = changeLanguage(this.state.languageId);
  }

  componentWillMount() {
    this.props.loadCTBDataRequest();
  }

  render() {  
    //EN == 0
    const languageId = this.state.languageId;
    const routesName = CONFIG.APP_ROUTE;
    const appMainUI = this.props.appMainUI.layout;
    return (
      <div 
        className={`${appMainUI} ${this.props.appMainUI.navbar} ${this.props.appMainUI.loading} `}
        id="Viewport">
        <LoadingIndicator />
        <BrowserRouter>
            <div 
              className="viewport-inner"
            >
            {appMainUI.indexOf('viewport layout--onboarding') >= 0 ?
            <HeadersOnboarding language={languageId} landingPage={appMainUI.indexOf('landing-page') >= 0} />
            : appMainUI.indexOf('layout--only-title') >= 0 ?
                <HeaderOnlyTitle language={languageId} landingPage={appMainUI.indexOf('landing-page') >= 0} />
                : <Headers language={languageId} />
            }
            <Switch>
                {/* { GA.init(this.props.authData) && <GA.RouteTracker /> } */}
                <Route
                  path="/"
                  exact
                  render={() => (
                    <Redirect
                      to={routesName.LOG_IN_APP}
                    />
                  )}
                />
                <Route
                  path={routesName.LOG_OUT}
                  exact
                  render={() => <LogOut language={languageId}/>}
                />
                <Route 
                  path={routesName.LOG_IN}
                  exact
                  render={() => <Auth language={languageId}/>}
                />
                <Route 
                  path={routesName.LOG_IN_APP}
                  exact
                  render={() => <FirstAuth language={languageId}/>}
                />
                <Route
                  path={routesName.DASHBOARD}
                  exact
                  render={() => <DashBoard language={languageId}/>}
                />
                <Route
                  path={routesName.GOALS.CREATE}
                  exact={true}
                  render={() => <Goals language={languageId}/>}
                />
                <Route
                  path={routesName.POSITION.DETAILS}
                  exact={true}
                  component={() => <PositionDetail language={languageId}/>}
                />
                <Route
                  path={routesName.GOALS.STRATEGY.DETAILS}
                  exact={true}
                  component={() => <GoalStrategyDetail language={languageId}/>}
                />
                <Route
                  path={routesName.GOALS.COMPLETE}
                  exact={true}
                  render={() => <GenericPageWrapper language={languageId} page='complete'/>}
                />
                <Route
                  path={routesName.GOALS.DETAIL.DETAILS}
                  exact
                  component={() => <GoalDetail language={languageId} />}
                />
                <Route 
                  path={routesName.BANK_ACCOUNT_EDIT.NEW}
                  exact
                  render={() => <BankAccount language={languageId}/>}
                />
                 <Route
                  path={routesName.GOALS.DETAIL.EDIT}
                  exact
                  component={() => <GoalEdit language={languageId} />}
                />
                <Route
                  path={routesName.ADMIN}
                  exact
                  component={Admin}
                />
                <Route
                  path={routesName.TRANSACTION_HISTORY.OVERVIEW}
                  exact
                  component={TransactionHistory}
                />
                <Route
                  path={routesName.TRANSACTION_HISTORY.SEARCH}
                  exact
                  render={() => <TransactionHistory screen="search"/>}

                />
                <Route
                  path={routesName.TRANSACTION_HISTORY.SECURITY}
                  exact
                  component={TransactionHistorySecurity}
                />
                <Route
                  path={routesName.TRANSACTION_HISTORY.FUNDING}
                  exact
                  render={() => <TransactionHistoryGenericPage dataType="funding"/>}
                />
                <Route
                  path={routesName.TRANSACTION_HISTORY.MONTHLY}
                  exact
                  component={MonthlyFee}
                />
                <Route
                  path={routesName.TRANSACTION_HISTORY.WITHDRAWAL}
                  exact
                  render={() => <TransactionHistoryGenericPage dataType="withdrawal"/>}
                />
                <Route
                  path={routesName.DELETED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page='delete'/>}
                />
                <Route 
                  path={routesName.ERROR}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page='error'/> }
                />
                <Route 
                  path={routesName.IN_PROGRESS}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page='inProgress'/>}
                />
                <Route
                  path={routesName.GOALS.DETAIL.EDIT_STRATEGY}
                  exact
                  component={() => <EditStrategy language={languageId}/>}
                />
                <Route
                  path={routesName.GOALS.DETAIL.EDIT_STRATEGY_COMPLETED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page='complete'/>}
                />
                <Route
                  path={routesName.REFERRAL}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page='referral'/>}
                />
                <Route
                  path={routesName.CONTACT_SUPPORT}
                  exact
                  component={ContactSupport}
                />
                <Route
                  path={routesName.MESSAGE_SUCCESS}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page='message'/>}
                />

                {/* Onboarding */}
                <Route
                  path={routesName.ONBOARDING.MAIN_PAGE}
                  exact
                  render={() => <Onboarding language={languageId} page='message'/>}
                />
                <Route
                  path={routesName.OTP}
                  exact
                  render={() => 
                    <OTP
                      language={languageId}
                      icon="icn--otp-lock"
                      title="Confirm withdrawal"
                      description="Please enter the code we have sent to your mobile device below to confirm your withdrawal. The code is valid for five minutes."
                      type="otp"
                    />
                  }
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.OTP.INPUT}
                  exact
                  render={() => 
                    <OTP
                      language={languageId}
                      icon="icn--verify-phone-portrait"
                      title="Verification"
                      description="Please enter the 6-digit one time password that we have sent via SMS to your registered mobile number."
                      isOnboarding={true}
                      type="otpOnboarding"
                    />
                  }
                />
                <Route
                  path={routesName.SIGN_OUT}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page='signOut'/>}
                />
                <Route
                  path={routesName.FUNDING_WITHDRAWAL}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page='inProgress'/>}
                />
                <Route
                  path={routesName.PLANNED_INVESTMENT}
                  exact
                  render={() => <PlannedTransfer language={languageId}/>}
                />
                <Route
                  path={routesName.FUNDING_TERMS}
                  exact
                  component={FundingTerms}
                />
                <Route
                  path={routesName.ONBOARDING.DISCOVER_MORE}
                  exact
                  render={() => <DiscoverMorePage language={languageId}/>}
                />
                <Route
                  path={routesName.ONBOARDING.INVESTMENT_DETAIL}
                  exact
                  render={() => <InvestmentDetail language={languageId}/>}
                />
                <Route
                  path={routesName.ONBOARDING.PERSONAL_DETAIL}
                  exact
                  render={() => <PersonalDetail language={languageId}/>}
                />
                <Route
                  path={routesName.ONBOARDING.PERSONAL_PARTICULAR}
                  exact
                  render={() => <PersonalParticular language={languageId}/>}
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.VERIFYING}
                  exact
                  render={() => 
                    <VerificationPage 
                      language={languageId} 
                      successLink={routesName.ONBOARDING.VERIFY.SUCCESS}
                      successStates={{
                        title: this.textStore.verification.verifySuccess,
                        desc: this.textStore.verification.mobileNumberVerified, 
                        buttonName: this.textStore.common.continueToOTP
                      }}
                      failedLink={routesName.ONBOARDING.VERIFY.FAILED}
                      failedStates={{
                        title: this.textStore.verification.verifyFailed,
                        buttonName: this.textStore.common.backToVerification
                      }}
                      timedoutLink={routesName.ONBOARDING.VERIFY.TIMED_OUT}
                      timedoutStates={{
                        title: this.textStore.verification.verificationTimeOut,
                        desc: this.textStore.verification.exceedVerifyDuration,
                        buttonName: this.textStore.common.requestNewVerificationCode
                      }}
                    />
                  }
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.FAILED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingFailed"/>}
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.SUCCESS}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingSuccess"/>}
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.OTP.VERIFYING}
                  exact
                  render={() => 
                    <VerificationPage
                      language={languageId}
                      successLink={routesName.ONBOARDING.VERIFY.OTP.SUCCESS}
                      successStates={{
                        title: this.textStore.verification.verifySuccess,
                        desc: this.textStore.common.mobileNumberUpdatedSuccess, 
                        buttonName: this.textStore.common.continueToRiskProfiling
                      }}
                      failedLink={routesName.ONBOARDING.VERIFY.OTP.FAILED}
                      failedStates={{
                        title: this.textStore.verification.verifyFailed,
                        buttonName: this.textStore.common.requestNewVerificationCode
                      }}
                      timedoutLink={routesName.ONBOARDING.VERIFY.OTP.TIMED_OUT}
                      timedoutStates={{
                        title: this.textStore.verification.verificationTimeOut,
                        desc: this.textStore.verification.exceedVerifyDuration,
                        buttonName: this.textStore.common.requestNewVerificationCode
                      }}
                    />
                  }
                />

                {/* ======================================================== */}
                <Route
                  path={routesName.ONBOARDING.VISIT_BRANCH.PATH}
                  exact
                  render={() => <VisitBranchPage language={languageId}/>}
                />

                {/* !======================================================= */}
                {/* ======================================================== */}
                <Route
                  path={routesName.ONBOARDING.PERSONAL_FINANCE.PATH}
                  exact
                  render={() => <PersonalFinancePage language={languageId}/>}
                />
                
                <Route
                  path={routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.VERIFYING}
                  exact
                  render={() => 
                    <VerificationPage 
                      language={languageId} 
                      successLink={routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.SUCCESS}
                      successStates={{
                        title: this.textStore.PersonalFinance.verify.title,
                        desc: this.textStore.PersonalFinance.verify.desc, 
                        buttonName: this.textStore.PersonalFinance.verify.buttonName,
                        footer: '',
                        routerName: routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.SUCCESS,
                      }}
                      failedLink={routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.FAILED}
                      failedStates={{
                        title: this.textStore.PersonalFinance.failure.title,
                        buttonName: this.textStore.PersonalFinance.failure.buttonName,
                        desc: this.textStore.PersonalFinance.failure.desc,
                        footer: this.textStore.PersonalFinance.failure.footer,
                      }}
                      timedoutLink={routesName.ONBOARDING.PERSONAL_FINANCE.PATH}
                    />
                  }
                />

                <Route
                  path={routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.FAILED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingFailed"/>}
                />
                <Route
                  path={routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.SUCCESS}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingSuccess"/>}
                />

                {/*! ========================================= */}

                <Route
                  path={routesName.ONBOARDING.VERIFY.INVESTMENT_PROPOSAL.VERIFYING}
                  exact
                  render={() => 
                    <VerificationPage
                      language={languageId}
                      successLink={routesName.ONBOARDING.PERSONAL_PARTICULAR}
                      failedLink={routesName.ONBOARDING.VERIFY.INVESTMENT_PROPOSAL.FAILED}
                      failedStates={{
                        title: this.textStore.investmentProposal.goalCreateFailed,
                        desc: this.textStore.investmentProposal.goalCreatationNotSuccess,
                        buttonName: this.textStore.common.backToVerification
                      }}
                      timedoutLink={routesName.ONBOARDING.GOAL_CALCULATOR}
                    />
                  }
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.INVESTMENT_PROPOSAL.FAILED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingFailed"/>}
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.OTP.FAILED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingFailed"/>}
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.OTP.SUCCESS}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingSuccess"/>}
                />
                {/* TODO: Timed out link */}
                <Route
                  path={routesName.ONBOARDING.VERIFY.TIMED_OUT}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="timedout"/>}
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.OTP.TIMED_OUT}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="timedout"/>}
                />
                <Route
                  path={routesName.ONBOARDING.RISK_PROFILING.QUESTIONNAIRE}
                  exact
                  render={() => <RiskProfiling language={languageId}/>}
                />
                
                <Route
                  path={routesName.ONBOARDING.REQUEST.REQUESTING}
                  exact
                  render={() => 
                    <VerificationPage
                      language={languageId}
                      successLink={routesName.ONBOARDING.RISK_PROFILING.RISK_SCORE}
                      failedLink={routesName.ONBOARDING.REQUEST.FAILED}
                      failedStates={{
                        title: this.textStore.riskProfiling.riskProfilingFailed,
                        desc: this.textStore.riskProfiling.yourRiskProfilingAssessmentFailed,
                        buttonName: this.textStore.common.tryAgain,
                      }}
                      timedoutLink={routesName.ONBOARDING.REQUEST.TIMED_OUT}
                      timedoutStates={{
                        title: this.textStore.riskProfiling.riskProfilingTimeout,
                        desc: this.textStore.riskProfiling.youHadExceed,
                        buttonName: this.textStore.common.tryAgain,
                      }}
                    />
                  }
                />
                <Route
                  path={routesName.ONBOARDING.REQUEST.FAILED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingFailed"/>}
                />
                <Route
                  path={routesName.ONBOARDING.REQUEST.TIMED_OUT}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="timedout"/>}
                />
                <Route
                  path={routesName.ONBOARDING.RISK_PROFILING.RISK_SCORE}
                  exact
                  render={() => <RiskScore language={languageId}/>}
                />
                 <Route
                  path={routesName.ONBOARDING.INVESTMENT_PROPOSAL}
                  exact
                  render={() => <InvestmentProposal language={languageId}/>}
                />
                <Route
                  path={routesName.ONBOARDING.GOAL_CALCULATOR}
                  exact
                  render={() => <GoalCalculator language={languageId}/>}
                />
                <Route
                  path={routesName.ONBOARDING.COMPLIANCE_DECLARATION}
                  exact
                  render={() => <ComplianceDeclaration language={languageId}/>}
                />
                
                {/* ------------- online veritication ---------- */}
                <Route
                  path={routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.VERIFYING}
                  exact
                  render={() => (
                    <VerificationPage
                      language={languageId}
                      successLink={routesName.ONBOARDING.COMPLIANCE_DECLARATION}
                      failedLink={routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.FAILED}
                      failedStates={{
                        title: this.textStore.customerOnlineVerification.failure.title,
                        desc:
                          'Online verification was not successful. Please try again or contact the customer support for assistance.',
                        buttonName: this.textStore.PersonalFinance.failure.buttonName
                      }}
                      timedoutLink={routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.PATH}
                    />
                  )}
                />
                <Route
                  path={routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.FAILED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingFailed" />}
                />
                
                <Route
                  path={routesName.ONBOARDING.VERIFY.COMPLIANCE_DECLARATION.VERIFYING}
                  exact
                  render={() => 
                    <VerificationPage
                      language={languageId}
                      successLink={routesName.ONBOARDING.GREETING}
                      failedLink={routesName.ONBOARDING.VERIFY.COMPLIANCE_DECLARATION.FAILED}
                      failedStates={{
                        title: this.textStore.complianceDeclaration.onlineVerificationFailed,
                        desc: this.textStore.complianceDeclaration.onlineVerificationNotSuccess,
                        buttonName: this.textStore.common.backToVerification
                      }}
                      timedoutLink={routesName.ONBOARDING.VERIFY.OTP.TIMED_OUT}
                    />}
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.COMPLIANCE_DECLARATION.FAILED}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="loadingFailed"/>}
                />
                <Route
                  path={routesName.ONBOARDING.VERIFY.COMPLIANCE_DECLARATION.TIMED_OUT}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="timedout"/>}
                />
                <Route
                  path={routesName.ONBOARDING.GREETING}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="greeting"/>}
                />
                <Route
                  path={routesName.ONBOARDING.KYC.ONLINE_VERIFICATION.PATH}
                  exact
                  render={() => <CustomerVerification language={languageId} />}
                />
                <Route
                  path={routesName.RISK_PROFILE}
                  exact
                  render={() => <RiskProfile language={languageId}/>}
                />
                <Route
                  path={routesName.SETTINGS_UPDATED}
                  render={() => <GenericPageWrapper language={languageId} page="settingsCompleted"/>}
                />
                <Route
                  path={routesName.NOTIFICATION}
                  exact
                  render={() => <Notification language={languageId}/>}
                />
                <Route
                  path={routesName.RESET_PASSWORD.EMAIL_INPUT}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="resetPassword"/>}
                />
                <Route
                  path={routesName.RESET_PASSWORD.EMAIL_SENT}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="emailSent"/>}
                />
                <Route
                  path={routesName.RESET_PASSWORD.NEW_PASSWORD}
                  exact
                  render={() => <ResetPassword language={languageId}/>}
                />
                <Route
                  path={routesName.RESET_PASSWORD.COMPLETE}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="passwordUpdated"/>}
                />
				        <Route
                  path={routesName.SETTINGS.MAIN}
                  exact
                  render={() => <Settings language={languageId} />}
                />
                <Route
                  path={routesName.SETTINGS.MAIN_SECONDS}
                  exact
                  render={() => <SettingsSeconds language={languageId} />}
                />
                <Route
                  path={routesName.SETTINGS.ACCOUNT_DETAILS}
                  exact
                  render={() => <AccountDetails language={languageId} />}
                />
                <Route
                  path={routesName.SETTINGS.CHANGE_EMAIL}
                  exact
                  render={() => <ChangeEmail language={languageId} />}
                />
                <Route
                  path={routesName.SETTINGS.CHANGE_PASSWORD}
                  exact
                  render={() => <ChangePassword language={languageId} />}
                />
                <Route
                  path={routesName.SETTINGS.FINANCIAL_SITUATION}
                  exact
                  render={() => <FinancialSituation language={languageId} />}
                />
                <Route
                  path={routesName.SETTINGS.PERSONAL_INFORMATION}
                  exact
                  render={() => <PersonalInformation language={languageId} />}
                />
                
                <Route
                  path={routesName.FEEDBACK.MAIN}
                  exact
                  render={() => <Feedback language={languageId} />}
                />
                <Route
                  path={routesName.FEEDBACK.LEAVE_SUGGESTION}
                  exact
                  render={() => <LeaveSuggestion language={languageId} />}
                />
                <Route
                  path={routesName.FEEDBACK.SENT_SUGGESTION}
                  exact
                  render={() => <SentSuggestion language={languageId} />}
                />
                
                <Route
                  path={routesName.DOCUMENTS.MAIN}
                  exact
                  render={() => <Documents language={languageId} />}
                />
                <Route
                  path={routesName.DOCUMENTS.SEARCH}
                  exact
                  render={() => <DocumentSearch language={languageId} />}
                />

                <Route 
                  path={routesName.SUPPORT.MAIN}
                  exact
                  render={() => <Support language={languageId} />}
                />
                <Route 
                  path={routesName.SUPPORT.CATEGORY}
                  exact
                  render={() => <SupportCategory language={languageId} />}
                />
                <Route 
                  path={routesName.SUPPORT.DETAIL}
                  exact
                  render={() => <SupportDetail language={languageId} />}
                />
                <Route
                  path={routesName.SUPPORT.SEND_MAIL}
                  exact
                  component={SupportSendMail}
                />
                
                <Route
                  path={routesName.MESSAGES.MAIN}
                  exact
                  component={Messages}
                />
                <Route
                  path={routesName.MESSAGES.ENGAGEMENT}
                  exact
                  component={Engagement}
                />
                <Route
                  path={routesName.MESSAGES.ARCHIVE}
                  exact
                  component={MessagesArchive}
                />
                <Route
                  path={routesName.MESSAGES.SEARCH}
                  exact
                  component={MessagesSearch}
                />
                <Route
                  path={routesName.MESSAGES.GOAL_MILESTONE}
                  exact
                  component={GoalMilestone}
                />
                <Route
                  path={routesName.MESSAGES.GOAL_ALERT}
                  exact
                  component={GoalAlert}
                />
                <Route
                  path={routesName.MESSAGES.SECURITY_TRANSACTION}
                  exact
                  component={SecurityTransaction}
                />
                <Route
                  path={routesName.MESSAGES.RISK_PROFILE_EXPIRED}
                  exact
                  component={RiskProfileExpired}
                />
                <Route
                  path={routesName.MESSAGES.NEW_DOCUMENT}
                  exact
                  component={NewDocument}
                />
                <Route
                  path={routesName.MESSAGES.CUSTOMER_SUPPORT}
                  exact
                  component={CustomerSupport}
                />

                <Route
                  path={routesName.BANK_ACCOUNT_EDIT.LINKED}
                  exact
                  render={() => <LinkedBankAccount language={languageId}/>}
                />
                <Route
                  path={routesName.BANK_ACCOUNT_EDIT.UPDATE}
                  exact
                  render={() => <BankAccount language={languageId} type="update"/>}
                />
                <Route
                  path={routesName.VERIFY_EMAIL}
                  exact
                  render={() => <GenericPageWrapper language={languageId} page="verifyEmail"/>}
                />
                <Route
                  path="*"
                  exact
                  render={() => (
                    <Redirect
                      to={routesName.ERROR}
                    />
                  )}
                />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}
function mapStateToProps(props){
  return {
    authData:props.authData,
    appMainUI:props.appMainUI,
  } 
}
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadCTBDataRequest: () => dispatch(loadCTBDataRequest()),
});


export default connect(mapStateToProps, mapDispatchToProps)(App);
