import React from 'react';
import TabBar from '../TabBar/TabBar';
import AmountValue from '../../DashBoard/AmountValue/AmountValue';
import PositionOverview from './PostionOverview';
import { connect } from 'react-redux';
import withBaseLayout from '../../HoC/withBaseLayout';
import ReactHighStock from 'react-highcharts/ReactHighstock';
import { switchTabBar } from '../../../actions/TabBar/TabBarActions';
import { switchHeader } from '../../../actions/Header/HeaderActions';
import { changeToRelativeChart,changeToAmountChart,resetLabelDate } from '../../../actions/Chart/ChartActions';
import ChartRange from '../../Charts/ChartRange/ChartRange';

class PositionDetail extends React.Component {
  constructor(){
    super()
    this.stockRef = React.createRef();
  }

  componentDidMount(){
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.props.positionDetailData.name
      },
      headerRight: "",
      currentPage: "position"
    });
    this.props.switchTabBar('Overview')
    this.props.changeToRelativeChart()
  }

  renderSection(){
    switch(this.props.tabBarUI.currentTab){
      case 'Overview':
        return <PositionOverview positionDetail={this.props.positionDetailData} />
      case 'Performance':
        return (
          <React.Fragment>
            <div className="chart chart--performance" style={{margin: '40px 0 10px 0'}}>
              <div className="chart__container">
                <div className="chart__element__outer">
                  <div className="chart__element__inner">
                    <ReactHighStock
                      config={this.props.chartUIRelative} 
                      domProps={{className:"chart__element"}} 
                      ref={this.stockRef}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="chart__range">
              <ChartRange 
                chartIndex={1}
                stockRef={this.stockRef} 
                padding={'1px 11px'}
              />
            </div>

          </React.Fragment>
        )
      default:
        return <PositionOverview positionDetail={this.props.positionDetailData} />
    }
  }
  render(){
    const tabs= ['Overview','Performance']
    const data = {
			currencyCode:this.props.positionDetailData.currencyCode,
			currentValue:this.props.positionDetailData.currentValue,
			isNewUser:false,
			currentPerformanceAmount:this.props.positionDetailData.performanceAmount,
			currentPerformance:this.props.positionDetailData.performance
    }
    const settings = {
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
			slide:"ul",
      swipeToSlide:true,
      infinite:false
		};
    return (
      <div className="content-main__body">
        <section className="invest-summary">
          <AmountValue amountValue={data} />
        </section>
        <nav className="tablist">
          <TabBar 
            tabs={tabs}
            settings={settings}
          />
        </nav>
        <div className="tabs">
          <section className="tabpannel">
            {this.renderSection()}
          </section>
        </div>
 
      </div>
    )
  }
  componentWillUnmount(){
    this.props.changeToAmountChart()
    this.props.resetLabelDate()
  }
}

const PositionDetailEnhanced = withBaseLayout(PositionDetail);

function mapStateToProps(props){
  return {
    tabBarUI:props.tabBarUI,
    chartUI:props.chartUI,
    chartUIRelative: props.chartUIRelative,
    positionDetailData:props.positionDetailData
  }
}

export default connect(mapStateToProps, { 
  switchTabBar,
  switchHeader,
  changeToRelativeChart,
  changeToAmountChart,
  resetLabelDate 
})(PositionDetailEnhanced);