const LEGENDS_STYLES = {
	STRING_STYLES: `<style>
		.highcharts-container  {margin-bottom: 22px}
		.highcharts-legend-item .highcharts-graph {
			display:none
		}
		.robo-legend-label{
			min-height: 18px;
			position:relative;
		}
		.robo-legend-label-icon {
			position: absolute; 
			left: -21px;
			top: 9px
		}
	</style>`,
	'Most likely': `
		height:10px;
		width:10px;
		border-radius: 5px;
		background:#a1b0fc;
		top: 5px;
		left: -18px;
	`,
	'Less likely': `	
		height:10px;
		width:10px;
		border-radius: 5px;
		background:#d2e1ff;
		top: 5px;
		left: -18px;
	`,
	'Expected returns': `
		height: 2px;
		width: 16px;
		background: #4261ff
	`,
	'Capital invested': `
		height:0;
		width:16px;
		border-top: 2px dashed #000;
	`
};

export { LEGENDS_STYLES };
