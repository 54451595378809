import { formatCurrency, formatRoundedCurrency } from '../../../helpers/formatCurrency';
import { nominalTypeHack } from 'prop-types';
//import yourInvestment from "../../../data/your_investment_onboarding.json";
//import worstCase from "../../../data/worst_case.json";
//import expectedCase from "../../../data/expected_case_onboarding.json";
//import bestCase from "../../../data/best_case.json";
import {LEGENDS_STYLES} from './OnboardingHighChartStyles';
const moment = require('moment');

const symbolsStyle = (color) => ({
	circle: `
		display: inline-block;
		position: absolute;
		top: 3px;
		left: 3px;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: ${color};
	`,
	diamond: `
		display: inline-block;
		position: absolute;
		top: 3px;
		left: 3px;
		width: 6px;
		height: 6px;
		background-color: ${color};
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	`,
	square: `
		display: inline-block;
		position: absolute;
		top: 3px;
		left: 3px;
		width: 6px;
		height: 6px;
		background-color: ${color};
	`,
	triangle: `
		display: inline-block;
		position: absolute;
		top: 3px;
		left: 3px;
		width: 6px;
		height: 6px;
		border-bottom: solid 6px ${color};
		border-left: solid 3px transparent;
		border-right: solid 3px transparent;
		box-sizing: border-box;
	`,
	'triangle-down': `
		display: inline-block;
		position: absolute;
		top: 3px;
		left: 3px;
		width: 6px;
		height: 6px;
		border-top: solid 6px ${color};
		border-left: solid 3px transparent;
		border-right: solid 3px transparent;
		box-sizing: border-box;
	`,
});


function hexToRgb(hex) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}

function getSymbol(point) {
	const rgbClr = hexToRgb(point.series.color) || {};
	return `<span 
			style="
				position: relative;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        width: 12px;
        height: 12px;
        text-align: center;
        background: rgba(${rgbClr.r}, ${rgbClr.g}, ${rgbClr.b}, 0.25);
        border-radius: 50%;
        line-height: .7;
			"
			><span style="${symbolsStyle(point.series.userOptions.marker.fillColor)[point.series.symbol]}"></span></span>`;
}

const fontStyle = {
	color: '#B3C0FF',
	fontFamily: 'OpenSans',
	fontSize: '13px',
};

const config = {
	credits: {
		enabled: false,
	},
	navigator: {
		enabled: false,
	},
	scrollbar: {
		enabled: false,
	},
	exporting: { enabled: false },
	title: {
		enabled: false,
		text: '',
	},
	chart: {
		style: fontStyle,
		spacing: 0,
		zoomType: '',
		pinchType: '',
		title: '',
		// panning: false,
	},
	legend: {
		margin: 12,
		align:'left',
		enabled: true,
		useHTML: true,
		squareSymbol: false,
		symbolHeight: 0,
		symbolWidth: 0,
		symbolPadding: 30,
		itemMarginBottom: 8,
		itemHiddenStyle: {
			color: '',
			fill: 'none',
		},
		labelFormatter: function() {
			return (
				`${LEGENDS_STYLES.STRING_STYLES}<span class="robo-legend-label">
					<i class="robo-legend-label-icon" style="${LEGENDS_STYLES[this.name]}"></i>
                ${this.name}
                </span>`
			);
		},
		itemStyle: {
			display: 'block',
			fontFamily: 'OpenSans',
			fontSize: '13px',
			fontWeight: 'normal',
		}
	},
	plotOptions: {
		spline: {},
		series: {
			events: {
					legendItemClick: function (e) {
							e.preventDefault();
					}
			}
		}

	},
	useHTML: true,
	tooltip: {
		shared: true,
    crosshairs: true,
		borderColor: '#4261FF',
		valueDecimals: 2,
		useHTML: true,
		formatter: function() {
			const points = this.points.splice(0, 4);
			const renderPoint = points.filter((point) => (point.color !== 'transparent'));
			const inner = renderPoint.reduce(function(s, point, idx) {
				const dataStyles = "font-family: OpenSans; font-size: 12px; line-height: 20px"
				const dateStyles = "font-family: OpenSans; font-size: 12px; font-weight: bold; line-height: 20px"

				const data = `<span style="${dataStyles}">USD ${formatRoundedCurrency(point.y,)}</span>`
				const date = idx === 0 ? `<span style="${dateStyles}">${moment(point.x).format("DD MMM YYYY")}</span>` : '';
				
				return s + `${date}<br/>${getSymbol(point)} &nbsp;${data}`;
			}, '');

			return `<div style="
        text-align: left; 
        border: 1px solid #7a91ff; 
        border-radius: 4px; 
				padding: 5px;
				background: #ffffff;
        box-shadow: 0px 2px 4px 0px #a1b0fc;
      ">${inner}</div>`;
		},
		shadow: false,
		borderWidth: 0,
		backgroundColor: 'rgba(255,255,255,0)',
	},
	xAxis: {
		lineWidth: 0,
		minorGridLineWidth: 0,
		lineColor: 'transparent',
		minorTickLength: 0,
		tickLength: 0,
		type: 'datetime',
		labels: {
			formatter: function() {
				if (this.isFirst) {
					return 'Today';
				}
				return moment(this.value).format("MM.YYYY");
			},
			style: fontStyle,
		},
		ordinal: false,
		minPadding: 0.15,
		maxPadding: 0.02,
		tickPositioner: function() {
			let positions = [];
			positions.push(this.dataMin);
			positions.push(this.dataMin + (this.dataMax - this.dataMin) / 2);
			positions.push(this.dataMax);
			return positions;
		},
		offset: -1,
	},
	yAxis: {
		opposite: false,
		gridLineDashStyle: 'dot',
		gridLineColor: '#a1b0fc',
		gridLineWidth: 1.6,
		title: '',
		min: 0,
		labels: {
			formatter: function() {
				if (this.value >= 1000000) {
					return formatCurrency(this.value / 1000000, 1) + 'M';
				}
				return this.value / 1000 + 'K';
			},
			style: fontStyle,
			x: 20,
			y: -5
		},
	},

	series: [
		{
			name: 'Most likely', // +1
			data: [],
			color: 'rgba(161, 176, 252, .1)',
			marker: {
				enabledThreshold: 100,
				fillColor: '#4261ff', //blue
				symbol: 'circle',
				radius: 2,
			},
			zIndex: 2,
			legendIndex: 3,
		},
		{
			name: 'Expected returns',
			data: [],
			color: '#4261ff',
			marker: {
				enabledThreshold: 100,
				fillColor: '#f5a623', //orange
				symbol: 'square',
				radius: 1,
			},
			zIndex: 2,
			legendIndex: 1,
		},	
		{
			name: 'Less likely', //-1
			data: [],
			color: 'rgba(161, 176, 252, .1)',
			marker: {
				enabledThreshold: 100,
				fillColor: '#26d4a5', //green
				symbol: 'diamond',
				radius: 2,
			},
			zIndex: 2,
			legendIndex: 4,
		},
		{
			name: 'Capital invested',
			data: [],
			dashStyle: 'ShortDash',
			color: '#2c2c39',
			marker: {
				enabledThreshold: 100,
				fillColor: '#2c2c39', // black
				symbol: 'triangle',
				radius: 2,
			},
			zIndex: 2,
			legendIndex: 2,
		},
		// {
		// 	name: '', // +2
		// 	data: [],
		// 	color: '#d2e1ff',
		// 	marker: { 
		// 		enabled: false 
		// 	},
		// 	showInLegend: false,
		// },
		// {
		// 	name: '', // -2
		// 	data: [],
		// 	color: '#d2e1ff',
		// 	marker: { 
		// 		enabled: false 
		// 	},
		// 	showInLegend: false,
		// },
		{
			name: '',
			data: [],
			type: 'arearange',
			color: '#a1b0fc',
			marker: { 
				enabled: false 
			},
			enableMouseTracking: false,
			zIndex: 1,
			showInLegend: false,
		},
		{
			name: '',
			data: [],
			type: 'arearange',
			color: '#d2e1ff',
			marker: { 
				enabled: false 
			},
			enableMouseTracking: false,
			zIndex: 0,
			showInLegend: false,
		},
		{
			name: '',
			color: 'transparent',
			className: 'hide-text',
			showInLegend: false, // ICOn
			data: [],
			zIndex: 2,
		},
		{
			name: 'Today Line',
			color: '#4261ff',
			data: [{ x: 0, y: 0 }, { x: 0, y: 0 }],
			showInLegend: false,
		},
	],
};
export default config;
