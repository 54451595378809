import React from 'react';
import { connect } from 'react-redux';
import { 
	handleGoalName,
	changeTargetAmount,
	handleInitialInvestment,
	changeInitialInvestment,
	changeMonthlyInvestment,
	handleTargetAmount,
	handleMonthlyInvestment,	
	handleTargetDate,
	calculateProbability
} from '../../../actions/Goals/GoalActions';
import { formatCurrency,unformatCurrency } from '../../../helpers/formatCurrency';
import changeLanguage from '../../../setting/languages/languages';
//TODO: move constant value to separate files for maintainability, refactor errors handling;

class GoalCreate extends React.Component {
	constructor(props){
		super(props)
		this.textStore = changeLanguage(props.language)
		this.onGoalNameChange = this.onGoalNameChange.bind(this);
		this.onTargetAmountChange = this.onTargetAmountChange.bind(this);
		this.onTargetAmountBlur = this.onTargetAmountBlur.bind(this);
		this.onTargetAmountFocus = this.onTargetAmountFocus.bind(this);
		this.onInitialInvestmentChange = this.onInitialInvestmentChange.bind(this);
		this.onInitialInvestmentBlur = this.onInitialInvestmentBlur.bind(this);
		this.onInitialInvestmentFocus = this.onInitialInvestmentFocus.bind(this);
		this.onMonthlyInvestmentChange = this.onMonthlyInvestmentChange.bind(this);
		this.onMonthlyInvestmentBlur = this.onMonthlyInvestmentBlur.bind(this);
		this.onMonthlyInvestmentFocus = this.onMonthlyInvestmentFocus.bind(this);
		this.onTargetDateChange = this.onTargetDateChange.bind(this);
		this.onCalculateClick = this.onCalculateClick.bind(this);
	}
	componentDidMount() {
		this.props.calculateProbability()
	}
	
	onGoalNameChange(e){
		if(e.target.value.length <= 35){
			this.props.handleGoalName(e.target.value);
		}
	}

	onTargetAmountChange(e){
		if(e.target.value.length <= 12){
			let initialInvestment = this.props.goalData.initialInvestment;
			if (this.props.screenUI.currentScreen === this.textStore.screen.editGoal) {
				initialInvestment = this.props.goalData.topUpInvestmentAmount;
			}
			this.props.handleTargetAmount(e.target.value, unformatCurrency(initialInvestment), unformatCurrency(this.props.goalData.monthlyInvestment));
		}
	}

	onInitialInvestmentChange(e){
		if(e.target.value.length <= 12){
			this.props.handleInitialInvestment(e.target.value, unformatCurrency(this.props.goalData.monthlyInvestment), unformatCurrency(this.props.goalData.targetAmount), this.props.goalData.type);
		}
	}

	onMonthlyInvestmentChange(e){
		if(e.target.value.length <= 12){
			let initialInvestment = this.props.goalData.initialInvestment;
			if (this.props.screenUI.currentScreen === this.textStore.screen.editGoal) {
				initialInvestment = this.props.goalData.topUpInvestmentAmount;
			}
			this.props.handleMonthlyInvestment(e.target.value, unformatCurrency(initialInvestment), unformatCurrency(this.props.goalData.targetAmount), this.props.goalData.type);
		}
	}

	onTargetDateChange(e){
		if(e.target.value.length <= 10 ){
			this.props.handleTargetDate(e.target.value);
		}
	}

	onTargetAmountBlur(){
		if(!this.props.goalUI.goalCreate.targetAmountError){
			 this.props.changeTargetAmount(formatCurrency(this.props.goalData.targetAmount));
		}
	}

	onInitialInvestmentBlur(){
		if(!this.props.goalUI.goalCreate.initialAmountError){
			this.props.changeInitialInvestment(formatCurrency(this.props.goalData.initialInvestment));
	 	}
	}

	onMonthlyInvestmentBlur(){
		if(!this.props.goalUI.goalCreate.monthlyAmountError){
			this.props.changeMonthlyInvestment(formatCurrency(this.props.goalData.monthlyInvestment));
		}
	}

	onTargetAmountFocus(){
		this.props.changeTargetAmount(unformatCurrency(this.props.goalData.targetAmount));
	}

	onInitialInvestmentFocus(){
		this.props.changeInitialInvestment(unformatCurrency(this.props.goalData.initialInvestment));
	}

	onMonthlyInvestmentFocus(){
		this.props.changeMonthlyInvestment(unformatCurrency(this.props.goalData.monthlyInvestment));
	}

	onCalculateClick(){
		this.props.calculateProbability();
	}

	render(){
		return (
			<div 
				className="form-section"
				style={{marginTop:"20px"}}
			>
				<div className="form-group">
					<label 
						className="control-label" 
						htmlFor="GoalName"
					>
						Goal name
					</label>
					<div className="control-wrapper">
						<input 
							type="text" 
							id="GoalName" 
							name="GoalName" 
							className={`form-control ${this.props.goalUI.goalCreate.goalNameError ? 'error-border-bottom' : ''}`}
							onChange={this.onGoalNameChange}
							value={this.props.goalData.goalName}
						/>
						<span
							className="error-message"
							style={{display: this.props.goalUI.goalCreate.goalNameError ? '' : "none"}}
						>
							Please enter a name
						</span>
					</div>
				</div>
				<div 
					className="form-group"
					style={{display:this.props.goalData.type === "investment" ? 'none' : ''}}
				>
					<label 
						className="control-label" 
						htmlFor="GoalAmount"
					>
						Target
						<span className="tooltip-toggle" role="button" tabIndex="-1"/>
					</label>
					<div className="control-wrapper">
						<input 
							type="text" 
							id="GoalAmount" 
							name="GoalAmount" 
							className={`form-control ${this.props.goalUI.goalCreate.targetAmountError ? 'error-border-bottom' : ''}`}
							onChange={this.onTargetAmountChange}
							onBlur={this.onTargetAmountBlur}
							onFocus={this.onTargetAmountFocus}
							value={this.props.goalData.targetAmount}
							data-module="CurrencyInput" 
						/>
						<span
							className="error-message"
							style={{display: this.props.goalUI.goalCreate.targetAmountError ? '' : "none"}}
						>
							{this.props.goalUI.goalCreate.targetAmountErrorMessage}
						</span>
					</div>
				</div>
				<div className="form-group" 
					style={{display: this.props.screenUI.currentScreen !== 'goalEditScreen' ? '' : 'none'}}>
					<label 
						className="control-label" 
						htmlFor="InitialInvestment"
					>
						Initial investment
						<span className="tooltip-toggle" role="button" tabIndex="-1"/>
					</label>
					<div className="control-wrapper">
						<input 
							type="text" 
							id="InitialInvestment" 
							name="InitialInvestment" 
							className={`form-control ${this.props.goalUI.goalCreate.initialAmountError ? 'error-border-bottom' : ''}`}
							onChange={this.onInitialInvestmentChange}
							onBlur={this.onInitialInvestmentBlur}
							onFocus={this.onInitialInvestmentFocus}
							value={this.props.goalData.initialInvestment}
							data-module="CurrencyInput" 
						/>
						<span
							className="error-message"
							style={{
								display: this.props.goalUI.goalCreate.initialAmountError ? '' : "none"
							}}
						>
							{this.props.goalUI.goalCreate.initialAmountErrorMessage}
						</span>
					</div>
				</div>
				<div className="form-group">
					<label 
						className="control-label" 
						htmlFor="RecurringInvestment"
					>
						Monthly investment
						<span className="tooltip-toggle" role="button" tabIndex="-1"></span>
					</label>
					<div className="control-wrapper">
						<input 
							type="text" 
							id="RecurringInvestment" 
							name="RecurringInvestment" 
							onChange={this.onMonthlyInvestmentChange}
							onBlur={this.onMonthlyInvestmentBlur}
							onFocus={this.onMonthlyInvestmentFocus}
							className={`form-control ${this.props.goalUI.goalCreate.monthlyAmountError ? 'error-border-bottom' : ''}`}
							value={this.props.goalData.monthlyInvestment}
							data-module="CurrencyInput" 
						/>
						<span
							className="error-message"
							style={{
								display: this.props.goalUI.goalCreate.monthlyAmountError ? '' : "none"
							}}
						>
							{this.props.goalUI.goalCreate.monthlyAmountErrorMessage}
						</span>
					</div>
				</div>
				<div 
					className="form-group"
					style={{
						display: this.props.screenUI.currentScreen === 'goalEditScreen' ? '' : 'none'
					}}
				>
					<label className="control-label" htmlFor="topUpInvestmentAmount">
						Top up
						<span className="tooltip-toggle" role="button" tabIndex="-1"></span>
					</label>
					<div className="control-wrapper">
						<input 
							type="text" 
							id="topUpInvestmentAmount" 
							name="topUpInvestmentAmount" 
							className={`form-control ${this.props.goalUI.goalCreate.topUpAmountError ? 'error-border-bottom' : ''}`}
							onChange={this.props.onTopUpChange}
							onBlur={this.props.onTopUpBlur}
							onFocus={this.props.onTopUpFocus}
							value={this.props.goalData.topUpInvestmentAmount}
						/>
						<span
							className="error-message"
							style={{
								display: this.props.goalUI.goalCreate.topUpAmountError ? '' : "none"
							}}
						>
							{this.props.goalUI.goalCreate.topUpAmountErrorMessage}
						</span>
					</div>
				</div>
				<div
					className="form-group"
					style={{
						display: this.props.screenUI.currentScreen === 'goalEditScreen' ? '' : 'none'
					}}
				>
					<label className="control-label" htmlFor="withdrawInvestmentAmount">
						Withdraw
						<span className="tooltip-toggle" role="button" tabIndex="-1"></span>
					</label>
					<div className="control-wrapper">
						<input 
							type="text" 
							id="withdrawInvestmentAmount" 
							name="withdrawInvestmentAmount" 
							className={`form-control ${this.props.goalUI.goalCreate.withdrawAmountError ? 'error-border-bottom' : ''}`}
							onChange={this.props.onWithdrawChange}
							onBlur={this.props.onWithdrawBlur}
							onFocus={this.props.onWithdrawFocus}
							value={this.props.goalData.withdrawInvestmentAmount}
						/>
						<span
							className="error-message"
							style={{
								display: this.props.goalUI.goalCreate.withdrawAmountError ? '' : "none"
							}}
						>
							{this.props.goalUI.goalCreate.withdrawAmountErrorMessage}
						</span>
					</div>
				</div>
				<div 
					className="form-group"
					style={{
						display:this.props.goalData.type === "investment" ? 'none' : ''
					}}
				>
					<label 
						className="control-label" 
						htmlFor="TargetDate"
					>
						Target Date
						<span className="tooltip-toggle" role="button" tabIndex="-1"></span>
					</label>
					<div className="control-wrapper" style={{borderBottom: 'none'}}>
					<div className="form-control-control--date datepicker-custom"/>
						<input
							type="text"
							id="TargetDate"
							name="TargetDate"
							onChange={this.onTargetDateChange}
							className={`form-control form-control--date--alt ${this.props.goalUI.goalCreate.targetDateError ? 'error-border-bottom' : ''}`}
							value={this.props.goalData.targetDate}
							data-module="DatePicker"
						/>
						<span className="icn icn--calendar-goal"/>
						<span
							className="error-message"
							style={{
								display: this.props.goalUI.goalCreate.targetDateError ? '' : "none"
							}}
						>
							{this.props.goalUI.goalCreate.targetDateErrorMessage}
						</span>
					</div>
				</div>
				<div className="form-actions p-t-r">
					<div className="form-actions__item">
						<button 
							type="button" 
							className="btn btn-primary btn-block btn-no-focus__primary" 
							id="btnCalculate"
							onClick={this.onCalculateClick}
						>
							Calculate
						</button>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(props){
	return {
		goalData:props.goalData,
		goalUI:props.goalUI,
		screenUI: props.screenUI
	}
}

GoalCreate.defaultProps = {
	type:"Create",
	onTopUpFocus: () => {},
	onTopUpBlur: () => {},
	onTopUpChange: () => {},
	onWithdrawFocus: () => {},
	onWithdrawBlur: () => {},
	onWithdrawChange: () => {},
}

export default connect(mapStateToProps,{
	handleGoalName,
	handleInitialInvestment,
	changeInitialInvestment,
	handleTargetAmount,
	changeTargetAmount,
	handleMonthlyInvestment,
	changeMonthlyInvestment,
	handleTargetDate,
	changeLanguage,
	calculateProbability
})(GoalCreate);