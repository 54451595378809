import React from "react";
import PropTypes from "prop-types";
import TabBar from "../../GoalDetail/TabBar/TabBar";
import LocalBank from "./LocalBank";
import { switchTabBar } from "../../../actions/TabBar/TabBarActions";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { connect } from "react-redux";
import withBaseLayout from "../../HoC/withBaseLayout";
import CONFIG from "../../../setting/config";
import changeLanguage from "../../../setting/languages/languages";

class BankAccount extends React.Component {
  constructor(props) {
    super(props)
    this.textStore = changeLanguage(props.language)
    this.config = {
      headerTitle: this.textStore.bankAccount.newBankAccount,
      bankName: '',
      iban: '',
      deleteButton: '',
      saveButton: this.textStore.bankAccount.saveBankAccount,
    }
    if (props.type !== "add") {
      this.config = {
        headerTitle: this.textStore.bankAccount.updateBankAccount,
        bankName: 'ZKB',
        iban: 'CH50 0048 0679 5014 3258 7',
        deleteButton: this.textStore.common.delete,
        saveButton: this.textStore.common.update,
      }
    }
  }
  componentDidMount() {
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: {
        type: "text",
        text: this.config.headerTitle,
      },
      headerRight: "",
      currentPage: "bankAccount"
    });
    this.textStore = null;
    this.routesName = CONFIG.APP_ROUTE;
    this.props.switchTabBar("Local bank");
  }
  renderSection() {
    switch (this.props.tabBarUI.currentTab) {
      case "Local bank":
        return <LocalBank language={this.props.language} config={this.config}/>;
      case "Overseas bank":
        return <div />;
      default:
        return <div />;
    }
  }
  render() {
    const tabs = ["Local bank", "Overseas bank"];
    const settings = {
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      slide: "ul",
      swipeToSlide: true,
      infinite: false
    };
    return (
      <React.Fragment>
        <section className="invest-summary" />
        <nav className="tablist">
          <TabBar tabs={tabs} settings={settings} />
        </nav>
        <section className="bank-account-tab-panel">
          {this.renderSection()}
        </section>
      </React.Fragment>
    );
  }
}

BankAccount.defaultProps = {
  type: 'add',
}

BankAccount.propTypes = {
  type: PropTypes.string,
}

const EnhancedBankAccount = withBaseLayout(BankAccount);

function mapStateToProps(props) {
  return {
    tabBarUI: props.tabBarUI
  };
}

export default connect(
  mapStateToProps,
  { switchTabBar, switchHeader }
)(EnhancedBankAccount);
