import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderButton from '../HeaderButton/HeaderButton';
import { switchTabBar, clearPreviousTab } from '../../../actions/TabBar/TabBarActions';
import { resetGoal, changeGoalStrategy } from '../../../actions/Goals/GoalActions';
import { openNavBar } from '../../../actions/AppMain/AppMainAction';
import { savePreviousScreen, clearPreviousScreen } from '../../../actions/Screen/ScreenAction';
import CONFIG from '../../../setting/config';
import changeLanguage from '../../../setting/languages/languages';
import { switchHeader } from '../../../actions/Header/HeaderActions';
import { saveVerifyOnlineStep } from '../../../actions/Onboarding/OnboardingActions';

class HeaderLeftIcon extends React.Component {
  constructor(props) {
    super(props)
    this.routesName = CONFIG.APP_ROUTE;
    this.onOpenNavBar = this.onOpenNavBar.bind(this);
    this.handleNavigateBack = this.handleNavigateBack.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.textStore = changeLanguage(props.language);
  }

  handleNavigateBack() {
    switch (this.props.currentPage) {
      case 'goal':
        this.props.history.push(this.routesName.DASHBOARD);
        this.props.switchTabBar('Overview');
        this.props.resetGoal();
        break;
      case 'goalEdit':
        this.props.history.goBack();
        this.props.resetGoal();
        break;
      case this.textStore.screen.personalParticular:
        this.props.history.push(this.routesName.ONBOARDING.INVESTMENT_PROPOSAL)
        break;
      case 'position':
        this.props.history.goBack();
        if (this.props.tabBarUI.previousTab === 'Performance') {
          return this.props.switchTabBar('Performance');
        }
        else {
          return this.props.switchTabBar('Allocation');
        }
      case 'strategyDetail':
        this.props.switchTabBar('Strategy');
        this.props.clearPreviousTab();
        this.props.history.goBack();
        break;
      case 'bankAccount':
        this.props.switchTabBar('Withdraw');
        this.props.history.goBack();
        break;
      case 'editStrategy':
        this.props.history.goBack();
        break;
      case this.textStore.screen.InvestmentProposal:
        this.props.history.push(this.routesName.ONBOARDING.GOAL_CALCULATOR)
        break;
      case 'securityTransaction':
      case 'fundingReceived':
      case 'monthlyFee':
      case 'transactionHistorySearch':
      case 'withdrawalHistory':
      case 'otp':
      case 'notification':
      case 'riskProfile':
      case 'personalInformation':
      case 'financialSituation':
      case 'SupportCategory':
      case 'SupportDetail':
      case 'MessagesSearch':
      case 'CustomerSupport':
      case 'GoalAlert':
      case 'GoalMilestone':
      case 'NewDocument':
      case 'RiskProfileExpired':
      case 'SecurityTransaction':
        this.props.history.goBack();
        break;
      case 'linkedBankAccount':
        this.props.history.push(this.routesName.SETTINGS.MAIN);
        break;
      case 'contactSupport':
        this.props.history.push('/transactionhistory')
        break;
      case 'fundingTerms':
        this.props.switchTabBar('Instructions');
        this.props.clearPreviousTab();
        this.props.history.goBack();
        break;
      case 'investmentDetail':
        this.props.history.push(this.routesName.ONBOARDING.MAIN_PAGE);
        break;
      case 'otpOnboarding':
      case 'timedout': 
      case 'riskProfiling':
        this.props.history.push(this.routesName.ONBOARDING.PERSONAL_DETAIL);
        break;
      case 'riskConfirmation':
        this.props.history.push(this.routesName.ONBOARDING.RISK_PROFILING.QUESTIONNAIRE);
        break;
      case 'leadGeneration':
        this.props.clearPreviousTab();
        this.props.history.push(this.routesName.ONBOARDING.INVESTMENT_DETAIL);
        break;
      case 'greeting':
        this.props.history.push(this.textStore.screen.complianceDeclaration);
        break;
      case 'accountDetails':
        this.props.history.push(this.routesName.SETTINGS.MAIN)
        break;
      case 'changeEmail':
      case 'changePassword':
        this.props.history.push(this.routesName.SETTINGS.ACCOUNT_DETAILS);
        break;
      case 'loading':
      case 'loadingFailed':
      case 'loadingSuccess':
        // TODO: Need to check 
        if (this.props.screenUI.previousScreen === this.textStore.screen.leadGeneration) {
          this.props.history.push(this.routesName.ONBOARDING.PERSONAL_DETAIL);
        } else if (this.props.screenUI.previousScreen === this.textStore.screen.riskProfiling) {
          this.props.history.push(this.routesName.ONBOARDING.RISK_PROFILING.QUESTIONNAIRE);
        } else if (this.props.screenUI.previousScreen === this.textStore.screen.investmentProposal) {
          this.props.history.push(this.routesName.ONBOARDING.INVESTMENT_PROPOSAL);
        } else if (this.props.screenUI.previousScreen === this.textStore.screen.personalDetail){
          this.props.history.push(this.routesName.ONBOARDING.PERSONAL_DETAIL);
        } else if (this.props.screenUI.previousScreen === this.textStore.screen.personalFinance) {
          this.props.history.push(this.routesName.ONBOARDING.PERSONAL_FINANCE.PATH)
        } else if (this.props.screenUI.previousScreen === this.textStore.screen.visitBranch
          || this.props.screenUI.previousScreen === this.textStore.screen.complianceDeclaration
          || this.props.screenUI.previousScreen === this.textStore.screen.onlineVerification) {
          this.props.history.push(this.routesName.ONBOARDING.PERSONAL_FINANCE.PATH)
        } else {
          this.props.history.goBack();
        }
        break;
      case this.textStore.screen.goalCalculator:
        this.props.history.push(this.routesName.ONBOARDING.RISK_PROFILING.QUESTIONNAIRE);
        break;
      case this.textStore.screen.personalFinance:
        this.props.history.push(this.routesName.ONBOARDING.PERSONAL_PARTICULAR);
        break;
      case this.textStore.screen.onlineVerification:
        if (this.props.onboardingData.verifyOnlineStep > 1) {
          this.props.saveVerifyOnlineStep(this.props.onboardingData.verifyOnlineStep - 1);
        } else {
          this.props.history.push({
            pathname: this.routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.SUCCESS,
            state: {
              title: this.textStore.PersonalFinance.verify.title,
              desc: this.textStore.PersonalFinance.verify.desc, 
              buttonName: this.textStore.PersonalFinance.verify.buttonName,
              footer: '',
            }
          })
        }
        break;
      case this.textStore.screen.visitBranch:
      case this.textStore.screen.complianceDeclaration:
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.SUCCESS,
          state: {
            title: this.textStore.PersonalFinance.verify.title,
            desc: this.textStore.PersonalFinance.verify.desc, 
            buttonName: this.textStore.PersonalFinance.verify.buttonName,
            footer: '',
          }
        })
        break;
      case 'resetPassword':
        this.props.history.goBack();
        break;
      default:
        this.props.switchTabBar('Overview');
        this.props.history.push(this.routesName.DASHBOARD);
        break;
    }
  }

  handleClose() {
    //TODO: Will have specs for this;
  }

  onOpenNavBar() {
    this.props.openNavBar("nav-in nav-in-left nav-open");
    this.props.clearPreviousScreen();
  }

  handleNavigateTo(url) {
    if (url) {
      this.props.history.push(url);
    }
  }

  renderHeaderLeftIcon() {
    switch (this.props.headerLeft.type) {
      case 'back-white-unclickable':
        return (
          <HeaderButton
            onClick={()=>{}}
            buttonClass="toggle-header--back-white"
            iconClass="nav-back-white-icon"
            styles={this.props.headerLeft.styles}
          />
        )
      case 'menu':
        return (
          <HeaderButton
            onClick={this.onOpenNavBar}
            buttonClass="toggle-header--nav"
            iconClass="nav-menu-icon"
          />
        )
      case 'menu-white':
        return (
          <HeaderButton
            onClick={this.onOpenNavBar}
            buttonClass="toggle-header--nav"
            iconClass="nav-menu-icon-white"
          />
        )
      case 'back':
        return (
          <HeaderButton
            onClick={this.handleNavigateBack}
            buttonClass="toggle-header--back"
            iconClass="nav-back-icon"
          />
        )
      case 'back-white':
        return (
          <HeaderButton
            onClick={this.handleNavigateBack}
            buttonClass="toggle-header--back-white"
            iconClass="nav-back-white-icon"
          />
        )
      case 'close':
        return (
          <HeaderButton
            onClick={this.handleNavigateBack}
            buttonClass="toggle-header--cancel"
            iconClass="nav-close-icon"
          />
        )
      case 'appName':
        return (
          <div className="nav__left-app-name">{this.textStore.common.classicRoboTitle}</div>
        )
      default:
        return '';
    }
  }

  render() {
    return (
      <div className="header-item header-item--toggle">
        {this.renderHeaderLeftIcon()}
      </div>
    )
  }

}

function mapStateToProps(props) {
  return {
    tabBarUI: props.tabBarUI,
    screenUI: props.screenUI,
    onboardingData: props.onboardingData
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      switchTabBar,
      resetGoal,
      clearPreviousTab,
      openNavBar,
      savePreviousScreen,
      clearPreviousScreen,
      changeGoalStrategy,
      switchHeader,
      saveVerifyOnlineStep
    }
  )(HeaderLeftIcon)
);