import React from 'react';

import './styles.less';

export default class MessagesDetail extends React.PureComponent {
  onReview = (evt) => {
    // evt.preventDefautl();

    const { onReview } = this.props;

    typeof onReview === 'function' && onReview();
  }
  
  onReply = (evt) => {
    //evt.preventDefautl();

    const { onReply } = this.props;

    typeof onReply === 'function' && onReply();
  }
  
  render() {
    const { children, data, hideReview } = this.props;

    return (
      <React.Fragment>
        <div className="messages-detail">
          <div className="messages-detail__header">
            <img src={data.icon} className="messages-detail__header-icon" />
            <div className="messages-detail__header-time">{data.time}</div>
            <div className="messages-detail__header-text">{data.text}</div>
          </div>
          <div className="messages-detail__body">
            {children}
          </div>
        </div>
        <div className="content-main__footer messages-detail__footer">
          {
            !hideReview ? (
              <button onClick={this.onReview} className="btn btn-primary btn-no-focus__primary">Review now</button>
            ) : ''
          }
          <button onClick={this.onReply} className="btn btn-primary btn-no-focus__white">Reply</button>
        </div>
      </React.Fragment>
    )
  }
}