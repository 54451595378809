import React from 'react';
import NavBar from '../NavBar/NavBar';
import HeaderLeftIcon from './HeaderLeftIcon/HeaderLeftIcon';
import HeaderMiddleIcon from './HeaderMiddleIcon/HeaderMiddleIcon';
import HeaderRightIcon from './HeaderRightIcon/HeaderRightIcon';
import { persistUser } from '../../actions/Auth/AuthActions';
import { connect } from 'react-redux';
class Headers extends React.Component {
  constructor() {
    super()
    this.state = {
      moveHeader:''
    }
    this.changeState = this.changeState.bind(this);
  }

  changeState(){
    if (this.state.moveHeader === '') {
      this.setState({moveHeader: 'nav-offcanvas-opened'})
    }
    else {
      this.setState({moveHeader: ''})
    }
  }

  componentDidMount(){
    this.props.persistUser()
  }

  render(){
    return (
      <React.Fragment>
        <header 
          className={`header-main ${this.state.moveHeader}`}
          id="HeaderMain" 
          role="banner"
        >
          <HeaderLeftIcon language={this.props.language} headerLeft={this.props.headerUI.headerLeftIcon} currentPage={this.props.headerUI.currentPage}/>
          <HeaderMiddleIcon headerMiddle={this.props.headerUI.headerMiddleIcon} />
          <HeaderRightIcon moveHeader={this.changeState} headerRight={this.props.headerUI.headerRightIcon} />
				</header>
        <NavBar moveHeader={this.changeState}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(props){
  return{
    headerUI:props.headerUI
  }
}
export default connect(mapStateToProps,{ persistUser })(Headers);