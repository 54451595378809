import React from "react";
import PropTypes from "prop-types";

class GoalFooter extends React.Component {
  renderBackButton() {
    if (this.props.renderBackButton) {
      return (
        <button
          onClick={this.props.onClickBack}
          type="button"
          value="prev"
          className="btn btn-default btn-no-focus__white"
        >
          {this.props.backButtonText}
        </button>
      );
    } else if (!this.props.nextButtonFullWidth) {
			return <div/>
		}
  }

  render() {
    return (
      <div className="content-main__footer" style={{ paddingTop: "20px" }}>
        <div className="form-actions">
          <div className="form-actions__item">
            {this.renderBackButton()}
            <button
              type="submit"
              value="next"
              className="btn btn-primary btn-no-focus__primary"
              onClick={this.props.onClickNext}
							disabled={!this.props.enableNextButton}
            >
              {this.props.nextButtonText}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

GoalFooter.defaultProps = {
  onClickNext: () => {},
	onClickBack: () => {},
	enableNextButton: true,
	renderBackButton: true,
  nextButtonFullWidth: false,
  backButtonText: "Back",
  nextButtonText: "Next",
};

GoalFooter.propTypes = {
  onClickNext: PropTypes.func.isRequired,
	onClickBack: PropTypes.func,
	enableNextButton: PropTypes.bool,
	renderBackButton: PropTypes.bool,
  nextButtonFullWidth: PropTypes.bool,
  backButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
};

export default GoalFooter;
