import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import Div100vh from 'react-div-100vh';
import { switchScreen } from '../../../actions/Screen/ScreenAction';
import changeLanguage from "../../../setting/languages/languages";
import CONFIG from "../../../setting/config";
import GoalSeclections from "../../Goals/GoalSelections/GoalSeclections";
import { getOnBoardingDefaultGoals } from "../../../actions/Goals/GoalActions"
import { scrollTo } from "../../../utils/scrollTo";


export class AvailableGoal extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.timeout = null;
    this.myRef = React.createRef();
    this.scrollToMyRef = this.scrollToMyRef.bind(this);
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: {
        type: "text",
        url: this.routesName.ONBOARDING,
        text: this.textStore.common.classicRoboTitle
      },
      headerMiddle: {},
      headerRight: {
        type: "link",
        url: this.routesName.LOG_IN,
        text: this.textStore.common.login
      },
      currentPage: "onBoardingPage"
    });
  }



  scrollToMyRef() {
    scrollTo(document.querySelector('.discover-more').offsetTop, 500)
  }

  onGoalSelect(goal) {
    this.props.selectGoal({
      goal: goal,
      selected: true
    });
  }

  render() {
    return (
      <React.Fragment>
        <Div100vh className="available-goal mask" ref={this.myRef}>
          <style>
            {`.available-goal.mask {margin-top:-60px;     transition: all 0.8s ;}`}
            </style>
          <legend className=" text-center">
            {this.textStore.common.whatIsYourInvestment}
          </legend>
          <GoalSeclections
            type='onboarding'
            defaultGoals={this.props.goalData.defaultGoals}
          />
          <div className="more-detail">
            <span onClick={this.scrollToMyRef} style={{ cursor: 'pointer' }}>
              <span> {this.textStore.common.more} <br /></span>
              <span className="icon-arrow-down list-action__link"></span>
            </span>

          </div>
        </Div100vh>
      </React.Fragment>
    );
  }
}
function mapStateToProps(props) {
  return {
    goalData: props.goalData,
  };
}

export default withRouter(
  connect(
    mapStateToProps, {
      switchScreen,
      getOnBoardingDefaultGoals,
      switchHeader
    }
  )(AvailableGoal)
);
