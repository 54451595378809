import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CONFIG from "../../setting/config";
function withAuth(WrappedComponent) {
  class WrappedComponentAuthEnhanced extends React.Component {
		constructor() {
			super();
			this.routesName = CONFIG.APP_ROUTE;
		}

    componentDidMount() {
      console.log(this.props.authData.isSignIn)

      if (!this.props.authData.isSignIn && !sessionStorage.getItem('isLogin')) {
        this.props.history.push(this.routesName.LOG_IN_APP);
      } else {
        sessionStorage.setItem('isLogin', true)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  function mapStateToProps(props) {
    return {
      authData: props.authData
    };
  }
  return withRouter(connect(mapStateToProps)(WrappedComponentAuthEnhanced));
}

export default withAuth;
