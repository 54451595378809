//suport ie 11
import 'babel-polyfill'; 
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import reducers from './reducers/index';
import firebase from 'firebase/app';
import 'moment/locale/en-gb';
import moment from 'moment';
import 'firebase/firestore'

moment.locale('en-gb')
const createStoreWithMiddleWare = applyMiddleware(ReduxThunk)(createStore);
let config = null;

if(process.env.REACT_APP_CONFIG_ENV === 'demo'){
	config = {
		apiKey: "AIzaSyA99IQjvRlUL3ZMMn51MXpxClU1DVhBfBk",
		authDomain: "cr40-demo.firebaseapp.com",
		databaseURL: "https://cr40-demo.firebaseio.com",
		projectId: "cr40-demo",
		storageBucket: "cr40-demo.appspot.com",
		messagingSenderId: "616136590501"
	}
}else {
	config = {
		apiKey: "AIzaSyDvZ8Aqx66f3KHmuKSnbfbBb7LFQGM0c3Q",
		authDomain: "cr40-14375.firebaseapp.com",
		databaseURL: "https://cr40-14375.firebaseio.com",
		projectId: "cr40-14375",
		storageBucket: "cr40-14375.appspot.com",
		messagingSenderId: "91971048895"
	}
}

firebase.initializeApp(config);
firebase.firestore().settings({
	timestampsInSnapshots: true
});




ReactDOM.render(
	<Provider store={createStoreWithMiddleWare(reducers)}>
		<App />
	</Provider>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
