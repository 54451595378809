import React from "react";
// import withBaseLayout from "../HoC/withBaseLayout";
import { withRouter } from "react-router-dom";
import changeLanguage from "../../../setting/languages/languages";
import CONFIG from "../../../setting/config";

export class DiscoverMoreSection extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.myRef = React.createRef();
    this.discoverMoreClick = this.discoverMoreClick.bind(this);
  }

  componentDidMount() {}

  scrollToMyRef = () => {
    window.scrollTo({
      top: this.myRef.offsetTop
    });
  };

  discoverMoreClick() {
    this.props.history.push(this.routesName.ONBOARDING.DISCOVER_MORE);
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-main__body discover-more">
          <div className="left">
            <div className="simple-affordable">
              <p>{this.textStore.onBoarding.simpleAffordable}</p>
            </div>
            <div className="a-digital-wealth">
              <p>{this.textStore.onBoarding.aDigitalWealth}</p>
            </div>
            <div className="z-button btn btn-default">
              <div className="text-contain">
                <span className="icn icn--play" onClick={this.discoverMoreClick}>{this.textStore.onBoarding.discoverMore}</span>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="half-dashboard-bg"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(DiscoverMoreSection);