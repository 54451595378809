export function checkBrowser(browserName) {
  switch (browserName) {
    case "safari":
      return /Safari/.test(navigator.userAgent) &&/Apple Computer/.test(navigator.vendor);
    case "chrome":
      return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    default:
      return false;
  }
}
