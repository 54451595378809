import "moment/locale/en-gb";
import { formatCurrency } from "../../../helpers/formatCurrency";

const config = {
  chart: {
    type: "solidgauge"
  },
  title: null,
  exporting: { enabled: false },
  pane: {
    center: ["50%", "85%"],
    size: "130%",
    startAngle: -90,
    endAngle: 90,
    background: {
      backgroundColor: "#a1b0fc",
      innerRadius: "75%",
      outerRadius: "100%",
      shape: "arc"
    }
  },

  tooltip: {
    enabled: false
  },

  // the value axis
  yAxis: {
    max: 100,
    min: 0,
    stops: [
      [0.1, "#4261ff"], // green
      [0.5, "#4261ff"], // yellow
      [0.9, "#4261ff"] // red
    ],
    labels: {
      enabled: false
    },
    lineWidth: 0,
    minorTickInterval: null,
    tickAmount: 2
  },

  plotOptions: {
    solidgauge: {
      innerRadius: "75%",
      dataLabels: {
        y: 5,
        borderWidth: 0,
        useHTML: true
      }
    }
  },
  credits: {
    enabled: false
  },

  series: [
    {
      data: [0],
      dataLabels: {
        formatter: function() {
          const percentage =
            (1 / this.series.yAxis.userOptions.max) *
            this.series.yAxis.dataMin *
            100;
          return `
				<div style="text-align:center">
					<span style="font-size:25px;color:black">
						${formatCurrency(percentage, 0)}%
					</span>
					<br/>
				</div>
				`;
        }
      }
    }
  ]
};
export default config;
