import { 
  SWITCH_SCREEN,
  SAVE_PREVIOUS_SCREEN,
  CLEAR_PREVIOUS_SCREEN,
} from '../../actions/Screen/ScreenActionType';


export default function(data = {
  currentScreen:'',
  previousScreen: ''
},actions){
  switch(actions.type){
    case SWITCH_SCREEN:
      return { ...data,currentScreen:actions.payload }; 
    case CLEAR_PREVIOUS_SCREEN:
      return { ...data,previousScreen: '' };
    case SAVE_PREVIOUS_SCREEN:
      return { ...data,previousScreen:actions.payload };
    default:
      return {...data};
  }
}