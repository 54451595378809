export const NAVBAR_OPEN = 'NAVBAR_OPEN'
export const NAVBAR_CLOSE = 'NAVBAR_CLOSE'
export const INVERT_LAYOUT = 'INVERT_LAYOUT'
export const LOADING_LAYOUT = 'LOADING_LAYOUT'
export const TOGGLE_LOADING = 'TOGGLE_LOADING'

export const LOAD_CTB_DATA_REQUEST = 'LOAD_CTB_DATA_REQUEST';
export const LOAD_CTB_DATA_SUCCESS = 'LOAD_CTB_DATA_SUCCESS';
export const LOAD_CTB_DATA_FAILURE = 'LOAD_CTB_DATA_FAILURE';

export const TOGGLE_ASYNC_LOADING_STATUS = 'TOGGLE_ASYNC_LOADING_STATUS';