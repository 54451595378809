import React from 'react';
import ReactHighChart from 'react-highcharts';
import { formatCurrency, unformatCurrency } from '../../../helpers/formatCurrency';


class AllocationPieChart extends React.Component {
	constructor(){
		super()
		this.pieChart = React.createRef();
	}
	renderLegends(){
		const data = [
			{
				"color": "#E64F97",
				"name": "Fixed income",
				"percentage": (this.props.allocations.fixedIncomeAllocation * 100).toFixed(2),
				"value": this.props.allocations.fixedIncomeValue
			}, {
				"color": "#5AB1FB",
				"name": "Equity",
				"percentage":  (this.props.allocations.equityAllocation * 100).toFixed(2) ,
				"value": this.props.allocations.equityValue
			}, {
				"color": "#6D41B8",
				"name": "Non-traditional",
				"percentage": (this.props.allocations.nonTraditionalAllocation * 100).toFixed(2),
				"value": this.props.allocations.nonTraditionalValue
			}
		]

		return data.map((value, index) => {
			return (
				<tr key={value.name}
					style={{
						display: unformatCurrency(value.percentage) > 0 ? '' : 'none',
						border: index === 0 ? 'none' : '',
					}}
				>
					<td className="cell-dot">
						<span 
							className="dot" 
							style={{backgroundColor:value.color}}>
						</span>
					</td>
					<td className="th cell-default cell-truncate">
						<span>{value.name}</span>
					</td>
					<td 
						className="text-right"
						style={{
							fontFamily:"OpenSans",
							fontWeight:"normal",
							fontSize:"15px"
						}}
					>
						{value.value ? formatCurrency(value.value): value.value}
					</td>
					<td className="cell-percentage text-right" style={{width: '20%'}}>
						{value.percentage}%
					</td>
				</tr>
			)
		})
		
	}
	render(){
		return(
			<section className="tabpanel">
				<div className="chart chart--pie">
					<div className="chart__element__container">
						<div className="chart__element__outer">
							<div className="chart__element__inner">
								<ReactHighChart
									config={this.props.allocations.pieChartConfig}
									domProps={{className:"chart__element"}} 
								/>
							</div>
						</div>
					</div>
					<div className="chart__legend">
						<table className="table-listing"
							style={{
								width:"100%",
							}}
						>
							<tbody>
								{this.renderLegends()}
							</tbody>
						</table>
					</div>
				</div>
			</section>
		)
	}
}



export default AllocationPieChart