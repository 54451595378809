import { findFirstNoAnswer } from './utils';

import {
  FETCH_QUESTIONS_DATA_SUCCESS,
  FETCH_QUESTIONS_DATA_FAILURE,

  UPDATE_EXPAND_QUESTION_INDEX,

  UPDATE_ANSWERED_INDEXES,

  RESET_QUESTIONNAIRES_STORE,
} from './constants';

const initialState = {
  answers: {},
  questions: [],
  questionnaires: {},
  groupQuestions: {},
  expandID: 0,
  answeredIndexes: [],
  isScroll: false,
};

function QuestionnairesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_QUESTIONS_DATA_SUCCESS:
      return {
        ...state,
        answers: { ...state.answers, ...action.payload.answers },
        questions: [...state.questions, ...action.payload.questions],
        questionnaires: action.payload.questionnaires,
        groupQuestions: action.payload.groupQuestions,
        expandID: action.payload.expandID,
      };

    case RESET_QUESTIONNAIRES_STORE:
    case FETCH_QUESTIONS_DATA_FAILURE:
      return { 
        ...state, 
        answers: {},
        questions: [], 
        questionnaires: {}, 
        groupQuestions: {}, 
        expandID: 0,
        answeredIndexes: [],
      };

    case UPDATE_EXPAND_QUESTION_INDEX:
      return { 
        ...state, 
        expandID: action.expandID !== -1 ? action.expandID : findFirstNoAnswer(state.answers, state.expandID),
        isScroll: action.expandID === -1
      };

    case UPDATE_ANSWERED_INDEXES:
      const answers = { ...state.answers, ...action.payload.answer };
      
      return {
        ...state,
        answers,
        isScroll: true,
        expandID: findFirstNoAnswer(answers),
        answeredIndexes: [...new Set([...state.answeredIndexes, action.payload.expandID])],
      };

    default:
      return state;
  }
}

export default QuestionnairesReducer;
