import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";

import Tabs from '../../common/Tabs';
import MessageList from '../MessageList';
import LoadMoreButton from '../../common/LoadMoreButton';

import { messages } from '../data';

import './styles.less';

class Messages extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      openConfirm: false,
      openSearchForm: false,
      searchStr: '',
    }
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'menu',
      headerMiddle: { type: 'text', text: 'Messages' },
      headerRight: { type: "search", onOpenSearch: this.onOpenSearchForm },
      currentPage: 'Messages',
    })
  }

  onOpenSearchForm = () => {
    this.props.history.push('/messages/search');
  }

  onSearch = (searchStr) => {
    this.setState({ searchStr });
  }

  render() {
    const tabs = [
      { 
        id: 'inbox', 
        header: 'Inbox', 
        comp: () => <MessageList messages={messages} />
      }, { 
        id: 'sent', 
        header: 'Sent', 
        comp: () => <MessageList messages={messages} />
      }, { 
        id: 'archived', 
        header: 'Archived', 
        comp: () => <MessageList messages={messages} />
      }
    ];

    return (
      <div className="messages">
        <Tabs tabs={tabs} headerFluid="true" defaultTab="inbox" />
        <LoadMoreButton />
      </div>
    )
  }
}

export default connect(null, { switchHeader })(withBaseLayout(withRouter(Messages)));