import React from 'react';
import withBaseLayout from '../HoC/withBaseLayout';
import { connect } from 'react-redux';
import { signIn } from '../../actions/Auth/AuthActions';
import { switchHeader } from '../../actions/Header/HeaderActions';
import { Redirect } from 'react-router-dom';
import CONFIG from '../../setting/config';

//subject to change when integrate with dfs4
class FirstAuth extends React.Component {
	constructor(){
		super()
		this.state = {
			username:'',
			password:''
		}
		this.onUserNameChange = this.onUserNameChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	componentDidMount(){
    this.props.switchHeader({
      headerLeft:'',
      headerMiddle: {
        type:'',
        text:''
      },
			headerRight:'',
			currentPage:'',
		});
	}

	onUserNameChange(e){
		this.setState({
			username:e.target.value
		});
	}

	onPasswordChange(e){
		this.setState({
			password:e.target.value
		});
	}

	onFormSubmit(e){
		e.preventDefault();
		this.props.signIn(this.state.username,this.state.password);
	}

	render(){
		if(this.props.authData.isSignIn){
			return (
				<Redirect  to={CONFIG.APP_ROUTE.ONBOARDING.MAIN_PAGE}/>
			)
		}

		return (
			<form 
				className="content-main__inner"
				onSubmit={this.onFormSubmit}
			>
				<div className="content-main__body content-main__body--centered">
					<img 
						src="https://www.additiv.com/assets/uploads/2018/07/additiv.png" 
						alt="Advisr" 
						style={{
							width:"100px",
							marginLeft:"auto",
							marginRight:"auto",
							display:"block"
						}}
						className="m-b-lg"
					/>
					<div className="form-section">
						<div className="form-group">
							<label htmlFor="username">Username</label>
							<div className="control-wrapper">
								<input 
									style={{
										boxShadow:'none',
										borderBottom: "1px",
										borderBottomStyle:"solid",
										borderBottomColor:"#4261FF"
									}}
									value={this.state.username}
									onChange={this.onUserNameChange}
									type="email" 
									name="username" 
									id="username" 
									className="form-control"
									required
								/>
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="password">Password</label>
							<div className="control-wrapper">
								<input 
									style={{
										boxShadow:'none',
										borderBottom: "1px",
										borderBottomStyle:"solid",
										borderBottomColor:"#4261FF"
									}}
									value={this.state.password}
									onChange={this.onPasswordChange}
									type="password" 
									name="password" 
									id="password" 
									className="form-control"
									required
								/>
							</div>
						</div>
					</div>
					<div 
						className="text-danger"
						style={{display: this.props.authUI.passwordError ? '' : 'none'}}
					>
						Password or Email is incorrect
					</div>
				</div>
				<div className="content-main__footer">
					<div className="form-actions">
						<div className="form-actions__item">
							<button className="btn btn-primary btn-no-focus__primary">Login</button>
						</div>
					</div>
			</div>
		</form>
		)
	}
}	

const AuthBaseLayout = withBaseLayout(FirstAuth);


function mapStateToProps(props){
	return {
		authUI:props.authUI,
		authData:props.authData
	}
}
export default connect(mapStateToProps,{ signIn,switchHeader })(AuthBaseLayout);