import CONFIG from "../setting/config";

const monthInAYear = 12;
export function getTotalInvestmentChart(initialInvestment, monthlyInvestment, timeZone) {
  let newSeriesData = [];  
  for (let timeZoneIndex = 0; timeZoneIndex < timeZone; timeZoneIndex++) {
    let totalMonthInAYear = monthInAYear;
    // Get the last data point of the month equal to Today + Time horizon.
    if (timeZoneIndex === timeZone -1) {
      totalMonthInAYear = totalMonthInAYear + 1;
    }
    for (let monthIndex = 0; monthIndex < totalMonthInAYear; monthIndex++) {
      let seriesObj = {x: 0, y: 0}
      seriesObj.x = getMillisecondFromFirstDateOfCurrentMonth(timeZoneIndex, monthIndex);
      if (timeZoneIndex === 0 && monthIndex === 0) {
        seriesObj.y = parseInt(initialInvestment)  ;
        newSeriesData.push(seriesObj);
        continue;
      }
      seriesObj.y = newSeriesData[timeZoneIndex*monthInAYear + monthIndex - 1].y + parseInt(monthlyInvestment);
      newSeriesData.push(seriesObj);
    }
  }
  return newSeriesData;
}

// Past AUM*(1+Current Return+(1*Standard Dev((Month Number/12)^0.5)))
export function getTwoPlusDeviationChart(initialInvestment, monthlyInvestment, timeZone, standardDev, listTotalBalance) {
  let newSeriesData = [];  
  for (let timeZoneIndex = 0; timeZoneIndex < timeZone; timeZoneIndex++) {
    let totalMonthInAYear = monthInAYear;
    // Get the last data point of the month equal to Today + Time horizon.
    if (timeZoneIndex === timeZone -1) {
      totalMonthInAYear = totalMonthInAYear + 1;
    }
    for (let monthIndex = 0; monthIndex < totalMonthInAYear; monthIndex++) {
      let seriesObj = {x: 0, y: 0};
      seriesObj.x = getMillisecondFromFirstDateOfCurrentMonth(timeZoneIndex, monthIndex);
      if (timeZoneIndex === 0 && monthIndex === 0){
        seriesObj.y = initialInvestment;
        newSeriesData.push(seriesObj);
        continue;
      } 
      let indexCurrentTotalBalance = timeZoneIndex*monthInAYear + monthIndex; // (timezone - 1)*12 + index
      let currentTotalBalance = listTotalBalance[indexCurrentTotalBalance].y;
      let J27 = currentTotalBalance/initialInvestment - 1;
      let value = initialInvestment * (1 + J27 + (2 * standardDev * Math.pow((indexCurrentTotalBalance/12), 0.5)));
      seriesObj.y = value;
      newSeriesData.push(seriesObj);
    }
  }
  return newSeriesData;
}

// Past AUM*(1+Current Return-(2*Standard Dev((Month Number/12)^0.5)))
export function getTwoMinusDeviationChart(initialInvestment, monthlyInvestment, timeZone, standardDev, listTotalBalance) {
  let newSeriesData = [];  
  for (let timeZoneIndex = 0; timeZoneIndex < timeZone; timeZoneIndex++) {
    let totalMonthInAYear = monthInAYear;
    // Get the last data point of the month equal to Today + Time horizon.
    if (timeZoneIndex === timeZone -1) {
      totalMonthInAYear = totalMonthInAYear + 1;
    }
    for (let monthIndex = 0; monthIndex < totalMonthInAYear; monthIndex++) {
      let seriesObj = {x: 0, y: 0};
      seriesObj.x = getMillisecondFromFirstDateOfCurrentMonth(timeZoneIndex, monthIndex);
      if (timeZoneIndex === 0 && monthIndex === 0){
        seriesObj.y = initialInvestment;
        newSeriesData.push(seriesObj);
        continue;
      } 
      let indexCurrentTotalBalance = timeZoneIndex*monthInAYear + monthIndex; // (timezone - 1)*12 + index
      let currentTotalBalance = listTotalBalance[indexCurrentTotalBalance].y;
      let J27 = currentTotalBalance/initialInvestment - 1;
      let value = initialInvestment * (1 + J27 - (2 * standardDev * Math.pow((indexCurrentTotalBalance/12), 0.5)));
      seriesObj.y = value;
      newSeriesData.push(seriesObj);
    }
  }
  return newSeriesData;
}

//Past AUM*(1+Current Return+(1*Standard Dev((Month Number/12)^0.5)))
export function getOnePlusDeviationChart(initialInvestment, monthlyInvestment, timeZone, standardDev, listTotalBalance) {
  let newSeriesData = [];  
  for (let timeZoneIndex = 0; timeZoneIndex < timeZone; timeZoneIndex++) {
    let totalMonthInAYear = monthInAYear;
    // Get the last data point of the month equal to Today + Time horizon.
    if (timeZoneIndex === timeZone -1) {
      totalMonthInAYear = totalMonthInAYear + 1;
    }
    for (let monthIndex = 0; monthIndex < totalMonthInAYear; monthIndex++) {
      let seriesObj = {x: 0, y: 0};
      seriesObj.x = getMillisecondFromFirstDateOfCurrentMonth(timeZoneIndex, monthIndex);
      if (timeZoneIndex === 0 && monthIndex === 0){
        seriesObj.y = initialInvestment;
        newSeriesData.push(seriesObj);
        continue;
      } 
      let indexCurrentTotalBalance = timeZoneIndex*monthInAYear + monthIndex; // (timezone - 1)*12 + index
      let currentTotalBalance = listTotalBalance[indexCurrentTotalBalance].y;
      let J27 = currentTotalBalance/initialInvestment - 1;
      let value = initialInvestment * (1 + J27 + (1 * standardDev * Math.pow((indexCurrentTotalBalance/12), 0.5)));
      seriesObj.y = value;
      newSeriesData.push(seriesObj);
    }
  }
  return newSeriesData;
}


//Past AUM*(1+Current Return-(1*Standard Dev((Month Number/12)^0.5)))
export function getOneMinusDeviationChart(initialInvestment, monthlyInvestment, timeZone, standardDev, listTotalBalance) {
  let newSeriesData = [];  
  for (let timeZoneIndex = 0; timeZoneIndex < timeZone; timeZoneIndex++) {
    let totalMonthInAYear = monthInAYear;
    // Get the last data point of the month equal to Today + Time horizon.
    if (timeZoneIndex === timeZone -1) {
      totalMonthInAYear = totalMonthInAYear + 1;
    }
    for (let monthIndex = 0; monthIndex < totalMonthInAYear; monthIndex++) {
      let seriesObj = {x: 0, y: 0};
      seriesObj.x = getMillisecondFromFirstDateOfCurrentMonth(timeZoneIndex, monthIndex);
      if (timeZoneIndex === 0 && monthIndex === 0){
        seriesObj.y = initialInvestment;
        newSeriesData.push(seriesObj);
        continue;
      } 
      let indexCurrentTotalBalance = timeZoneIndex*monthInAYear + monthIndex; // (timezone - 1)*12 + index
      let currentTotalBalance = listTotalBalance[indexCurrentTotalBalance].y;
      let J27 = currentTotalBalance/initialInvestment - 1;
      let value = initialInvestment * (1 + J27 - (1 * standardDev * Math.pow((indexCurrentTotalBalance/12), 0.5)));
      seriesObj.y = value;
      newSeriesData.push(seriesObj);
    }
  }
  return newSeriesData;
}

export function getTotalBalanceChart(initialInvestment, monthlyInvestment, timeZone, expReturnValue) {
  let expReturn = getExpReturn(expReturnValue);
  let newSeriesData = [];  
  for (let timeZoneIndex = 0; timeZoneIndex < timeZone; timeZoneIndex++) {
    let totalMonthInAYear = monthInAYear;
    // Get the last data point of the month equal to Today + Time horizon.
    if (timeZoneIndex === timeZone -1) {
      totalMonthInAYear = totalMonthInAYear + 1;
    }
    for (let monthIndex = 0; monthIndex < totalMonthInAYear; monthIndex++) {
      let seriesObj = {x: 0, y: 0};
      seriesObj.x = getMillisecondFromFirstDateOfCurrentMonth(timeZoneIndex, monthIndex);
      if (timeZoneIndex === 0 && monthIndex === 0){
        seriesObj.y = initialInvestment;
        newSeriesData.push(seriesObj)
        continue;
      } 
      let totalInvestmentBefore = 0;  
      totalInvestmentBefore = newSeriesData[(timeZoneIndex * monthInAYear) + monthIndex - 1].y;  
      let returnExcel_H = parseFloat(expReturn * totalInvestmentBefore).toFixed(2);
      let value = getTotalBalance(totalInvestmentBefore, parseInt(monthlyInvestment), parseInt(returnExcel_H), 0)
      seriesObj.y = value;
      newSeriesData.push(seriesObj)
    }
  }
  return newSeriesData;
}

function getMillisecondFromFirstDateOfCurrentMonth(timeZone, monthly) {
  let currentDate = new Date();
  let currentMonth = (currentDate.getMonth() + 1) + monthly; // Current month index + month start until 12 months
  let currentYear = currentDate.getFullYear() + timeZone;

  if (currentMonth > monthInAYear) {
    currentMonth = currentMonth - monthInAYear;
    currentYear = currentYear + 1;
  }
  let date = currentDate.getDate();
  let strMMDDYYYY = currentMonth + `/${date}/` + currentYear;
  let millisecond = new Date(strMMDDYYYY) - new Date('1/1/1970');
  return millisecond;
}

export function getLastestValueOfBoarding(arr) {
  return arr[arr.length -1].y;
}

export function getExpReturn(value) {
  return Math.pow((value) + 1, 1/monthInAYear) - 1;
}

export function getTotalBalance(totalInvestmentBefore, monthlyChange, expReturn, yearlyFee) {
  return (totalInvestmentBefore + parseInt(monthlyChange) + expReturn) + (1 - yearlyFee/monthInAYear);
}