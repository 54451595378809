import React from 'react';
import angleBoldRight from '../../../css/images/icons/arrow-list.svg';
import { Link } from 'react-router-dom';

import './styles.less';

export default class SupportItem extends React.PureComponent {
  render() {
    const { text, link } = this.props;
    return (
      <Link to={link} className="support-item">
        <span className="support-item__text">{text}</span>
        <img className="support-item__go-icon" src={angleBoldRight} alt="goto" />
      </Link>
    )
  }
}