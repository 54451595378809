export const FIREBASE_INFO = {
  questionnaires: {
    collection: 'Compliance_GetQuestionnaires',
    doc: '2',
  }
};

export const FETCH_GROUP_QUESTION_PATH = '/getQuestions';


// Fetch Questions data
export const FETCH_QUESTIONS_DATA_SUCCESS = 'FETCH_QUESTIONS_DATA_SUCCESS';

export const FETCH_QUESTIONS_DATA_FAILURE = 'FETCH_QUESTIONS_DATA_FAILURE';


// Fetch Questionnaires data
export const FETCH_QUESTIONNAIRES_SUCCESS = 'FETCH_QUESTIONNAIRES_SUCCESS';

export const FETCH_QUESTIONNAIRES_FAILURE = 'FETCH_QUESTIONNAIRES_FAILURE';


// Fetch Group data
export const FETCH_GROUP_QUESTION_SUCCESS = 'FETCH_GROUP_QUESTION_SUCCESS';

export const FETCH_GROUP_QUESTION_FAILURE = 'FETCH_GROUP_QUESTION_FAILURE';


// Update expand Question Index
export const UPDATE_EXPAND_QUESTION_INDEX = 'UPDATE_EXPAND_QUESTION_INDEX';


// Update Answer question list
export const UPDATE_ANSWERED_INDEXES = 'UPDATE_ANSWERED_INDEXES';


// Update Answer question list
export const RESET_QUESTIONNAIRES_STORE = 'RESET_QUESTIONNAIRES_STORE';
