import { 
  SWITCH_TAB_BAR,
  SAVE_PREVIOUS_TAB,
  SET_TABBAR_REF,
  CLEAR_PREVIOUS_TAB,
} from '../../actions/TabBar/TabBarActionType';


export default function(data = {
  currentTab:'Overview',
  previousTab: '',
  tabBarRef:null
},actions){
  switch(actions.type){
    case SWITCH_TAB_BAR:
      return { ...data,currentTab:actions.payload }; 
    case SAVE_PREVIOUS_TAB:
      return { ...data,previousTab:actions.payload };
    case CLEAR_PREVIOUS_TAB:
      return { ...data,previousTab:'' };
    case SET_TABBAR_REF:
      return { ...data,tabBarRef:actions.payload };
    default:
      return data;
  }
}