import React from 'react';
import { connect } from 'react-redux';
import HeaderButton from '../HeaderButton/HeaderButton';
import { openNavBar } from '../../../actions/AppMain/AppMainAction';
import { switchHeader } from '../../../actions/Header/HeaderActions';
import {withRouter} from 'react-router-dom';
import { savePreviousScreen } from '../../../actions/Screen/ScreenAction';
import CONFIG from '../../../setting/config';


class HeaderRightIcon extends React.Component {
  constructor(){
    super()
    this.routesName = CONFIG.APP_ROUTE;
    this.onOpenOptions = this.onOpenOptions.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.onNotificationClick = this.onNotificationClick.bind(this);
  }

  onOpenOptions(){
    this.props.openNavBar("nav-in nav-in-top nav-open");
    this.props.moveHeader();
  }

  onSearchClick() {
    const { onOpenSearch } = this.props.headerRight;

    if(typeof onOpenSearch === 'function') {
      onOpenSearch();
      return;
    } 

    if (this.props.headerRight.type) {
      this.props.savePreviousScreen(this.props.screenUI.currentScreen);
      this.props.switchHeader({headerLeft: 'back', headerMiddle: { type: 'text', text: 'Search'}, currentPage: "transactionHistorySearch"})
      this.props.history.push(this.routesName.TRANSACTION_HISTORY.SEARCH);
    }
  }

  onSendClick() {
    this.props.history.push(this.routesName.MESSAGE_SUCCESS)
  }

  onNotificationClick() {
    this.props.history.push(this.routesName.IN_PROGRESS)
  }

  renderNotification() {
    return (
      <span 
        className="notification-indicator"
        style={{
          display: this.props.dashBoardData.unreadMessage === 0 ? 'none' : ''
        }}
      >
        <span 
          style={{
            position:"absolute",
            left: this.props.dashBoardData.unreadMessage > 9 ? "2px" : "5px",
            fontSize:"12px",
          }}
        >
          {this.props.dashBoardData.unreadMessage > 9 ? '9+' : this.props.dashBoardData.unreadMessage}
        </span>
      </span>
    )
  }

  renderHeaderRightIcon(){
    switch(this.props.headerRight.type){
      case 'search':
        return(
          <HeaderButton
            onClick={this.onSearchClick}
            buttonClass="toggle-header--search"
            iconClass="icn--search"
          />
        )
      case 'menu':
        return(
          <HeaderButton
            onClick={this.onOpenOptions}
            buttonClass="toggle-header--more"
            iconClass="icn--menu-toggle"
          />
        )
      case 'notification':
        return(
          <HeaderButton
            onClick={this.onNotificationClick}
            buttonClass="toggle-header--notification"
            iconClass="icn--notification"
            renderNotification={this.renderNotification()} 
          />
        )
      case 'send':
        return(
          <HeaderButton
            onClick={this.onSendClick}
            buttonClass="toggle-header--send"
            iconClass="icn--send"
            text="Send"
          />
        )
      case 'link':
        return (
          <span
            className="header-onboarding-right-black"
            style={this.props.headerRight.styles}
            onClick={() =>this.props.history.push(this.props.headerRight.url)}
          >
            {this.props.headerRight.text}
          </span>
        );
      default:
        return;
    }
  }

  render(){
    return(
      <div className="header-item header-item--toggle">
        {this.renderHeaderRightIcon()}
      </div>
    )
  }
}

function mapStateToProps(props){
  return {
    dashBoardData: props.dashBoardData,
    screenUI: props.screenUI
  }
}

export default withRouter(connect(mapStateToProps, {openNavBar, switchHeader, savePreviousScreen})(HeaderRightIcon));