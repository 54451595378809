import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import Slider from 'react-slick';
import { switchScreen } from '../../../actions/Screen/ScreenAction';
import changeLanguage from "../../../setting/languages/languages";
import CONFIG from "../../../setting/config";
import StatsSection from "../../Goals/StatsSection/StatsSection";
import AllocationPieChart from '../../GoalDetail/AllocationSection/AllocationPieChart';
import AllocationRegions from '../../GoalDetail/AllocationSection/AllocationRegions';
import AllocationCurrencies from '../../GoalDetail/AllocationSection/AllocationCurrencies';
import { getGoalDetail } from '../../../actions/GoalDetail/GoalDetailActions';
import { calculateProbability, getDefaultGoalsStrategy } from '../../../actions/Goals/GoalActions';
import { passDataFromPerformanceGoalDetail } from '../../../actions/Positions/PostionActions';
import { scrollTo } from "../../../utils/scrollTo";

export class GoalPlanning extends React.Component {
	constructor(props) {
		super(props);
		this.textStore = changeLanguage(props.language);
		this.routesName = CONFIG.APP_ROUTE;
		this.timeout = null;
		this.onStartInvestingClick = this.onStartInvestingClick.bind(this);
		this.sliderRef = React.createRef();
		this.state = { 
			currentIndex: 0,
		}
	}

	componentDidMount() {
		window.addEventListener('touchstart', this.touchStart);
		window.addEventListener('touchmove', this.preventTouch, { passive: false });
	}

	touchStart(e) {
		this.firstClientX = e.touches[0].clientX;
		this.firstClientY = e.touches[0].clientY;
	}

	preventTouch(e) {
		const minValue = 5; // threshold
		this.clientX = e.touches[0].clientX - this.firstClientX;
		this.clientY = e.touches[0].clientY - this.firstClientY;

		// Vertical scrolling does not work when you start swiping horizontally.
		if (Math.abs(this.clientX) > minValue) {
			e.defaultPrevented && e.preventDefault();
			e.returnValue = false;
			return false;
		}
	}

	componentWillUnmount() {
		window.removeEventListener('touchstart', this.touchStart);
		window.removeEventListener('touchmove', this.preventTouch, { passive: false });
	}

	renderTitle() {
		return this.textStore.onBoarding.listTitleSlider[this.state.currentIndex];
	}

	onStartInvestingClick() {
		scrollTo(0, 1000)
	}

	render() {
		const settings = {
			speed: 500,
			slidesToScroll: 1,
			swipeToSlide: true,
			arrows: false,
			dots: true,
			infinite: false,
			useTransform: false,
			afterChange: ((index) => {
				this.setState({
					currentIndex: index
				});
			})
		};

		return (
			<React.Fragment>
				<div className="row-title borderless-top">{this.renderTitle()}</div>
				<Slider ref={this.sliderRef} {...settings}>
					<StatsSection
						textStore={this.textStore}
						screen={this.textStore.screen.onBoarding}
						statusErrorDisplay={this.props.goalUI.statusErrorDisplay}
						planning={this.props.goalData.planning}
					/>
					<AllocationPieChart allocations={this.props.goalData.strategy.allocation} />
					<AllocationRegions regions={this.props.goalData.strategy.allocation.regions} />
					<AllocationCurrencies />
				</Slider>
				<div className="content-main__footer">
					<div className="form-actions p-t-sm">
						<div className="form-actions__item">
							<input
								className="btn btn-primary btn-no-focus__primary"
								type="button"
								value={this.textStore.onBoarding.startInvesting}
								onClick={this.onStartInvestingClick}
							/>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

function mapStateToProps(props) {
	return {
		goalUI: props.goalUI,
		tabBarUI: props.tabBarUI,
		goalData: props.goalData,
		goalDetailData: props.goalDetailData,
	};
}

export default withRouter(
	connect(
		mapStateToProps, {
			switchScreen,
			switchHeader,
			getGoalDetail,
			calculateProbability,
			passDataFromPerformanceGoalDetail,
			getDefaultGoalsStrategy
		}
	)(GoalPlanning)
);
