import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Stepper from './Stepper/Stepper';
import { switchHeader } from "../../../actions/Header/HeaderActions";
import CONFIG from '../../../setting/config';
import withBaseLayout from "../../HoC/withBaseLayout";
import changeLanguage from "../../../setting/languages/languages";
import { getCTB, getCalculateRisk, getTestData } from "../../../actions/Onboarding/OnboardingActions";
import QuestionnairesPage from "../containers/QuestionnairesPage";
import { savePreviousScreen } from "../../../actions/Screen/ScreenAction";

import { toggleAsyncLoadingStatus } from '../../../actions/AppMain/AppMainAction';

class RiskProfilingPage extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.state = {
      activeStep: 3,
      totalSteps: 8
    }
    this.routesName = CONFIG.APP_ROUTE;
    this.onNextRiskScore = this.onNextRiskScore.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text:  this.textStore.common.classicRoboTitle },
      headerRight: '',
      currentPage: 'riskProfiling',
    })
  }

  componentWillMount() {
    this.props.savePreviousScreen(this.textStore.screen.riskProfiling);
  }

  onNextRiskScore() {
    this.props.getCalculateRisk()
    this.props.getTestData()
    
    // this.props.history.push(this.routesName.ONBOARDING.RISK_PROFILING.RISK_SCORE)
    this.props.toggleAsyncLoadingStatus({ status: true, text: this.textStore.common.requesting });
    let timerSubmit = window.setTimeout(() => {
      window.clearTimeout(timerSubmit);
      this.props.toggleAsyncLoadingStatus({ status: false, text: this.textStore.common.requesting });

      if (this.props.responsePostQuestionnaire === 504 || this.props.responseComplianceGetCalculateRisk === 504) {
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.REQUEST.TIMED_OUT,
          state: {
            title: this.textStore.riskProfiling.riskProfilingTimeout,
            desc: this.textStore.riskProfiling.youHadExceed,
            buttonName: this.textStore.common.tryAgain,
          }
        });
        return;
      }
      if (this.props.responsePostQuestionnaire !== 200 || this.props.responseComplianceGetCalculateRisk !== 200) {
        this.props.history.push({
          pathname: this.routesName.routesName.ONBOARDING.REQUEST.FAILED,
          state: {
            title: this.textStore.riskProfiling.riskProfilingFailed,
              desc: this.textStore.riskProfiling.yourRiskProfilingAssessmentFailed,
              buttonName: this.textStore.common.tryAgain,
          }
        });
        return;
      } 
      this.props.history.push({
        pathname: this.routesName.ONBOARDING.RISK_PROFILING.RISK_SCORE,
        state: {}
      });
    }, 1000);
  }
  
  render() {
    return (
      <React.Fragment>
        <Stepper 
            activeStep={this.state.activeStep} 
            totalSteps={this.state.totalSteps}
            textStore={this.textStore} 
            headerLeft={this.textStore.riskProfiling.riskProfilingLabel}
            />
        <label className="description risk-profiling">
          {this.textStore.riskProfiling.description}
        </label>
        <QuestionnairesPage />
        <section className="content-main__footer">
          <div className="form-actions p-t-sm">
            <div className="form-actions__item">
              <button
                disabled={
                  this.props.questions.length === 0 ||
                  this.props.answeredIndexes.length !==
                    this.props.questions.length
                }
                className="btn btn-primary btn-no-focus__primary"
                onClick={this.onNextRiskScore}
              >
                {this.textStore.common.next}
              </button>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ 
  goalData, 
  appMainUI, 
  questionnaires: { answeredIndexes, questions },
  onboardingData: { testParams: { responsePostQuestionnaire, responseComplianceGetCalculateRisk }}
}) => ({
  questions,
  goalData,
  appMainUI,
  answeredIndexes,
  responsePostQuestionnaire,
  responseComplianceGetCalculateRisk,
});

const RiskProfilingContainer = withBaseLayout(RiskProfilingPage);

export default withRouter(
  connect(
    mapStateToProps,
    { switchHeader, getCTB, getCalculateRisk, getTestData, savePreviousScreen, toggleAsyncLoadingStatus }
  )(RiskProfilingContainer)
);