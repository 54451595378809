import React from 'react';
import withBaseLayout from "../../HoC/withBaseLayout";

import changeLanguage from "../../../setting/languages/languages";
import CONFIG from '../../../setting/config';

import Stepper from '../RiskProfiling/Stepper/Stepper';
import CustomDropdown from '../../common/CustomDropdown';
import CommonRadioGroup from './CommonRadio';

import { formatDataDropdown } from '../../../utils/formatDataDropdown';

import './styles.less';

const exposedRadios = [
  { value: 'Y', text: 'Yes' },
  { value: 'N', text: 'No' },
];

class PersonalFinance extends React.PureComponent {
  constructor(props) {
    super(props);
    
    this.textStore = changeLanguage(props.language);

    this.state = {
      activeStep: 6,
      totalSteps: 8,
      serialize: {
        annualIncome: props.formData.annualIncome || '',
        totalWealth: props.formData.totalWealth || '',
        originOfInvestment: props.formData.originOfInvestment || '',
        pep: props.formData.pep || 'N',
        beneficalOwner: props.formData.beneficalOwner || 'N',
      }
    };
    this.routesName = CONFIG.APP_ROUTE;
  }

  componentWillMount() {
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text:  this.textStore.common.classicRoboTitle },
      headerRight: '',
      currentPage: this.textStore.screen.personalFinance,
    });
    this.props.savePreviousScreen(this.textStore.screen.personalFinance);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.props.savePreviousScreen(this.textStore.screen.personalFinance);
  }

  onChangeRadio = ({ name, value }) => {
    this.setState(({ serialize }) => ({
      serialize: { ...serialize, [name]: value }
    }))
  }

  onSelectDropdown = ({ name, value }) => {
    this.setState(({ serialize }) => ({
      serialize: { ...serialize, [name]: value }
    }));
  }

  onSubmit = (evt) => {
    evt.preventDefault();

    this.props.getTestData();
    this.props.updatePersonalFinanceData(this.state.serialize);

    this.props.toggleAsyncLoadingStatus({ status: true, text: this.textStore.verification.verifying });
    let timerSubmit = window.setTimeout(() => {
      window.clearTimeout(timerSubmit);
      this.props.toggleAsyncLoadingStatus({ status: false, text: this.textStore.verification.verifying });

      if (this.props.responsePostRegistration === 504) {
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.PERSONAL_FINANCE.PATH,
          state: {}
        });
        return;
      }
      if (this.props.responsePostRegistration !== 200) {
        this.props.history.push({
          pathname: this.routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.FAILED,
          state: {
            title: this.textStore.PersonalFinance.failure.title,
            buttonName: this.textStore.PersonalFinance.failure.buttonName,
            desc: this.textStore.PersonalFinance.failure.desc,
            footer: this.textStore.PersonalFinance.failure.footer,
          }
        });
        return;
      } 
      this.props.history.push({
        pathname: this.routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.SUCCESS,
        state: {
          title: this.textStore.PersonalFinance.verify.title,
          desc: this.textStore.PersonalFinance.verify.desc, 
          buttonName: this.textStore.PersonalFinance.verify.buttonName,
          footer: '',
          routerName: this.routesName.ONBOARDING.PERSONAL_FINANCE.VERIFY.SUCCESS,
        }
      });
    }, 1000);
  }

  render() {
    const { TotalWealth, YearlyIncome, OriginOfInvestment } = this.props;
    const { 
      serialize: { 
        originOfInvestment, 
        totalWealth, 
        annualIncome,
        pep,
        beneficalOwner,
      }
    } = this.state;
    
    return (
      <React.Fragment>
        <Stepper 
          activeStep={this.state.activeStep} 
          totalSteps={this.state.totalSteps}
          textStore={this.textStore} 
          headerLeft={this.textStore.PersonalFinance.PersonalFinanceLabel}
        />
        <form className="personal-finance" onSubmit={this.onSubmit}>
          {
            YearlyIncome && YearlyIncome.length ? (
              <CustomDropdown 
                className="personal-finance-dropdown"
                tooltip="true"
                name="annualIncome" 
                required="true" 
                label="Annoual income (USD)" 
                placeholder="Please select"
                defaultSelected={annualIncome}
                data={formatDataDropdown(YearlyIncome)} 
                onSelect={this.onSelectDropdown}
              />
            ) : ''
          }
          {
            TotalWealth && TotalWealth.length ? (
              <CustomDropdown 
                className="personal-finance-dropdown"
                tooltip="true"
                name="totalWealth" 
                required="true" 
                label="Total wealth (USD)" 
                placeholder="Please select"
                defaultSelected={totalWealth}
                data={formatDataDropdown(TotalWealth)} 
                onSelect={this.onSelectDropdown}
              />
            ) : ''
          }
          {
            OriginOfInvestment && OriginOfInvestment.length ? (
              <CustomDropdown 
                className="personal-finance-dropdown"
                tooltip="true"
                name="originOfInvestment" 
                required="true" 
                label="Origin of investment" 
                placeholder="Please select"
                defaultSelected={originOfInvestment}
                data={formatDataDropdown(OriginOfInvestment)} 
                onSelect={this.onSelectDropdown}
              />
            ) : ''
          }
          <div className="personal-finance-form-control">
            <span className="personal-finance-form-control__label required">
              Are you a political exposed person?
            </span>
            <span className="personal-finance-form-control__tooltip-icon"></span>
            <div className="personal-finance-form-control-radios">
              <CommonRadioGroup 
                radios={exposedRadios} 
                defaultChecked={pep || 'N'}
                name="pep" 
                onChange={this.onChangeRadio}
              />
            </div>
          </div>
          <div className="personal-finance-form-control">
            <span className="personal-finance-form-control__label required">
              Are you the benefical owner?
            </span>
            <span className="personal-finance-form-control__tooltip-icon"></span>
            <div className="personal-finance-form-control-radios">
              <CommonRadioGroup 
                radios={exposedRadios}
                defaultChecked={beneficalOwner || 'N'} 
                name="beneficalOwner"
                onChange={this.onChangeRadio}
              />
            </div>
          </div>
        </form>
        <div className="content-main__footer">
          <div className="form-actions">
            <div className="form-actions__item">
              {/* <button type="submit" className="btn btn-primary btn-no-focus__primary">Next</button> */}
              <button type="submit" onClick={this.onSubmit} disabled={!originOfInvestment || !annualIncome || !totalWealth} className="btn btn-primary btn-no-focus__primary">Next</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withBaseLayout(PersonalFinance);