export const support = [
  { id: "1", link: "/support/category/1", text: "General questions" },
  { id: "2", link: "/support/category/1", text: "Getting started" },
  { id: "3", link: "/support/category/1", text: "Managing account" },
  { id: "4", link: "/support/category/1", text: "Fees" },
  { id: "5", link: "/support/category/1", text: "Investments" },
  { id: "6", link: "/support/category/1", text: "Deposit and withdrawal" },
  { id: "7", link: "/support/category/1", text: "Returns and dividends" },
  { id: "8", link: "/support/category/1", text: "Taxes" },
  { id: "9", link: "/support/category/1", text: "Security" }
];

export const supportCategory = [
  { id: "1", link: "/support/detail/1", text: "What is goal-based investing?" },
  { id: "2", link: "/support/detail/1", text: "Do you give investment advice?" },
  { id: "3", link: "/support/detail/1", text: "How old do I have to be to open an account?" },
  { id: "4", link: "/support/detail/1", text: "Can I open a joint account?" },
  { id: "5", link: "/support/detail/1", text: "Can I open multiple accounts?" },
  { id: "6", link: "/support/detail/1", text: "Is there a minimum balance?" },
  { id: "7", link: "/support/detail/1", text: "Is there a fixed amount I need to invest?" },
  { id: "8", link: "/support/detail/1", text: "Why don't you invest in cryptocurrencies?" },
  { id: "9", link: "/support/detail/1", text: "What is rebalancing?" },
  { id: "10", link: "/support/detail/1", text: "What is re-optimisation?" }
]