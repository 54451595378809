import React from "react";
import Slider from "react-slick";
import TabBarItem from "./TabBarItem";

class TabBarProgress extends React.Component {
  render() {
    const items = this.props.tabs.map((e,id) => {
      return <TabBarItem title={e.title} currentTabItemState={e.currentTabItemState} key={id}/>;
    });
    const settings = {
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeToSlide: true,
      infinite: false
    }
    return (
      <nav className="progressbar">
        <div className="progressbar__wrapper">
          <Slider {...settings}>{items}</Slider>
        </div>
      </nav>
    );
  }
}

export default TabBarProgress;