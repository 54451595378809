import {

  FETCH_QUESTIONS_DATA_SUCCESS,
  FETCH_QUESTIONS_DATA_FAILURE,

  UPDATE_EXPAND_QUESTION_INDEX,

  UPDATE_ANSWERED_INDEXES,

  RESET_QUESTIONNAIRES_STORE,
} from './constants';


// _____________________________________________________________________________
//                                                          FETCH_QUESTIONS_DATA
export const fetchQuestionsDataSuccess = (payload) => ({
	type: FETCH_QUESTIONS_DATA_SUCCESS, payload,
});

export const fetchQuestionsDataFailure = (error) => ({
	type: FETCH_QUESTIONS_DATA_FAILURE, error,
});


// _____________________________________________________________________________
//                                                  UPDATE_EXPAND_QUESTION_INDEX
export const updateExpandQuestionIndex = (expandID) => ({
	type: UPDATE_EXPAND_QUESTION_INDEX, expandID,
});


// _____________________________________________________________________________
//                                                       UPDATE_ANSWERED_INDEXES
export const updateAnsweredIndexes = (payload) => ({
	type: UPDATE_ANSWERED_INDEXES, payload,
});


// _____________________________________________________________________________
//                                                       UPDATE_ANSWERED_INDEXES
export const resetQuestionnairesStore = () => ({
	type: RESET_QUESTIONNAIRES_STORE,
});
