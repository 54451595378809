import React from 'react';
import { connect } from 'react-redux';
import Goals from '../../Goals/Goals';
import { switchTabBar } from '../../../actions/TabBar/TabBarActions';
import { switchHeader } from '../../../actions/Header/HeaderActions';
import { handleTopUpAmount,handleWithdrawAmount, changeTopUpAmount,  changeWithdrawAmount, getConfiguration } from '../../../actions/Goals/GoalActions';
import { formatCurrency,unformatCurrency } from '../../../helpers/formatCurrency';
import { switchScreen } from '../../../actions/Screen/ScreenAction';
import changeLanguage from '../../../setting/languages/languages';

//TODO: move constant value to separate files for maintainability, refactor errors handling;
class GoalEdit extends React.Component {
	constructor(props) {
		super(props);
		this.textStore = changeLanguage(props.language);
		this.onTopUpChange = this.onTopUpChange.bind(this);
		this.onTopUpBlur = this.onTopUpBlur.bind(this);
		this.onTopUpFocus = this.onTopUpFocus.bind(this);
		this.onWithdrawBlur = this.onWithdrawBlur.bind(this);
		this.onWithdrawFocus = this.onWithdrawFocus.bind(this);
		this.onWithdrawChange = this.onWithdrawChange.bind(this);
	}

	componentDidMount(){
		this.props.switchScreen(this.textStore.screen.editGoal);
		this.props.switchHeader({
      headerLeft: "close",
      headerMiddle: { type: "text", text: "Edit Goal" },
      headerRight: "",
      currentPage: "goalEdit"
    });
		this.props.getConfiguration();
	};

	onTopUpChange(e) {
		if (e.target.value.length <= 12){
			this.props.handleTopUpAmount(e.target.value, this.props.goalData);
		}
	}
	onTopUpBlur() {
		if(!this.props.goalUI.goalCreate.topUpAmountErrorMessage){
			this.props.changeTopUpAmount(formatCurrency(this.props.goalData.topUpInvestmentAmount));
	   }
	}

	onTopUpFocus() {
		this.props.changeTopUpAmount(unformatCurrency(this.props.goalData.topUpInvestmentAmount));
	}

	onWithdrawChange(e) {
		if (e.target.value.length <= 12){
			this.props.handleWithdrawAmount(e.target.value, this.props.goalData);
		}
	}

	onWithdrawBlur() {
		if(!this.props.goalUI.goalCreate.withDrawAmountErrorMessage){
			this.props.changeWithdrawAmount(formatCurrency(this.props.goalData.withdrawInvestmentAmount));
		}
	}

	onWithdrawFocus() {
		this.props.changeWithdrawAmount(unformatCurrency(this.props.goalData.withdrawInvestmentAmount));
	}

	render(){
		return (
			<React.Fragment>
				<Goals
				  language={this.props.language}
					onTopUpChange={this.onTopUpChange}
					onWithdrawChange={this.onWithdrawChange}
					onTopUpBlur={this.onTopUpBlur} 
					onTopUpFocus={this.onTopUpFocus} 
					onWithdrawBlur={this.onWithdrawBlur} 
					onWithdrawFocus={this.onWithdrawFocus} 
					type="EditGoal" />
			</React.Fragment>
		)
	}
}

function mapStateToProps(props){
	return {
		goalData:props.goalData,
		goalUI:props.goalUI,
		screenUI: props.screenUI
	}
}


export default connect(mapStateToProps,{
	handleTopUpAmount,
	handleWithdrawAmount,
	changeTopUpAmount,
	changeWithdrawAmount,
	getConfiguration,
	switchTabBar,
	switchHeader,
	switchScreen
})(GoalEdit);
