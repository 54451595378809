import React from 'react';
import changeLanguage from '../../../setting/languages/languages';

class HeaderOnlyTitle extends React.Component {

  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
  }

  render(){
    return (
      <React.Fragment>
        <header 
          className={`header-main header-onboarding `}
          id="HeaderMain" 
          role="banner"
        >
          <div style={headerTitle} className="left">
            <span>{this.textStore.common.classicRoboTitle}</span>
          </div>
				</header>
      </React.Fragment>
    )
  }
}

const headerTitle = {
  fontSize: '14px',
};

export default HeaderOnlyTitle;