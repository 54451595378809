import React from "react";
import { withRouter } from "react-router-dom";
import withBaseLayout from "../../HoC/withBaseLayout";
import changeLanguage from "../../../setting/languages/languages";
import ExpandableView from "../../common/ExpandableView/ExpandableView";
import { invertLayout } from "../../../actions/AppMain/AppMainAction";
import { connect } from "react-redux";
import CONFIG from '../../../setting/config';
import { checkBrowser } from "../../../helpers/checkBrowser";

class DiscoverMorePage extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  onBackButtonClick() {
    this.props.history.push(this.routesName.ONBOARDING.MAIN_PAGE);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.appMainUI.layout !== "viewport layout--inverted") {
      this.props.invertLayout("viewport layout--onboarding");
    }
  }

  componentWillUnmount() {
    if (this.props.appMainUI.layout !== "viewport layout--default") {
      this.props.invertLayout("viewport layout--default");
    }
  }

  render() {
    const listData = [
      {icon: "icn--onboarding-pie-chart", text: this.textStore.discoverMore.determine},
      {icon: "icn--onboarding-bulb", text: this.textStore.discoverMore.entrust},
      {icon: "icn--onboarding-equalizer", text: this.textStore.discoverMore.modify},
      {icon: "icn--onboarding-lock", text: this.textStore.discoverMore.never},
    ]
    const collapsibleData = [
      {title: this.textStore.discoverMore.firstItemTitle, description: this.textStore.discoverMore.firstItemDescription},
      {title: this.textStore.discoverMore.secondItemTitle, description: this.textStore.discoverMore.secondItemDescription},
      {title: this.textStore.discoverMore.thirdItemTitle, description: this.textStore.discoverMore.thirdItemDescription},
    ]
    return (
      <React.Fragment>
        <div
          className="mask"
          style={{
            height: checkBrowser("safari") ? "calc(100vh + 7vh)" : ""
          }}
        >
          <div className="discover-more-page">
            <legend className="header text-center">
              {this.textStore.discoverMore.header}
            </legend>
            <div className="full-dashboard-bg" />
          </div>
        </div>
        <div
          className="contain"
          // style={{marginTop: window.screen.height >= 812 ? "10px" : ""}}
        >
          <p className="discover-more-text text-title__large">
            {this.textStore.discoverMore.theRightBalance}
          </p>
          <p
            className="discover-more-text dm-description"
            style={{ marginBottom: "20px" }}
          >
            {this.textStore.discoverMore.inThePast}
          </p>
          {listData.map((el, id) => (
            <div key={id} className="discover-list-item">
              <span className={`icn ${el.icon} discover-list-icon`} />
              <div style={{ marginLeft: "10px" }}>{el.text}</div>
            </div>
          ))}
          <p
            className="discover-more-text text-title__large"
            style={{ marginTop: "30px" }}
          >
            {this.textStore.discoverMore.riskManagement}
          </p>
          <p className="discover-more-text dm-description">
            {this.textStore.discoverMore.riskManagementDescription}
          </p>
          <div style={{ marginTop: "20px" }}>
            <ExpandableView
              data={collapsibleData}
              firstElementHasBorderTop={true}
              fixedRowHeight="47px"
            />
          </div>
          <div className="content-main__footer">
            <div className="form-actions p-t-sm">
              <div className="form-actions__item">
                <input
                  className="btn btn-primary btn-no-focus__primary"
                  type="button"
                  value="Back"
                  onClick={this.onBackButtonClick}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const EnhancedDiscoverMorePage = withBaseLayout(DiscoverMorePage)

function mapStateToProps(props){
  return {
    appMainUI:props.appMainUI,
  } 
}

export default withRouter(
  connect(
    mapStateToProps, {
      invertLayout
    }
  )(EnhancedDiscoverMorePage)
);