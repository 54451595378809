import React from 'react';

const withBaseLayout = (WrappedComponent) => {
  class BaseLayout extends React.Component {
    render(){
      return (
        <main className="content-main" id="ContentMain">
          <div className="content-main__inner">
             <WrappedComponent {...this.props}/>
          </div>
        </main>
      )
    }
  }
  return BaseLayout;
}

export default withBaseLayout