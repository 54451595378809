import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { switchHeader } from "../../../../actions/Header/HeaderActions";
import withBaseLayout from "../../../HoC/withBaseLayout";
import changeLanguage from "../../../../setting/languages/languages";
import CONFIG from "../../../../setting/config";
import { invertLayout } from "../../../../actions/AppMain/AppMainAction";
import ChartGauge from '../../../../components/Charts/ChartRange/ChartGauge';
import { getCalculateRisk } from "../../../../actions/Onboarding/OnboardingActions";
import { initiateGaugeChartBlueConfig } from "../../../../actions/Chart/ChartActions";

class RiskScore extends React.Component {
  constructor(props) {
    super(props);
    this.textStore = changeLanguage(props.language);
    this.routesName = CONFIG.APP_ROUTE;
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentWillMount() {
    this.props.initiateGaugeChartBlueConfig()
  }

  componentDidMount() {
    this.props.switchHeader({
      headerLeft: 'back-white',
      headerMiddle: { type: 'text', text: this.textStore.common.classicRoboTitle },
      headerRight: '',
      currentPage: 'riskConfirmation',
    })
    this.props.invertLayout("viewport layout--inverted");
    this.props.getCalculateRisk();
    window.scrollTo(0,0);
  }

  componentWillUnmount() {
    this.props.invertLayout("viewport layout--default");
  }

  handleConfirm() {
    this.props.history.push(this.routesName.ONBOARDING.GOAL_CALCULATOR);
  }

  render() {
    const riskCategoryName = this.props.onboardingData.calculateRisk.riskCategory.Name;
    const riskScore = this.props.onboardingData.calculateRisk.frontEndRiskMapping[riskCategoryName]
    return (
      <React.Fragment>
        <section className="risk-score-container">
          <section className="risk-score-chart">
            <ChartGauge
              data={riskScore}
              min={this.props.onboardingData.CTB.min.riskProfileScale}
              max={this.props.onboardingData.CTB.max.riskProfileScale}
            />
          </section>
          <section className="risk-score-status">
            <span>{this.textStore.riskProfiling.riskScoreStatus}
              <br />
              {riskCategoryName}
            </span>
          </section>
          <section className="risk-score-cap">
            <span>{this.textStore.riskProfiling.riskScoreCap}
            </span>
          </section>
        </section>
        <section className="content-main__footer">
          <div className="form-actions p-t-sm">
            <div className="form-actions__item">
              <button
                className="btn btn-primary btn-no-focus__primary__inverted"
                onClick={this.handleConfirm}
              >
                {this.textStore.common.confirm}
              </button>
            </div>
          </div>
          <div className="risk-score-footer-description">
            <span>
              {this.textStore.riskProfiling.riskScoreFooterFirst}
              <br />
              {this.textStore.riskProfiling.riskScoreFooterMid}
              <Link to={this.routesName.ONBOARDING.RISK_PROFILING.QUESTIONNAIRE}>
                {this.textStore.riskProfiling.reassessAnswer}
              </Link>
            </span>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(props) {
  return {
    goalData: props.goalData,
    appMainUI: props.appMainUI,
    onboardingData: props.onboardingData,
  };
}

const RiskScoreContainer = withBaseLayout(RiskScore);

export default withRouter(
  connect(
    mapStateToProps,
    { invertLayout, switchHeader, getCalculateRisk, initiateGaugeChartBlueConfig }
  )(RiskScoreContainer)
);