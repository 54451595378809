import React from 'react';
import { Radio } from 'antd';

import 'antd/lib/radio/style/index.css';
import './styles.less';

const RadioGroup = Radio.Group;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

export default class CommonRadioGroup extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      value: '',
    }
  }

  componentWillMount() {
    const { defaultChecked } = this.props;

    this.setState({ value: defaultChecked });
  }

  onChange = (evt) => {
    evt.preventDefault();

    const { value } = evt.target;
    const { onChange, name } = this.props;

    this.setState({ value }, () => {
      typeof onChange === 'function' && onChange({ name, value });
    });
  }

  render() {
    const { radios, name } = this.props;

    return (
      <RadioGroup onChange={this.onChange} value={this.state.value} name={name}>
        {
          radios.map((radio) => (
            <Radio key={radio.value} style={radioStyle} value={radio.value}>{radio.text}</Radio>
          ))
        }
      </RadioGroup>
    )
  }
}