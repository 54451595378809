import { formatCurrency } from "../../../helpers/formatCurrency";
import yourInvestment from "../../../data/your_investment.json";
import worstCase from "../../../data/worst_case.json";
import bestCase from "../../../data/best_case.json";
import expectedCase from "../../../data/expected_case.json";

const moment = require("moment");

const fontStyle = {
  color: "#B3C0FF",
  fontFamily: "OpenSans",
  fontSize: "13px"
};
const config = {
  exporting: { enabled: false },
  credits: {
    enabled: false
  },
  navigator: {
    enabled: false
  },
  scrollbar: {
    enabled: false
  },
  title: {
    enabled: false
  },
  chart: {
    style: fontStyle,
    spacing: 0,
    zoomType: '',
    pinchType: '',
    panning: false,
  },
  legend: {
    align: "center",
    enabled: true,
    itemStyle: {
      fontFamily: "OpenSans",
      fontSize: "13px",
      fontWeight: "normal"
    }
  },
  plotOptions: {
    spline: {}
  },
  useHTML: true,
  tooltip: {
    borderColor: "#4261FF",
    valueDecimals: 2,
    formatter: function() {
      if (this.y >= 1000000) {
        return `<strong>USD ${formatCurrency(this.y, 2)}</strong>
			<br/>
			${moment(this.x).format("LL")}
			`;
      }
      return `<strong>USD ${formatCurrency(this.y, 2)}</strong>
		<br/>
		${moment(this.x).format("LL")}`;
    }
  },
  xAxis: {
    lineWidth: 0,
    minorGridLineWidth: 0,
    lineColor: "transparent",
    minorTickLength: 0,
    tickLength: 0,
    type: "datetime",
    labels: {
      formatter: function() {
        if(this.isFirst){
          return 'Today';
        }
        return new Date(this.value).getFullYear();
      },
      style: fontStyle
    },
    ordinal: false,
    minPadding: 0.15,
    maxPadding: 0.02,
    tickPositioner: function() {
      let positions = [];
      positions.push(this.dataMin);
      positions.push(this.dataMin + (this.dataMax - this.dataMin) / 2);
      positions.push(this.dataMax);
      return positions;
    },
    offset: -1,
  },
  yAxis: {
    opposite: false,
    gridLineDashStyle: "dot",
    gridLineColor: "#a1b0fc",
    gridLineWidth: 1.6,
    labels: {
      formatter: function() {
        if (this.value >= 1000000) {
          return formatCurrency(this.value / 1000000, 1) + "M";
        }
        return this.value / 1000 + "K";
      },
      style: fontStyle,
      x: 20
    }
  },

  series: [
    {
      name: "Your Investment",
      data: yourInvestment,
      color: "#2c2c39"
    },
    {
      name: "Best Case",
      data: bestCase,
      color: "#4261ff"
    },
    {
      name: "Worst Case",
      data: worstCase,
      color: "#dd0f43"
    },
    {
      name: "Expected Case",
      data: expectedCase,
      color: "#a1b0fc"
    },
    {
      name: '',
      color: 'none',
      className: 'hide-text',
      data: [
      ]
    },
    {
      name:'Today Line',
      color:'#4261ff',
      data:[{"x":0,"y":0},{"x":0,"y":0}],
      showInLegend: false
    },
  ]
};
export default config;
