import React from "react";
class LoadingScreen extends React.Component {
  render() {
    return (
      <div className="loading">
          <span className="icn icn--loading"></span>
      </div>
    );
  }
}

export default LoadingScreen;
