import {
  GET_FUNDING_PLANNED,
  GET_FUNDING_INSTRUCTIONS
} from "../../../actions/Funding/FundingActionsType";

const mockData = {
  totalInitialInvestmentAmount: 0,
	totalMonthlyInvestmentAmount: 0,
  transfer: [],
  instructions: {
    accountNumber: "",
    bicSwift: "",
    bankName: "",
    currencyCode: "",
    iban: "",
    message: "",
    receiptName: "",
    initialInvestment: 0,
    monthlyInvestment: 0
  }
};

export default function(data = mockData, actions) {
  switch (actions.type) {
    case GET_FUNDING_PLANNED:
      return { ...data, 
        totalInitialInvestmentAmount: actions.payload.totalOneTimeTransfer,
        totalMonthlyInvestmentAmount: actions.payload.totalMonthlyInvestmentAmount,
        transfer: actions.payload.transfer };
    case GET_FUNDING_INSTRUCTIONS:
      return {
        ...data,
        instructions: {
          ...data.instructions,
          accountNumber: actions.payload.AccountNumber,
          bicSwift: actions.payload.BICSwift,
          bankName: actions.payload.BankName,
          currencyCode: actions.payload.CurrencyCode,
          iban: actions.payload.IBAN,
          message: actions.payload.Message,
          receiptName: actions.payload.ReceiptName,
          initialInvestment: actions.payload.initialInvestment,
          monthlyInvestment: actions.payload.monthlyInvestment
        }
      };
    default:
      return data;
  }
}
