import "firebase/firestore";
import firebase from "firebase/app";
import {
  TRANSACTION_HISTORY_OVERVIEW,
  TRANSACTION_HISTORY_WITHDRAWAL,
  TRANSACTION_HISTORY_SECURITY,
  TRANSACTION_HISTORY_FUNDING,
  TRANSACTION_HISTORY_MONTHLY_FEE
} from "./TransactionHistoryType";
const moment = require('moment');
function transactionHistoryOverview(data) {
  return {
    type: TRANSACTION_HISTORY_OVERVIEW,
    payload: data
  };
}

function transactionHistorySecurity(data) {
  return {
    type: TRANSACTION_HISTORY_SECURITY,
    payload: data
  };
}

function transactionHistoryWithdrawal(data) {
  return {
    type: TRANSACTION_HISTORY_WITHDRAWAL,
    payload: data
  };
}

function transactionHistoryFunding(data) {
  return {
    type: TRANSACTION_HISTORY_FUNDING,
    payload: data
  };
}

function transactionHistoryMonthlyFee(data) {
  return {
    type: TRANSACTION_HISTORY_MONTHLY_FEE,
    payload: data
  };
}

export function getTransactionHistoryWithDraw() {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_TransactionHistory_Detail_Withdrawal")
      .onSnapshot(snapshots => {
        dispatch(transactionHistoryWithdrawal(snapshots.docs[0].data()));
      });
  };
}

export function getTransactionHistorySecurity() {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_TransactionHistory_Detail_SecTransaction")
      .onSnapshot(snapshots => {
        dispatch(transactionHistorySecurity(snapshots.docs[0].data()));
      });
  };
}

export function getTransactionHistoryFunding() {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_TransactionHistory_Detail_Funding")
      .onSnapshot(snapshots => {
        dispatch(transactionHistoryFunding(snapshots.docs[0].data()));
      });
  };
}

export function getTransactionHistoryMonthlyFee() {
  return dispatch => {
    firebase
      .firestore()
      .collection("New_TransactionHistory_Detail_MonthlyFee")
      .onSnapshot(snapshots => {
        dispatch(transactionHistoryMonthlyFee(snapshots.docs[0].data()));
      });
  };
}
export function getTransactionHistoryOverview(offSet) {
  // Start index
  //let sIndex = startIndex > 0 ? startIndex: 0;
  //let number = !offSet ? 0: offSet; // Specs: Default loading is 10
	return (dispatch => {
		firebase.firestore().collection('New_TransactionHistory_Overview').onSnapshot(snapshots => {
            let data = snapshots.docs[0].data();
            data.Details.sort((a, b) => 
                moment(a.TransactionDate, 'DD-MM-YYYY') > moment(b.TransactionDate, 'DD-MM-YYYY') ? -1 : 1
            );
            data.offSet = offSet;
            data.Details.forEach(element => {
                switch(element.Type) {
                    case 1: // With draw
                        element['iconClass'] = 'icn--withdraw'; 
                        break;
                    case 2: // Security
                        element['iconClass'] = 'icn--security';
                        break;
                    case 3: // Funding
                        element['iconClass'] = 'icn--funding-received';
                        break;
                    case 4: // Monthly
                        element['iconClass'] = 'icn--recurring';
                        break;
                    default:
                        element['iconClass'] = 'icn--funding-received';
                        break;
                    
                }
                return element;
            });
			dispatch(transactionHistoryOverview(data))
		})
	})
}
