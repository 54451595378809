import React from "react";
import { connect } from "react-redux";
import { resetGoal } from "../../actions/Goals/GoalActions";
import { invertLayout } from "../../actions/AppMain/AppMainAction";
import { switchHeader } from "../../actions/Header/HeaderActions";

function withGenericLayout (
  WrappedComponent,
  isInverted,
  headerData,
  title,
  text,
  bodyComponent,
  footerComponent,
  icon
)  {
  class GenericLayout extends React.Component {
    componentDidMount() {
      this.props.switchHeader(headerData);
      if (isInverted) {
        if (this.props.appMainUI.layout !== "viewport layout--inverted") {
          this.props.invertLayout("viewport layout--inverted");
        }
      }
    }

    changeLayout() {

    }

    render() {
      const extraProps = {
        title: { style: title.style, text: title.text },
        text: text,
        bodyComponent: bodyComponent,
        footerComponent: footerComponent,
        icon: icon
      };
      return <WrappedComponent {...this.props} {...extraProps} />;
    }
    componentWillUnmount() {
      if (this.props.appMainUI.layout !== "viewport layout--default") {
        this.props.invertLayout("viewport layout--default");
      }
      this.props.resetGoal();
    }
  }
  function mapStateToProps(props) {
    return {
      appMainUI: props.appMainUI
    };
  }
  return connect(
    mapStateToProps,
    { invertLayout, switchHeader, resetGoal }
  )(GenericLayout);
};

export default withGenericLayout;
