import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withBaseLayout from "../../HoC/withBaseLayout";
import GenericListItem from "../../common/GenericList/GenericListItem";
import { switchHeader } from "../../../actions/Header/HeaderActions";
import { getTransactionHistoryMonthlyFee } from "../../../actions/TransactionHistory/TransactionHistoryAction";
import { formatCurrency } from "../../../helpers/formatCurrency";
import { setSupportQuestion } from "../../../actions/Support/SupportActions";
import CONFIG from "../../../setting/config";

class MonthlyFee extends React.Component {
  componentDidMount() {
    this.props.getTransactionHistoryMonthlyFee();
    this.props.switchHeader({
      headerLeft: "back",
      headerMiddle: { type: "text", text: "Monthly fee" },
      headerRight: "",
      currentPage: "monthlyFee"
    });
    this.routesName = CONFIG.APP_ROUTE;
    this.onContactClick = this.onContactClick.bind(this);
  }
  checkRenderType(type, monthlyFee) {
    let monthlyItems = [
      {
        title: "Referral discount",
        data: `${formatCurrency(monthlyFee.referralDiscount * 100)}%`
      },
      {
        title: "Monthly fee",
        data: formatCurrency(monthlyFee.calculatedTotalMonthlyFee)
      }
    ];
    let totalItems = [
      {
        title: "Portfolio management fee",
        data: `${formatCurrency(monthlyFee.portfolioManagementFee)}%`
      },
      {
        title: "Product fee",
        data: `${formatCurrency(monthlyFee.productFee)}%`
      }
    ];
    switch (type) {
      case "monthly":
        if (monthlyFee.referralDiscount !== 0) {
          return monthlyItems;
        }
        return null;
      case "total":
        return totalItems;
      default:
        return null;
    }
  }

  renderDetails(type) {
    let monthlyFee = this.props.transactionHistoryData.monthly;
    let list = null;
    list = this.checkRenderType(type, monthlyFee);
    if (list === null) return;
    return (
      <React.Fragment>
        {list.map((item, index) => (
          <GenericListItem
            key={index}
            containerStyle={{ marginLeft: "20px" }}
            left={item.title}
            right={item.data}
            hasBorder={index !== list.length - 1}
          />
        ))}
      </React.Fragment>
    );
  }
  setSupportQuestion() {
    const data = this.props.transactionHistoryData.monthly;
    this.props.setSupportQuestion({
      transactionType: "'Monthly Fee'",
      date: data.transactionDate,
      currencyAmount: data.totalMonthlyFee
    });
  }
  onContactClick() {
    this.setSupportQuestion();
    this.props.history.push(this.routesName.CONTACT_SUPPORT);
  }
  render() {
    const monthlyFee = this.props.transactionHistoryData.monthly;
    return (
      <React.Fragment>
        <GenericListItem
          containerStyle={{ padding: "0 0 18px 0" }}
          iconClass="icn--recurring"
          topLeft={monthlyFee.transactionDate}
          left={monthlyFee.transactionName}
        />
        <GenericListItem
          left="Total monthly fee"
          leftStyle={{ fontFamily: "SansSemibold" }}
          right={formatCurrency(monthlyFee.totalMonthlyFee)}
          hasBorder={false}
        />
        {this.renderDetails("monthly")}
        <GenericListItem
          containerStyle={{ padding: "16px 0 6px 0" }}
          left="Details"
          leftClass="transaction-text-left"
        />
        <GenericListItem
          left="Total AuM"
          right={formatCurrency(monthlyFee.totalAuMAmount)}
        />
        <GenericListItem
          left="Total fees"
          leftStyle={{ fontFamily: "SansSemibold" }}
          right={`${formatCurrency(monthlyFee.totalFee)}%`}
        />
        {this.renderDetails("total")}
        <div className="content-main__footer" style={{ paddingTop: "27px" }}>
          <div className="form-actions__item">
            <input
              type="button"
              className="btn btn-default"
              value="Contact support"
              onClick={this.onContactClick}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const EnhancedMonthlyFee = withBaseLayout(MonthlyFee);

function mapStateToProps(props) {
  return {
    transactionHistoryData: props.transactionHistoryData
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { switchHeader, getTransactionHistoryMonthlyFee, setSupportQuestion }
  )(EnhancedMonthlyFee)
);
