import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import withBaseLayout from "../../HoC/withBaseLayout";
import { switchHeader } from "../../../actions/Header/HeaderActions";


import './styles.less';
import CONFIG from '../../../setting/config';

class ChangePassword extends React.PureComponent {
  constructor() {
    super()
    this.routesName = CONFIG.APP_ROUTE;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.switchHeader({
      headerLeft: 'back',
      headerMiddle: { type: 'text', text: 'Change password' },
      headerRight: '',
      currentPage: 'changePassword',
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="account-change-password">
          <div className="form-group">
            <p className="control-label">Current Password</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                value="********"
                type="password"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <p className="control-label">New Password</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                type="password"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group">
            <p className="control-label">Repeat Password</p>
            <div className="control-wrapper">
              <input
                className="onboarding-input-field__full__large input-custom"
                type="password"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="form-group text-center forgot-password-link">
            <Link to="#" className="text-link-normal"> Forgot password?</Link>
          </div>
        </div>
        <div className="content-main__footer">
          <div className="form-actions">
            <div className="form-actions__item">
              <button type="submit" className="btn btn-primary btn-no-focus__primary" onClick={() => this.props.history.push(this.routesName.SETTINGS_UPDATED)}>Save</button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(connect(null, { switchHeader })(withBaseLayout(ChangePassword)));