import gaugeBlueConfig from "../../components/Charts/config/HighChartGaugeBlueConfig";
import gaugeWhiteConfig from "../../components/Charts/config/HighChartGaugeWhiteConfig";
import {
  INITIATE_GAUGE_CHART_BLUE_CONFIG,
  INITIATE_GAUGE_CHART_WHITE_CONFIG
} from "../../actions/Chart/ChartActionType";

export default function(states = gaugeBlueConfig, actions) {
  switch (actions.type) {
    case INITIATE_GAUGE_CHART_BLUE_CONFIG:
      states = gaugeBlueConfig
      return states;
    case INITIATE_GAUGE_CHART_WHITE_CONFIG:
      states = gaugeWhiteConfig
      return states;
    default:
      return states;
  }
}
